// images
import CapitalCategoryImage from '../../static/images/loan-categories/capital.png';
import GroceryCategoryImage from '../../static/images/loan-categories/grocery.png';
import HealthCategoryImage from '../../static/images/loan-categories/health.png';
import RentCategoryImage from '../../static/images/loan-categories/rent.png';
import StudyCategoryImage from '../../static/images/loan-categories/study.png';
import TransportCategoryImage from '../../static/images/loan-categories/transport.png';

// constants
import {
  LOAN_CATEGORIES,
  LOAN_CATEGORIES_VALUES,
} from '../../config/constants';

export const CATEGORIES = [
  { category: LOAN_CATEGORIES.health, value: LOAN_CATEGORIES_VALUES.health, image: HealthCategoryImage },
  { category: LOAN_CATEGORIES.study, value: LOAN_CATEGORIES_VALUES.study, image: StudyCategoryImage },
  { category: LOAN_CATEGORIES.rent, value: LOAN_CATEGORIES_VALUES.rent, image: RentCategoryImage },
  { category: LOAN_CATEGORIES.transport, value: LOAN_CATEGORIES_VALUES.transport, image: TransportCategoryImage },
  { category: LOAN_CATEGORIES.capital, value: LOAN_CATEGORIES_VALUES.capital, image: CapitalCategoryImage },
  { category: LOAN_CATEGORIES.grocery, value: LOAN_CATEGORIES_VALUES.grocery, image: GroceryCategoryImage },
];

export const STORY_PICTURES = [
  {
    description: 'Donde te veas tu en tu mejor versión',
    title: 'Una foto tuya'
  },
  {
    description: 'Algo que muestra en lo que invertiras la plata',
    title: 'Para que necesitas'
  },
  {
    description: 'A qué te dedicas o que te gusta hacer',
    title: 'De lo que haces'
  },
  {
    description: 'Algo que tu quieras mostrar en tu historia',
    title: 'Otras'
  }
];

export const ERRORS_HANDLER = {
  category: '',
  description: '',
  phrase: '',
};
