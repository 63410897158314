import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import logo from '../../static/images/yellowLogo.png';

import { useStyles } from './styles';

export const Step3 = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.grid3}>
      <Grid className={classes.blueBoxText}>
        <Typography className={classes.text7}>BIENVENIDO!</Typography>
      </Grid>
      <Typography style={{ marginTop: 30 }} className={classes.text8}>que dicha tenerte en</Typography>
      <img alt="Giveme 5 Logo" src={logo} className={classes.brandLogo} />
      <Typography style={{ marginTop: -105 }} className={classes.text8}>somos la primera</Typography>
      <Typography className={classes.text8}>plataforma en Colombia de</Typography>
      <Typography style={{ fontWeight: 'bold' }} className={classes.text8}>créditos entre personas</Typography>
    </Grid>
  );
};

export default Step3;
