import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import smallLogo from '../../static/images/logo3.svg';

// styles
const useStyles = makeStyles({
  image: {
    maxWith: '100px',
  },
});

const BlueLogo = () => {
  const classes = useStyles();
  return <img alt="ConfigureID Logo" className={classes.image} src={smallLogo} />;
};

export default BlueLogo;
