// vendors
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

// constants
import { THREAD_TYPES } from '../../config/constants';
import { routes } from '../../config/routes';

// styles
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    textAlign: 'initial',
  },
  primaryText: {
    fontWeight: 'bold',
    textAlign: 'initial',
  },
  secondaryText: {
    textAlign: 'initial',
  },
  unreadMessageBadge: {
    backgroundColor: '#74C8D2',
    marginRight: 10,
  }
}));

const GmPeopleList = ({ contacts, unreadMessages }) => {
  const classes = useStyles();
  const history = useHistory();

  const hasUnreadMessages = (threadId) => unreadMessages.find((msg) => msg.threadId === threadId);

  const unreadMessagesLabel = ({ lastMessageDate, threadId }) => {
    const unreadMessage = hasUnreadMessages(threadId);

    if (!lastMessageDate && !unreadMessage) {
      return null;
    }

    const newNoun = unreadMessage?.count > 1 ? 'nuevos' : 'nuevo';
    const msgNoun = unreadMessage?.count > 1 ? 'mensajes' : 'mensaje';
    const unreadMessageContent = unreadMessage
      ? `¡Tienes ${unreadMessage.count} ${newNoun} ${msgNoun} sin leer!`
      : null;

    return (
      <Grid container direction="column">
        <Grid item>
          {unreadMessageContent}
        </Grid>
        <Grid item>
          {moment(lastMessageDate).format('DD MMMM YYYY, h:mm a')}
        </Grid>
      </Grid>
    );
  };

  return (
    <List dense className={classes.root}>
      {Array.isArray(contacts) && contacts.length
        ? contacts.map((contact) => (
          <ListItem key={contact.id} button>
            <ListItemAvatar>
              <Avatar
                alt={`Avatar n°${contact.id}`}
                src={contact.userDetail.profilePicture}
              />
            </ListItemAvatar>
            <ListItemText
              classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}
              id={contact.id}
              onClick={() => contact.threadType === THREAD_TYPES.history
                ? history.push(routes.chatHistory.replace(':id', contact.id))
                : history.push(routes.chatReferralHistory.replace(':id', contact.id))}
              primary={contact.userDetail.fullName}
              secondary={(
                <>
                  <Typography
                    color="textPrimary"
                    component="span"
                    variant="body2"
                  >
                    {contact.historyTitle}
                  </Typography>
                  {unreadMessagesLabel({ lastMessageDate: contact?.lastMessageDate || '', threadId: contact.id })}
                </>
              )}
            />
            {hasUnreadMessages(contact.id)
              ? (
                <Badge
                  className={classes.unreadMessageBadge}
                  color="primary"
                  variant="dot"
                />
              )
              : null}
          </ListItem>
        ))
        : null}
    </List>
  );
};

export default GmPeopleList;
