// components
import calculatorApi from '../../config/api/calculator';

const generateProposal = async (
  frequency,
  sanitizedLoanAmount,
  installments,
  administrativeExpensesPercent,
  administrativeExpensesSettings,
  proposalId
) => {
  const admnistrativeExpenseValue = sanitizedLoanAmount * administrativeExpensesPercent;
  const loanAmountWithAdministrativeExpenses = sanitizedLoanAmount + admnistrativeExpenseValue;

  const calculatedObject = await calculatorApi.calculateLoanValues({
    frequency,
    interestRate: '3',
    itsFromFeeValueOption: true,
    loanAmount: loanAmountWithAdministrativeExpenses,
    loanTime: installments,
  });
  const calculatedValues = calculatedObject.data.result.value;

  const proposal = {
    administrativeExpensePercent: administrativeExpensesPercent,
    admnistrativeExpenseValue,
    financingValue: sanitizedLoanAmount,
    frequency,
    id: proposalId ?? null,
    interestRate: '3',
    interestRatePercent: 0.02,
    interestRateValue: calculatedValues - loanAmountWithAdministrativeExpenses,
    isDefaultProposal: true,
    itsFromFeeValueOption: false,
    lifeInsurangePercent: administrativeExpensesSettings.Endorsement,
    loanAmount: loanAmountWithAdministrativeExpenses,
    loanTime: parseFloat(installments),
    messageThreadId: null,
    serviceCostPercent: administrativeExpensesSettings.FeessGivemeFive,
    value: calculatedValues,
  };

  return proposal;
};

export { generateProposal };
