import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { routes } from '../../config/routes';
import GmLogo from '../../components/logo';
import {
  DEFINITIONS1,
  GENERALITIES2,
  IDENTIFICATION_DATA3,
  LEGAL_CAPACITY_USER4,
  OBJECT5,
  NON_ALLOWED_USE_OF_APP6,
  PRODUCT_AND_SERVICE_DESCRIPTION7,
  CREDIT_APPLICATION_PROCESS8,
  REFERRALS9,
  EXCLUSION_OF_LIABILITY10,
  COMISSION11,
  INFORMATION_PROVIDED_BY_USERS12,
  CLAIMS13,
  LEGAL_WARRANTY14,
  WITHDRAWAL_RIGHT15,
  PAYMENT_REVERSION16,
  PERSONAL_DATA17,
  EXONERATION18,
  INTELECTUAL_PROPERTY19,
  APPLICABLE_LAW_AND_JURISDICTION20,
  INDEPENDENCE_OF_PROVISIONS21,
  NOTIFICATIONS22,
  VIGENCY23,
  PUBLICATION_DATE
} from './termsAndConditionsText';
import AccordionItem from '../../components/accordion-item/accordionItem';

const GmTermsAndConditions = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoBackToProfile = () => {
    history.push(routes.main.replace(':userId?', ''));
  };

  return (
    <>
      <Container className={classes.root}>
        <ArrowBackIcon onClick={handleGoBackToProfile} className={classes.arrowBackIcon} />
        <Grid container justify="center" className={classes.center}>
          <div className={classes.brandLogo}>
            <GmLogo />
          </div>
          <Grid item xs={12}>
            <Typography gutterBottom style={{ color: '#ffffffff' }} variant="subtitle2">
              Términos y Condiciones
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <AccordionItem
        title="1. Definiciones"
        content={DEFINITIONS1}
        classes={classes}
        id="1. Definiciones"
      />
      <AccordionItem
        title="2. Generalidades"
        content={GENERALITIES2}
        classes={classes}
        id="2. Generalidades"
      />
      <AccordionItem
        title="3. Datos de Identificacion"
        content={IDENTIFICATION_DATA3}
        classes={classes}
        id="3. Datos de Identificacion"
      />
      <AccordionItem
        title="4. Capacidad Legal del Usuario"
        content={LEGAL_CAPACITY_USER4}
        classes={classes}
        id="4. Capacidad Legal del Usuario"
      />
      <AccordionItem
        title="5. Objeto"
        content={OBJECT5}
        classes={classes}
        id="5. Objeto"
      />
      <AccordionItem
        title="6. Usos No Permitidos de la App"
        content={NON_ALLOWED_USE_OF_APP6}
        classes={classes}
        id="6. Usos No Permitidos de la App"
      />
      <AccordionItem
        title="7. Descripción de los Productos y Servicios de la App"
        content={PRODUCT_AND_SERVICE_DESCRIPTION7}
        classes={classes}
        id="7. Descripción de los Productos y Servicios de la App"
      />
      <AccordionItem
        title="8. Proceso de Solicitud del Crédito"
        content={CREDIT_APPLICATION_PROCESS8}
        classes={classes}
        id="8. Proceso de Solicitud del Crédito"
      />
      <AccordionItem
        title="9. Referidos"
        content={REFERRALS9}
        classes={classes}
        id="9. Referidos"
      />
      <AccordionItem
        title="10. Exclusión de Responsabilidad"
        content={EXCLUSION_OF_LIABILITY10}
        classes={classes}
        id="10. Exclusión de Responsabilidad"
      />
      <AccordionItem
        title="11. Comisión"
        content={COMISSION11}
        classes={classes}
        id="11. Comisión"
      />
      <AccordionItem
        title="12. Información Suministrada por los Usuarios"
        content={INFORMATION_PROVIDED_BY_USERS12}
        classes={classes}
        id="12. Información Suministrada por los Usuarios"
      />
      <AccordionItem
        title="13. Reclamaciones"
        content={CLAIMS13}
        classes={classes}
        id="13. Reclamaciones"
      />
      <AccordionItem
        title="14. Garantía Legal"
        content={LEGAL_WARRANTY14}
        classes={classes}
        id="14. Garantía Legal"
      />
      <AccordionItem
        title="15. Derecho de Retracto"
        content={WITHDRAWAL_RIGHT15}
        classes={classes}
        id="15. Derecho de Retracto"
      />
      <AccordionItem
        title="16. Reversión del Pago"
        content={PAYMENT_REVERSION16}
        classes={classes}
        id="16. Reversión del Pago"
      />
      <AccordionItem
        title="17. Datos Personales o 'Habeas Data'"
        content={PERSONAL_DATA17}
        classes={classes}
        id="17. Datos Personales o 'Habeas Data'"
      />
      <AccordionItem
        title="18. Exoneración"
        content={EXONERATION18}
        classes={classes}
        id="18. Exoneración"
      />
      <AccordionItem
        title="19. Propiedad Intelectual"
        content={INTELECTUAL_PROPERTY19}
        classes={classes}
        id="19. Propiedad Intelectual"
      />
      <AccordionItem
        title="20. Legislación Aplicable y Jurisdicción"
        content={APPLICABLE_LAW_AND_JURISDICTION20}
        classes={classes}
        id="20. Legislación Aplicable y Jurisdicción"
      />
      <AccordionItem
        title="21. Independencia de Disposiciones"
        content={INDEPENDENCE_OF_PROVISIONS21}
        classes={classes}
        id="21. Independencia de Disposiciones"
      />
      <AccordionItem
        title="22. Notificaciones"
        content={NOTIFICATIONS22}
        classes={classes}
        id="22. Notificaciones"
      />
      <AccordionItem
        title="23. Vigencia"
        content={VIGENCY23}
        classes={classes}
        id="23. Vigencia"
      />
      <br />
      <Typography>
        {PUBLICATION_DATE}
      </Typography>
    </>
  );
};

export default GmTermsAndConditions;
