import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FF8189',
    backgroundPosition: 'center',
    display: 'grid',
    height: '38vh',
    gridTemplateRows: 'auto 1fr auto',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  brandLogo: {
    margin: 20,
  },
  editIcon: {
    color: 'white',
    margin: '5px 0 10px 5px',
    fontSize: '23px',
  },
  imgProfile: {
    background: '#daa8ab',
    width: '140px',
    height: '140px',
    borderRadius: '100%',
    margin: '15px auto',
  },
  editProfile: {
    width: '33px',
    height: '33px',
    background: '#1DCAD3',
    borderRadius: '100%',
    position: 'relative',
    top: '100px',
    left: '104px',
    cursor: 'pointer',
  },
  contenDoc: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  inpuLabelStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    width: 37,
  },
  fileInput: {
    width: '90%',
    display: 'flex',
    border: '1px solid #DADADA',
    borderRadius: 4,
    justifyContent: 'center',
    height: 56,
    alignItems: 'flex-end',
  },
  inpuLabelDateStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: '#000',
    width: 127,
  },
  DocumentImage: {
    width: '100%',
    borderRadius: 5,
  },
  inpuLabelMaritalStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: 'gray',
    width: 87,
  },
  inpuLabelFileStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: '#000',
    width: 190,
  },
  radioGroupCustom: {
    flexDirection: 'row !important',
    justifyContent: 'center',
  },
  input: {
    '&::placeholder': {
      color: '#999', // Color predeterminado del placeholder
    },
    '&.MuiOutlinedInput-input': {
      '&::placeholder': {
        color: '#ff0000', // Color del placeholder cuando hay un error
      },
    },
  },
}));

export { useStyles };
