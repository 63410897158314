// vendors
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// actions
import { getActiveTransactions, getReferralPerimeterStatus } from '../../redux/actions/payments';

// components
import GmBlueLogo from '../../components/blue-logo';
import GmKarmaReview from '../../components/karma-review';
import GmLatePaymentSummary from '../../components/late-payment-summary';
import GmSuperModal from '../../components/super-modal';
import GmSvgIcon from '../../static/svg';
import SideMenu from '../../components/side-menu';

// constants
import { routes } from '../../config/routes';
import { userIsGodfather, TRANSACTION_STATE } from '../../config/constants';

// images
import latePayment from '../../static/images/late-payment.png';

// styles
import { useStyles } from './styles';

const Loans = () => {
  const [latePaymentData, setLatPaymentData] = useState({});
  const [visibleLatePayment, toggleVisibleLatePayment] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth, transaction: { transactionsList: transactions, referralPerimeter } } = useSelector((state) => state);

  const isGodfather = userIsGodfather(auth.userInfo.role);

  useEffect(() => {
    dispatch(getActiveTransactions());
    dispatch(getReferralPerimeterStatus());
  }, []);

  const handleOnReportPayment = (transaction) => {
    const pathname = transaction?.areFeeAndInsurancePaid
      ? routes.paymentsReport.replace(':transactionId', transaction.transactionId)
      : routes.paymentTransaction;

    history.push({
      pathname,
      state: {
        amountToDisplay: isGodfather
          ? transaction.borrowedValueWithoutTaxes
          : transaction.loanValue,
        amount: isGodfather
          ? transaction.loanAmount
          : transaction.loanValue,
        transactionId: transaction.transactionId,
        historyId: transaction.historyId,
        hasPromissoryNote: transaction.hasPromissoryNote,
        userImage: transaction.user.profilePicture,
        userName: transaction.user.fullName,
      },
    });
  };

  const handleToggleVisibleLatePayment = (transaction) => {
    setLatPaymentData(transaction);
    toggleVisibleLatePayment(!visibleLatePayment);
  };

  const handleOnLatePayment = (transaction) => {
    if (transaction.isLatePayment) {
      handleToggleVisibleLatePayment(transaction);
    }
  };

  const handleOnPaymentDetail = (transaction) =>
    history.push({
      pathname: routes.paymentsDetail.replace(':transactionId', transaction.transactionId),
      state: { loanAmount: transaction.loanAmount }
    });

  const goToChat = (messageThreadId, referralId) => {
    if (messageThreadId) {
      history.push(routes.chatReferralHistory.replace(':id', messageThreadId));
      return;
    }

    history.push(routes.newChatReferralHistory.replace(':id', referralId));
  };

  return (
    <>
      <GmSuperModal
        confirmLabel="Pagar"
        headerImage={latePayment}
        headerTitle="Estás acumulando mal Karma"
        onClose={() => handleToggleVisibleLatePayment()}
        onConfirm={() => handleOnReportPayment(latePaymentData)}
        visible={visibleLatePayment}
      >
        <GmLatePaymentSummary
          items={[{
            icon: 'red-pentagon',
            label: 'Afectar el karma de quién confió en ti',
          }, {
            icon: 'interest-rate-enabled',
            iconConfig: {
              viewBox: '0 0 22 22'
            },
            label: 'El bloqueo de tus nuevas historias o capítulos',
          }, {
            icon: 'eyes-enabled',
            iconConfig: {
              style: { width: 30 },
              viewBox: '3 0 26 26'
            },
            label: 'Limitar tus referidos',
          }]}
          latePaymentValueLabel="Valor de couta en mora:"
          latePaymentValue={latePaymentData?.loanValue}
          title={(
            <Typography className={classes.latePaymentTitle}>
              <b>{`${latePaymentData?.user?.fullName} `}</b>
              continua esperando tu pago. Paga a tiempo y evita:
            </Typography>
          )}
        />
      </GmSuperModal>
      <Paper className={classes.root}>
        <Container className={classes.textHeader}>
          <Grid container justify="center" className={classes.center}>
            <Grid item xs={12} className={classes.forgotPassword}>
              <Grid className={classes.gmBlueLogo} item xs={12}>
                <GmBlueLogo />
              </Grid>
              <Grid item xs={4} className={classes.sideMenu}>
                <SideMenu
                  disabledReferringButtons={false}
                  isProfileVisiting={false}
                  whoHasImReferredList={[]}
                  whoRefferedMeList={[]}
                  hideReferralButton
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.forgotPassword}>
              <Typography variant="h4" gutterBottom className={classes.textHeader}>
                Balance de tu Karma
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Container className={classes.textHeader}>
        <Grid container justify="center" className={classes.center}>
          <Grid container direction="row">
            <Grid item xs={10}>
              <Typography xs={12} variant="h4" gutterBottom className={classes.textKarma}>
                Karma acumulado
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.gmKarmaReview} item xs={12} style={{ marginTop: 10 }}>
            <GmKarmaReview
              readOnly
              value={auth.userInfo.karma}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        {transactions.length > 0 && (
          <Grid container className={classes.transactionListContainer}>
            <Grid item xs={12} style={{ marginBottom: 15 }}>
              <Typography variant="caption" gutterBottom>
                Retribución de Karma
              </Typography>
            </Grid>
            {/* TODO: The wrapped components and logic inside the transactions iteration should be a new isolated component */}
            {transactions.map((transaction) => (
              <Grid
                key={transaction.transactionId}
                item
                xs={6}
                className={classes.transactionContainer}
              >
                <Grid container justify="center" className={classes.imgTransactionContainer}>
                  <Grid item>
                    <img
                      alt="Transaction"
                      src={transaction.imageUrl}
                      style={{
                        height: 85,
                        objectFit: 'cover',
                        width: 85,
                      }}
                    />
                  </Grid>
                  <Grid container justify="space-between">
                    <Grid className={classes.latePaymentButton} item>
                      <IconButton
                        disabled={!transaction.isLatePayment}
                        onClick={isGodfather ? Function.prototype : () => handleOnLatePayment(transaction)}
                      >
                        <GmSvgIcon
                          disabled={!transaction.isLatePayment}
                          enabled={transaction.isLatePayment}
                          icon="alert"
                          viewBox="0 24 64 10"
                        />
                      </IconButton>
                    </Grid>
                    <Grid className={classes.otherLoansActionButtons} item>
                      <IconButton
                        className={classes.reportPaymentButton}
                        disabled={transaction.state === TRANSACTION_STATE.close || (isGodfather && transaction.state === TRANSACTION_STATE.open)}
                        onClick={() => handleOnReportPayment(transaction)}
                      >
                        <GmSvgIcon
                          disabled={transaction.state === TRANSACTION_STATE.close || (isGodfather && transaction.state === TRANSACTION_STATE.open)}
                          enabled={transaction.state === TRANSACTION_STATE.open || transaction.state === TRANSACTION_STATE.openPendingOfLoan}
                          icon="payment-report"
                        />
                      </IconButton>
                      <IconButton
                        className={classes.detailPaymentButton}
                        onClick={() => handleOnPaymentDetail(transaction)}
                      >
                        <GmSvgIcon icon="payment-detail" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={classes.loanInfo}>
                  <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom className={classes.textFullName}>
                      {transaction.user.fullName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.textLoan}>
                      {`${numeral(transaction.borrowedValueWithoutTaxes).format('$0,0')} COP`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.textFullName}>
                      {moment(transaction.nextPaymentDate || moment()).format('DD MMMM YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        {referralPerimeter.length > 0 && (
          <Grid container className={classes.transactionListContainer}>
            <Grid item xs={12} style={{ marginBottom: 15 }}>
              <Typography variant="caption" gutterBottom>
                Comportamiento de tus amigos
              </Typography>
            </Grid>
            {referralPerimeter.map((referral) => (
              <Grid
                key={referral.id}
                item
                xs={6}
                className={classes.transactionContainer}
              >
                <Grid container justify="center" className={classes.imgReferralContainer}>
                  <Grid item>
                    <img
                      src={referral.profilePicture}
                      style={{
                        height: 85,
                        objectFit: 'cover',
                        width: 85,
                      }}
                    />
                  </Grid>
                  <Grid container justify="space-between">
                    <Grid className={classes.latePaymentButton} item>
                      <IconButton onClick={Function.prototype}>
                        <GmSvgIcon
                          enabled
                          icon={referral.isKarmaOk ? 'fine-karma' : 'bad-karma'}
                          viewBox="0 24 64 10"
                        />
                      </IconButton>
                    </Grid>
                    <Grid className={classes.otherLoansActionButtons} item>
                      <IconButton
                        className={classes.detailPaymentButton}
                        onClick={() => goToChat(referral.messageThreadId, referral.id)}
                      >
                        <GmSvgIcon
                          enabled
                          icon="message"
                          viewBox="0 -4 24 24"
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={classes.loanInfo}>
                  <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom className={classes.textFullName}>
                      {referral.fullName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Loans;
