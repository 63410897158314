import PQRS1 from '../../static/images/PQRS1.png';
import PQRS3 from '../../static/images/PQRS3.png';
import PQRS4 from '../../static/images/PQRS4.png';
import PQRS5 from '../../static/images/PQRS5.png';
import PQRS6 from '../../static/images/PQRS6.png';
import PQRS7 from '../../static/images/PQRS7.png';
import PQRS8 from '../../static/images/PQRS8.png';
import PQRS9 from '../../static/images/PQRS9.png';
import PQRS10 from '../../static/images/PQRS10.png';

/* eslint-disable max-len */
export const pngItems = [
  {
    title: 'Creación de cuenta',
    content: [
      { title: '¿Cuáles son los requisitos para crear una cuenta?', texts: ['Haber recibido una invitación con un código de referido por alguien que ya haga parte de la plataforma', 'Ser mayor de edad', 'Tener un número celular', 'Tener un correo electrónico', 'Tener unas cuentas bancarias'] },
      { title: '¿Cómo abro la cuenta?', texts: ['Una vez recibas un correo electrónico de un amigo que confío en ti puedes empezar a abrir tu cuenta. Luego debes subir tus datos personales, tu ID y elegir si eres de los que necesita apoyo o quiere apoyar a alguien.'] },
      { title: '¿Si elegí “necesito apoyo” y luego quiero cambiar a “quiero apoyar” lo puedo hacer?', texts: ['En este momento sólo puedes usar la plataforma con el rol que elegiste inicialmente.'] },
      { title: '¿Puedo eliminar mi cuenta?', texts: ['Sí, en cualquier momento que lo desees.'] },
      { title: '¿Cómo puedo editar mi cuenta?', texts: ['En el menú que encuentras en tu perfil puedes editar los datos de tu perfil. Sin embargo, por seguridad, hay ciertos campos que no puedes cambiar.'] },
      { title: '¿Quién puede ver mi perfil?', texts: ['Si eres un ángel, sólo pueden ver tu perfil quienes hagan parte de tu cadena de referidos y, adicionalmente, a quienes tú le hayas hecho una propuesta.', 'Si eres un SuperViviente, todas las personas que entran a la aplicación pueden ver tu perfil.'] }
    ],
    image: PQRS1
  },
  {
    title: 'Costos del crédito',
    content: [
      { title: '¡Pronto tendremos respuestas!', texts: ['Vuelve pronto.'] },
    ],
    image: PQRS3
  },
  {
    title: '¿Cómo puedo subir mi buen Karma?',
    content: [
      { title: '¿Cómo funciona  el karma?', texts: ['A través de inteligencia artificial AI, creamos nuestro score financiero denominado Karma….'] },
      { title: '¿Cómo puedo acumular buen karma?', texts: ['Pagando tus créditos a tiempo y bajo las condiciones que acordaron; refiriendo amigos con buen comportamiento; cuándo tus referidos hacen pagos a tiempo; entre más historias subas más buen Karma puedes acumular. Te aconsejamos que pidas préstamos pequeños y los pagues rápido, esto te ayudará a tener un mejor Karma y a que la gente confíe más en ti.'] },
      { title: '¿Qué pasa si no me pagan?', texts: ['Si alguien no te paga, le avisamos a toda su cadena de referidos que es un “mala paga”. Esto  afecta inmediatamente de forma negativa el Buen Karma de toda su cadena de referidos, así cada uno de ellos pierde puntos y se afecta su score crediticio. Además, tenemos una alianza con el Fondo de Coberturas Crediticias FCC quien avala tu préstamo en caso de que alguien no te pague.'] },
    ],
    image: PQRS4
  },
  {
    title: 'Términos de la negociación',
    content: [
      { title: '¿Cuánto se demora para otorgar un crédito?', texts: ['No tenemos un tiempo exacto de respuesta. Esto dependerá de que encontremos pronto a un Ángel dispuesto a apoyarte. '] },
      { title: '¿Qué pasa si alguien me hace una propuesta y no realiza el desembolso?', texts: ['En este caso, te pedimos que te comuniques con nosotros directamente al número de Whatsapp +573054707951 y evaluemos tu caso.'] },
      { title: '¿Qué pasa si no me pagan?', texts: ['Si alguien no te paga, le avisamos a toda su cadena de referidos que es un “mala paga”. Esto  afecta inmediatamente de forma negativa el Buen Karma de toda su cadena de referidos, así cada uno de ellos pierde puntos y se afecta su score crediticio. Además, tenemos una alianza con el Fondo de Coberturas Crediticias FCC quien avala tu préstamo en caso de que alguien no te pague.'] },
      { title: '¿Cómo puedo realizar mis pagos?', texts: ['A través del medio que para ti sea más fácil. Dentro de la plataforma debes reportar tus pagos adjuntando un comprobante de la transferencia, subiendo una foto de cómo te ha ido y mandándole un mensaje a quién te ayudó contándole sobre tu progreso.'] },
    ],
    image: PQRS5
  },
  {
    title: 'Tasas, plazos y montos',
    content: [
      { title: '¿Qué tasas manejan? ', texts: ['A la fecha, noviembre 2022, son:  0% 1% 1.5% y 2 efectivo mensual. '] },
      { title: '¿Cuánto es el monto máximo?', texts: ['Inicias con un monto máximo de $2.000.000. entre mejor “buena paga” seas, se te irá subiendo el monto máximo a prestar.'] },
    ],
    image: PQRS6
  },
  {
    title: '¿Cómo funciona la aplicación?',
    content: [
      { title: '¿Cómo subir una historia?', texts: ['Debes responder las preguntas que encuentras una vez ingresas a “subir una historia”. Hacerlo bien te ayudará a que más gente se enamore de tu historia y tengas más oportunidades de acceder a crédito. Cada pregunta debe estar acompañada por una foto que permita mostrar mejor tu situación. Todos los campos son obligatorios.'] },
      { title: '¿Cuántas historias puedo subir simultáneamente?', texts: ['Puedes subir una historia a la vez, una vez termines de pagarla podrás subir la siguiente historia. '] },
      { title: '¿Cómo puedo ver las historias?', texts: ['En el menú “historias” puedes encontrar todas las historias.'] },
      { title: '¿Cómo sé si la historia ya tuvo un crédito?', texts: ['Las historias marcadas con la bandera amarilla y estrella roja, son las historias que ya obtuvieron un crédito y ya lo pagaron.'] },
      { title: '¿Cuántas historias puedo apoyar simultáneamente?', texts: ['Puedes apoyar un máximo de 10 historias simultáneamente. Entre más rápido te paguen y cierres esa historia, más rápido puedes apoyar a alguien más'] },
      { title: '¿La gente puede ver las historias que estoy apoyando?', texts: ['Tu perfil solo lo puede ver a quienes hayas decidido apoyar.'] },
      { title: '¿Cómo puedo simular mi crédito?', texts: ['En la calculadora puedes elegir: el monto que necesitas prestar, la frecuencia con que la puedes pagar, a qué tasa de interés y cuál es tu capacidad de pago. Con esta información la calculadora te simula el número de cuotas y el valor que debes pagar en cada una de ellas.'] },
      { title: '¿Cómo puedo realizar un desembolso?', texts: ['Una vez la persona que necesita apoyo haya aceptado las condiciones de tu préstamo, aparecerá un botón que te lleve a hacer el desembolso. Éste lo puedes hacer a través de la plataforma financiera de tu elección. Debes adjuntar el comprobante de dicha transferencia y un mensaje de ánimo para la persona que necesita tu apoyo.'] },
      { title: '¿Cómo puedo ver si ya me hicieron los pagos?', texts: ['Dentro de la plataforma, en cada préstamo que tienes activo, puedes ver qué porcentaje del préstamo ya te han retribuido. '] },
    ],
    image: PQRS7
  },
  {
    title: 'Sistema de referidos',
    content: [
      { title: '¿Cómo puedo referir a alguien?', texts: ['Dentro de tu perfil encuentras el menú. Le das click y ahí encuentras “referir un amigo”. Necesitas los siguientes datos: Su correo electrónico y su número celular.'] },
      { title: '¿Qué pasa si mi referido no paga?', texts: ['Recibirás una notificación donde indique que no ha pagado. Esto afecta negativamente tu Karma y, por ende, tu score crediticio. Ten esto en cuenta para que siempre referencies gente que sea un verdadero Ángel y un verdadero Buena Paga.'] },
      { title: '¿Qué pasa si no me pagan?', texts: ['Si alguien no te paga, le avisamos a toda su cadena de referidos que es un “mala paga”. Esto  afecta inmediatamente de forma negativa el Buen Karma de toda su cadena de referidos, así cada uno de ellos pierde puntos y se afecta su score crediticio.'] },
      { title: '¿Si elegí “necesito apoyo” y luego quiero cambiar a “quiero apoyar” lo puedo hacer?', texts: ['En este momento sólo puedes usar la plataforma con el rol que elegiste inicialmente.'] },
      { title: '¿Puedo eliminar mi cuenta?', texts: 'Sí, en cualquier momento que lo desees.' },
      { title: '¿Cómo puedo editar mi cuenta?', texts: ['En el menú que encuentras en tu perfil puedes editar los datos de tu perfil. Sin embargo, por seguridad, hay ciertos campos que no puedes cambiar.'] },
      { title: '¿Quién puede ver mi perfil?', texts: ['Si eres un ángel, sólo pueden ver tu perfil quienes hagan parte de tu cadena de referidos y, adicionalmente, a quienes tú le hayas hecho una propuesta.', 'Si eres un SuperViviente, todas las personas que entran a la aplicación pueden ver tu perfil.'] },
    ],
    image: PQRS8
  },
  {
    title: 'Quejas y reclamos',
    content: [
      { title: 'Cuéntanos, a través del botón de contactar, si hay algo que no te ha agradado o que no ha funcionado bien.', texts: ['Pronto nos comunicaremos contigo dándote una respuesta.'] },
    ],
    image: PQRS9
  },
  {
    title: 'Sugerencias',
    content: [
      { title: 'Sugiérenos algo', texts: ['Tu opinión nos encanta, vía el botón contactar, cuéntanos qué te gustaría que hicieramos mejor.'] },
    ],
    image: PQRS10
  },
];
/* eslint-enable max-len */
