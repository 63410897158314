import { makeStyles } from '@material-ui/core/styles';

const commonStyles = {
  text: {
    display: 'block',
    textAlign: 'initial'
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: '50px 0 20px',
  },
  primaryText: {
    ...commonStyles.text,
    fontSize: '0.75rem'
  },
  secondaryText: {
    ...commonStyles.text,
    ...theme.typography.lightCaption
  },
  name: {
    fontSize: '0.75rem',
    fontWeight: 'bold'
  },
  pictureContainer: {
    height: 42,
    width: 42
  },
  defaultPicture: {
    paddingLeft: 10,
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain'
  },
  noActivitiesText: {
    marginTop: 100
  }
}));

export { useStyles };
