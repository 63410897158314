// vendors
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

// actions
import { getActiveTransactions, getTransactionRecord } from '../../redux/actions/payments';

// constants
import { routes } from '../../config/routes';
import { userIsGodfather, WOMPI_TRANSACTION_STATUS } from '../../config/constants';

// images
import BlueLogo from '../../components/blue-logo';

// styles
import { useStyles } from './styles';

const TransactionResult = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { pathname, search } = useLocation();

  const transactionRecord = useSelector((state) => state.payments.transactionRecord);
  const transactions = useSelector((state) => state.transaction?.transactionsList);
  const { auth: { userInfo } } = useSelector((state) => state);

  const [timer, setTimer] = useState(0);

  const routeArray = pathname.split('/');
  const transactionRecordId = routeArray[routeArray.length - 1];
  const isGodfather = userIsGodfather(userInfo.role);

  const checkOnTransactionRecordStatus = () => {
    dispatch(getTransactionRecord(transactionRecordId))
      .then((response) => {
        const { status } = response.data.result;

        if (!status || Object.values(WOMPI_TRANSACTION_STATUS).some(status)) {
          setTimer(10);
        }
      })
      .catch(() => setTimer(0));
  };

  useEffect(() => {
    dispatch(getActiveTransactions());
    checkOnTransactionRecordStatus();
  }, []);

  useMemo(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    } else {
      checkOnTransactionRecordStatus();
    }
  }, [timer]);

  const isTransactionRecordCompleted = useMemo(() =>
    transactionRecord?.status
    && Object.values(WOMPI_TRANSACTION_STATUS).some((x) => x === transactionRecord?.status), [transactionRecord]);

  const handleOnSendReport = () => {
    const urlSearchParams = new URLSearchParams(search);
    const threadId = urlSearchParams.get('threadId');
    const pathname = routes.chatHistory.replace(':id', threadId);
    const referenceArray = transactionRecord.reference.split(';');
    const transactionId = referenceArray[1];
    const currentTransaction = transactions.find((t) => Number(t.transactionId) === Number(transactionId));

    history.push({
      pathname,
      state: {
        amount: isGodfather
          ? currentTransaction.loanAmount
          : currentTransaction.loanValue,
        transactionId: currentTransaction.transactionId,
        hasPromissoryNote: currentTransaction.hasPromissoryNote,
        userImage: currentTransaction.user.profilePicture,
        userName: currentTransaction.user.fullName,
      },
    });
  };

  const hasTransactionFailed = transactionRecord?.status === WOMPI_TRANSACTION_STATUS.error
    || transactionRecord?.status === WOMPI_TRANSACTION_STATUS.declined
    || transactionRecord?.status === WOMPI_TRANSACTION_STATUS.voided;

  const getTransactionStatusMessage = () => {
    switch (transactionRecord?.status) {
      case WOMPI_TRANSACTION_STATUS.approved:
        return 'Pago exitoso';
      case WOMPI_TRANSACTION_STATUS.error:
      case WOMPI_TRANSACTION_STATUS.declined:
        return 'El pago no se pudo completar';
      case WOMPI_TRANSACTION_STATUS.voided:
        return 'El pago ha sido anulado';
      default:
        return 'El pago se está procesando';
    }
  };

  const transactionResultBodyClass = () => {
    switch (transactionRecord?.status) {
      case WOMPI_TRANSACTION_STATUS.approved:
        return classes.transactionResultBodySuccess;
      case WOMPI_TRANSACTION_STATUS.error:
      case WOMPI_TRANSACTION_STATUS.declined:
      case WOMPI_TRANSACTION_STATUS.voided:
        return classes.transactionResultBodyFailed;
      default:
        return classes.transactionResultBody;
    }
  };

  const renderTransactionResultIcon = () => {
    switch (transactionRecord?.status) {
      case WOMPI_TRANSACTION_STATUS.approved:
        return <CheckCircleIcon style={{ color: '#98EEAA', fontSize: 80 }} />;
      case WOMPI_TRANSACTION_STATUS.error:
      case WOMPI_TRANSACTION_STATUS.declined:
      case WOMPI_TRANSACTION_STATUS.voided:
        return <CancelIcon style={{ color: '#f28080', fontSize: 80 }} />;
      default:
        return null;
    }
  };

  const timerMessage = `Por favor espere un momento. La información del pago se actualizará en: ${timer} segundos`;

  return (
    <Grid container justify="center" className={classes.transactionResult}>
      <Grid className={classes.transactionResultTitle} item xs={12}>
        <BlueLogo />
      </Grid>
      <Grid className={classes.transactionResultTitle} item xs={12} md={12}>
        <Typography component="span" variant="h1">
          {getTransactionStatusMessage()}
        </Typography>
      </Grid>
      <Grid className={classes.transactionResultMessage} item xs={12} md={12}>
        {!isTransactionRecordCompleted ? (
          <Typography component="span" variant="body">
            {timerMessage}
          </Typography>
        ) : renderTransactionResultIcon()}
      </Grid>
      {isTransactionRecordCompleted && (
        <Grid item xs={12} md={12}>
          <List className={transactionResultBodyClass()} component={Paper} variant="outlined">
            <ListItemText
              primary="Referencia de la transacción:"
              secondary={transactionRecord.id}
            />
            <ListItemText
              primary="Valor:"
              secondary={`${numeral(transactionRecord?.amountInCents / 100).format('$0,0')} ${transactionRecord?.currency}`}
            />
            <ListItemText
              primary="Email:"
              secondary={transactionRecord?.customerEmail}
            />
          </List>
        </Grid>
      )}
      {!hasTransactionFailed && (
        <Grid className={classes.summaryTextContainer} xs={12}>
          <Typography className={classes.summaryText}>
            {/* eslint-disable-next-line max-len */}
            Antes de realizar el desembolso, debes firmar el pagaré; en el chat encontrarás el botón para realizarlo. Con esto tu apoyo a esta historia quedará protegido
          </Typography>
        </Grid>
      )}
      {isTransactionRecordCompleted && !hasTransactionFailed && (
        <Grid item xs={12} md={12}>
          <Button
            className={classes.continueProcessButton}
            color="primary"
            onClick={handleOnSendReport}
            variant="contained"
          >
            Continuar con tu proceso
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default TransactionResult;
