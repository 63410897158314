// vendors
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';

// constants
import { userIsGodson } from '../../config/constants';

const LoanConfirmation = ({
  auth,
  classes,
  eventMessage,
  // isLoanPaymentConfirmed,
  messageId,
  messageType,
  onLoanConfirmation,
  renderDetails,
}) => {
  const isGodson = userIsGodson(auth.userInfo.role);

  return (
    <Paper className={classes.paper} elevation={0} key={messageId}>
      <Grid
        container
        justify="center"
        className={classes.paymentBlueContainer}
        spacing={0}
      >
        <Grid item className={classes.proposalAvatar}>
          <Avatar
            alt="gm-user-avatar"
            src={eventMessage.User.ProfilePicture}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.proposalName} component="div">
            {eventMessage.User.FullName}
          </Typography>
          <Typography className={classes.proposalName} component="div">
            {isGodson ? 'Ha decidido apoyarte' : 'Te ha realizado una trasferencia'}
          </Typography>
        </Grid>
        {renderDetails({
          classes,
          date: eventMessage.Date,
          isGodson,
          messageType,
          paymentType: eventMessage?.PaymentType,
          value: eventMessage?.Value,
        })}
        <Grid>
          {isGodson && (
            <Button
              className={classes.confirmButtonBlue}
              // disabled={messageType === MESSAGE_TYPES.loanConfirmation && isGodson && isLoanPaymentConfirmed}
              onClick={() => onLoanConfirmation({ ...eventMessage, messageId })}
              variant="contained"
              disableElevation
            >
              CONFIRMAR
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoanConfirmation;
