// vendors
import Grid from '@material-ui/core/Grid';
import React from 'react';

// components
import ActionItem from './action-item';

// styles
import { useStyles } from './styles';

const PaymentDetailActions = ({ actions }) => {
  const classes = useStyles();

  return actions.map((action) => (
    <Grid className={classes.actionItem} item>
      <ActionItem
        key={action.paymentValue}
        date={action.paymentDate}
        type={action.type}
        value={action.paymentValue}
      />
    </Grid>
  ));
};

export default PaymentDetailActions;
