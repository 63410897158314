// vendors
import React from 'react';

const AddStoryDisabled = (
  <>
    <path d="M77.39 29.74H2.97998V75.81H77.39V29.74Z" fill="#7D7D7D" />
    <path d="M77.39 29.74H2.97998V42.58H77.39V29.74Z" fill="black" fillOpacity="0.38" />
    <path d="M72.3812 0.0020067L-0.00488281 17.2502L2.97143 29.741L75.3575 12.4928L72.3812 0.0020067Z" fill="black" fillOpacity="0.38" />
    <path d="M40.9 42.58H34.03L39.46 29.74H46.33L40.9 42.58Z" fill="#CFD2D7" />
    <path d="M25.37 42.58H18.5L23.93 29.74H30.8L25.37 42.58Z" fill="#CFD2D7" />
    <path d="M9.84998 42.58H2.97998L8.40998 29.74H15.28L9.84998 42.58Z" fill="#CFD2D7" />
    <path d="M56.42 42.58H49.55L54.99 29.74H61.86L56.42 42.58Z" fill="#CFD2D7" />
    <path d="M71.95 42.58H65.08L70.51 29.74H77.38L71.95 42.58Z" fill="#CFD2D7" />
    <path d="M39.86 20.95L33.18 22.54L35.49 8.78995L42.17 7.19995L39.86 20.95Z" fill="#CFD2D7" />
    <path d="M24.76 24.55L18.08 26.14L20.39 12.39L27.07 10.8L24.76 24.55Z" fill="#CFD2D7" />
    <path d="M9.65998 28.15L2.97998 29.74L5.27998 15.99L11.97 14.4L9.65998 28.15Z" fill="#CFD2D7" />
    <path d="M54.97 17.35L48.29 18.94L50.59 5.18998L57.28 3.59998L54.97 17.35Z" fill="#CFD2D7" />
    <path d="M70.07 13.75L63.39 15.34L65.7 1.59L72.38 0L70.07 13.75Z" fill="#CFD2D7" />
  </>

);

export default AddStoryDisabled;
