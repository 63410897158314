// constants
import * as actionsTypes from '../action-types';

// state
import { historyInitialState } from '../../config/state/initialState';
import { MESSAGE_TYPES, PROPOSAL_STATUS } from '../../config/constants';
import { ADD_NEW_CHAT_THREAD_ID, CLEAR_NEW_CHAT_THREAD_ID } from '../actions/createHistory';

const historyReducer = (state = historyInitialState, action) => {
  switch (action.type) {
    case actionsTypes.CLEAR_MESSAGES:
      return {
        ...state,
        chatsHistory: {},
        messages: [],
      };

    case actionsTypes.CLEAR_UNREAD_MESSAGES:
      const unreadMessagesToClear = state.unreadMessages.filter((msg) => msg.threadId.toString() !== action.payload);
      return {
        ...state,
        unreadMessages: unreadMessagesToClear,
      };

    case actionsTypes.SAVE_STORY_PARTIAL_BEGIN:
      return {
        ...state,
        error: null,
        data: [],
      };

    case actionsTypes.SAVE_STORY_PARTIAL_SUCCESS:
      return {
        ...state,
        data: [],
        editData: [],
        error: null,
        historyInfo: {},
      };

    case actionsTypes.SAVE_STORY_PARTIAL_ERROR:
      return {
        ...state,
        error: true,
        data: [],
      };

    case actionsTypes.AUTH_LOGOUT:
      return historyInitialState;

    case actionsTypes.GET_HISTORY_BEGIN:
      return {
        ...state,
        error: null,
        data: [],
      };

    case actionsTypes.GET_HISTORY_SUCCESS:
      const newHistories = action.payload.histories.reduce((acc, history) => {
        const index = acc.findIndex((item) => item.id === history.id);

        if (index === -1) {
          acc.push(history);
        } else {
          acc[index] = history;
        }

        return acc;
      }, []);

      return {
        ...state,
        data: [],
        error: null,
        histories: [...state.histories, ...newHistories],
        schemaQuestion: [],
        totalCount: action.payload.totalCount,
      };

    case actionsTypes.GET_HISTORY_ERROR:
      return {
        ...state,
        error: true,
        data: [],
        histories: [],
      };

    case actionsTypes.GET_MY_HISTORY_BEGIN:
      return {
        ...state,
        error: null,
        myHistories: [],
      };

    case actionsTypes.GET_MY_HISTORY_SUCCESS:
      return {
        ...state,
        editData: {},
        error: null,
        historyInfo: {},
        ...action.payload,
      };

    case actionsTypes.GET_MY_HISTORY_ERROR:
      return {
        ...state,
        error: true,
        myHistories: [],
      };

    case actionsTypes.GET_QUESTIONS_BEGIN:
      return {
        ...state,
        error: null,
        editData: [],
      };

    case actionsTypes.GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        error: null,
        editData: action.payload.questions,
      };

    case actionsTypes.GET_QUESTIONS_ERROR:
      return {
        ...state,
        error: true,
        editData: [],
      };

    case actionsTypes.GET_CHATS_BEGIN:
      return {
        ...state,
        error: null,
        chatsContacts: [],
      };

    case actionsTypes.GET_CHATS_SUCCESS:
      return {
        ...state,
        error: null,
        chatsContacts: action.payload.chats,
        historyProgress: 0,
      };

    case actionsTypes.GET_CHATS_ERROR:
      return {
        ...state,
        error: true,
        chatsContacts: [],
        historyProgress: 0,
      };

    case actionsTypes.GET_HISTORY_INFO_BEGIN:
      return {
        ...state,
        error: null,
        historyInfo: {},
      };

    case actionsTypes.GET_CHATS_HISTORY_SUCCESS:
      return {
        ...state,
        error: null,
        chatsHistory: action.payload.history,
        messages: action.payload.history.messages.items.reverse(),
        calculator: action.payload.calculatorInfo,
      };

    case actionsTypes.GET_HISTORY_INFO_SUCCESS:
      return {
        ...state,
        error: null,
        historyInfo: { ...action.payload.data, historyQuestions: state.editData },
      };

    case 'SOCKET_CONNECTION_SUCCESS':
      return {
        ...state,
        lSignalrConnection: action.payload.socket,
        socketOn: true,
      };

    case actionsTypes.RECEIVE_MESSAGE_SUCCESS:
      let currentChatMessages = state.messages;
      let unreadMessagesToSet = state.unreadMessages;

      const {
        content,
        messageId,
        messageThreadId,
        messageType,
        // postByUserId could be null if messageType equals MESSAGE_TYPES.accountData, MESSAGE_TYPES.signPromissoryNote, MESSAGE_TYPES.systemMessage, MESSAGE_TYPES.messageUpdated among others.
        postByUserId,
        userId,
      } = action.payload;

      // RegExp to match following cases: /chat/[0-9] - /new-chat/[0-9]/[0-9] - /new-chat-referral/[0-9]
      const isWorkingOnChatPage = window.location.pathname.match(new RegExp('/?[a-z]*-?chat-?[a-z]*/[0-9]*/?[0-9]*')) || false;

      // chatId is only obtained if the user is using this url http://localhost/chat/:messageThreadId
      // eslint-disable-next-line no-unused-vars
      const [_url, _uri, chatId] = window.location.pathname.split('/');

      const isCurrentMessageDoneByCurrentUser = postByUserId ? userId.toString() === postByUserId.toString() : false;
      const isIncomingMessageForCurrentChat = isWorkingOnChatPage && Boolean(chatId)
        // state.newChatMessageThreadId is only stored in redux if the user is working in /new/chat/:userId/:historyId url
        ? chatId.toString() === messageThreadId.toString() || messageThreadId.toString() === state.newChatMessageThreadId.toString()
        : false;

      const newMessage = {
        content,
        id: messageId,
        postByUserId,
        type: messageType,
      };

      // This if statement below is in charge of update in real-time proposals when are edited, updated or disabled.
      if (messageType === MESSAGE_TYPES.messageUpdated) {
        // Id and ProposalStatus from new message.
        const { Id, ProposalStatus } = JSON.parse(content);
        if (ProposalStatus >= 0) {
          if (ProposalStatus === PROPOSAL_STATUS.deleted
            || ProposalStatus === PROPOSAL_STATUS.edited
            || ProposalStatus === PROPOSAL_STATUS.disabled) {
            currentChatMessages = state.messages.map((msg) => {
              if (msg.type === MESSAGE_TYPES.proposal) {
                // parsedContent is the parsed content from each message already stored in redux.
                const parsedContent = JSON.parse(msg.content);
                if (msg.id === messageId && parsedContent?.Id === Id) {
                  return {
                    ...msg,
                    content: JSON.stringify({ ...parsedContent, ProposalStatus })
                  };
                }

                return msg;
              }

              return msg;
            });
          }
        }
      }

      // This if statement below is in charge of setting up unread messages in the redux store
      if ((!isWorkingOnChatPage && messageType !== MESSAGE_TYPES.message) || (!isWorkingOnChatPage && !isCurrentMessageDoneByCurrentUser)) {
        const messageThreadIdAlreadyExists = state.unreadMessages.find((msg) => msg.threadId === messageThreadId);
        unreadMessagesToSet = !messageThreadIdAlreadyExists
          ? [...state.unreadMessages, { count: 1, threadId: messageThreadId }]
          : state.unreadMessages.map((msg) => {
            if (msg.threadId === messageThreadId) {
              return { ...msg, count: msg.count + 1 };
            }

            return msg;
          });

        return {
          ...state,
          unreadMessages: unreadMessagesToSet,
        };
      }

      if (isWorkingOnChatPage && isIncomingMessageForCurrentChat) {
        return {
          ...state,
          messages: [...currentChatMessages, newMessage],
        };
      }

      return { ...state };

    case actionsTypes.GET_UNREAD_MESSAGES:
      const unreadMessages = action.payload.map(({ messageThreadId, unreadMessages }) => ({ count: unreadMessages, threadId: messageThreadId }));
      return { ...state, unreadMessages };

    case actionsTypes.GET_INTEREST_BEGIN:
      return {
        ...state,
        error: null,
      };

    case actionsTypes.GET_INTEREST_SUCCESS:
      return {
        ...state,
        calculator: { ...action.payload.calculatorInfo, ...state.calculator },
      };

    case actionsTypes.GET_HISTORY_INFO_ERROR:
      return {
        ...state,
        error: true,
        historyInfo: {},
      };

    case actionsTypes.SET_LIKE_BEGIN:
      return {
        ...state,
        error: null,
        gaveLike: null,
      };

    case actionsTypes.SET_LIKE_SUCCESS:
      return {
        ...state,
        error: null,
        gaveLike: action.payload.gaveLike,
      };

    case actionsTypes.SET_LIKE_ERROR:
      return {
        ...state,
        error: true,
        gaveLike: null,
      };

    case actionsTypes.GET_WHO_REFER_ME_SUCCESS:
      return {
        ...state,
        referMe: action.payload.referMe,
      };

    case actionsTypes.GET_WHO_REFER_ME_ERROR:
      return {
        ...state,
        error: true,
        referMe: [],
      };

    case actionsTypes.GET_WHO_HAS_IM_REFERRED_SUCCESS:
      return {
        ...state,
        whoHasImReferred: action.payload.whoHasImReferred,
      };

    case actionsTypes.GET_WHO_HAS_IM_REFERRED_ERROR:
      return {
        ...state,
        error: true,
        whoHasImReferred: [],
      };

    case actionsTypes.GET_HISTORY_PROGRESS_BEGIN:
      return {
        ...state,
        error: null,
        historyProgress: 0,
      };

    case actionsTypes.GET_HISTORY_PROGRESS_SUCCESS:
      return {
        ...state,
        historyProgress: action.payload.historyProgress,
      };

    case actionsTypes.GET_HISTORY_PROGRESS_ERROR:
      return {
        ...state,
        error: true,
        historyProgress: 0,
      };

    case actionsTypes.CLEAR_HISTORIES:
      return {
        ...state,
        data: [],
        error: null,
        histories: [],
      };

    case ADD_NEW_CHAT_THREAD_ID:
      return {
        ...state,
        newChatMessageThreadId: action.payload,
      };

    case CLEAR_NEW_CHAT_THREAD_ID:
      return {
        ...state,
        newChatMessageThreadId: null
      };

    default:
      return state;
  }
};

export default historyReducer;
