// vendors
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// actions
import { getAdministrativeExpensesSettings } from '../../redux/actions/settings';
import { getIsLastPaymentReport, startTransactionRecord } from '../../redux/actions/payments';
import { getUserInfo } from '../../redux/actions/register';

// constants
import { routes } from '../../config/routes';
import { userIsGodson } from '../../config/constants';

// styles
import { useStyles } from './styles';

// utils
import { createWompiWidgetCheckout } from '../../utils/utils';
import { getLoanAmountFeeService, getLoanAmountFeeInsurance } from '../loan-calculator/utils';

// images
import { getHistoryById } from '../../redux/actions/createHistory';
import moneyIcon from '../../static/images/money.png';
import blueLogo from '../../static/images/logo3.svg';

const PaymentTransaction = () => {
  const {
    auth,
    history: { chatsHistory },
    payments: { isLastPayment },
    settings,
    transaction: { transactionsList },
  } = useSelector((state) => state);
  const currentHistoryId = useSelector((state) => state.history.chatsHistory.historyId);
  const currentHistory = useSelector((state) => state.history.historyInfo);

  const classes = useStyles({ isLastPayment });
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const [error, setError] = useState(false);

  const isGodson = userIsGodson(auth.userInfo.role);

  if (isGodson) {
    return null;
  }

  const { state: { transactionId, historyId, isInsuranceIncluded } } = useLocation();
  const amount = useMemo(() => currentHistory?.financingValue, [currentHistory]);
  const currentTransaction = transactionsList?.find((t) => t.transactionId === transactionId);
  const isInsuranceInTransactionIncluded = isInsuranceIncluded ?? currentTransaction?.isInsuranceIncluded;

  useEffect(() => {
    if (currentHistoryId || historyId) {
      dispatch(getHistoryById(currentHistoryId ?? historyId));
    }

    dispatch(getUserInfo());
    dispatch(getAdministrativeExpensesSettings());
  }, []);

  useEffect(() => {
    if (isGodson) {
      dispatch(getIsLastPaymentReport(params.transactionId));
    }
  }, [params.transactionId]);

  const loanFeeService = getLoanAmountFeeService({
    loanAmountWihoutAnyFees: amount,
    serviceFeePercentage: settings.administrativeExpensesSettings.FeessGivemeFive
  });

  const loanFeeInsurance = getLoanAmountFeeInsurance({
    loanAmountWihoutAnyFees: amount,
    insuranceFeePercentage: settings.administrativeExpensesSettings.Endorsement
  });

  const handleOnStartPayment = () => {
    dispatch(startTransactionRecord())
      .then((response) => {
        const transactionRecordId = response.data.result;
        const threadId = chatsHistory?.threadId ?? currentTransaction?.messageThreadId;
        const redirectPath = `${routes.transactionResult.replace(':transactionRecordId', transactionRecordId)}?threadId=${threadId}`;
        const payment = isInsuranceInTransactionIncluded ? (loanFeeService + loanFeeInsurance) * 100 : loanFeeService * 100;
        const reference = `${transactionRecordId};${transactionId}`;

        const wompiWidgetCheckout = createWompiWidgetCheckout({
          payment,
          redirectUrl: `${window.location.protocol}//${window.location.host}${redirectPath}`,
          reference
        });

        wompiWidgetCheckout.open(() =>
          history.push(redirectPath));
      })
      .catch(() => setError(true));
  };

  const renderPaymentSummary = () => (
    <Paper className={classes.paper} elevation={0}>
      <Grid
        container
        justify="center"
        className={classes.summaryContainer}
        spacing={0}
      >
        <Paper className={classes.messageContainer} elevation={0}>
          <Grid className={classes.summaryContentContainer} item xs={12}>
            <Grid className={classes.summaryTextContainer} xs={12}>
              <img className={classes.summaryImage} src={moneyIcon} />
            </Grid>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography className={classes.loanAmmountHeader} component="div">
                  COSTO DEL SERVICIO
                </Typography>
                <Typography className={classes.summaryNumber} component="div">
                  {numeral(loanFeeService + loanFeeInsurance).format('$0,0')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Paper>
  );

  return (
    <div className={classes.paymentsReportPage}>
      <Grid className={classes.subHeader} container>
        <Grid item xs={2}>
          <ArrowBackIcon
            className={classes.arrowBackIcon}
            onClick={() => history.goBack()}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <img className={classes.logo} src={blueLogo} />
        </Grid>
      </Grid>
      <Grid className={classes.summaryTitleContainer} item xs={12}>
          <Typography className={classes.summaryName} component="div">
            Resumen
          </Typography>
      </Grid>
      <Grid className={classes.summaryTextContainer} xs={12}>
        <Typography className={classes.summaryText}>
          <b>¡Gracias por querer apoyar esta historia! </b>
          Este préstamo lo realizas en dos pagos: el primero lo haces a través de Wompi,
          con estos recursos estamos desarrollando la infraestructura para poder juntar a ÁNGELES con BUENA PAGAS en un ambiente de confianza;
            además implementamos todo el sistema de cobranza para apoyarte en este proceso.
        </Typography>
        <br />
        <Typography className={classes.summaryText}>
        El segundo pago lo haces directamente a la cuenta del Buena Paga, una vez él o ella han firmado el pagaré,
         que es el título valor que los protege a ambos. ¡Es una dicha contar contigo!
        </Typography>
      </Grid>
      <Grid className={classes.form} container direction="column">
        {renderPaymentSummary()}
        <Grid item xs={12}>
          <Button
            className={classes.sendButton}
            color="primary"
            disabled={error}
            fullWidth
            onClick={handleOnStartPayment}
            variant="contained"
          >
            ENVIAR
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentTransaction;
