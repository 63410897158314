import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  MonthView,
  WeekView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useSelector, useDispatch } from 'react-redux';
import { getCalendarPayments } from '../../redux/actions/transaction';
import { useStyles } from './styles';
import MonthHeader from './month-header';
import CustomTimeTableCell from './custom-time-table-cells';

const todayDate = new Date();

function CalendarPayment() {
  const classes = useStyles();
  const history = useHistory();
  const [viewName, setViewName] = useState('Month');
  const [currentDate, setCurrentDate] = useState(todayDate);
  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.transaction);

  useEffect(() => {
    dispatch(
      getCalendarPayments({
        month: currentDate.getUTCMonth() + 1,
        year: currentDate.getUTCFullYear(),
      })
    );
  }, [currentDate, dispatch]);

  const DayScaleCell = (props) => (
    <MonthView.DayScaleCell {...props} style={{ textAlign: 'center', fontWeight: 'bold' }} />
  );

  const WeekScaleCell = (props) => (
    <WeekView.DayScaleCell {...props} style={{ textAlign: 'center', fontWeight: 'bold' }} />
  );

  const handleDateChange = (next) => {
    const nextDate = new Date(next);
    setCurrentDate(nextDate);
    dispatch(
      getCalendarPayments({
        month: nextDate.getUTCMonth() + 1,
        year: nextDate.getUTCFullYear(),
      })
    );
  };

  const Appointment = withStyles(classes, {
    name: 'Appointment',
  })(({ classes, ...restProps }) => (
    <Appointments.Appointment {...restProps} className={classes.appointment} />
  ));

  const AppointmentContent = withStyles(classes, {
    name: 'AppointmentContent',
  })(({ classes, ...restProps }) => (
    <Appointments.AppointmentContent {...restProps} className={classes.apptContent} />
  ));

  const ToolbarWithLoading = withStyles(classes, { name: 'Toolbar' })(
    ({ children, classes, ...restProps }) => (
      <div className={classes.toolbarRoot}>
        <Toolbar.Root {...restProps}>{children}</Toolbar.Root>
        <LinearProgress className={classes.progress} />
      </div>
    )
  );

  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <>
      <Paper className={classes.root}>
        <CloseIcon className={classes.closeIconCustom} onClick={() => routeChange('/main')} />
        <Container>
          <Grid container justify="center" className={classes.center}>
            <Grid item xs={12}>
              <h1 className={classes.supportTitle}>
                Calendario de pagos
              </h1>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <div>
        <div className={classes.buttonsContainer}>
          <button
            type="button"
            className={`${viewName === 'Month' ? 'active' : ''}`}
            onClick={() => setViewName('Month')}
          >
            Mes
          </button>
          <button
            type="button"
            className={`${viewName === 'Week' ? 'active' : ''}`}
            onClick={() => setViewName('Week')}
          >
            Semanal
          </button>
        </div>
        <div className={classes.schedulerContainer}>
          <MonthHeader currentDate={currentDate} />
          <Scheduler data={transaction.calendarPayment}>
            <ViewState
              defaultCurrentDate={currentDate}
              currentViewName={viewName}
              onCurrentDateChange={handleDateChange}
            />

            <MonthView
              timeTableCellComponent={(props) => (
                <CustomTimeTableCell {...props} currentMonth={currentDate.getMonth()} />
              )}
              dayScaleCellComponent={DayScaleCell}
            />

            <WeekView dayScaleCellComponent={WeekScaleCell} />

            <Appointments
              appointmentComponent={Appointment}
              appointmentContentComponent={AppointmentContent}
            />

            <Toolbar {...(transaction.loading ? { rootComponent: ToolbarWithLoading } : null)} />
            <DateNavigator />
            <TodayButton />
            <AppointmentTooltip showCloseButton />
          </Scheduler>
        </div>
      </div>
    </>
  );
}

export default CalendarPayment;
