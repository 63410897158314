/* eslint-disable max-len */

// vendors
import React from 'react';

const InterestRateEnabled = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0405 16.6009V20.7752H11.1297V22H9.85061H10.1468H8.86776V20.7752H7.95691V16.6009V16.5952C7.9403 16.5895 7.92369 16.5838 7.90985 16.5781C5.31295 15.683 3.44141 13.1653 3.44141 10.1958C3.44141 6.47894 6.37607 3.4668 9.99733 3.4668C13.6186 3.4668 16.5533 6.47894 16.5533 10.1958C16.5533 13.1738 14.6706 15.6972 12.0599 16.5867C12.0516 16.5895 12.0461 16.5923 12.0377 16.5952V16.6009H12.0405Z" fill="#FFC600" />
    <path d="M12.0432 17.7061H7.95959V18.4847H12.0432V17.7061Z" fill="#33647E" />
    <path d="M10.3793 0H9.62073V1.76466H10.3793V0Z" fill="#1DCAD3" />
    <path d="M1.71926 9.80957H0V10.5882H1.71926V9.80957Z" fill="#1DCAD3" />
    <path d="M19.9999 9.80957H18.2806V10.5882H19.9999V9.80957Z" fill="#1DCAD3" />
    <path d="M3.87525 15.9358L2.65955 17.1836L3.19594 17.7341L4.41164 16.4864L3.87525 15.9358Z" fill="#1DCAD3" />
    <path d="M16.8009 2.66724L15.5852 3.91504L16.1216 4.46559L17.3373 3.2178L16.8009 2.66724Z" fill="#1DCAD3" />
    <path d="M16.1187 15.9298L15.5824 16.4805L16.7983 17.7281L17.3346 17.1775L16.1187 15.9298Z" fill="#1DCAD3" />
    <path d="M3.19099 2.66322L2.65466 3.21387L3.87053 4.46148L4.40686 3.91084L3.19099 2.66322Z" fill="#1DCAD3" />
  </>
);

export default InterestRateEnabled;
