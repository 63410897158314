export function isObjectNullOrEmpty(obj) {
  return !obj || (obj && Object.keys(obj).length === 0 && obj.constructor === Object);
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}
