/* eslint-disable max-len */

// vendors
import React from 'react';

const PaymentDone = (
  <>
    <path d="M0.017984 21.0014L0.0894007 8.51047C0.114607 7.32383 1.08923 6.37036 2.28651 6.36203C3.44599 6.36203 4.39541 7.27803 4.42902 8.42303L4.38701 16.0675C4.38701 16.4339 4.68108 16.7295 5.05076 16.7378H5.05496C5.42045 16.742 5.72292 16.4464 5.72292 16.0841L5.79013 4.28854C5.81114 3.09774 6.78577 2.14427 7.98725 2.14011C9.15092 2.14011 10.1045 3.06027 10.1297 4.21776L10.0835 11.8455C10.0835 12.2119 10.3776 12.5076 10.7473 12.5159H10.7515C11.117 12.5159 11.4152 12.2244 11.4152 11.8622L11.4699 2.14844C11.4951 0.9618 12.4697 0.00416364 13.667 0C14.8306 0 15.7843 0.920163 15.8095 2.07765L15.7885 5.51265V5.54596L15.7549 12.4784C15.7591 12.8323 16.0447 13.1238 16.4018 13.1321C16.7715 13.1446 17.0782 12.8573 17.0908 12.4909C17.137 4.63413 17.1286 5.61674 17.1286 5.52514C17.1664 4.34684 18.1368 3.41002 19.3257 3.40169C20.4894 3.40169 21.443 4.32185 21.4682 5.47934L21.3884 19.2527C21.3884 19.4275 21.4556 19.5941 21.5816 19.7231C21.8421 19.9855 22.2664 19.9896 22.531 19.7315L25.3079 17.0001C26.1481 16.1591 27.5134 16.1424 28.3788 16.9585C28.3914 16.971 28.404 16.9835 28.4166 16.996C29.219 17.8245 29.1896 19.1402 28.3536 19.9355L19.0988 29.0372C16.9479 30.9108 14.1921 31.9601 11.327 31.9975C3.53001 32.1266 -0.29709 27.1636 0.017984 21.0014Z" fill="#1DCAD3" />
    <path d="M26.5935 21.9008C26.5935 26.4683 22.8589 30.1698 18.2504 30.1698C13.6419 30.1698 9.90723 26.4683 9.90723 21.9008C9.90723 17.3333 13.6419 13.6318 18.2504 13.6318C22.8547 13.6318 26.5935 17.3333 26.5935 21.9008Z" fill="#FFC600" />
    <path d="M18.2462 28.6209C14.5073 28.6209 11.4658 25.6064 11.4658 21.9008C11.4658 18.1951 14.5073 15.1807 18.2462 15.1807C21.9851 15.1807 25.0266 18.1951 25.0266 21.9008C25.0266 25.6064 21.9851 28.6209 18.2462 28.6209ZM18.2462 16.2174C15.0829 16.2174 12.5077 18.7697 12.5077 21.9049C12.5077 25.0402 15.0829 27.5925 18.2462 27.5925C21.4095 27.5925 23.9847 25.0402 23.9847 21.9049C23.9847 18.7656 21.4095 16.2174 18.2462 16.2174Z" fill="white" />
    <path d="M18.2463 25.5189C17.0952 25.5189 16.1626 24.5904 16.1626 23.4537H17.2044C17.2044 24.0241 17.6707 24.4863 18.2463 24.4863C18.8218 24.4863 19.2881 24.0241 19.2881 23.4537C19.2881 22.8833 18.8218 22.4211 18.2463 22.4211C17.0952 22.4211 16.1626 21.4926 16.1626 20.3518C16.1626 19.2109 17.0994 18.2866 18.2463 18.2866C19.3974 18.2866 20.3342 19.2151 20.3342 20.3518H19.2923C19.2923 19.7814 18.826 19.3192 18.2505 19.3192C17.675 19.3192 17.2086 19.7814 17.2086 20.3518C17.2086 20.9222 17.675 21.3844 18.2505 21.3844C19.4016 21.3844 20.3384 22.3129 20.3384 23.4495C20.3384 24.5862 19.3974 25.5189 18.2463 25.5189Z" fill="white" />
    <path d="M18.7669 17.25H17.7251V18.7989H18.7669V17.25Z" fill="white" />
    <path d="M18.7669 25.0024H17.7251V26.5555H18.7669V25.0024Z" fill="white" />
  </>
);

export default PaymentDone;
