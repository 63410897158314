/* eslint-disable max-len */

// vendors
import React from 'react';

const FinalizedStory = (
  <>
    <path d="M31.49 41.33L15.74 36.33L0 41.33V0H31.49V41.33Z" fill="#FFC600" />
    <path d="M15.74 6.55005L18.71 12.55L25.33 13.51L20.54 18.18L21.67 24.78L15.74 21.67L9.82 24.78L10.95 18.18L6.16 13.51L12.78 12.55L15.74 6.55005Z" fill="#F93549" />
  </>
);

export default FinalizedStory;
