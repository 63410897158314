// http
import request from '../request';
import env from '../env';

const activityApi = {
  getMyActivities: ({ maxResultCount = 20, skipCount = 0 } = {}) =>
    request(`${env.mainBaseUrl}/services/app/Activity/GetMyActivities?MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`, 'get')
};

export default activityApi;
