/* eslint-disable no-unused-vars */
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';

// redux
import { clearFogotPasswordOk, checkIsResetCodeValid, onRecoverPassword } from '../../redux/actions/user';

// routes
import { routes } from '../../config/routes';

// components
import GmLogo from '../../components/logo';
import { NewPasswprd } from '../../components/forms/new-password';

// styles
import Image from '../../static/images/forgot.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#ffbe38',
    backgroundPosition: 'center',
    display: 'grid',
    height: '45vh',
    gridTemplateRows: 'auto 1fr auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  forgotPassword: {
    marginTop: '3vh',
    width: '90%',
  },
  textField: {
    width: '90%',
  },
  center: {
    textAlign: 'center',
  },
  textFollowInstruction: {
    textAlign: 'center',
    marginTop: '10vh',
  },
}));

function RecoverPassword({
  clearFogotPasswordOk,
  checkIsResetCodeValid,
  onRecoverPassword,
  loading
}) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [sendPasswordOk, setSendPasswordOk] = useState(false);
  const [isResetCodeValid, setIsResetCodeValid] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const resetCode = urlSearchParams.get('resetCode');
    const userId = urlSearchParams.get('userId');

    checkIsResetCodeValid({ resetCode, userId }).then(({ data: { result } }) => setIsResetCodeValid(result));
  }, []);

  const onSubmitPassword = (values) => {
    const urlSearchParams = new URLSearchParams(location.search);
    const resetCode = urlSearchParams.get('resetCode');
    const userId = urlSearchParams.get('userId');

    const data = {
      password: values.password,
      resetCode,
      userId
    };

    onRecoverPassword(data).then(() => {
      setSendPasswordOk(true);
      setTimeout(() => {
        setSendPasswordOk(false);
        setIsResetCodeValid(false);
        clearFogotPasswordOk();
        history.push(routes.login);
      }, 3500);
    });
  };

  return (
    <>
      <Paper className={classes.root}>
        <Container>
          <Grid container justify="center" className={classes.center}>
            <Grid item xs={12} className={classes.forgotPassword}>
              <GmLogo />
            </Grid>
            <Grid item xs={12} className={classes.forgotPassword}>
              <Typography variant="body2" gutterBottom>
                ¿Olvidaste tu contraseña?
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Grid container justify="center">
        <Grid item className={classes.forgotPassword}>
          {!sendPasswordOk && isResetCodeValid && (
            <Typography variant="body2" gutterBottom>
              Por favor ingresa tu nueva Contraseña
            </Typography>
          )}
          {!isResetCodeValid && (
            <Typography variant="body2" gutterBottom>
              Parece que el código de recuperación es incorrecto o ha expirado, intentelo de nuevo.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6} className={classes.center}>
          {!sendPasswordOk && isResetCodeValid && (
            <NewPasswprd isResetCodeValid={isResetCodeValid} onSubmitPassword={onSubmitPassword} loading={loading} />
          )}
          {sendPasswordOk && (
            <Grid item xs={12} md={6} className={classes.textFollowInstruction}>
              <Typography variant="body2" gutterBottom>
                Tu contraseña ha sido cambiada con exito, en un momento serás redirigido al inicio de sesión.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ auth, common }) => ({
  loading: common.loading,
  sendPasswordOk: auth.sendPasswordOk,
  error: auth.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearFogotPasswordOk,
      checkIsResetCodeValid,
      onRecoverPassword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
