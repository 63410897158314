// vendors
import React from 'react';

const NotificationsDisabled = (
  <>
    <path d="M46.39 18.9699H31L39.66 0.679932H20.41L15.6 26.6699H28.11L16.56 56.4999L46.39 18.9699Z" fill="#7D7D7D" />
    <path d="M9.81998 27.63H0.199982V29.56H9.81998V27.63Z" fill="#CFD2D7" />
    <path d="M57.94 27.63H48.32V29.56H57.94V27.63Z" fill="#CFD2D7" />
    <path d="M56.7889 -0.00121173L50.0502 6.7384L51.408 8.09604L58.1467 1.35642L56.7889 -0.00121173Z" fill="#CFD2D7" />
    <path d="M6.74828 48.1221L0.00866699 54.8607L1.3663 56.2185L8.10592 49.4799L6.74828 48.1221Z" fill="#CFD2D7" />
    <path d="M51.4019 49.0891L50.0443 50.4468L56.7829 57.1854L58.1405 55.8278L51.4019 49.0891Z" fill="#CFD2D7" />
    <path d="M1.35666 -0.00326206L-0.000976562 1.35437L6.73769 8.09303L8.09532 6.7354L1.35666 -0.00326206Z" fill="#CFD2D7" />
  </>
);

export default NotificationsDisabled;
