// vendors
import { makeStyles } from '@material-ui/core/styles';

// images
import seekSupportImage from '../../static/images/seek-support.png';
import giveSupportImage from '../../static/images/give-support.png';

export const useStyles = makeStyles(() => ({
  buttonText: {
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  continueButton: {
    backgroundColor: '#1DCAD3',
    color: '#FFFFFF',
    fontWeight: 'bold',
    margin: '-25px 25px 25px 25px',
    width: '95%',
  },
  root: {
    boxShadow: 'none',
    padding: '20px 0px',
  },
  brandLogo: {
    marign: 20,
  },
  supportTitle: {
    marginBottom: 15,
    fontSize: 20,
    fontWeight: 'bold',
  },
  supportDesc: {
    width: 300,
    margin: '0 auto',
  },
  supportDescription: {
    padding: '0 20px',
    width: 210,
  },
  arrowBackIcon: {
    position: 'absolute',
    marginTop: 58,
    marginLeft: 22,
  },
  seekSupport: {
    alignContent: 'flex-end',
    backgroundImage: `url(${seekSupportImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 25,
    display: 'flex',
    height: 280,
    justifyContent: 'center',
    marginBottom: 15,
    width: '90%',
  },
  giveSupport: {
    alignContent: 'flex-start',
    backgroundImage: `url(${giveSupportImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 25,
    display: 'flex',
    height: 280,
    justifyContent: 'center',
    marginBottom: 15,
    width: '90%',
  },
  actionText: {
    fontStyle: 'normal',
    color: '#FFFFFF',
    lineHeight: '140.1%',
    fontSize: 16,
  },
  cardTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    textAlign: 'center',
    color: '#fff',
  },
  supportCard: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
