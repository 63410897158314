/* eslint-disable max-len */

// vendors
import React from 'react';

const EyesDisabled = (
  <>
    <path d="M14.012 0H13.0657V2.9764H14.012V0Z" fill="#FFC600" />
    <path d="M21.3822 1.4622L19.8444 4.02429L20.659 4.49864L22.1968 1.93655L21.3822 1.4622Z" fill="#FFC600" />
    <path d="M5.69943 1.46041L4.88464 1.93448L6.42155 4.49716L7.23634 4.0231L5.69943 1.46041Z" fill="#FFC600" />
    <path d="M14.012 22.0236H13.0657V25H14.012V22.0236Z" fill="#FFC600" />
    <path d="M6.41953 20.5047L4.88171 23.0668L5.69631 23.5412L7.23413 20.9791L6.41953 20.5047Z" fill="#FFC600" />
    <path d="M20.6578 20.5047L19.843 20.9789L21.3802 23.5415L22.195 23.0674L20.6578 20.5047Z" fill="#FFC600" />
    <path d="M27 12.6666C27 12.6666 20.9563 19.3354 13.5 19.3354C6.04368 19.3354 0 12.6666 0 12.6666C0 12.6666 6.04368 5.99786 13.5 5.99786C20.9563 5.99786 27 12.6666 27 12.6666Z" fill="#33647E" />
    <path d="M13.5092 7.73593C16.275 7.73593 18.5197 9.94685 18.5197 12.6711C18.5197 15.3953 16.2796 17.6062 13.5092 17.6062C10.7433 17.6062 8.49866 15.3953 8.49866 12.6711C8.50323 9.94234 10.7433 7.73593 13.5092 7.73593Z" fill="white" />
    <path d="M13.5092 11.0051C14.4418 11.0051 15.1961 11.748 15.1961 12.6666C15.1961 13.5852 14.4418 14.3282 13.5092 14.3282C12.5766 14.3282 11.8223 13.5852 11.8223 12.6666C11.8223 11.7525 12.5812 11.0051 13.5092 11.0051Z" fill="#1DCAD3" />
  </>
);

export default EyesDisabled;
