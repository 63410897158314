export const MESSAGE_TYPES = {
  accountData: 3,
  disbursementButton: 12,
  feeAndInsuranceButton: 14,
  lastPaymentConfirmation: 7,
  loanConfirmation: 5,
  message: 0,
  messageUpdated: 13,
  notification: 1,
  paymentComment: 4,
  paymentConfirmation: 6,
  paymentConfirmed: 9,
  proposal: 2,
  proposalAccepted: 8,
  signPromissoryNote: 10,
  systemMessage: 11,
};

export const MESSAGE_TYPES_ENUM = {
  0: 'message',
  10: 'signPromissoryNote',
  11: 'systemMessage',
  12: 'disbursementButton',
  13: 'messageUpdated',
  14: 'feeAndInsuranceButton',
  1: 'notification',
  2: 'proposal',
  3: 'accountData',
  4: 'paymentComment',
  5: 'loanConfirmation',
  6: 'paymentConfirmation',
  7: 'lastPaymentConfirmation',
  8: 'proposalAccepted',
  9: 'paymentConfirmed',
};
