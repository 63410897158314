// initial state
import { activityInitialState } from '../../config/state/initialState';

// actions
import {
  GET_MY_ACTIVITIES_BEGIN,
  GET_MY_ACTIVITIES_ERROR,
  GET_MY_ACTIVITIES_SUCCESS
} from '../action-types';

const activityReducer = (state = activityInitialState, action) => {
  switch (action.type) {
    case GET_MY_ACTIVITIES_BEGIN:
    case GET_MY_ACTIVITIES_ERROR:
      return {
        ...state,
        items: [],
        totalCount: 0
      };

    case GET_MY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        totalCount: action.payload.totalCount
      };

    default:
      return state;
  }
};

export default activityReducer;
