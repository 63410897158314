// packages
import React from 'react';
import Carousel from 'react-elastic-carousel';

// components
import GmSvgIcon from '../../static/svg';

// styles
import useStyles from './styles';

const GmStoryCarousel = ({
  storyPictures,
  isStoryFinalized,
}) => {
  const classes = useStyles();

  return (storyPictures.length > 0 && (
    <Carousel
      className={classes.gmStoryCardCarouselContainer}
      enableMouseSwipe
      enableSwipe
      initialFirstItem={0}
      itemsToShow={1}
      showArrows={false}
    >
      {storyPictures.map((picture) => (
        <div
          className={classes.gmStoryCardCarouselItem}
          key={picture}
          style={{ backgroundImage: `url("${picture}")` }}
        >
          {isStoryFinalized && (
            <GmSvgIcon
              className={classes.gmStoryCardCarouselFinalizedStoryBadge}
              icon="finalized-story"
              viewBox="0 0 32 42"
            />
          )}
        </div>
      ))}
    </Carousel>
  ));
};

export default GmStoryCarousel;
