// vendors
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

// components
import GmSvgIcon from '../../static/svg';

// environment
import {
  STORY_STATUS,
  userIsGodson,
  userIsGodfather,
} from '../../config/constants';
import { routes } from '../../config/routes';

// styles
import { useStyles } from './styles';

export const GmStoriesList = ({
  canCreateNewHistory,
  loading,
  profileVisitingUserId,
  stories,
  userRole,
  visible,
}) => {
  const classes = useStyles();
  const history = useHistory();

  if (!visible || loading) {
    return null;
  }

  const handleOnAddStory = (created) => {
    if (canCreateNewHistory && !created) {
      history.push('create-history');
    }
  };

  const renderCreateStoryCard = () => {
    if (userIsGodfather(userRole) || (userIsGodson(userRole) && profileVisitingUserId)) {
      return null;
    }

    const created = Boolean(stories?.find((story) => story.status === STORY_STATUS.created));

    return (
      <Grid item xs={4}>
        <Card
          className={classes.gmStoryCard}
          onClick={() => handleOnAddStory(created)}
          variant="outlined"
        >
          <CardContent>
            <div className={classes.gmStoryCardIconContainer}>
              <GmSvgIcon
                disabled={created || !canCreateNewHistory}
                enabled={!created || canCreateNewHistory}
                icon="add-story"
                viewBox="0 0 78 76"
              />
            </div>
            <div className={classes.gmAddStoryCardLabelContainer}>
              <Typography className={classes.gmAddStoryCardLabel}>
                Crear historia
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderStoryCard = (story) => {
    const finalized = story?.status === STORY_STATUS.finalized;

    return (
      <Card
        className={classes.gmStoryCard}
        onClick={() => history.push(routes.history.replace(':id', story?.id))}
        variant="outlined"
      >
        {finalized
          ? (
            <GmSvgIcon
              className={classes.gmFinalizedStoryBadge}
              icon="finalized-story"
              viewBox="0 0 32 42"
            />
          )
          : null}
        <CardMedia
          className={classes.gmStoryCardImage}
          image={story?.defaultImage}
          title={story?.id}
        />
      </Card>
    );
  };

  const renderCreatedStoryCard = (story) => (
    <Card
      className={classes.gmStoryCard}
      onClick={() => history.push(routes.history.replace(':id', story.id))}
      variant="outlined"
    >
      <CardContent>
        <div className={classes.gmStoryCardIconContainer}>
          <GmSvgIcon
            disabled={story?.status !== STORY_STATUS.created}
            enabled={story?.status === STORY_STATUS.created}
            icon="add-story"
            viewBox="0 0 78 76"
          />
        </div>
        <div className={classes.gmAddStoryCardLabelContainer}>
          <Typography className={classes.gmAddStoryCardLabel}>
            ¡Aún no has terminado esta historia!
          </Typography>
        </div>
      </CardContent>
    </Card>
  );

  const renderStoriesCards = () => stories
    .map((story) => (
      <Grid key={story.id} item xs={4}>
        {story.status === STORY_STATUS.created ? renderCreatedStoryCard(story) : renderStoryCard(story)}
      </Grid>
    ));

  return (
    <Grid container direction="row">
      {renderCreateStoryCard()}
      {renderStoriesCards()}
    </Grid>
  );
};

export default GmStoriesList;
