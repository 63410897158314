import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  bottomNavigation: {
    borderTop: '1px solid #CFD2D7',
    bottom: 0,
    height: 60,
    position: 'fixed',
    width: '100%',
    zIndex: 1
  },
  bottomNavigationItem: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: -10,
  },
  bottomNavigationItemLabel: {
    marginTop: -10,
  },
  unreadMessages: {
    backgroundColor: '#74C8D2',
    color: '#FFFFFF',
  }
});
