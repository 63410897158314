/* eslint-disable max-len */

// vendors
import React from 'react';

const PaymentIcon = (
  <>
    <path d="M14.9964 0L0 10.8519L5.72421 28.4211H24.2686L30 10.8519L14.9964 0Z" fill="#FFC600" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1914 12.4901C11.1914 10.397 12.895 8.7002 14.9965 8.7002C17.0979 8.7002 18.8015 10.397 18.8015 12.4901H16.5493C16.5493 11.6359 15.8541 10.9434 14.9965 10.9434C14.1388 10.9434 13.4436 11.6359 13.4436 12.4901C13.4436 13.3443 14.1388 14.0367 14.9965 14.0367C17.0979 14.0367 18.8015 15.7336 18.8015 17.8266C18.8015 19.9197 17.0979 21.6165 14.9965 21.6165C12.895 21.6165 11.1914 19.9197 11.1914 17.8266H13.4436C13.4436 18.6808 14.1388 19.3733 14.9965 19.3733C15.8541 19.3733 16.5493 18.6808 16.5493 17.8266C16.5493 16.9724 15.8541 16.28 14.9965 16.28C12.895 16.28 11.1914 14.5832 11.1914 12.4901Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8702 9.81462V7.14635H16.1224V9.81462H13.8702Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8702 23.1703V20.502H16.1224V23.1703H13.8702Z"
      fill="white"
    />
  </>
);

export default PaymentIcon;
