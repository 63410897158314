// vendors
import React from 'react';

const KarmaAlert = (
  <>
    <path d="M2.89795 18.4334C2.89795 12.0399 8.09336 6.85498 14.4998 6.85498C20.9062 6.85498 26.1016 12.0399 26.1016 18.4334" fill="#F93549" />
    <path d="M29 17.8384H0V23.0002H29V17.8384Z" fill="#FFC600" />
    <path d="M15.2811 0H13.7188V4.96811H15.2811V0Z" fill="#FFC600" />
    <path d="M26.9141 4.54711L23.394 8.06006L24.4988 9.16254L28.0188 5.64959L26.9141 4.54711Z" fill="#FFC600" />
    <path d="M2.11351 4.54287L1.00879 5.64551L4.52935 9.15846L5.63406 8.05582L2.11351 4.54287Z" fill="#FFC600" />
  </>
);

export default KarmaAlert;
