// constants
import * as actionsTypes from '../action-types';
// services
import transactionApi from '../../config/api/transaccion';

const sendSMSCodeBegin = () => ({ type: actionsTypes.SET_SEND_SMS_CODE_BEGIN });
const validationCodeBegin = () => ({ type: actionsTypes.SET_VALIDATE_CODE_BEGIN });
const createTransactionBegin = () => ({ type: actionsTypes.SET_CREATE_TRANSACTION_BEGIN });
const getPaymentCalendarBegin = () => ({ type: actionsTypes.GET_PAYMENT_CALENDAR_BEGIN });
const setReferredUser = () => ({ type: actionsTypes.SET_REFERRED_USER_BEGIN });

export const sendSMSCode = ({ countryCode, phoneNumber }) => (dispatch) => {
  dispatch(sendSMSCodeBegin());

  return new Promise((resolve, reject) => {
    transactionApi
      .sendSMSCode({ phoneCode: countryCode, phoneNumber })
      .then(({ data }) => {
        resolve(data.success);
        dispatch({
          type: actionsTypes.SET_SEND_SMS_CODE_SUCCESS,
          payload: {
            success: data.success,
          },
        });
      })
      .catch((error) => {
        reject(error);
        dispatch({ type: actionsTypes.SET_SEND_SMS_CODE_ERROR, error });
      });
  });
};

export const validationCode = ({ code, phoneCode, phoneNumber }) => (dispatch) => {
  dispatch(validationCodeBegin());

  return new Promise((resolve) => {
    transactionApi
      .validateCode({ code, phoneCode, phoneNumber })
      .then(({ data }) => {
        dispatch({
          type: actionsTypes.SET_VALIDATE_CODE_SUCCESS,
          payload: {
            success: data.success,
          },
        });

        resolve(data.result);
      })
      .catch((error) => dispatch({ type: actionsTypes.SET_VALIDATE_CODE_ERROR, error }));
  });
};

export const createTransaction = (data) => (dispatch) => {
  dispatch(createTransactionBegin());

  return new Promise((resolve) => {
    transactionApi
      .createTransaction(data)
      .then(({ data }) => {
        dispatch({
          type: actionsTypes.SET_CREATE_TRANSACTION_SUCCESS,
          payload: {
            success: data.success,
            transactionId: data?.result?.transactionId,
          },
        });

        resolve();
      })
      .catch((error) => dispatch({ type: actionsTypes.SET_CREATE_TRANSACTION_ERROR, error }));
  });
};

export const getCalendarPayments = (data) => (dispatch) => {
  dispatch(getPaymentCalendarBegin());

  return transactionApi
    .getPaymentCalendar(data)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_PAYMENT_CALENDAR_SUCCESS,
        payload: {
          calendarPayments: data.result,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.GET_PAYMENT_CALENDAR_ERROR, error }));
};

export const setRefferedUser = (data) => (dispatch) => {
  dispatch(setReferredUser());

  return transactionApi
    .setRefferUser(data)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.SET_REFERRED_USER_SUCCESS,
        payload: {
          refferUser: data.result,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.SET_REFERRED_USER_ERROR, error }));
};
