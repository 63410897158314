// vendors
import React from 'react';

const CalculatorEnabled = (
  <>
    <path d="M60.11 0H0V75.44H60.11V0Z" fill="#33647E" />
    <path d="M53.28 6.84998H6.84003V21.81H53.28V6.84998Z" fill="#FF8189" />
    <path d="M15.86 29.71H6.84003V36.95H15.86V29.71Z" fill="#FFC600" />
    <path d="M28.33 29.71H19.31V36.95H28.33V29.71Z" fill="#FFC600" />
    <path d="M40.8 29.71H31.78V36.95H40.8V29.71Z" fill="#FFC600" />
    <path d="M53.28 29.71H44.26V36.95H53.28V29.71Z" fill="#FFC600" />
    <path d="M15.86 39.87H6.84003V47.11H15.86V39.87Z" fill="#FFC600" />
    <path d="M28.33 39.87H19.31V47.11H28.33V39.87Z" fill="#FFC600" />
    <path d="M40.8 39.87H31.78V47.11H40.8V39.87Z" fill="#FFC600" />
    <path d="M53.28 39.87H44.26V47.11H53.28V39.87Z" fill="#FFC600" />
    <path d="M15.86 50.03H6.84003V57.27H15.86V50.03Z" fill="#FFC600" />
    <path d="M28.33 50.03H19.31V57.27H28.33V50.03Z" fill="#FFC600" />
    <path d="M40.8 50.03H31.78V57.27H40.8V50.03Z" fill="#FFC600" />
    <path d="M53.28 50.03H44.26V57.27H53.28V50.03Z" fill="#FFC600" />
    <path d="M15.86 60.1899H6.84003V67.4299H15.86V60.1899Z" fill="#FFC600" />
    <path d="M28.33 60.1899H19.31V67.4299H28.33V60.1899Z" fill="#FFC600" />
    <path d="M40.8 60.1899H31.78V67.4299H40.8V60.1899Z" fill="#FFC600" />
    <path d="M53.28 60.1899H44.26V67.4299H53.28V60.1899Z" fill="#FFC600" />
  </>
);

export default CalculatorEnabled;
