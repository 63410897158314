import { makeStyles } from '@material-ui/core/styles';

import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffff',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    boxShadow: 'none',
  },
  closeIconCustom: {
    position: 'absolute',
    marginTop: 37,
    marginLeft: 22,
    fontSize: 28,
  },
  supportTitle: {
    marginTop: 38,
    fontSize: 20,
  },
  cell: {
    color: '#78909C!important',
    position: 'relative',
    userSelect: 'none',
    verticalAlign: 'top',
    padding: 0,
    height: 100,
    '&:first-child': {
      borderLeft: 'none',
    },
    '&:last-child': {
      paddingRight: 0,
    },
    'tr:last-child &': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: fade(theme.palette.primary.main, 0.15),
      outline: 0,
    },
  },
  otherMonthCell: {
    backgroundColor: '#f0f0f0',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
  },
  text: {
    padding: '0.5em',
    textAlign: 'center',
  },
  opacity: {
    opacity: '0.5',
  },
  appointment: {
    backgroundColor: 'red !important',
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.6,
    },
  },
  apptContent: {
    '&>div>div': {
      whiteSpace: 'normal !important',
      lineHeight: 1.2,
    },
  },
  tooltipContent: {
    padding: theme.spacing(3, 1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box',
    width: '400px',
  },
  tooltipText: {
    ...theme.typography.body2,
    display: 'inline-block',
  },
  icon: {
    color: theme.palette.action.active,
    verticalAlign: 'middle',
  },
  circle: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    verticalAlign: 'super',
  },
  textCenter: {
    textAlign: 'center',
  },
  dateAndTitle: {
    lineHeight: 1.1,
  },
  titleContainer: {
    paddingBottom: theme.spacing(2),
  },
  container: {
    paddingBottom: theme.spacing(1.5),
  },
  schedulerContainer: {
    margin: '10px 20px',
    maxHeight: '75vh',
    overflowY: 'scroll',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '70%',
    alignItems: 'center',
    margin: '20px auto',
    '& button': {
      border: 'none',
      background: 'transparent',
      color: '#0F1E36',
      fontSize: 15,
      fontWeight: 'normal',
      cursor: 'pointer',
      '&.active': {
        color: '#1DCAD3',
        outline: 'none',
      },
    },
  },
  monthHeader: {
    textAlign: 'center',
    padding: '10px 0',
    fontSize: '1.5em',
    fontWeight: 'bold',
  }
}));

export { useStyles };
