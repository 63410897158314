// vendors
import React from 'react';

const AlertEnabled = (
  <>
    <path d="M6.27002 39.96C6.27002 26.1 17.51 14.86 31.37 14.86C45.23 14.86 56.47 26.1 56.47 39.96" fill="#F93549" />
    <path d="M62.74 38.67H0V49.86H62.74V38.67Z" fill="#FFC600" />
    <path d="M33.0601 0H29.6801V10.77H33.0601V0Z" fill="#FFC600" />
    <path d="M58.2269 9.8564L50.6115 17.4719L53.0014 19.8619L60.6169 12.2464L58.2269 9.8564Z" fill="#FFC600" />
    <path d="M4.57213 9.84856L2.18213 12.2389L9.79867 19.8544L12.1887 17.464L4.57213 9.84856Z" fill="#FFC600" />
  </>
);

export default AlertEnabled;
