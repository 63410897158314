// @packages
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  itemLabel: {
    color: '#0F1E36',
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 25,
    paddingLeft: 15,
    textAlign: 'left',
  },
  latePaymentSummary: {
    padding: 20
  },
  latePaymentValueLabel: {
    color: '#676767',
    fontSize: 14,
    fontWeight: 400,
  },
  latePaymentValue: {
    color: '#33647E',
    fontSize: 32,
    fontWeight: 700,
  },
}));
