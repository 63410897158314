import React from 'react';
// images
import page1 from '../../static/images/onNew1.svg';
import page2 from '../../static/images/onNew2.svg';
import page3 from '../../static/images/onNew3.svg';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

const items = [
  {
    image: page1,
    content: <Step1 />,
  },
  {
    image: page2,
    content: <Step2 />
  },
  {
    image: page3,
    content: <Step3 />
  },
];

export default items;
