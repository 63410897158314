// vendors
import React from 'react';

const NotificationsEnabled = (
  <>
    <path d="M18.3517 7.29873H12.2635L15.6893 0.261658H8.07409L6.17126 10.2613H11.1202L6.55104 21.7384L18.3517 7.29873Z" fill="#FFC600" />
    <path d="M3.88474 10.6306H0.0791016V11.3732H3.88474V10.6306Z" fill="#FF8189" />
    <path d="M22.9209 10.6306H19.1152V11.3732H22.9209V10.6306Z" fill="#FF8189" />
    <path d="M22.4654 -0.000353211L19.7992 2.59235L20.3363 3.11477L23.0024 0.52207L22.4654 -0.000353211Z" fill="#FF8189" />
    <path d="M2.66799 18.5143L0.00219727 21.107L0.54207 21.6321L3.20786 19.0394L2.66799 18.5143Z" fill="#FF8189" />
    <path d="M20.3335 18.8859L19.7966 19.4084L22.4636 22L23.0004 21.4775L20.3335 18.8859Z" fill="#FF8189" />
    <path d="M0.542069 -0.00114355L0.00219727 0.523926L2.66799 3.11663L3.20786 2.59156L0.542069 -0.00114355Z" fill="#FF8189" />
  </>
);

export default NotificationsEnabled;
