import React from 'react';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';

function GmSimpleMessage({ messageValue, optionalContent }) {
  const classes = useStyles();
  const { content } = messageValue;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography
            style={{
              wordWrap: 'break-word',
              maxWidth: 272,
              justify: 'left'
            }}
            component="div"
          >
            {optionalContent ?? content}
          </Typography>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <Typography className={classes.messageDate} component="div" variant="caption" gutterBottom>
            {moment(messageValue.creationTime).format('YYYY/MM/DD, h:mm a')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GmSimpleMessage;
