import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  option: {
    width: '100%',
    textAlign: 'left',
    '& > span': {
      fontSize: 18,
      textAlign: 'left'
    },
  },
});

export const GmAutoComplete = ({
  error = false,
  helperText = '',
  label = '',
  onOptionChange,
  optionLabelShown,
  options,
  required = false,
  style,
  value = '',
}) => {
  const classes = useStyles();
  const defaulValue = options.length > 0 && value ? options.find((ps) => ps.name === value || ps.value === value) : {};

  return (
    <>
      <Autocomplete
        autoHighlight
        autoSelect
        classes={{ option: classes.option }}
        defaultValue={defaulValue}
        getOptionLabel={(option) => option.name || option[optionLabelShown] || ''}
        id={label}
        inputValue={defaulValue.name}
        onChange={(_event, newValue) => onOptionChange(newValue)}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            label={label}
            inputProps={{
              ...params.inputProps,
              // TODO: disable autocomplete and autofill
              autoComplete: 'new-password',
            }}
            required={required}
            variant="outlined"
          />
        )}
        renderOption={(option) => <>{option.name || option[optionLabelShown] || ''}</>}
        style={{ width: '100%', textAlign: 'left', ...style }}
      />
    </>
  );
};

export default GmAutoComplete;
