// vendors
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

// constants
import { routes } from '../../config/routes';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    textAlign: 'initial',
  },
  primaryText: {
    fontWeight: 'bold',
    textAlign: 'initial',
  },
}));

export const GmPeopleCard = ({ contacts, visible }) => {
  const classes = useStyles();
  const history = useHistory();

  if (!visible) {
    return null;
  }

  return (
    <List dense className={classes.root}>
      {Array.isArray(contacts) && contacts.length
        ? contacts.map((contact) => (
          <ListItem key={contact.id} button>
            <ListItemAvatar>
              <Avatar
                alt={`Avatar n°${contact.id}`}
                onClick={() => history.push(routes.main.replace(':userId?', contact.id))}
                src={contact.profilePicture}
              />
            </ListItemAvatar>
            <ListItemText
              classes={{ primary: classes.primaryText }}
              id={contact.id}
              onClick={() => history.push(routes.main.replace(':userId?', contact.id))}
              primary={contact.fullName}
            />
          </ListItem>
        ))
        : null}
    </List>
  );
};

export default GmPeopleCard;
