// constants
import env from '../env';

// http
import request from '../request';

const paymentsApi = {
  confirmLastPayment({
    messageId,
    paymentId,
    review,
    transactionId,
  }) {
    return request(`${env.mainBaseUrl}/services/app/Transaction/ReviewToGodson`, 'post', {
      messageId,
      paymentId,
      review,
      transactionId,
    });
  },

  confirmPayment({ messageId, paymentId }) {
    return request(`${env.mainBaseUrl}/services/app/Transaction/ConfirmPayment`, 'post', { messageId, paymentId });
  },

  getAllActiveTransactions() {
    return request(`${env.mainBaseUrl}/services/app/Transaction/GetAllActiveTransactions?MaxResultCount=100&SkipCount=0`, 'get');
  },

  getReferralPerimeterStatus() {
    return request(`${env.mainBaseUrl}/services/app/Transaction/GetReferralPerimeterStatus`, 'get');
  },

  getDetail(transactionId) {
    return request(
      `${env.mainBaseUrl}/services/app/Transaction/GetDetail?TransactionId=${transactionId}`,
      'get'
    );
  },

  getDetailActions({ skip, take, transactionId }) {
    return request(
      `${env.mainBaseUrl}/services/app/Transaction/GetTransactionActions?TransactionId=${transactionId}&SkipCount=${skip}&MaxResultCount=${take}`,
      'get'
    );
  },

  getIsLastPaymentReport(transactionId) {
    return request(`${env.mainBaseUrl}/services/app/PaymentCalendar/IsTheLastFee`, 'post', { transactionId });
  },

  registerLastPaymentReport(report) {
    return request(`${env.mainBaseUrl}/services/app/Transaction/RegisterLastPaymentTransfer`, 'post', {
      ...report,
    });
  },

  registerPaymentReport(report) {
    return request(`${env.mainBaseUrl}/services/app/Transaction/RegisterPaymentTransfer`, 'post', {
      ...report,
    });
  },

  // TODO: This must be the first payment to be report and must be done by a Godfather.
  registerLoanTransfer(report) {
    return request(`${env.mainBaseUrl}/services/app/Transaction/RegisterLoanTransfer`, 'post', {
      ...report,
    });
  },

  startTransactionRecord: () =>
    request(`${env.mainBaseUrl}/services/app/TransactionRecord/StartTransactionRecord`, 'post'),

  getTransactionRecord: (transactionRecordId) =>
    request(
      `${env.mainBaseUrl}/services/app/TransactionRecord/GetTransactionRecordById?transactionRecordId=${transactionRecordId}`,
      'get'
    )
};

export default paymentsApi;
