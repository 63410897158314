import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import cameraIcon from '../../static/images/cameraIcon.png';

// utils
import { getBase64 } from '../../utils';

const GmUploadPicture = ({
  callback,
  containerStyle,
  customStyle,
  isImage,
  nameDocFile,
  onClick = Function.prototype,
  showCameraIcon = true,
  text,
  uploadActionStyle,
}) => {
  const classes = makeStyles({
    image: {
      maxWith: '100px',
    },
    formControlContainer: {
      width: '100%',
      height: 'auto',
      alignSelf: 'center',
      padding: 20,
      border: '1px solid #CFD2D7',
      borderRadius: 5,
      ...containerStyle,
    },
    uploadAction: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      '& span': {
        color: 'rgba(0, 0, 0, 0.38)',
        fontSize: 14,
        width: 300,
      },
      '& .image-text': {
        width: '100%',
      },
      ...uploadActionStyle,
    },
  })();

  const onChangeImage = ({ target }) => {
    if (target.files && target.files[0]) {
      const img = target.files[0];
      getBase64(img).then((data) => {
        const imageReturned = {
          name: img.name,
          type: img.type,
          content: data,
          size: img.size
        };
        target.value = null;
        callback(imageReturned);
      });
    }
  };

  return (
    <FormControl
      className={classes.formControlContainer}
      onClick={onClick}
      style={{ ...customStyle, backgroundSize: 'cover' }}
    >
      <input
        accept="image/*"
        id={nameDocFile}
        onChange={onChangeImage}
        style={{ display: 'none' }}
        type="file"
      />
      <label className={classes.uploadAction} htmlFor={nameDocFile}>
        <Button component="div">
          {showCameraIcon ? <img alt="menu" className={classes.image} src={cameraIcon} /> : null}
        </Button>
        <span className="image-text">{isImage ? 'Cambiar imagen' : text}</span>
      </label>
    </FormControl>
  );
};

export default GmUploadPicture;
