// vendors
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useState } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// actions
import { authLogout, startReferringFriend, stopReferring } from '../../redux/actions/user';
import { getWhoHasReferredMe, getWhoHasImReferred } from '../../redux/actions/createHistory';

// components
import GmSuperModal from '../super-modal';
import GmSvgIcon from '../../static/svg';

// constants
import { routes } from '../../config/routes';

// images
import HamMenu from '../../static/images/hamMenu.png';
import ReferImage from '../../static/images/referral.png';
import KarmaIcon from '../../static/images/review-disabled.png';
import Logout from '../../static/images/logout.png';
import PQRS from '../../static/images/sideMenuPQRS.png';
import ReferIcon from '../../static/images/referIcon.png';
import TermsAndConditionsIcon from '../../static/images/termsAndConditions.png';
import CalendarIcon from '../../static/images/calendar.png';

// styles
import { useStyles } from './styles';

export const TabPanel = (props) => {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      aria-labelledby={`wrapped-tab-${index}`}
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (children)}
    </div>
  );
};

export const SideMenu = ({
  disabledReferringButtons,
  isProfileVisiting,
  whoHasImReferredList,
  whoRefferedMeList,
  hideReferralButton,
  hideCalendarButton
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state);

  const [tabIndex, changeTabIndex] = useState(0);
  const [rightMenu, setRightMenu] = useState(false);
  const [visibleWhoReferMe, toggleVisibleWhoReferMe] = useState(false);

  const routeChange = (path) => history.push(path);

  const handleToggleVisibleWhoReferMe = () => {
    toggleVisibleWhoReferMe(!visibleWhoReferMe);
  };

  const handleOnLogout = () => {
    dispatch(authLogout());
    routeChange('/');
  };

  const requestReferringList = () => {
    dispatch(getWhoHasReferredMe({ userId: auth.userInfo.userId }));
    dispatch(getWhoHasImReferred({ userId: auth.userInfo.userId }));
  };

  const handleStartReferringFriend = (startReferringUserId) =>
    startReferringFriend({ startReferringUserId }).then(() => requestReferringList());

  const handleStopReferring = (stopReferringUserId) =>
    stopReferring({ stopReferringUserId }).then(() => requestReferringList());

  return (
    <>
     <GmSuperModal
       headerImage={ReferImage}
       headerTitle=""
       onClose={handleToggleVisibleWhoReferMe}
       showConfirmButton={false}
       visible={visibleWhoReferMe}
     >
      <Tabs
        value={tabIndex}
        onChange={(_, newTabIndex) => changeTabIndex(newTabIndex)}
        wrapped
      >
        <Tab label="Amigos que han confiado en ti" style={{ textTransform: 'unset', width: '50%' }} />
        <Tab label="En los que tu has confiado" style={{ textTransform: 'unset', width: '50%' }} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        {whoRefferedMeList.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.user.id}`;
          return (
            <ListItem key={value.user.id} button style={{ padding: 5 }}>
              <ListItemAvatar>
                <Avatar alt={`Avatar n°${value.user.id}`} src={value.user.profilePicture} />
              </ListItemAvatar>
              <ListItemText
                id={labelId}
                classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}
                primary={value.user.fullName}
                secondary={<>{moment(value.date).format('DD MMM YYYY')}</>}
              />
              {disabledReferringButtons && (
                <Button
                  color="primary"
                  disabled={whoHasImReferredList.find(({ user }) => user.id === value.user.id)}
                  onClick={() => handleStartReferringFriend(value.user.id)}
                  style={{ color: '#ffffff', height: 24 }}
                  variant="contained"
                >
                  REFERIR
                </Button>
              )}
            </ListItem>
          );
        })}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {whoHasImReferredList.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.user.id}`;
          return (
            <ListItem key={value.user.id} button style={{ padding: 5 }}>
              <ListItemAvatar>
                <Avatar alt={`Avatar n°${value.user.id}`} src={value.user.profilePicture} />
              </ListItemAvatar>
              <ListItemText
                id={labelId}
                classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}
                primary={value.user.fullName}
                secondary={<>{moment(value.date).format('DD MMM YYYY')}</>}
              />
              {disabledReferringButtons && (
                <Button
                  disabled={value.isRegisterWithMyCode}
                  color="primary"
                  variant="contained"
                  onClick={() => handleStopReferring(value.user.id)}
                  style={{ color: '#ffffff', height: 24 }}
                >
                  DEJAR
                </Button>
              )}
            </ListItem>
          );
        })}
      </TabPanel>
     </GmSuperModal>
      <Grid
        alignItems="center"
        container
        direction="row"
        style={{ height: 50 }}
        justify="flex-end"
      >
        {!hideReferralButton
          ? (
            <IconButton
              onClick={handleToggleVisibleWhoReferMe}
            >
              <GmSvgIcon
                disabled
                enabled={false}
                icon="referring"
                viewBox="0 -5 77 42"
                style={{ marginRight: -10 }}
              />
            </IconButton>
          )
          : null}
          {!hideCalendarButton
            ? (
              <div role="button">
                <Button onClick={() => routeChange(routes.calendarPayment)}>
                  <img src={CalendarIcon} alt="calendar icon" style={{ width: 20, height: 20, marginRight: -30 }} />
                </Button>
              </div>
            )
            : null}
          {!isProfileVisiting
            ? (
              <IconButton onClick={() => setRightMenu(!rightMenu)}>
                <img alt="menu" src={HamMenu} style={{ marginTop: 2 }} />
              </IconButton>
            )
            : null}
          <Drawer anchor="right" open={rightMenu} onClose={() => setRightMenu(!rightMenu)}>
            <List>
              <ListItem button>
                <ListItemIcon className={classes.listIcon} style={{ marginTop: 0 }}>
                  <img src={ReferIcon} alt="refer icon" style={{ width: 22, height: 16 }} />
                </ListItemIcon>
                <ListItemText
                  onClick={() => {
                    if (auth.userInfo.hasSendInvitationEnable) {
                      routeChange(routes.referredUser);
                    }
                  }}
                  primary="Referir un amigo"
                  className={classes.listText}
                />
              </ListItem>
              <ListItem button alignItems="flex-start">
                <ListItemIcon className={classes.listIcon}>
                  <img src={KarmaIcon} alt="karma icon" />
                </ListItemIcon>
                <ListItemText onClick={() => routeChange(routes.karma)} primary="Aprende sobre el Karma" className={classes.listText} />
              </ListItem>
              <ListItem button>
                <ListItemIcon className={classes.listIcon}>
                  <img src={PQRS} alt="PQRS icon" style={{ width: 19, height: 20 }} />
                </ListItemIcon>
                <ListItemText onClick={() => routeChange(routes.pqrs)} primary="PQRS" className={classes.listText} />
              </ListItem>
              <ListItem button>
                <ListItemIcon className={classes.listIcon} style={{ marginTop: 2 }}>
                  <img src={TermsAndConditionsIcon} alt="terms and conditions icon" style={{ width: 19, height: 20 }} />
                </ListItemIcon>
                <ListItemText onClick={() => routeChange(routes.termsAndConditions)} primary="Términos y Condiciones" className={classes.listText} />
              </ListItem>
              <ListItem onClick={handleOnLogout} button>
                <ListItemIcon className={classes.listIcon} style={{ marginTop: 0 }}>
                  <img src={Logout} alt="logout icon" style={{ width: 19, height: 20 }} />
                </ListItemIcon>
                <ListItemText primary="Cerrar Sesión" className={classes.listText} />
              </ListItem>
            </List>
          </Drawer>
      </Grid>
    </>
  );
};

export default SideMenu;
