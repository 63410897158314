// vendors
import * as yup from 'yup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

// actions
import { setRefferedUser } from '../../redux/actions/transaction';

// components
import BlueLogo from '../../components/blue-logo';

// constants
import { routes } from '../../config/routes';

// styles
import { useStyles } from './styles';
import ReferralConfirmation from './referral-confirmation';

const validationSchema = yup.object({
  email: yup
    .string('ingresa el correo electrónico')
    .email('ingresa el correo electrónico')
    .required('El correo electrónico es requerido'),
  name: yup
    .string('ingresa el nombre del referido')
    .required('El nombre del referido es requerido'),
  lastName: yup.string('ingresa el apellido del referido').required('El apellido es requerido'),
  cellPhone: yup
    .number('El numero de número de celular debe ser numerico')
    .required('El número de celular es requerido'),
});

const ReferredUser = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [referralSuccess, setReferralSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      documentType: 'CC',
      documentNumber: '',
      cellphoneCode: '+57',
      cellPhone: '',
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(setRefferedUser(values));
        setReferralSuccess(true);
      } catch (error) {
      }
    },
  });

  const sanitizeEmail = () => {
    const sanitizedEmail = formik.values.email.toLowerCase().trim();
    formik.setFieldValue('email', sanitizedEmail);
  };

  if (referralSuccess) {
    return (
      <div className={classes.confirmationScreen}>
        <ReferralConfirmation name={formik.values.name} />
      </div>
    );
  }

  return (
    <>
      <Paper className={classes.root}>
        <ArrowBackIcon
          className={classes.ArrowBackIconCustom}
          onClick={() => history.push(routes.main.replace('/:userId?', ''))}
        />
        <Container>
          <Grid container justify="center" className={classes.center}>
            <Grid className={classes.logIn} item xs={12}>
              <BlueLogo />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Referir a un amigo
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Grid className={classes.viewContainer} container justify="center">
        <Grid item xs={12} md={6} className={classes.textInfo}>
          <Typography variant="body2" gutterBottom style={{ textAlign: 'left' }}>
            Al referir un amigo, estás adquiriendo responsabilidades sobre el comportamiento
            financiero de esa persona, y tanto tu economía como tu Karma se pueden ver afectados.
          </Typography>
          <Typography variant="body2" gutterBottom style={{ textAlign: 'left', marginTop: 20 }}>
            Recuerda siempre realizar una recomendación consciente.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.center}>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField
              className={clsx(classes.margin, classes.textField)}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              id="name"
              label="Nombre del referido"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              variant="outlined"
            />

            <TextField
              className={clsx(classes.margin, classes.textField)}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              id="lastName"
              label="Apellidos del referido"
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              variant="outlined"
            />

            <TextField
              className={clsx(classes.margin, classes.textField)}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              id="email"
              label="Correo electrónico"
              name="email"
              onBlur={sanitizeEmail}
              onChange={formik.handleChange}
              value={formik.values.email}
              variant="outlined"
            />
            <div className={classes.contenDoc}>
              <FormControl className={classes.smallFormControlStyle}>
                <TextField
                  error={formik.touched.cellphoneCode && Boolean(formik.errors.cellphoneCode)}
                  helperText={formik.touched.cellphoneCode && formik.errors.cellphoneCode}
                  id="cellphoneCode"
                  label="Código"
                  name="cellphoneCode"
                  onChange={formik.handleChange}
                  type="numeric"
                  value={formik.values.cellphoneCode}
                  variant="outlined"
                />
              </FormControl>
              <TextField
                error={formik.touched.cellPhone && Boolean(formik.errors.cellPhone)}
                helperText={formik.touched.cellPhone && formik.errors.cellPhone}
                id="cellPhone"
                label="Número de celular"
                name="cellPhone"
                onChange={formik.handleChange}
                style={{ width: '70%', margin: '10px 10px 0px 0px', zIndex: 0.9 }}
                type="numeric"
                value={formik.values.cellPhone}
                variant="outlined"
                InputLabelProps={{
                  classes: {
                    outlined: classes.labelZIndex,
                  },
                }}

              />
            </div>

            <Grid item className={classes.startButton}>
              <Button
                className={classes.cancelButton}
                color="primary"
                disableElevation
                onClick={() => history.push(routes.main.replace('/:userId?', ''))}
              >
                Cancelar
              </Button>
              <Button variant="contained" color="primary" type="submit" disableElevation>
                REFERIR
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default ReferredUser;
