// vendors
import { makeStyles } from '@material-ui/core/styles';

const transactionResultBody = {
  margin: '10px 40px',
  padding: '35px 0 35px'
};

export const useStyles = makeStyles((theme) => ({
  arrowBackIcon: {
    color: '#7D7D7D',
    float: 'left',
    margin: '24px 0px 0px 22px',
  },
  avatar: {
    borderRadius: '50%',
    height: 90,
    width: 90,
  },
  avatarContainer: {
    marginBottom: 20,
  },
  form: {
    padding: '20px 22px',
  },
  formItem: {
    marginBottom: 20,
  },
  formLabel: {
    color: '#0F1E36',
    fontSize: 14,
    fontWeight: 600,
  },
  formLabelArea: {
    color: '#0F1E36',
    fontSize: 14,
    fontWeight: 600,
    marginTop: -5,
    position: 'absolute',
    textAlign: 'left',
  },
  header: {
    backgroundColor: '#AFE2E3',
    height: 275,
    marginBottom: 40,
  },
  karmaReview: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  lastFormLabel: {
    color: '#000000',
    fontSize: 12,
  },
  lastPaymentDescription: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: 14,
    fontWeight: 400,
    paddingTop: 20,
  },
  lastPaymentFormLabel: {
    color: '#0F1E36',
    fontSize: 14,
    fontWeight: 400,
  },
  lastPaymentTitle: {
    color: '#0F1E36',
    fontSize: 16,
    fontWeight: 700,
  },
  name: {
    color: '#33647E',
    fontSize: 16,
    fontWeight: 700,
  },
  paymentsReportPage: {
    paddingBottom: 80,
  },
  paperRoot: {
    borderRadius: 0,
    boxShadow: 'unset',
  },
  reviewPositiveIcon: {
    height: 16,
    marginRight: 7,
    width: 17,
  },
  sendButton: {
    color: '#FFFFFF',
    backgroundColor: '#127480',
    '&:hover': {
      backgroundColor: '#106873'
    },
    marginTop: 20
  },
  sharedImageLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left',
  },
  subHeader: {
    marginBottom: 36,
  },
  textField: {
    marginBottom: 36,
  },
  textFieldArea: (props) => ({
    marginTop: props.isLastPayment ? 30 : 0,
    marginBottom: 36,
  }),
  title: {
    color: '#0F1E36',
    fontSize: 16,
    marginTop: 24,
    fontWeight: 'bold',
  },
  voucher: {
    marginBottom: 15
  },
  transactionResult: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 80
  },
  transactionResultTitle: {
    margin: '50px 0px 5px'
  },
  transactionResultBody: {
    ...transactionResultBody,
    backgroundColor: theme.palette.background.lightGrey1,
  },
  transactionResultBodySuccess: {
    ...transactionResultBody,
    backgroundColor: '#e6f7e9',
  },
  transactionResultBodyFailed: {
    ...transactionResultBody,
    backgroundColor: '#f8dfdf',
  },
  transactionResultMessage: {
    margin: '20px 0px'
  },
  itemTextContent: {
    color: '#33647E',
    fontSize: 18,
    fontWeight: 700
  },
  paper: {
    marginBottom: 25,
  },
  summaryContainer: {
    borderRadius: 10,
    padding: '25px',
    backgroundColor: '#AFE2E3'
  },
  summaryName: {
    color: '#0F1E36',
    fontSize: 20,
    fontWeight: 'bold'
  },
  summaryTitleContainer: {
    marginBottom: 30,
    marginTop: -15
  },
  summarySubTitleContainer: {
    marginBottom: 20
  },
  summaryTextContainer: {
    margin: 20
  },
  summaryNumber: {
    color: '#1A2E3C',
    fontSize: 32,
    fontWeight: 700,
    marginTop: -10,
    marginBottom: 10
  },
  summaryAvatar: {
    marginBottom: 10,
    marginTop: 5,
  },
  summaryAvatarImg: {
    height: 70,
    width: 70
  },
  summaryContentContainer: {
    margin: 75,
    marginTop: 10,
    marginBottom: 10
  },
  loanAmmountHeader: {
    textTransform: 'uppercase',
    color: '#657E91',
    fontSize: 15,
    marginTop: -10
  },
  itemTitle: {
    textTransform: 'uppercase',
    color: '#33647E',
    fontSize: 11
  },
  itemUpperLeft: {
    padding: 5,
    borderTop: '1px solid #AFE2E3',
    borderBottom: '1px solid #AFE2E3',
    borderRight: '1px solid #AFE2E3'
  },
  itemUpperRight: {
    padding: 5,
    borderTop: '1px solid #AFE2E3',
    borderBottom: '1px solid #AFE2E3'
  },
  itemLowerLeft: {
    padding: 5,
    borderRight: '1px solid #AFE2E3',
    borderBottom: '1px solid #AFE2E3'
  },
  itemLowerRight: {
    padding: 5,
    borderBottom: '1px solid #AFE2E3'
  },
  itemAcceptedUpperLeft: {
    padding: 5,
    borderTop: '1px solid #F4D3D6',
    borderBottom: '1px solid #F4D3D6',
    borderRight: '1px solid #F4D3D6'
  },
  itemAcceptedUpperRight: {
    padding: 5,
    borderTop: '1px solid #F4D3D6',
    borderBottom: '1px solid #F4D3D6'
  },
  itemAcceptedLowerLeft: {
    padding: 5,
    borderRight: '1px solid #F4D3D6',
    borderBottom: '1px solid #F4D3D6'
  },
  itemAcceptedLowerRight: {
    padding: 5,
    borderBottom: '1px solid #F4D3D6'
  },
  summaryText: {
    color: '#000000',
    fontSize: 14,
    textAlign: 'left'
  },
  summaryTitle: {
    color: '#33647E',
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 24,
  },
  summarySubTitle: {
    color: '#33647E',
    fontSize: 18,
    marginTop: -30
  },
  summaryImage: {
    maxWidth: 250
  },
  continueProcessButton: {
    color: '#FFFFFF',
    backgroundColor: '#127480',
    width: 250,
    '&:hover': {
      backgroundColor: '#106873'
    }
  },
  alert: {
    color: '#FF0000',
    fontSize: '0.7rem',
    display: 'block',
    textTransform: 'none'
  },
  logo: {
    float: 'left',
    margin: '14px 0px 0px 10px',
  }
}));
