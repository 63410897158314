// vendors
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';

// components
import GmKarmaReview from '../../components/karma-review';

// styles
import { useStyles } from './styles';

const GmStoryReview = ({ review }) => {
  const classes = useStyles();

  const renderReview = () => {
    const { user, ranking, comment } = review;

    return (
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <Avatar className={classes.commentPic} sizes="42" alt={user.fullName} src={user.profilePicture} />
        </Grid>
        <Grid item container xs={10}>
          <Grid className={classes.reviewer} item xs={7}>
            <Typography className={classes.reviewerName} component="span" variant="body2">
              {user.fullName}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <GmKarmaReview
              className={classes.ranking}
              iconStyles={{ height: 20, width: 20 }}
              readOnly
              value={ranking}
            />
          </Grid>
        </Grid>
        <Grid item xs={2} />
        <Grid className={classes.comment} item xs={10}>
          <Typography component="span" variant="body2">
            {comment}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
      <Grid className={classes.reviewContainer} container item md={12} xs={12}>
        {renderReview()}
      </Grid>
  );
};

export default GmStoryReview;
