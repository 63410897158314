// api
import activityApi from '../../config/api/activity';

// actions
import {
  GET_MY_ACTIVITIES_BEGIN,
  GET_MY_ACTIVITIES_ERROR,
  GET_MY_ACTIVITIES_SUCCESS
} from '../action-types';

// action creators
const getMyActivitiesBegin = () => ({ type: GET_MY_ACTIVITIES_BEGIN });

export const getMyActivities = () => async (dispatch) => {
  dispatch(getMyActivitiesBegin());

  return activityApi
    .getMyActivities()
    .then(({ data }) => {
      dispatch({
        type: GET_MY_ACTIVITIES_SUCCESS,
        payload: data.result
      });
    })
    .catch((error) => dispatch({ type: GET_MY_ACTIVITIES_ERROR, error }));
};
