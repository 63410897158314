// http
import AUCO_API from '../../config/api/AUCO';
import settingsApi from '../../config/api/settings';

// constants
export const AUCO_ID_VALIDATION_BEGIN = 'AUCO_ID_VALIDATION_BEGIN';
export const AUCO_ID_VALIDATION_SUCCESS = 'AUCO_ID_VALIDATION_SUCCESS';
export const AUCO_ID_VALIDATION_ERROR = 'AUCO_ID_VALIDATION_ERROR';
export const GET_IS_AUCO_VALIDATION_ENABLED_BEGIN = 'GET_IS_AUCO_VALIDATION_ENABLED_BEGIN';
export const GET_IS_AUCO_VALIDATION_ENABLED_SUCCESS = 'GET_IS_AUCO_VALIDATION_ENABLED_SUCCESS';
export const GET_IS_AUCO_VALIDATION_ENABLED_ERROR = 'GET_IS_AUCO_VALIDATION_ENABLED_ERROR';

// actions
export const getAUCOValidationBegin = () => ({ type: AUCO_ID_VALIDATION_BEGIN });
export const getIsAUCOValidationEnabledBegin = () => ({ type: GET_IS_AUCO_VALIDATION_ENABLED_BEGIN });

export const getAUCOValidation = ({ country, identification, type }) => async (dispatch) => new Promise((resolve, reject) => {
  dispatch(getAUCOValidationBegin());

  return AUCO_API
    .validate({ country, identification, type })
    .then((res) => {
      resolve(res);
      dispatch({
        type: AUCO_ID_VALIDATION_SUCCESS,
        payload: res
      });
    })
    .catch((error) => {
      reject(error);
      dispatch({ type: AUCO_ID_VALIDATION_ERROR, error });
    });
});

export const getIsAUCOValidationEnabled = () => async (dispatch) => {
  dispatch(getIsAUCOValidationEnabledBegin());

  return settingsApi.getSettings()
    .then(({ data: { result: res } }) => dispatch({ type: GET_IS_AUCO_VALIDATION_ENABLED_SUCCESS, payload: res }))
    .catch(() => dispatch({ type: GET_IS_AUCO_VALIDATION_ENABLED_ERROR }));
};
