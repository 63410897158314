import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #CFD2D7',
    boxShadow: 'none',
    display: 'grid',
    marginTop: 20
  },
  brandLogo: {
    margin: 20,
  },
  imgProfile: {
    background: '#c2c2c2',
    width: '121px',
    height: '121px',
    borderRadius: '100%',
    margin: '31px auto',
    marginBottom: 10,
  },
  editProfile: {
    width: '27px',
    height: '27px',
    color: '#fff',
    background: '#1DCAD3',
    borderRadius: '100%',
    position: 'relative',
    top: '90px',
    left: '40px',
    cursor: 'pointer',
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 54,
    marginRight: 22,
  },
  navAction: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
    width: '25%',
  },
  mainDesc: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.78)',
    fontSize: 18,
  },
  bottomNavigation: {
    borderTop: '1px solid #CFD2D7',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  contentActionBottom: {
    display: 'flex',
    flexDirection: 'row',
    gap: 50,
    alignItems: 'center',
    marginTop: '2px',
  },
  reWriteBtnStyles: {
    border: 'none',
    background: '#fff',
    outline: 'none',
  },
  gmKarmaReview: {
    display: 'flex',
    justifyContent: 'center',
  },

  // Story card
  gmStoryCard: {
    height: 120,
  },
  gmAddStoryCardLabel: {
    fontSize: 12,
    fontWeight: 400,
  },
  gmStoryCardImage: {
    width: '100%',
    height: '100%',
  },
  gmStoryCardIconContainer: {
    marginTop: 10,
  },
  gmAddStoryCardLabelContainer: {
    marginTop: 5,
  },
  gmFinalizedStoryBadge: {
    float: 'right',
  },
  sideMenu: {
    position: 'relative',
    marginRight: 20
  },
  editIcon: {
    color: 'white',
    opacity: 1,
    position: 'absolute',
    top: 123,
    left: 87,
    margin: '5px 0 10px 5px',
    fontSize: '30px',
    background: 'gray',
    borderRadius: 23,
    padding: 3
  }
});

export { useStyles };
