/* eslint-disable max-len */

// vendors
import React from 'react';

const Voice = (
  <svg width="32" height="18" viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.6533 5.54456H0V12.4554H2.6533V5.54456Z" fill="#F93549" />
    <path d="M24.8933 0H22.24V18H24.8933V0Z" fill="#F93549" />
    <path d="M22.24 18L2.65332 12.4554V5.54455L22.24 0V18Z" fill="#FFC600" />
    <path
      d="M31.8044 8.63364H28.0397H27.844V8.83166V9.59205V9.79007H28.0397H31.8044H32V9.59205V8.83166V8.63364H31.8044Z"
      fill="#F93549"
    />
    <path
      d="M28.4309 4.95052L28.5718 5.08913L28.7088 4.95052L31.3464 2.28121L31.4834 2.14259L31.3464 2.00398L30.8142 1.46537L30.6733 1.32279L30.5363 1.46537L27.8987 4.13071L27.7617 4.27329L27.8987 4.4119L28.4309 4.95052Z"
      fill="#F93549"
    />
    <path
      d="M28.7088 13.4732L28.5718 13.3346L28.4309 13.4732L27.8987 14.0119L27.7617 14.1544L27.8987 14.293L30.5363 16.9623L30.6733 17.101L30.8142 16.9623L31.3464 16.4237L31.4834 16.2812L31.3464 16.1425L28.7088 13.4732Z"
      fill="#F93549"
    />
  </svg>
);

export default Voice;
