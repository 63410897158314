// vendors
import { combineReducers } from 'redux';

// reducers
import activityReducer from './reducers/activityReducer';
import authReducer from './reducers/authReducer';
import bankReducer from './reducers/bankReducer';
import commonReducer from './reducers/commonReducer';
import historyReducer from './reducers/historyReducer';
import paymentsReducer from './reducers/paymentsReducer';
import profileVisitingReducer from './reducers/profileVisitingReducer';
import registerReducer from './reducers/registerReducer';
import settingsReducer from './reducers/settingsReducer';
import transactionReducer from './reducers/transactionReducer';

const reducers = combineReducers({
  activity: activityReducer,
  auth: authReducer,
  bank: bankReducer,
  common: commonReducer,
  history: historyReducer,
  payments: paymentsReducer,
  profileVisiting: profileVisitingReducer,
  register: registerReducer,
  settings: settingsReducer,
  transaction: transactionReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    // TODO:
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return reducers(state, action);
};

export default rootReducer;
