/* eslint-disable max-len */

// vendors
import React from 'react';

const SearchEnabled = (
  <>
  <path d="M87.1 6.87H60.16H32.97V16.06H60.16H87.1V6.87Z" fill="#33647E" />
    <path d="M52.4 26.2C52.4 40.67 40.67 52.4 26.2 52.4C11.73 52.4 0 40.67 0 26.2C0 11.73 11.73 0 26.2 0C40.67 0 52.4 11.73 52.4 26.2Z" fill="#33647E" />
    <path d="M45.01 26.2C45.01 36.59 36.59 45.01 26.2 45.01C15.81 45.01 7.39001 36.59 7.39001 26.2C7.39001 15.81 15.81 7.39001 26.2 7.39001C36.59 7.39001 45.01 15.81 45.01 26.2Z" fill="#AFE2E3" />
    <path d="M120.37 26.2C120.37 40.67 108.64 52.4 94.17 52.4C79.7 52.4 67.97 40.67 67.97 26.2C67.97 11.73 79.7 0 94.17 0C108.64 0 120.37 11.73 120.37 26.2Z" fill="#33647E" />
    <path d="M112.98 26.2C112.98 36.59 104.56 45.01 94.17 45.01C83.78 45.01 75.36 36.59 75.36 26.2C75.36 15.81 83.78 7.39001 94.17 7.39001C104.56 7.39001 112.98 15.81 112.98 26.2Z" fill="#AFE2E3" />
    <path d="M60.18 3.03998C60.16 3.03998 60.13 3.03998 60.11 3.03998C55.49 3.07998 51.76 6.82998 51.76 11.46C51.76 16.09 55.49 19.84 60.11 19.88C60.14 19.88 60.16 19.88 60.18 19.88C64.83 19.88 68.61 16.11 68.61 11.45C68.61 6.80998 64.84 3.03998 60.18 3.03998Z" fill="#33647E" />
    <path d="M60.18 8.33002C60.16 8.33002 60.15 8.33002 60.14 8.33002C58.43 8.35002 57.05 9.75002 57.05 11.46C57.05 13.18 58.43 14.57 60.14 14.59C60.15 14.59 60.17 14.59 60.18 14.59C61.91 14.59 63.32 13.19 63.32 11.45C63.32 9.74002 61.92 8.33002 60.18 8.33002Z" fill="#FFC600" />
  </>
);

export default SearchEnabled;
