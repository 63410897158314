// vendors
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import React, {
  memo,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { AucoSDK } from 'auco-sdk-integration';

// actions
import { clearUnreadMessages } from '../../redux/actions/chat';

// components
import GmMessage from './message';

// constants
import { AUCO_SDK_PRV_DEV_KEY, AUCO_SDK_QA_PROD_KEY, AUCO_SDK_PRV_PROD_KEY } from '../../config/env';
import { MESSAGE_TYPES } from '../../config/constants';

// routes
import { routes } from '../../config/routes';

const MessagesHistoryContainer = memo(({
  onLastPaymentConfirmation,
  onLoanConfirmation,
  onPaymentConfirmation,
  setShowAUCOSdkIframe,
  showAUCOSdkIframe,
}) => {
  const dispatch = useDispatch();
  const refToBottom = useRef(null);
  const params = useParams();
  const history = useHistory();

  const { history: { chatsHistory, messages } } = useSelector((state) => state);

  useEffect(() => {
    if (showAUCOSdkIframe && messages && messages.length) {
      const { SignId, DocumentId } = JSON.parse(messages.find((msg) =>
        (msg.type === MESSAGE_TYPES.signPromissoryNote)).content);

      const unscribeAUCOSdk = AucoSDK({
        iframeId: 'auco-sdk-iframe',
        // eslint-disable-next-line no-nested-ternary
        keyPublic: process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'development'
          ? AUCO_SDK_PRV_DEV_KEY
          : process.env.REACT_APP_ENV === 'qa'
            ? AUCO_SDK_QA_PROD_KEY
            : AUCO_SDK_PRV_PROD_KEY,
        sdkType: 'sign',
        language: 'es',
        events: {
          onSDKReady: () => {},
          onSDKClose: () => {
            unscribeAUCOSdk();
            setShowAUCOSdkIframe(false);
          },
        },
        sdkData: {
          document: DocumentId.toString() + SignId.toString(),
          uxOptions: {
            primaryColor: '#1DCAD3',
            alternateColor: '#19857b',
          },
        },
        env: process.env.REACT_APP_ENV === 'production'
          ? 'PROD'
          : 'DEV',
      });
    }
  }, [showAUCOSdkIframe, messages]);

  const goToBottom = () =>
    refToBottom.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest'
    });

  useEffect(() => {
    dispatch(clearUnreadMessages({ unreadMessage: params.id }));
  }, [params.id]);

  useEffect(() => {
    goToBottom();
  }, [messages.length]);

  const handleOnAddStory = () => history.push(routes.createHistory.replace(':id?', ''));
  const handleOnGoToStories = () => history.push(routes.histories);
  const handleOnRefer = () => history.push(routes.referredUser);
  const isLoanPaymentConfirmed = messages.find(({ type: msgType }) => msgType === MESSAGE_TYPES.loanConfirmation);
  const toggleShowAUCOSdkIframe = () => setShowAUCOSdkIframe(!showAUCOSdkIframe);

  if (showAUCOSdkIframe) {
    return (
      <iframe
        allow="camera;microphone;geolocation"
        id="auco-sdk-iframe"
        style={{ height: 'calc(95vh - 190px)', width: '100%' }}
        title="auco-sdk-iframe"
      />
    );
  }

  return (
    <Grid
      container
      style={{
        height: 'calc(100vh - 130px)',
        maxHeight: 'calc(100vh - 130px)',
        overflow: 'auto',
        padding: '0px 10px 60px',
        postition: 'relative',
      }}
    >
      <List component="nav">
        {messages && messages.length
          ? messages.map((message, index) => (
            <GmMessage
              hasPromissoryNote={chatsHistory.hasPromissoryNote}
              hasTransaction={chatsHistory.hasTransaction}
              isLoanPaymentConfirmed={isLoanPaymentConfirmed}
              key={index}
              messageValue={message}
              onAddStory={handleOnAddStory}
              onGoToStories={handleOnGoToStories}
              onLastPaymentConfirmation={onLastPaymentConfirmation}
              onLoanConfirmation={onLoanConfirmation}
              onPaymentConfirmation={onPaymentConfirmation}
              onRefer={handleOnRefer}
              toggleShowAUCOSdkIframe={toggleShowAUCOSdkIframe}
            />
          ))
          : null}
        <div ref={refToBottom} style={{ clear: 'both', float: 'left' }} />
      </List>
    </Grid>
  );
});

export default MessagesHistoryContainer;
