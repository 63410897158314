// constants
import { ERRORS_HANDLER } from './constants';

export const allValuesAreValid = ({
  category,
  frequency,
  initialFormStoryQuestions,
  installments,
  loanAmount,
  processedFormStoryQuestions,
  setErrors,
  setInitialFormStoryQuestions,
  setStoryPictures,
  storyDescription,
  storyPictures,
  storyQuestionSelected,
}) => {
  // cleanup object errors handler before validation
  setErrors(ERRORS_HANDLER);

  // cleanup errors from dynamic form story questions before validation
  const cleanedUpFormStoryQuestions = initialFormStoryQuestions.map((storyQuestion) => ({ ...storyQuestion, error: '' }));
  setInitialFormStoryQuestions(cleanedUpFormStoryQuestions);

  if (!storyDescription) {
    setErrors((prevState) => ({ ...prevState, description: 'El título de la historia es un campo requerido.' }));
  }

  if (category === null) {
    setErrors((prevState) => ({ ...prevState, category: 'La "categoria" es un campo requerido' }));
  }

  if (!storyQuestionSelected) {
    setErrors((prevState) => ({ ...prevState, phrase: 'La "frase" con la que te identificas es un campo requerido.' }));
  }

  if (!frequency && frequency !== 0) {
    setErrors((prevState) => ({ ...prevState, frequency: 'Debes seleccionar con qué frecuencia pagarás.' }));
  }

  if (loanAmount < 200000) {
    setErrors((prevState) => ({ ...prevState, loanAmount: 'El préstamo mínimo es de $200.000.' }));
  }

  if (!installments) {
    setErrors((prevState) => ({ ...prevState, installments: 'Debes especificar a cuántas cuotas puedes pagar.' }));
  }
  // validate story pictures
  if (storyPictures.some((picture) => !picture.base64)) {
    const processedStoryPictures = storyPictures.map((picture) => {
      if (!picture.base64) {
        return {
          ...picture,
          error: `La foto "${picture.title}" es requerida.`,
        };
      }

      return picture;
    });
    setStoryPictures(processedStoryPictures);
  }

  // validation if no question were answered
  if (!processedFormStoryQuestions.length) {
    const validatedFormStoryQuestions = initialFormStoryQuestions.map((storyQuestion) => ({
      ...storyQuestion,
      error: `La pregunta "${storyQuestion.name}" es un campo requerido.`
    }));
    setInitialFormStoryQuestions(validatedFormStoryQuestions);
    return false;
  }

  // specify error in form story questions
  if (processedFormStoryQuestions.some((question) => !question.answer)) {
    const validatedFormStoryQuestions = processedFormStoryQuestions.map((pQ) => {
      const questionMatch = initialFormStoryQuestions.find((iQ) => pQ.id === iQ.id.toString());

      if (!pQ.answer && questionMatch.id.toString() === pQ.id) {
        return {
          ...questionMatch,
          error: `La pregunta "${questionMatch.name}" es un campo requerido.`,
        };
      }

      return {
        ...questionMatch,
        error: '',
        value: pQ.answer,
      };
    });
    setInitialFormStoryQuestions(validatedFormStoryQuestions);
    return false;
  }

  // return values to form story questions array if they're fulfilled
  if (processedFormStoryQuestions.every((question) => question.answer)) {
    const validatedFormStoryQuestions = processedFormStoryQuestions.map((pQ) => {
      const questionMatch = initialFormStoryQuestions.find((iQ) => pQ.id === iQ?.id?.toString());
      return {
        ...questionMatch,
        error: '',
        value: pQ.answer,
      };
    });
    setInitialFormStoryQuestions(validatedFormStoryQuestions);
  }

  // if all above if statments passed return true
  return true;
};
