/* eslint-disable max-len */

// vendors
import React from 'react';

const ProfileEnable = (
  <>
    <path d="M22.0104 11C22.0104 5.41121 17.8424 0.797404 12.4451 0.0940171C12.4242 0.090535 12.3998 0.0870529 12.3789 0.0870529C12.2048 0.0661602 12.0307 0.0487496 11.8531 0.0348211C11.5711 0.0104463 11.289 0 11.0035 0H11C4.92371 0.00348211 0 4.92719 0 11C0 17.0763 4.92719 22 11.0035 22C17.0833 22 22.0104 17.0763 22.0104 11Z" fill="#FF8189" />
    <path d="M7.21495 6.56376C7.93923 6.52546 8.53119 7.1209 8.49637 7.84518C8.46503 8.465 7.96361 8.96642 7.34379 8.99776C6.61951 9.03258 6.02755 8.44062 6.06237 7.71634C6.09371 7.09653 6.59514 6.5951 7.21495 6.56376Z" fill="#F93549" />
    <path d="M14.6667 6.56376C15.3909 6.52546 15.9829 7.1209 15.9481 7.84518C15.9167 8.465 15.4153 8.96642 14.7955 8.99776C14.0712 9.03258 13.4758 8.44062 13.5141 7.71634C13.5454 7.09653 14.0468 6.5951 14.6667 6.56376Z" fill="#F93549" />
    <path d="M17.5846 12.372C17.5115 12.2641 17.4035 12.1735 17.2747 12.1178C17.0623 12.0308 16.829 12.0586 16.6479 12.177C16.533 12.2501 16.439 12.3581 16.3833 12.4939C15.464 14.7468 13.3538 16.2058 11.0069 16.2058C11.0034 16.2058 10.9999 16.2058 10.993 16.2058C8.6495 16.2023 6.5463 14.7468 5.63051 12.4939C5.48774 12.1422 5.0873 11.975 4.73908 12.1178C4.39087 12.2606 4.22025 12.661 4.36302 13.0092C5.49122 15.781 8.09932 17.5743 11.0069 17.5743C11.4178 17.5743 11.8182 17.5395 12.2117 17.4698C12.2988 17.4559 12.3858 17.4385 12.4729 17.4176C12.8629 17.334 13.2424 17.2191 13.608 17.0729C13.6881 17.0415 13.7717 17.0067 13.8518 16.9719C13.9319 16.9371 14.012 16.8988 14.0886 16.8604C14.2836 16.763 14.4786 16.6585 14.6631 16.5471C14.7362 16.5018 14.8128 16.4565 14.886 16.4078C15.0322 16.3103 15.175 16.2093 15.3143 16.1014C15.4187 16.0213 15.5197 15.9377 15.6207 15.8506C15.6869 15.7949 15.753 15.7357 15.8192 15.673C15.8853 15.6139 15.948 15.5512 16.0107 15.4885C16.1047 15.3945 16.1952 15.297 16.2858 15.1995C16.4634 15.001 16.634 14.7921 16.7907 14.5727C16.9474 14.3533 17.0936 14.1235 17.2294 13.8832C17.3861 13.6047 17.5289 13.3122 17.6508 13.0092C17.6856 12.9222 17.703 12.8316 17.703 12.7411C17.696 12.6123 17.6577 12.4834 17.5846 12.372Z" fill="#F93549" />
  </>
);

export default ProfileEnable;
