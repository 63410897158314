// vendors
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  gmTransferSummary: {},
  gmTransferSummaryDate: {
    color: '#676767',
    fontSize: 12,
    fontWeight: 400,
  },
  gmTransferSummaryDateContainer: {},
  gmTransferSummaryTitle: {
    color: '#000000',
    fontSize: 22,
    fontWeight: 700,
  },
  gmTransferSummaryTitleContainer: {
    paddingBottom: 25,
    paddingTop: 15,
  },
  gmTransferSummaryValueAmount: {
    color: '#33647E',
    fontSize: 32,
    fontWeight: 700,
  },
  gmTransferSummaryValueAmountContainer: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  gmTransferSummaryValueLabel: {
    color: '#676767',
    fontSize: 14,
    fontWeight: 400,
  },
  gmTransferSummaryValueLabelContainer: {},
}));
