// constants
import env from '../env';

// http
import request from '../request';

const userApi = {
  getProfile() {
    return request(`${env.mainBaseUrl}/services/app/UserProfileService/GetProfile`, 'get');
  },

  login({
    password,
    rememberClient,
    userNameOrEmailAddress,
  }) {
    return request(`${env.mainBaseUrl}/TokenAuth/Authenticate`, 'post', {
      password,
      rememberClient,
      userNameOrEmailAddress,
    });
  },

  forgotPassword({ emailAddress }) {
    return request(`${env.mainBaseUrl}/TokenAuth/SendPasswordResetCode`, 'post', { emailAddress });
  },

  recoverPassword(data) {
    return request(`${env.mainBaseUrl}/TokenAuth/ResetPassword`, 'post', data);
  },

  checkCode(data) {
    return request(
      `${env.mainBaseUrl}/services/app/Account/ValidateInvitationCode`,
      'post',
      data
    );
  },

  getUnreadMessages() {
    return request(`${env.mainBaseUrl}/services/app/Message/GetUnreadMessage`, 'get');
  },

  startReferringFriend({ startReferringUserId }) {
    return request(`${env.mainBaseUrl}/services/app/User/StarReferringToAFriend`, 'post', { startReferringUserId });
  },

  stopReferring({ stopReferringUserId }) {
    return request(`${env.mainBaseUrl}/services/app/User/StopReferring`, 'post', { stopReferringUserId });
  },

  isResetCodeValid({ resetCode, userId }) {
    return request(`${env.mainBaseUrl}/TokenAuth/IsResetCodeValid`, 'post', { resetCode, userId });
  }

};

export default userApi;
