// vendors
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const SystemMessage = ({
  classes,
  message,
  messageId,
  visible
}) => {
  if (!visible) {
    return null;
  }

  return (
    <ListItem className={classes.systemMessage} key={messageId}>
      <Typography className={classes.systemMessageContent}>
        {message}
      </Typography>
    </ListItem>
  );
};

export default SystemMessage;
