// packages
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// images
import yellowLogo from '../../static/images/logo4.svg';

// styles
const useStyles = makeStyles({
  image: {
    maxWith: '100px',
  },
});

const GmYellowLogo = ({ style }) => {
  const classes = useStyles();
  return (
    <img
      alt="gmf-yellow-logo"
      className={classes.image}
      src={yellowLogo}
      style={style}
    />
  );
};

export default GmYellowLogo;
