import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@material-ui/core';

import items from './items';
import ReferralCodePage from '../referral-code';
import { useStyles } from './styles';

export const GmOnboardingItem = ({
  item,
  isLast,
  content,
  handleOnButtonClick,
}) => {
  const classes = useStyles(item);

  return (
    <Paper className={classes.root} elevation={10}>
      {content}
      {isLast && (
        <Button
          color="primary"
          onClick={handleOnButtonClick}
          style={{ color: '#FFF' }}
          variant="contained"
          className={classes.nextButton}
        >
          SIGUIENTE
        </Button>
      )}
    </Paper>
  );
};

export const OnBoardingPage = () => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const classes = useStyles();

  if (isButtonClicked) {
    return <ReferralCodePage />;
  }

  const handleOnButtonClick = () => {
    setIsButtonClicked(true);
  };

  return (
    <div className={classes.carouselRoot}>
        <Carousel
          pagina
          autoPlay={false}
          cycleNavigation={false}
          indicatorContainerProps={{
            style: {
              top: '88%',
              position: 'fixed',
            }
          }}
          indicatorIconButtonProps={{
            style: {
              margin: 10,
              color: '#FFFFFF',
            }
          }}
          activeIndicatorIconButtonProps={{
            style: {
              margin: 10,
              color: '#949494',
            }
          }}
        >
          {items.map((item, i) => (
            <GmOnboardingItem
              key={item}
              item={item}
              isLast={i === items.length - 1}
              content={item.content}
              handleOnButtonClick={handleOnButtonClick}
            />
          ))}
        </Carousel>
    </div>
  );
};
