// TODO: This whole implementation is meant to be a storybook implementation.
/* eslint-disable max-len */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

// styles
import { useStyles } from './styles';

// components
import GmInformativeText from '../../components/informative-text';
import GmStoryQuestions from '../../components/story-questions';
import GmStoryTextField from '../../components/story-text-field';
import GmStoryUploadPictures from '../../components/story-upload-pictures';

export const ComponentPlayground = () => {
  const classes = useStyles();
  const [questionSelected, onSelectQuestion] = useState('1');
  const [storyTextFieldValue, onChangeStoryTextFieldValue] = useState('');
  const [storyPictures, onChangeStoryPictures] = useState([
    {
      description: 'Donde te veas tu en tu mejor versión',
      id: '1',
      title: 'Una foto tuya'
    },
    {
      description: 'Algo que muestra en lo que invertiras la plata',
      id: '2',
      title: 'Para que necesitas la'
    },
    {
      description: 'A qué te dedicas o que te gusta hacer',
      id: '3',
      title: 'De lo que haces'
    },
    {
      description: 'Algo que tu quieras mostrar en tu historia',
      id: '4',
      title: 'Otras'
    }
  ]);

  return (
    <div className={classes.componentPlaygroundContainer}>
      <Grid container direction="column" style={{ margin: 0, padding: 0 }}>
        <Grid className={classes.componentPlaygroundItem} item>
          <GmInformativeText>
            Es tu momento para crear una historia que enamore. Recuerda, los ángeles aún no te conocen y esta es tu oportunidad para que se conecten contigo.
          </GmInformativeText>
        </Grid>
        <Grid className={classes.componentPlaygroundItem} item>
          <GmStoryQuestions
            onClick={(questionId) => onSelectQuestion(questionId)}
            questionSelected={questionSelected}
            questions={[
              { id: '1', description: 'Más vale pájaro en mano que cien volando', name: 'question1' },
              { id: '2', description: 'Más vale pájaro en mano que cien volando', name: 'question2' },
              { id: '3', description: 'Más vale pájaro en mano que cien volando', name: 'question3' },
              { id: '4', description: 'Más vale pájaro en mano que cien volando', name: 'question4' },
            ]}
          />
        </Grid>
        <Grid className={classes.componentPlaygroundItem} item>
          <GmStoryTextField
            title="¿Quién eres? Cuéntanos un poco sobre ti"
            onChange={(value) => onChangeStoryTextFieldValue(value)}
            placeholder="Muchos de los que te están leyendo quieren saber más sobre ti, especialmente qué has hecho para salir adelante. Encuentra en tu historia algo que te hace ÚNICO: Un tip: No cuentes muchoooo pero tampoco muy poquito. Trata de encontrar un justo medio. Las personas que de entrada cuantan muchoooo de su vida, al igual que las personas que son demasiado reservadas no generan mucha confianza. Acá lo que queremos es que la gente CONFÍE EN TI."
            value={storyTextFieldValue}
          />
        </Grid>
        <Grid className={classes.componentPlaygroundItem} item>
          <GmStoryUploadPictures
            onChangePictures={(newStoryPictures) => onChangeStoryPictures(newStoryPictures)}
            pictures={storyPictures}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ComponentPlayground;
