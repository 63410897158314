/* eslint-disable no-multi-spaces */
// vendors
import * as yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

// actions
import { getAdministrativeExpensesSettingsChain } from '../../redux/actions/settings';
import { getCalculateLoanValues, getTotalInterestEarned } from '../../redux/actions/calculator';

// components
import GmNewCalculator from './new-calculator';

// constants
import { INTEREST_RATE, userIsGodfather, userIsGodson } from '../../config/constants';

// utils
import {
  getLoanAmountWithAdministrativeExpenses,
  getQuotaValueAndInterestEarned,
  getTotalAdministrativeExpensesPercent,
  recalculateInitialLoanCalculatorValues
} from './utils';

const validationSchema = yup.object({
  fees: yup.number('Este campo es numerico').required('Este campo es requerido').min(1),
  frecuency: yup.number('Este campo es numerico').required('Este campo es requerido'),
  loan: yup.number('ingresa la cantidad de prestamo').required('Este campo es requerido'),
  quota: yup.number('Este campo es numerico').required('Este campo es requerido'),
});

const GmLoanCalculator = ({
  hasTransaction,
  isCalculatorOpenFromActionBar,
  onOpenLoanCalculator,
  onCreateProposal,
  visible,
}) => {
  if (!visible) {
    return null;
  }

  const dispatch = useDispatch();
  const [loanFeeService, onSetLoanFeeService] = useState('');
  const [loanFeeInsurance, onSetLoanAmountFeeInsurance] = useState('');
  const [loanAmountWithoutAnyFees, onSetLoanAmountWithoutAnyFees] = useState('');
  const [loanAmountWithAdministrativeExpenses, onSetLoanAmountWithAdministrativeExpenses] = useState('');
  const [isInsuranceIncluded, setIsInsuranceIncluded] = useState(true);

  const { auth, history: { calculator: calculatorInfo }, settings } = useSelector((state) => state);

  const isGodson = userIsGodson(auth.userInfo.role);
  const isGodfather = userIsGodfather(auth.userInfo.role);

  const suggestedInterestRate = INTEREST_RATE.findIndex(({ rate }) => rate === calculatorInfo.suggestedInterest);

  const formik = useFormik({
    initialValues: {
      fees: 1,                                                                              // Quota/Fees number
      frecuency: '3',                                                                       // Payment frequency, daily, weekly, biweekly or monthly
      interestRatePercent: calculatorInfo.interestRates[calculatorInfo.suggestedInterest],  // Interest rate percentage expression, 0.00, 0.01, 0.015, 0.02
      interestRate: suggestedInterestRate,                                                 // Interest rate enum, 0, 1, 2 or 3.
      itsFromFeeValueOption: false,                                                         // Review src/config/api/create-history.js to check this propertyh definition and description
      loan: loanAmountWithAdministrativeExpenses,                                           // Loan amount requested by godson with administrative expenses.
      loanAmountWithFeeAndAdministrativeExpenses: 0,                                        // Loan amount with fee insurance, GiVEmeFiVE fees and administrative expenses.
      quota: loanAmountWithAdministrativeExpenses,                 //  Quota amount godson user must pay
      totalInterestEarned: 0,
    },
    validationSchema,
    onSubmit: (values) => {
      if (loanAmountWithoutAnyFees && values.loan > loanAmountWithAdministrativeExpenses) {
        formik.setErrors({ loan: 'No puedes sobrepasar el limite del préstamo.' });
        return;
      }

      if (!values.loan) {
        formik.setErrors({ loan: 'El prestamo debe tener un valor valido.' });
        return;
      }

      onCreateProposal({
        proposal: {
          frequency: formik.values.frecuency,
          interestRate: formik.values.interestRate,
          interestRatePercent: formik.values.interestRatePercent,
          interestRateValue: formik.values.totalInterestEarned,
          isInsuranceIncluded,
          itsFromFeeValueOption: formik.values.itsFromFeeValueOption,
          lifeInsurangePercent: settings.administrativeExpensesSettings.Endorsement,
          loanAmount: formik.values.loan,
          loanTime: formik.values.fees,
          serviceCostPercent: settings.administrativeExpensesSettings.FeessGivemeFive,
          value: formik.values.quota
        },
      });
    },
  });

  const calculateLoanProjection = ({ frequency, interestRate, loanTime }) => {
    dispatch(getAdministrativeExpensesSettingsChain()).then((values) => {
      recalculateInitialLoanCalculatorValues({
        calculatorInfo,
        formik,
        isInsuranceIncluded,
        onSetLoanAmountFeeInsurance,
        onSetLoanAmountWithAdministrativeExpenses,
        onSetLoanAmountWithoutAnyFees,
        onSetLoanFeeService,
        settings,
        values
      });
    });

    const totalAdministrativeExpensesPercent = getTotalAdministrativeExpensesPercent({ settings, isInsuranceIncluded });
    const loanAmountWithAdministrativeExpenses = getLoanAmountWithAdministrativeExpenses({
      loanAmountFromCalculatorInfo: calculatorInfo.loan,
      totalAdministrativeExpensesPercent
    });

    getQuotaValueAndInterestEarned({
      dispatch,
      frequency,
      getCalculateLoanValues,
      getTotalInterestEarned,
      interestRate,
      loanAmount: loanAmountWithAdministrativeExpenses,
      loanTime
    }).then(({ quotaValue, totalInterestEarned }) => {
      formik.setFieldValue('quota', quotaValue);
      formik.setFieldValue('totalInterestEarned', totalInterestEarned);
      formik.setFieldValue('loanAmountWithFeeAndAdministrativeExpenses', loanAmountWithAdministrativeExpenses + totalInterestEarned);
    });
  };

  useEffect(() => {
    calculateLoanProjection({ frequency: 3, interestRate: 3, loanTime: 1 });
  }, []);

  useEffect(() => {
    const { frecuency, interestRate, fees } = formik.values;

    if (frecuency !== undefined
      && interestRate !== undefined
      && fees !== undefined) {
      calculateLoanProjection({ frequency: frecuency, interestRate, loanTime: fees });
    }
  }, [isInsuranceIncluded]);

  const handleFrequency = ({ target: { value } }) => {
    formik.setFieldValue('frecuency', value, true);
    formik.setFieldValue('itsFromFeeValueOption', false, true);
    getQuotaValueAndInterestEarned({
      dispatch,
      frequency: value,
      getCalculateLoanValues,
      getTotalInterestEarned,
      interestRate: formik.values.interestRate,
      loanAmount: loanAmountWithAdministrativeExpenses,
      loanTime: formik.values.fees,
    }).then(({ quotaValue, totalInterestEarned }) => {
      formik.setFieldValue('quota', quotaValue);
      formik.setFieldValue('totalInterestEarned', totalInterestEarned);
      formik.setFieldValue('loanAmountWithFeeAndAdministrativeExpenses', loanAmountWithAdministrativeExpenses + totalInterestEarned);
    });
  };

  const handleInterest = ({ target: { value } }) => {
    if (value !== formik.values.interestRate) {
      formik.setFieldValue('interestRate', value, true);
      // eslint-disable-next-line max-len
      formik.setFieldValue('interestRatePercent', calculatorInfo.interestRates[INTEREST_RATE.find((ir) => ir.value.toString() === value.toString()).rate]);
      formik.setFieldValue('itsFromFeeValueOption', false, true);
      getQuotaValueAndInterestEarned({
        dispatch,
        frequency: formik.values.frecuency,
        getCalculateLoanValues,
        getTotalInterestEarned,
        interestRate: value,
        loanAmount: loanAmountWithAdministrativeExpenses,
        loanTime: formik.values.fees,
      }).then(({ quotaValue, totalInterestEarned }) => {
        formik.setFieldValue('quota', quotaValue);
        formik.setFieldValue('totalInterestEarned', totalInterestEarned);
        formik.setFieldValue('loanAmountWithFeeAndAdministrativeExpenses', loanAmountWithAdministrativeExpenses + totalInterestEarned);
      });
    }
  };

  const handleFees = ({ target: { value } }) => {
    formik.setFieldValue('fees', value, true);
    formik.setFieldValue('itsFromFeeValueOption', false, true);
    if (value > 0) {
      getQuotaValueAndInterestEarned({
        dispatch,
        frequency: formik.values.frecuency,
        getCalculateLoanValues,
        getTotalInterestEarned,
        interestRate: formik.values.interestRate,
        loanAmount: loanAmountWithAdministrativeExpenses,
        loanTime: value,
      }).then(({ quotaValue, totalInterestEarned }) => {
        formik.setFieldValue('quota', quotaValue);
        formik.setFieldValue('totalInterestEarned', totalInterestEarned);
        formik.setFieldValue('loanAmountWithFeeAndAdministrativeExpenses', loanAmountWithAdministrativeExpenses + totalInterestEarned);
      });
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid alignItems="center" alignContent="center" container justify="center">
        <Grid item xs={3}>
          <CloseIcon
            onClick={() => onOpenLoanCalculator({
              isLoanCalculatorOpen: false,
              isLoanCalculatorOpenFromActionBar: false
            })}
          />
        </Grid>
        <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={6}>
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 700,
              textAlign: 'center',
              margin: '25px 0px 33px'
            }}
          >
            Calcula tu préstamo
          </Typography>
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <GmNewCalculator
        calculatorInfo={calculatorInfo}
        formik={formik}
        handleFees={handleFees}
        handleFrequency={handleFrequency}
        handleInterest={handleInterest}
        handleIsInsuranceIncludedChanged={() => setIsInsuranceIncluded((prevState) => !prevState)}
        hasTransaction={hasTransaction}
        isCalculatorOpenFromActionBar={isCalculatorOpenFromActionBar}
        isGodfather={isGodfather}
        isGodson={isGodson}
        isInsuranceIncluded={isInsuranceIncluded}
        loanAmountWithFeeAndAdministrativeExpenses={formik.values.loanAmountWithFeeAndAdministrativeExpenses}
        loanAmountWithoutAnyFees={loanAmountWithoutAnyFees}
        loanFeeInsurance={loanFeeInsurance}
        loanFeeService={loanFeeService}
        loanWithAdministrativeExpenses={loanAmountWithAdministrativeExpenses}
        totalInterestEarned={formik.values.totalInterestEarned}
        visible
      />
    </form>
  );
};

export default GmLoanCalculator;
