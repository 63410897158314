// TODO: These constants should be managed by the API.
export const ROLES = {
  both: 'Both',
  godfather: 'Godfather',
  godson: 'Godson',
};

export const ROLES_ENUM = {
  1: 'Godfather',
  2: 'Godson',
  3: 'Both',
};

export const userIsGodfather = (userRole) => userRole === ROLES.godfather;
export const userIsGodson = (userRole) => userRole === ROLES.godson;
