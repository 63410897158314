/**
 * Get total loan amount without any extra fees or any other kind of calucalated values, e.g: If godson user is asking for 1.000.000 that's the value returned.
 * @param {{ loanAmountFromCalculatorInfo:string|number|null|undefined }}
 * @returns {number|string}
 */
export const getPlainLoanWithoutFees = ({
  loanAmountFromCalculatorInfo,
}) => {
  let plainLoanAmountWithoutFees;

  if (loanAmountFromCalculatorInfo) {
    plainLoanAmountWithoutFees = loanAmountFromCalculatorInfo;
  }

  return plainLoanAmountWithoutFees;
};

/**
 * Get total loan amount with calculated administrative expenses percentage.
 * @param {{ loanAmountFromCalculatorInfo:string|number|null|undefined,
 *  totalAdministrativeExpensesPercent: number }}
 * @param {number|string} plainLoanAmount
 * @returns {number|string}
 */
export const getLoanAmountWithAdministrativeExpenses = ({
  loanAmountFromCalculatorInfo,
  totalAdministrativeExpensesPercent
}) => {
  let totalLoanAmount;

  if (loanAmountFromCalculatorInfo) {
    totalLoanAmount = loanAmountFromCalculatorInfo;
  }

  const totalLoanAmountWithAdministrativeExpenses = (totalLoanAmount + (totalLoanAmount * totalAdministrativeExpensesPercent));

  return totalLoanAmountWithAdministrativeExpenses;
};

/**
 * Get GiVEmeFiVE total fee service.
 * @param {{ loanAmountWihoutAnyFees: string|number|null|undefined,
 *  serviceFeePercentage: number }}
 * @returns {number|string}
 */
export const getLoanAmountFeeService = ({ loanAmountWihoutAnyFees, serviceFeePercentage }) => {
  const totalLoanFeeService = loanAmountWihoutAnyFees * serviceFeePercentage;
  return totalLoanFeeService;
};

/**
 * Get GiVEmeFiVE total fee insurance.
 * @param {{ loanAmountWihoutAnyFees: string|number|null|undefined,
 *  insuranceFeePercentage: number }}
 * @returns {number|string}
 */
export const getLoanAmountFeeInsurance = ({
  insuranceFeePercentage,
  loanAmountWihoutAnyFees
}) => {
  const totalLoanFeeService = loanAmountWihoutAnyFees * insuranceFeePercentage;
  return totalLoanFeeService;
};

export const getTotalAdministrativeExpensesPercent = ({ settings, isInsuranceIncluded }) => {
  let totalAdministrativeExpensesPercent = settings.administrativeExpensesPercent;

  if (!isInsuranceIncluded) {
    totalAdministrativeExpensesPercent = settings.administrativeExpensesSettings.FeessGivemeFive
      + settings.administrativeExpensesSettings.Emergency;
  }

  return totalAdministrativeExpensesPercent;
};

export const recalculateInitialLoanCalculatorValues = ({
  calculatorInfo,
  formik,
  isInsuranceIncluded,
  onSetLoanAmountFeeInsurance,
  onSetLoanAmountWithAdministrativeExpenses,
  onSetLoanAmountWithoutAnyFees,
  onSetLoanFeeService,
  settings,
  values
}) => {
  if (values) {
    const loanAmountWihoutAnyFees = getPlainLoanWithoutFees({
      loanAmountFromCalculatorInfo: calculatorInfo.loan
    });
    onSetLoanAmountWithoutAnyFees(loanAmountWihoutAnyFees);

    const totalAdministrativeExpensesPercent = getTotalAdministrativeExpensesPercent({ settings, isInsuranceIncluded });

    const loanAmountWithAdministrativeExpenses = getLoanAmountWithAdministrativeExpenses({
      loanAmountFromCalculatorInfo: calculatorInfo.loan,
      totalAdministrativeExpensesPercent
    });
    onSetLoanAmountWithAdministrativeExpenses(loanAmountWithAdministrativeExpenses);

    const loanAmountFeeService = getLoanAmountFeeService({
      loanAmountWihoutAnyFees,
      serviceFeePercentage: settings.administrativeExpensesSettings.FeessGivemeFive
    });
    onSetLoanFeeService(loanAmountFeeService);

    const loanAmountFeeInsurance = getLoanAmountFeeInsurance({
      insuranceFeePercentage: settings.administrativeExpensesSettings.Endorsement,
      loanAmountWihoutAnyFees
    });
    onSetLoanAmountFeeInsurance(loanAmountFeeInsurance);
    formik.setFieldValue('loan', loanAmountWithAdministrativeExpenses);
  }
};

export const getQuotaValueAndInterestEarned = ({
  dispatch,
  frequency,
  getCalculateLoanValues,
  getTotalInterestEarned,
  interestRate,
  loanAmount,
  loanTime,
}) => new Promise((resolve) => {
  dispatch(getCalculateLoanValues({
    frequency,
    interestRate,
    itsFromFeeValueOption: true,
    loanAmount,
    loanTime,
  })).then(async ({ value: quotaValue }) => {
    const { value: totalInterestEarned } = await dispatch(
      getTotalInterestEarned({
        frequency,
        interestRate,
        itsFromFeeValueOption: true,
        loanAmount,
        loanTime,
        quotaValue,
      })
    );
    resolve({ quotaValue, totalInterestEarned });
  });
});
