import { transactionInitialState } from '../../config/state/initialState';
import {
  GET_ACTIVE_TRANSACTION_BEGIN,
  GET_ACTIVE_TRANSACTION_ERROR,
  GET_ACTIVE_TRANSACTION_SUCCESS,
  GET_PAYMENT_CALENDAR_BEGIN,
  GET_PAYMENT_CALENDAR_ERROR,
  GET_PAYMENT_CALENDAR_SUCCESS,
  GET_REFERRAL_PERIMETER_STATUS_BEGIN,
  GET_REFERRAL_PERIMETER_STATUS_ERROR,
  GET_REFERRAL_PERIMETER_STATUS_SUCCESS,
  SET_CREATE_TRANSACTION_BEGIN,
  SET_CREATE_TRANSACTION_ERROR,
  SET_CREATE_TRANSACTION_SUCCESS,
  SET_SEND_SMS_CODE_BEGIN,
  SET_SEND_SMS_CODE_ERROR,
  SET_SEND_SMS_CODE_SUCCESS,
  SET_VALIDATE_CODE_BEGIN,
  SET_VALIDATE_CODE_ERROR,
  SET_VALIDATE_CODE_SUCCESS,
} from '../action-types';

function transactionReducer(state = transactionInitialState, action) {
  switch (action.type) {
    case SET_SEND_SMS_CODE_BEGIN:
      return {
        ...state,
        error: false,
      };

    case SET_SEND_SMS_CODE_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case SET_SEND_SMS_CODE_ERROR:
      return {
        ...state,
        error: true,
      };

    case SET_VALIDATE_CODE_BEGIN:
      return {
        ...state,
        error: true,
      };

    case SET_VALIDATE_CODE_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case SET_VALIDATE_CODE_ERROR:
      return {
        ...state,
        error: true,
      };

    case SET_CREATE_TRANSACTION_BEGIN:
      return {
        ...state,
        error: true,
      };

    case SET_CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        error: false,
        transactionId: action?.payload?.transactionId,
      };

    case SET_CREATE_TRANSACTION_ERROR:
    case GET_PAYMENT_CALENDAR_ERROR:
      return {
        ...state,
        error: true,
      };

    case GET_REFERRAL_PERIMETER_STATUS_BEGIN:
    case GET_ACTIVE_TRANSACTION_BEGIN:
      return {
        ...state,
        error: true,
      };

    case GET_PAYMENT_CALENDAR_BEGIN:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_PAYMENT_CALENDAR_SUCCESS:
      const calendarPayments = action.payload.calendarPayments
        .map((payment) =>
          payment.calendar.map((ps) => ({
            id: ps.Id,
            title: `${payment.user.fullName}, $ ${ps.valueToPay}`,
            startDate: new Date(ps.startDate),
            endDate: new Date(ps.endDate),
            ownerId: payment.user.id,
          })))
        .flat(1);
      return {
        ...state,
        loading: false,
        error: false,
        calendarPayment: calendarPayments,
      };

    case GET_REFERRAL_PERIMETER_STATUS_SUCCESS:
      return {
        ...state,
        error: false,
        referralPerimeter: action.payload.data.result,
      };

    case GET_ACTIVE_TRANSACTION_SUCCESS:
      return {
        ...state,
        error: false,
        transactionsList: action.payload.data.result.items,
      };

    case GET_REFERRAL_PERIMETER_STATUS_ERROR:
    case GET_ACTIVE_TRANSACTION_ERROR:
      return {
        ...state,
        error: true,
      };

    default:
      return state;
  }
}

export default transactionReducer;
