// vendors
import React from 'react';

const PQRSEnabled = (
  <>
    <path d="M74 0H0V53.72H10.44L15.13 63.19L19.9 53.72H74V0Z" fill="#33647E" />
    <path d="M60.07 20.71H13.92V33.02H60.07V20.71Z" fill="#FFC600" />
  </>
);

export default PQRSEnabled;
