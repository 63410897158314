import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  createHistoryPage: () => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 25px 80px',
  }),
  customCarousel: {
    '& .rec-pagination button': {
      backgroundColor: '#1DCAD3',
      boxShadow: 'none',
      width: 6,
      height: 6,
      opacity: '0.4',
    },
    '& .rec-pagination .rec-dot_active': {
      width: 9,
      opacity: 1,
      height: 9,
    },
  },
  sliderCard: {
    width: 224,
    height: 166,
    background: 'gray',
    borderRadius: 5,
    padding: 20,
    outline: 'none',
  },
  titleCategory: {
    position: 'relative',
    top: '-35px',
    '& strong': {
      fontWeight: 600,
      fontSize: 16,
    },
    color: '#fff'
  },
  notSelectCategory: {
    top: -10,
    position: 'relative',
  },
  menuItem: {
    textAlign: 'left',
  }
}));

export { useStyles };
