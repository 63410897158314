// packages
import React, { useEffect, useState } from 'react';

// components
import GmStoryTextField from '../../components/story-text-field';

const GmFormStoryQuestionsSelector = ({
  questions,
  onQuestionsChange,
}) => {
  const [questionFormAnswers, setQuestionFormAnswer] = useState({});

  useEffect(() => {
    if (questions.length) {
      const processedQuestions = questions.reduce((acc, val) => {
        if (!Object.prototype.hasOwnProperty.call(acc, val.id)) {
          return { ...acc, [val.id]: val?.value || '' };
        }

        return acc;
      }, {});
      setQuestionFormAnswer(processedQuestions);
    }
  }, [questions]);

  const handleOnFormStoryQuestionChange = (event) => {
    const processedQuestions = { ...questionFormAnswers, [event.target.id]: event.target.value };
    setQuestionFormAnswer(processedQuestions);
    onQuestionsChange(processedQuestions);
  };

  return (questions.map(({
    description,
    error,
    id,
    name,
  }) => (
    <GmStoryTextField
      id={id?.toString()}
      isFormikHandled
      key={id?.toString()}
      error={error}
      name={id?.toString()}
      normalText
      onChange={handleOnFormStoryQuestionChange}
      placeholder={description}
      style={{ marginBottom: 15 }}
      title={name}
      value={questionFormAnswers[id]}
    />
  )));
};

export default GmFormStoryQuestionsSelector;
