// vendors
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// import Switch from '@material-ui/core/Switch';
// import { withStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { createTransaction } from '../../redux/actions/transaction';
import { editProposal, deleteProposal, getCalculatorInfo } from '../../redux/actions/createHistory';
import { getCalculateLoanValues, getTotalInterestEarned } from '../../redux/actions/calculator';

// components
import GmLoanCalculatorFeeDisclaimer from './fee-disclaimer';

// constants
import { INTEREST_RATE } from '../../config/constants';

// images
import MoneyIcon from '../../static/images/money.png';

// styles
import { useStyles } from './styles';

// utils
import {
  getLoanAmountFeeService,
  getLoanAmountWithAdministrativeExpenses,
  getQuotaValueAndInterestEarned,
  getTotalAdministrativeExpensesPercent
} from './utils';

// Insurance currently disabled
// const GmSwitch = withStyles({
//  switchBase: {
//    color: '#e4e4e4',
//    '&$checked': {
//      color: '#34657F',
//    },
//    '&$checked + $track': {
//      backgroundColor: '#FFC600',
//      opacity: 1,
//    },
//  },
//  checked: {},
//  track: {},
// })(Switch);

export const GmNewCalculator = ({
  calculatorInfo,
  formik,
  frequency,
  frequencyLabel,
  handleFees,
  handleFrequency,
  handleInterest,
  isInsuranceIncluded,
  // handleIsInsuranceIncludedChanged,
  handleEditProposalFromStoryCreation,
  handleProposalFromStoryCreation,
  hasTransaction,
  interestRate,
  interestRateLabel,
  isCalculatorOpenFromActionBar,
  isCalculatorOpenFromStoryCreation = false,
  isGodfather,
  isGodson,
  isProposalAccepted,
  loanAmountWithFeeAndAdministrativeExpenses,
  loanAmountWithoutAnyFees,
  loanFeeInsurance,
  loanFeeService,
  loanTime,
  loanWithAdministrativeExpenses,
  messageId,
  proposalId,
  quotaValue,
  showAsSummary = false,
  totalInterestEarned,
  visible,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { auth, settings } = useSelector((state) => state);
  const { historyId, threadId } = useSelector((state) => state.history.chatsHistory);
  const { interestRates, suggestedInterest, loan } = useSelector((state) => state.history.calculator);
  const { proposal } = useSelector((state) => state.history?.historyInfo);

  const [creationProposalId, setCreationProposalId] = useState(proposal?.id);
  const [isEditMode, setIsEditMode] = useState(false);
  const [lFrequency, setlFrequency] = useState(frequency);
  const [lFeesNumber, setlFeesNumber] = useState(loanTime);
  const [lQuotaValue, setlQuotaValue] = useState(quotaValue);
  const [lInterestRate, setlInterestRate] = useState(interestRate);
  const [lLoanFeeService, setlLoanFeeService] = useState(loanFeeService);
  const [lTotalInterestEarned, setlTotalInterestEarned] = useState(totalInterestEarned);
  const [lLoanAmountWithAdministrativeExpenses, setlLoanAmountWithAdministrativeExpenses] = useState(loanWithAdministrativeExpenses);
  const [lLoanAmountWithoutAdministrativeExpenses, setlLoanAmountWithoutAdministrativeExpenses] = useState(loanWithAdministrativeExpenses);
  // const [creationFeeInsurance, setCreationFeeInsurance] = useState(lLoanFeeInsurance);
  const [creationFeeService, setCreationFeeService] = useState(lLoanFeeService);
  // eslint-disable-next-line max-len
  const [lLoanAmountWithFeeAndAdministrativeExpenses, setlLoanAmountWithFeeAndAdministrativeExpenses] = useState(loanAmountWithFeeAndAdministrativeExpenses);

  useEffect(() => {
    if (isCalculatorOpenFromStoryCreation) {
      setCreationProposalId(proposal?.id);
      setlFrequency(frequency);
      setlFeesNumber(loanTime);
      setlQuotaValue(quotaValue);
      setlInterestRate(interestRate);
      setlLoanFeeService(loanFeeService);
      setlTotalInterestEarned(totalInterestEarned);
      setlLoanAmountWithAdministrativeExpenses(loanWithAdministrativeExpenses);
      setlLoanAmountWithoutAdministrativeExpenses(loanWithAdministrativeExpenses);
    }
  }, [isCalculatorOpenFromStoryCreation]);

  // effect to handle if proposal is in edit mode
  useEffect(() => {
    if (isEditMode) {
      dispatch(getCalculatorInfo(historyId));
    }
  }, [historyId, isEditMode]);

  if (!visible) {
    return null;
  }

  const totalAdministrativeExpensesPercent = getTotalAdministrativeExpensesPercent({ settings, isInsuranceIncluded });
  const isSuggestedInterestRate = (interestRateKeyLabel) => calculatorInfo?.suggestedInterest === interestRateKeyLabel
    || suggestedInterest === interestRateKeyLabel;

  const SuggestedInterestLabel = ({ visible }) => {
    if (!visible) {
      return null;
    }

    return (<Typography className={classes.suggestedInterestLabel}>TASA SUGERIDA</Typography>);
  };

  const handleDeleteProposal = () => {
    dispatch(deleteProposal(messageId, proposalId));
  };

  const handleEditProposal = () => {
    setIsEditMode(true);
  };

  const handleOnCreateTransaction = () => {
    if (isGodson) {
      dispatch(createTransaction({
        historyId,
        messageThreadId: threadId,
        isInsuranceIncluded,
        proposal: {
          administrativeExpensePercent: totalAdministrativeExpensesPercent,
          administrativeExpenseValue: loanAmountWithoutAnyFees * totalAdministrativeExpensesPercent,
          frequency,
          interestRate,
          itsFromFeeValueOption: false,
          loanAmount: loanWithAdministrativeExpenses,
          loanTime,
          user: {
            fullName: auth.userInfo.fullName,
            id: auth.userInfo.userId,
            profilePicture: auth.userInfo.profilePicture,
          },
          value: quotaValue,
          id: proposalId,
        }
      }));
    }
  };

  const handleOnCreateTransactionFromStoryCreation = () => {
    if (creationProposalId) {
      handleEditProposalFromStoryCreation({
        administrativeExpensePercent: settings.administrativeExpensesPercent,
        administrativeExpenseValue: lLoanAmountWithoutAdministrativeExpenses * settings.administrativeExpensesPercent,
        financingValue: lLoanAmountWithoutAdministrativeExpenses,
        frequency: lFrequency,
        historyId,
        interestRate: lInterestRate,
        interestRatePercent: interestRates[INTEREST_RATE[lInterestRate]?.rate],
        interestRateValue: lTotalInterestEarned,
        isDefaultProposal: true,
        isInsuranceIncluded,
        itsFromFeeValueOption: false,
        lifeInsurangePercent: settings.administrativeExpensesSettings.Endorsement,
        loanAmount: lLoanAmountWithAdministrativeExpenses,
        loanTime: lFeesNumber,
        messageThreadId: null,
        serviceCostPercent: settings.administrativeExpensesSettings.FeessGivemeFive,
        value: lQuotaValue,
        id: creationProposalId
      });
    }

    if (!creationProposalId) {
      handleProposalFromStoryCreation({
        administrativeExpensePercent: settings.administrativeExpensesPercent,
        administrativeExpenseValue: lLoanAmountWithoutAdministrativeExpenses * settings.administrativeExpensesPercent,
        financingValue: lLoanAmountWithoutAdministrativeExpenses,
        frequency: lFrequency,
        historyId,
        interestRate: lInterestRate,
        interestRatePercent: interestRates[INTEREST_RATE[lInterestRate]?.rate],
        interestRateValue: lTotalInterestEarned,
        isDefaultProposal: true,
        isInsuranceIncluded,
        itsFromFeeValueOption: false,
        lifeInsurangePercent: settings.administrativeExpensesSettings.Endorsement,
        loanAmount: lLoanAmountWithAdministrativeExpenses,
        loanTime: lFeesNumber,
        messageThreadId: null,
        serviceCostPercent: settings.administrativeExpensesSettings.FeessGivemeFive,
        value: lQuotaValue,
      });
    }
  };

  const getActionButtons = () => {
    if (isProposalAccepted) {
      return null;
    }

    if (isCalculatorOpenFromStoryCreation) {
      return (
        <Grid item xs={12}>
          <Button
            color="primary"
            fullWidth
            onClick={handleOnCreateTransactionFromStoryCreation}
            style={{
              backgroundColor: '#33647E',
              color: '#fff',
              height: 25,
              marginTop: 20,
              width: 80,
            }}
            type="submit"
            variant="contained"
          >
            ACEPTAR
          </Button>
        </Grid>
      );
    }

    if (((!isCalculatorOpenFromActionBar && isGodfather)
     || (isCalculatorOpenFromActionBar && isGodfather)
     || (!isCalculatorOpenFromActionBar && isGodson))
     && !hasTransaction) {
      if (!isCalculatorOpenFromActionBar && isGodfather) {
        return (
          <>
          <Grid item xs={6}>
            <Button
              color="primary"
              fullWidth
              onClick={handleEditProposal}
              style={{
                backgroundColor: '#33647E',
                color: '#fff',
                height: 25,
                marginTop: 20,
                width: 80,
              }}
              type="submit"
              variant="contained"
            >
              EDITAR
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              fullWidth
              onClick={handleDeleteProposal}
              style={{
                backgroundColor: '#33647E',
                color: '#fff',
                height: 25,
                marginTop: 20,
                width: 80,
              }}
              type="submit"
              variant="contained"
            >
              ELIMINAR
            </Button>
          </Grid>
          </>
        );
      }
      return (
        <Grid item xs={12}>
          <Button
            color="primary"
            fullWidth
            onClick={handleOnCreateTransaction}
            style={{
              backgroundColor: '#33647E',
              color: '#fff',
              height: 25,
              marginTop: 20,
              width: 80,
            }}
            type="submit"
            variant="contained"
          >
            {isGodson ? 'ACEPTAR' : 'ENVIAR'}
          </Button>
        </Grid>
      );
    }

    return null;
  };

  const getlFeesNumber = isEditMode || isCalculatorOpenFromStoryCreation ? lFeesNumber : formik?.values?.fees;
  const getlInterestRate = isEditMode || isCalculatorOpenFromStoryCreation ? lInterestRate : formik?.values?.interestRate;
  const getlFrequency = isEditMode || isCalculatorOpenFromStoryCreation ? lFrequency : formik?.values?.frecuency;
  const getlInterestRates = isEditMode || isCalculatorOpenFromStoryCreation ? interestRates : calculatorInfo?.interestRates;
  const getlQuotaValue = isEditMode || isCalculatorOpenFromStoryCreation ? lQuotaValue : formik?.values?.quota || quotaValue;
  const getlTotalInterestedEarned = isEditMode || isCalculatorOpenFromStoryCreation ? lTotalInterestEarned : totalInterestEarned;

  const handleFeesIsCalculatorOpenFromStoryCreation = () => {
    if (isCalculatorOpenFromStoryCreation) {
      const feeService = getLoanAmountFeeService({
        loanAmountWihoutAnyFees: lLoanAmountWithoutAdministrativeExpenses,
        serviceFeePercentage: settings.administrativeExpensesSettings.FeessGivemeFive
      });
      setlLoanFeeService(feeService);
    }
  };

  const handleFeesLocally = ({ target: { value } }) => {
    const loanTime = parseInt(value, 10);
    setlFeesNumber(loanTime);
    dispatch(getCalculateLoanValues({
      frequency: getlFrequency,
      interestRate: getlInterestRate,
      itsFromFeeValueOption: true,
      loanAmount: lLoanAmountWithAdministrativeExpenses,
      loanTime,
    })).then(({ value: quotaValue }) => {
      dispatch(getTotalInterestEarned({
        frequency: getlFrequency,
        interestRate: getlInterestRate,
        itsFromFeeValueOption: true,
        loanAmount: lLoanAmountWithAdministrativeExpenses,
        loanTime,
        quotaValue,
      })).then(({ value: rTotalInterestEarned }) => {
        setlQuotaValue(quotaValue);
        setlTotalInterestEarned(rTotalInterestEarned);
        setlLoanAmountWithFeeAndAdministrativeExpenses(lLoanAmountWithAdministrativeExpenses + rTotalInterestEarned);
      });
    });
  };

  const handleInterestLocally = ({ target: { value } }) => {
    setlInterestRate(parseInt(value, 10));
    handleFeesIsCalculatorOpenFromStoryCreation();

    dispatch(getCalculateLoanValues({
      frequency: getlFrequency,
      interestRate: value,
      itsFromFeeValueOption: true,
      loanAmount: lLoanAmountWithAdministrativeExpenses,
      loanTime: getlFeesNumber,
    })).then(({ value: quotaValue }) => {
      dispatch(getTotalInterestEarned({
        frequency: getlFrequency,
        interestRate: value,
        itsFromFeeValueOption: true,
        loanAmount: lLoanAmountWithAdministrativeExpenses,
        loanTime: getlFeesNumber,
        quotaValue,
      })).then(({ value: rTotalInterestEarned }) => {
        setlQuotaValue(quotaValue);
        setlTotalInterestEarned(rTotalInterestEarned);
        setlLoanAmountWithFeeAndAdministrativeExpenses(lLoanAmountWithAdministrativeExpenses + rTotalInterestEarned);
      });
    });
  };

  const handleFrequencyLocally = ({ target: { value } }) => {
    setlFrequency(parseInt(value, 10));
    handleFeesIsCalculatorOpenFromStoryCreation();

    dispatch(getCalculateLoanValues({
      frequency: value,
      interestRate: getlInterestRate,
      itsFromFeeValueOption: true,
      loanAmount: lLoanAmountWithAdministrativeExpenses,
      loanTime: getlFeesNumber,
    })).then(({ value: quotaValue }) => {
      dispatch(getTotalInterestEarned({
        frequency: value,
        interestRate: getlInterestRate,
        itsFromFeeValueOption: true,
        loanAmount: lLoanAmountWithAdministrativeExpenses,
        loanTime: getlFeesNumber,
        quotaValue,
      })).then(({ value: rTotalInterestEarned }) => {
        setlQuotaValue(quotaValue);
        setlTotalInterestEarned(rTotalInterestEarned);
        setlLoanAmountWithFeeAndAdministrativeExpenses(lLoanAmountWithAdministrativeExpenses + rTotalInterestEarned);
      });
    });
  };

  const handleLoanAmountWithExpensesLocally = ({ target: { value } }) => {
    let lValue = 0;

    if (value && value !== '' && value !== '$') {
      const parsedValue = parseInt(numeral(value).value(), 10);
      lValue = parsedValue <= 2000000 ? parsedValue : 2000000;
    }

    const lValueWithAdministrativeExpenses = Math.round((lValue + (lValue * settings.administrativeExpensesPercent)));

    setlLoanAmountWithoutAdministrativeExpenses(lValue);
    setlLoanAmountWithAdministrativeExpenses(lValueWithAdministrativeExpenses);
    // setCreationFeeInsurance(lValue * settings.administrativeExpensesSettings.Endorsement);
    setCreationFeeService(lValue * settings.administrativeExpensesSettings.FeessGivemeFive);
    handleFeesIsCalculatorOpenFromStoryCreation();

    if (lValue.toString().length < 2) {
      setlQuotaValue(0);
      setlTotalInterestEarned(0);
      setlLoanAmountWithFeeAndAdministrativeExpenses(0);
    }

    if (lValue.toString().length >= 4) {
      dispatch(getCalculateLoanValues({
        frequency: getlFrequency,
        interestRate: getlInterestRate,
        itsFromFeeValueOption: true,
        loanAmount: lValueWithAdministrativeExpenses,
        loanTime,
      })).then(({ value: quotaValue }) =>
        dispatch(getTotalInterestEarned({
          frequency: getlFrequency,
          interestRate: getlInterestRate,
          itsFromFeeValueOption: true,
          loanAmount: lValueWithAdministrativeExpenses,
          loanTime: getlFeesNumber,
          quotaValue,
        })).then(({ value: rTotalInterestEarned }) => {
          setlQuotaValue(quotaValue);
          setlTotalInterestEarned(rTotalInterestEarned);
          setlLoanAmountWithFeeAndAdministrativeExpenses(lValueWithAdministrativeExpenses + rTotalInterestEarned);
        }));
    }
  };

  const handleOnEditProposal = () => {
    if (isGodfather && isEditMode) {
      dispatch(editProposal({
        editedProposalId: proposalId ? parseInt(proposalId, 10) : null,
        frequency: getlFrequency,
        interestRate: getlInterestRate,
        interestRatePercent: interestRates[INTEREST_RATE.find((ir) => ir.value.toString() === getlInterestRate.toString())?.rate],
        interestRateValue: getlTotalInterestedEarned,
        isInsuranceIncluded,
        itsFromFeeValueOption: true,
        lifeInsurangePercent: settings.administrativeExpensesSettings.Endorsement,
        lifeInsurangeValue: loanFeeInsurance,
        loanAmount: lLoanAmountWithAdministrativeExpenses,
        loanTime: getlFeesNumber,
        messageId: parseInt(messageId, 10),
        serviceCostPercent: settings.administrativeExpensesSettings.FeessGivemeFive,
        serviceCostValue: loanFeeService,
        value: getlQuotaValue,
      }));
    }
  };

  const calculateLoanProjection = () => {
    const totalAdministrativeExpensesPercent = getTotalAdministrativeExpensesPercent({ settings, isInsuranceIncluded });
    const loanAmountWithAdministrativeExpenses = getLoanAmountWithAdministrativeExpenses({
      loanAmountFromCalculatorInfo: loan,
      totalAdministrativeExpensesPercent
    });

    getQuotaValueAndInterestEarned({
      dispatch,
      frequency: getlFrequency,
      getCalculateLoanValues,
      getTotalInterestEarned,
      interestRate: getlInterestRate,
      loanAmount: loanAmountWithAdministrativeExpenses,
      loanTime: getlFeesNumber,
    }).then(({ quotaValue, totalInterestEarned }) => {
      setlQuotaValue(quotaValue);
      setlTotalInterestEarned(totalInterestEarned);
      setlLoanAmountWithAdministrativeExpenses(loanAmountWithAdministrativeExpenses);
      setlLoanAmountWithFeeAndAdministrativeExpenses(loanAmountWithAdministrativeExpenses + totalInterestEarned);
    });
  };

  useEffect(() => {
    if (isEditMode
      && getlFrequency !== undefined
      && getlInterestRate !== undefined
      && getlFeesNumber !== undefined) {
      calculateLoanProjection();
    }
  }, [isInsuranceIncluded, isEditMode]);

  const margin = showAsSummary
    ? '0px'
    : '0px 22px 22px 22px';

  const grossLoanAmountValue = isEditMode ? lLoanAmountWithAdministrativeExpenses : loanWithAdministrativeExpenses;

  return (
    <div style={{ margin }}>
      <Grid className={classes.summaryContainer} container>
        <Grid className={classes.summaryContainerRow} container direction="row">
          <Grid
            className={classes.summaryContainerRowItem}
            item
            xs={12}
          >
            <img src={MoneyIcon} />
            <Typography
              style={{
                color: '#657E91',
                fontSize: 12,
                fontWeight: 400
              }}
            >
              {isGodfather && 'DESEMBOLSO TOTAL'}
              {isGodson && 'APOYO A TU HISTORIA'}
            </Typography>
            {isCalculatorOpenFromStoryCreation
              ? (
                <TextField
                  id="fees"
                  inputProps={{ style: { fontSize: '28px', textAlign: 'center', max: 2000000 } }}
                  min={0}
                  max={2000000}
                  name="fees"
                  onChange={handleLoanAmountWithExpensesLocally}
                  type="text"
                  value={numeral(lLoanAmountWithoutAdministrativeExpenses).format('$0,0')}
                  variant="standard"
                />
              )
              : (
                  <Typography
                    style={{
                      color: '#000000',
                      fontSize: 28,
                      fontWeight: 700
                    }}
                  >
                    {numeral(isGodfather
                      ? grossLoanAmountValue
                      : loanAmountWithoutAnyFees).format('$0,0')}
                  </Typography>
              )}
            <Typography
              style={{
                color: '#8B8B8B',
                fontSize: 8,
              }}
            >
              {isGodfather && 'IncluYe: apoyo a la historia y costos del servicio'}
              {isGodson && 'Este es el valor que tú necesitas '}
            </Typography>
            {isCalculatorOpenFromStoryCreation
              ? (
                <Typography
                  style={{
                    color: '#8B8B8B',
                    fontSize: 8,
                  }}
                >
                  Puedes pedir prestado hasta $2,000,000
                </Typography>
              )
              : null}
          </Grid>
        </Grid>
        {isGodfather && (
          <>
            <Grid
              className={classes.summaryContainerRowItemHeaderContainer}
              container
              justify="center"
            >
              <Grid item>
                <Typography className={classes.summaryContainerRowItemHeaderTitle}>
                  DESEMBOLSOS
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid
                className={classes.summaryContainerRowItem}
                item
                xs={isGodson ? 12 : 6}
                style={{ borderRight: '1px solid #BDE1E2' }}
              >
                <Typography className={classes.summaryContainerRowItemTitle}>
                  {isGodson ? 'PRÉSTAMO TOTAL' : 'APOYO A LA HISTORIA'}
                </Typography>
                <Typography className={classes.summaryContainerRowItemValue}>{numeral(loanAmountWithoutAnyFees).format('$0,0')}</Typography>
                <Typography className={classes.summaryContainerRowItemCaption}>Lo pagas directamente al Buena Paga</Typography>
              </Grid>
              <Grid
                className={classes.summaryContainerRowItem}
                item
                xs={isGodson ? 12 : 6}
              >
                <Typography className={classes.summaryContainerRowItemTitle}>
                  COSTO DEL SERVICIO
                </Typography>
                <Typography className={classes.summaryContainerRowItemValue}>
                  {numeral(isInsuranceIncluded ? loanFeeService + loanFeeInsurance : loanFeeService).format('$0,0')}
                </Typography>
                <Typography className={classes.summaryContainerRowItemCaption}>
                  Lo haces por Wompi
                  <br />
                  {`(Servicio ${numeral(loanFeeService).format('$0,0')})`}
                </Typography>
              </Grid>
            </Grid>
            {/*
            Insurance currently disabled
            <Grid className={classes.summaryContainerRow} container direction="row">
              <Grid
                container
                justify="center"
                alignContent="center"
                style={{
                  borderTop: '1px solid #BDE1E2',
                  margin: '0px 20px 10px',
                  paddingBottom: 10,
                  paddingTop: 10
                }}
              >
                <Grid item xs={12}>
                  <Typography className={classes.summaryContainerRowItemTitle}>
                    QUIERO ASEGURAR MI INVERSION
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <GmSwitch
                    checked={isInsuranceIncluded}
                    onChange={handleIsInsuranceIncludedChanged}
                    disabled={showAsSummary && !isEditMode}
                  />
                  <Typography className={classes.summaryContainerRowItemValue}>{numeral(loanFeeInsurance).format('$0,0')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.summaryContainerRowItemCaption}>
                    Es opcional
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            */}
          </>
        )}
        <Grid
          className={classes.summaryContainerRowItemHeaderContainer}
          container
          justify="center"
        >
          <Grid item>
            <Typography className={classes.summaryContainerRowItemHeaderTitle}>
              CONDICIONES
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ margin: '0px 20px' }}>
          <Grid
            className={classes.summaryContainerRowItem}
            item
            style={{ borderBottom: '1px solid #BDE1E2', borderRight: '1px solid #BDE1E2', paddingLeft: 0 }}
            xs={6}
          >
            <Typography className={classes.summaryContainerRowItemTitle}>
              NÚMERO DE CUOTAS
            </Typography>
            {!showAsSummary || isEditMode
              ? (
                <TextField
                  disabled={showAsSummary && !isEditMode}
                  error={formik?.touched?.fees && Boolean(formik?.errors?.fees)}
                  helperText={formik?.touched?.fees && formik?.errors?.fees}
                  id="fees"
                  inputProps={{ style: { fontSize: '11px', textAlign: 'center' } }}
                  min={0}
                  name="fees"
                  onChange={!isEditMode && !isCalculatorOpenFromStoryCreation ? handleFees : handleFeesLocally}
                  type="number"
                  value={getlFeesNumber}
                  variant="standard"
                />
              )
              : (
                <Typography className={classes.summaryContainerRowItemValue}>
                  {loanTime}
                </Typography>
              )}
          </Grid>
          <Grid
            className={classes.summaryContainerRowItem}
            item
            style={{ borderBottom: '1px solid #BDE1E2', paddingRight: 0, paddingBottom: 6 }}
            xs={6}
          >
            <Typography className={classes.summaryContainerRowItemTitle}>
              TASA DE INTERÉS
            </Typography>
            {!showAsSummary || isEditMode
              ? (
                <Select
                  disabled={showAsSummary && !isEditMode}
                  error={formik?.touched?.interestRates && Boolean(formik?.errors?.interestRates)}
                  fullWidth
                  id="interestRates"
                  label="Tipo"
                  labelId="type"
                  name="interestRates"
                  onChange={!isEditMode && !isCalculatorOpenFromStoryCreation ? handleInterest : handleInterestLocally}
                  style={{ fontSize: '11px', textAlign: 'center' }}
                  value={getlInterestRate}
                  variant="standard"
                >
                  <MenuItem
                    className={isSuggestedInterestRate('None') && classes.suggestedInterest}
                    value="0"
                  >
                    {`Nada ${numeral(getlInterestRates?.None).format('%0.0')}`}
                    <br />
                    <SuggestedInterestLabel visible={isSuggestedInterestRate('None')} />
                  </MenuItem>
                  <MenuItem
                    className={isSuggestedInterestRate('Low') && classes.suggestedInterest}
                    value="1"
                  >
                    {`Baja ${numeral(getlInterestRates?.Low).format('%0.0')}`}
                    <br />
                    <SuggestedInterestLabel visible={isSuggestedInterestRate('Low')} />
                  </MenuItem>
                  <MenuItem
                    className={isSuggestedInterestRate('Medium') && classes.suggestedInterest}
                    value="2"
                  >
                    {`Media ${numeral(getlInterestRates?.Medium).format('%0.0')}`}
                    <br />
                    <SuggestedInterestLabel visible={isSuggestedInterestRate('Medium')} />
                  </MenuItem>
                  <MenuItem
                    className={isSuggestedInterestRate('High') && classes.suggestedInterest}
                    value="3"
                  >
                    {`Alta ${numeral(getlInterestRates?.High).format('%0.0')}`}
                    <br />
                    <SuggestedInterestLabel visible={isSuggestedInterestRate('High')} />
                  </MenuItem>
                </Select>
              )
              : (
                <Typography className={classes.summaryContainerRowItemValue}>
                  {interestRateLabel}
                </Typography>
              )}
            {isGodson && (
              <Typography className={classes.summaryContainerRowItemCaption}>
                Puedes modelarla, pero es el ángel quien la elige.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid
            className={classes.summaryContainerRowItem}
            item
            style={{ borderRight: '1px solid #BDE1E2' }}
            xs={6}
          >
            <Typography className={classes.summaryContainerRowItemTitle}>
              VALOR POR CUOTA
            </Typography>
            <Typography className={classes.summaryContainerRowItemValue}>
              {numeral(getlQuotaValue).format('$0,0')}
            </Typography>
          </Grid>
          <Grid
            className={classes.summaryContainerRowItem}
            item
            xs={6}
          >
            <Typography className={classes.summaryContainerRowItemTitle}>
              FRECUENCIA DEL PAGO
            </Typography>
            {!showAsSummary || isEditMode
              ? (
                <Select
                  disabled={showAsSummary && !isEditMode}
                  error={formik?.touched?.frecuency && Boolean(formik?.errors?.frecuency)}
                  fullWidth
                  id="frecuency"
                  label="Tipo"
                  labelId="type"
                  name="frecuency"
                  onChange={!isEditMode && !isCalculatorOpenFromStoryCreation ? handleFrequency : handleFrequencyLocally}
                  style={{ fontSize: 11, textAlign: 'center' }}
                  value={getlFrequency}
                  variant="standard"
                >
                  <MenuItem value="0">Diario</MenuItem>
                  <MenuItem value="1">Semanal</MenuItem>
                  <MenuItem value="2">Quincenal</MenuItem>
                  <MenuItem value="3">Mensual</MenuItem>
                </Select>
              )
              : (
                <Typography className={classes.summaryContainerRowItemValue}>
                  {frequencyLabel}
                </Typography>
              )}
          </Grid>
        </Grid>
        {isGodfather && (
          <>
            <Grid
              className={classes.summaryContainerRowItemHeaderContainer}
              container
              justify="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item className={classes.summaryContainerRowItemHeaderTitle} style={{ height: 12 }} />
            </Grid>
            <Grid
              className={classes.summaryContainerRowItemHeaderContainer}
              container
              justify="center"
            />
            <Grid
              container
              justify="center"
              style={{
                borderBottom: '1px solid #BDE1E2',
                margin: '0px 20px 10px',
                paddingBottom: 10,
              }}
            >
              <Grid item>
                <Typography className={classes.summaryContainerRowItemTitle}>
                  INGRESOS TOTALES
                </Typography>
                <Typography className={classes.summaryContainerRowItemValue} style={{ paddingBottom: 10, textAlign: 'center' }}>
                  {numeral(loanAmountWithFeeAndAdministrativeExpenses).format('$0,0')}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              style={{
                borderBottom: '1px solid #BDE1E2',
                margin: '0px 20px 34px',
                paddingBottom: 10,
              }}
            >
              <Grid item>
                <Typography
                  style={{
                    color: '#657E91',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  LO QUE TE GANAS AL AYUDAR
                </Typography>
                <Typography
                  style={{
                    color: '#000',
                    fontSize: '28px',
                    fontWeight: '700',
                    textAlign: 'center',
                  }}
                >
                  {numeral(getlTotalInterestedEarned).format('$0,0')}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {isGodson && (
          <>
            <Grid
              className={classes.summaryContainerRowItemHeaderContainer}
              container
              justify="center"
            >
              <Grid item>
                <Typography className={classes.summaryContainerRowItemHeaderTitle}>
                  PAGOS
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              style={{
                borderBottom: '1px solid #BDE1E2',
                marginBottom: 9,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <Grid
                className={classes.summaryContainerRowItem}
                item
                style={{ padding: '10px 17px 10px' }}
                xs={6}
              >
                <Typography className={classes.summaryContainerRowItemTitle}>
                  INTERESES
                </Typography>
                <Typography className={classes.summaryContainerRowItemValue}>
                  {numeral(getlTotalInterestedEarned).format('$0,0')}
                </Typography>
              </Grid>
              <Grid
                className={classes.summaryContainerRowItem}
                item
                style={{ padding: '10px 14px 10px 11px' }}
                xs={6}
              >
                <Typography className={classes.summaryContainerRowItemTitle}>
                  COSTO DEL SERVICIO
                </Typography>
                <Typography className={classes.summaryContainerRowItemValue}>
                  {numeral(creationFeeService).format('$0,0')}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.summaryContainerRow} container direction="row">
              <Grid
                className={classes.summaryContainerRowItem}
                item
                style={{ paddingTop: 0 }}
                xs={12}
              >
                <Typography
                  style={{
                    color: '#657E91',
                    fontSize: 12,
                    fontWeight: 400
                  }}
                >
                  TOTAL A PAGAR
                </Typography>
                <Typography
                  style={{
                    color: '#000000',
                    fontSize: 28,
                    fontWeight: 700
                  }}
                >
                  {numeral(isCalculatorOpenFromStoryCreation
                    ? lLoanAmountWithFeeAndAdministrativeExpenses
                    : loanAmountWithFeeAndAdministrativeExpenses).format('$0,0')}
                </Typography>
                <Typography
                  style={{
                    color: '#8B8B8B',
                    fontSize: 8,
                  }}
                >
                  ESTO INCLUYE: APOYO A LA HISTORIA, INTERESES, COSTOS DEL SERVICIO E IVA
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          container
          direction="row"
          justify="center"
          style={{
            backgroundColor: '#BDE1E2',
            height: 60,
            textAlign: 'center',
          }}
        >
          {isEditMode
            ? (
              <Grid item xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={handleOnEditProposal}
                  style={{
                    backgroundColor: '#33647E',
                    color: '#fff',
                    height: 25,
                    marginTop: 20,
                    width: 80,
                  }}
                  type="submit"
                  variant="contained"
                >
                  ENVIAR
                </Button>
              </Grid>
            )
            : getActionButtons()}
        </Grid>
      </Grid>
      <GmLoanCalculatorFeeDisclaimer
        isGodfather={isGodfather}
        isGodson={isGodson}
        visible={!showAsSummary}
      />
    </div>
  );
};

export default GmNewCalculator;
