import { makeStyles } from '@material-ui/core/styles';

import Image from '../../static/images/referral.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#ffbe38',
    backgroundPosition: 'center',
    display: 'grid',
    height: '45vh',
    gridTemplateRows: 'auto 1fr auto',
  },
  ArrowBackIconCustom: {
    position: 'absolute',
    marginTop: 37,
    marginLeft: 22,
    fontSize: 28,
    color: '#000',
  },
  margin: {
    margin: theme.spacing(1),
  },
  smallmargin: {
    margin: theme.spacing(1),
    width: 70,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '90%',
  },
  errorText: {
    color: '#ff382e',
    fontSize: 11,
    display: 'block',
  },
  center: {
    marginBottom: 30,
    marginTop: 27,
    textAlign: 'center',
  },
  textInfo: {
    margin: '30px 22px -5px',
  },
  viewContainer: {
    marginBottom: 55,
  },
  contenDoc: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 10px',
  },
  startButton: {
    position: 'relative',
    marginTop: 15,
    '& .MuiButton-label': {
      minWidth: 100,
      color: 'white',
      fontWeight: 500,
    },
  },
  cancelButton: {
    fontFamily: 'Roboto',
    marginRight: 25,
    '& .MuiButton-label': {
      color: 'rgba(0, 0, 0, 0.38)',
      fontWeight: 500,
      '&:hover': {
        color: 'rgb(20, 141, 147)',
      },
    },
  },
  form: {
    input: {
      padding: 11.5,
    },
  },
  inpuLabelStyle: {
    background: '#fff',
    left: 12,
    position: 'absolute',
    top: '-6px',
    width: 37,
    zIndex: 1,
  },
  smallFormControlStyle: {
    margin: '10px 0px 0px 10px',
    paddingBottom: 20,
    paddingRight: 20,
    width: '30%',
  },
  confirmationMessageBox: {
    border: '15px solid #2DCCD3',
    padding: theme.spacing(2),
  },
  confirmationMessageTitle: {
    fontFamily: 'Bebas Neue',
    fontSize: 112,
    fontWeight: 'bold',
    lineHeight: 0.9,
    color: '#2DCCD3'
  },
  confirmationMessageText: {
    fontFamily: 'Source Sans Pro',
    fontSize: 14,
    maxWidth: '85%',
    marginLeft: 25
  },
  confirmationMessageIcon: {
    marginTop: 20,
    marginBottom: 25
  },
  confirmationMessageHeader: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    boxShadow: 'none',
  },
  labelZIndex: {
    zIndex: 1
  }
}));

export { useStyles };
