// vendors
import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';

// components
import GmSuperModal from '../super-modal';

// styles
import 'react-image-crop/dist/ReactCrop.css';
import { useStyles } from './styles';

const GmImageCrop = ({
  aspectRatio = '1:1',
  isImgTooBig = false,
  onCancelCrop = Function.prototype,
  onConfirmCrop = Function.prototype,
  src,
  visible = false
}) => {
  const classes = useStyles();
  const [imageRef, setImageRef] = useState(null);
  const [crop, setCrop] = useState(null);

  const onLoad = (img) => {
    const ratioArray = aspectRatio.split(':');
    const aspect = parseInt(ratioArray[0], 10) / parseInt(ratioArray[1], 10);
    const width = img.width / aspect < img.height * aspect ? 100 : ((img.height * aspect) / img.width) * 100;
    const height = img.width / aspect > img.height * aspect ? 100 : (img.width / aspect / img.height) * 100;
    const y = (100 - height) / 2;
    const x = (100 - width) / 2;

    setCrop({
      aspect,
      height,
      unit: '%',
      width,
      x,
      y
    });
    setImageRef(img);

    return false;
  };

  const onConfirm = () => {
    if (crop && imageRef) {
      onConfirmCrop(crop, imageRef);
    }
  };

  return (
    <GmSuperModal
      confirmLabel="Recortar"
      fullSize={false}
      headerImage={null}
      headerTitle="Recortar imagen"
      isConfirmButtonEnabled={!isImgTooBig}
      isContentMedia
      onClose={onCancelCrop}
      onConfirm={onConfirm}
      visible={visible}
    >
      {isImgTooBig ? (
        <div className={classes.sizeMessage}>No puedes subir imagenes mayores a 10 MB :(</div>
      ) : (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onImageLoaded={onLoad}
          ruleOfThirds
          src={src}
        />
      )}
    </GmSuperModal>
  );
};

export default GmImageCrop;
