/* eslint-disable max-len */

import React from 'react';

const UnreadMessages = (
  <>
    <path d="M69.2924 5.67981H0V58.0836H69.2924V5.67981Z" fill="#D7384A" />
    <path d="M69.2924 5.67981H0L34.6459 31.0899L69.2924 5.67981Z" fill="#F6C500" />
    <path d="M66.8597 13.6521C70.8033 13.6521 74.0002 10.596 74.0002 6.82606C74.0002 3.05613 70.8033 0 66.8597 0C62.916 0 59.7191 3.05613 59.7191 6.82606C59.7191 10.596 62.916 13.6521 66.8597 13.6521Z" fill="#74C8D2" />
    <path d="M69.163 9.31931V10.287H65.1038V9.31931H66.54V5.53486C66.54 5.4524 66.5414 5.368 66.5454 5.28231C66.5481 5.19662 66.5535 5.109 66.5602 5.02009L65.6127 5.77324C65.5466 5.82092 65.4812 5.8512 65.4186 5.86409C65.3559 5.87633 65.2959 5.87761 65.2393 5.86602C65.182 5.85507 65.1328 5.83574 65.089 5.80932C65.0452 5.78226 65.0128 5.75456 64.9893 5.72557L64.5559 5.16763L66.7793 3.3656H67.9115V9.31931H69.163Z" fill="white" />
  </>
);

export default UnreadMessages;
