// vendors
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import moment from 'moment';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import 'moment/locale/es';

// components
import GmRouter from '../config/router';

// store
import superStore from '../redux/store/redux-store';

// styles
import theme from '../config/theme';

moment.updateLocale('es', {
  months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
});

const GmAppContainer = () => (
  <ThemeProvider theme={theme}>
   <PersistGate loading={null} persistor={superStore.persistorStore}>
    <CssBaseline />
    <Provider store={superStore.reduxStore}>
      <GmRouter />
    </Provider>
   </PersistGate>
  </ThemeProvider>
);

export default GmAppContainer;
