export const splitUserFullName = (userFullName) => {
  const output = {
    lastName: '',
    name: '',
    surName: '',
  };

  const splittedUserFullName = userFullName?.split(' ') || [...Object.keys(output).map((value) => output[value])];
  const [name, lastName, surName, lastSurName] = splittedUserFullName;

  if (splittedUserFullName.length === 1) {
    output.name = name || '';
  }

  if (splittedUserFullName.length <= 2) {
    output.lastName = lastName || '';
    output.name = name || '';
  }

  if (splittedUserFullName.length >= 3 && splittedUserFullName.length <= 4) {
    output.lastName = lastName || '';
    output.name = name;
    output.surName = `${surName || ''} ${lastSurName || ''}`.trim();
  }

  return output;
};

export const format = (str, ...keys) =>
  str.replace(/{(\d+)}/g, (match, number) =>
    typeof keys[number] !== 'undefined'
      ? keys[number]
      : match);
