import { makeStyles } from '@material-ui/core/styles';
import Image from '../../static/images/loans.png';

export const useStyles = makeStyles(() => ({
  latePaymentButton: {
    float: 'left',
  },
  otherLoansActionButtons: {
    display: 'flex',
  },
  root: {
    backgroundColor: '#ffbe38',
    backgroundImage: `url(${Image})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '45vh',
  },
  textHeader: {
    color: '#0F1E36',
    fontSize: 15,
    fontWeight: 700,
    paddingBottom: 90,
  },
  textKarma: {
    color: '#0F1E36',
    fontSize: 15,
    fontWeight: 700,
    marginTop: 30,
    marginRight: -60
  },
  latePaymentTitle: {
    paddingBottom: 30,
  },
  loanInfo: {
    textAlign: 'left',
    marginBottom: 15
  },
  transactionContainer: {
    textAlign: 'center',
  },
  imgTransactionContainer: {
    backgroundColor: '#AFE2E3',
    height: 170,
    width: 170,
    padding: '30px 0px 0px 0px',
    borderRadius: 4
  },
  imgReferralContainer: {
    backgroundColor: '#F4D3D6',
    height: 170,
    width: 170,
    padding: '30px 0px 0px 0px',
    borderRadius: 4
  },
  textFullName: {
    fontSize: 12,
    color: '#676767',
    marginTop: 5,
  },
  textLoan: {
    color: '#0F1E36',
    fontSize: 16,
    fontWeight: 700,
    marginBottom: -5,
  },
  transactionListContainer: {
    textAlign: 'center',
    marginTop: 15
  },
  detailPaymentButton: {
    padding: '0px 10px 0px 0px',
  },
  reportPaymentButton: {
    padding: 0,
  },
  gmBlueLogo: {
    marginTop: 25,
  },
  gmKarmaReview: {
    display: 'grid',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: 13,
    bottom: 10
  },
  calendarIcon: {
    marginTop: 25,
  },
  sideMenu: {
    position: 'absolute',
    top: 20,
    right: 20
  }
}));
