export const TRANSACTION_TYPES = {
  godfatherTransfer: 0,
  godsonPaymentOnTime: 1,
  godsonPaymentOutOfTime: 2,
};

export const WOMPI_TRANSACTION_STATUS = {
  approved: 'APPROVED',
  declined: 'DECLINED',
  error: 'ERROR',
  voided: 'VOIDED'
};
