import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// styles
const useStyles = makeStyles({
  gmCaptionTextContainer: (props) => ({
    width: '100%',
    textAlign: `${props.textAlign || 'center'}`,
    marginBottom: 15,
  }),
  gmCaptionText: {
    color: '#1A2E3C',
    fontWeight: 700,
    fontSize: 15,
  },
});

/**
 *
 * @param {JSX.Element} props.children
 * @param {string} props.textAlign
 * @returns {JSX.Element}
 */
const GmCaptionText = ({
  children,
  textAlign,
}) => {
  const classes = useStyles({ textAlign });
  return (
    <div className={classes.gmCaptionTextContainer}>
      <strong className={classes.gmCaptionText}>{children}</strong>
    </div>
  );
};

export default GmCaptionText;
