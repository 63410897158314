// vendors
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useFormik } from 'formik';

// components
import GmAutoComplete from '../../../components/autocomplete';
import GmLogo from '../../../components/logo';
import GmAboutMe1 from '../../../components/forms/about-me-1';

// styles
import { useStyles } from '../styles';

const validationSchema = yup.object({
  address: yup.string('Seleccione una direccion').required('La direccion es requerida'),
  city: yup.string('Seleccione una ciudad').required('La ciudad es requerida'),
  country: yup.string('Selecciona un pais').required('El pais es requerido'),
  state: yup.string('Seleccione un departamento').required('El departamento es requerido'),
});

export const Step2 = ({
  answers,
  cities,
  countries,
  getLocationData,
  handleBack,
  handleData,
  states,
}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      address: '',
      city: '',
      cityId: '',
      country: '',
      countryId: '',
      state: '',
      stateId: '',
      ...answers,
    },
    validationSchema,
    onSubmit: (values) => {
      handleData({
        data: values,
        // TODO: isAuthValidation value as false until AUCO identity validation is turned on
        isAuthValidation: false,
        isLastStep: true
      });
    },
  });

  const onCountryChange = (values) => {
    if (values) {
      formik.setFieldValue('country', values.name);
      formik.setFieldValue('countryId', values.id);
      formik.setFieldValue('state', '');
      formik.setFieldValue('stateId', '');
      formik.setFieldValue('city', '');
      formik.setFieldValue('cityId', '');
      getLocationData('state', values.id);
    }
  };

  const onStatesChange = (values) => {
    if (values) {
      formik.setFieldValue('state', values.name);
      formik.setFieldValue('stateId', values.id);
      formik.setFieldValue('city', '');
      formik.setFieldValue('cityId', '');
      getLocationData('city', values.id);
    }
  };

  const onCityChange = (values) => {
    if (values) {
      formik.setFieldValue('city', values.name);
      formik.setFieldValue('cityId', values.id);
    }
  };

  return (
    <>
      <Paper className={classes.root2}>
        <Container>
          <Grid container justify="center">
            <div style={{ paddingTop: 20 }}>
              <GmLogo />
            </div>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                style={{ color: '#ffffff', width: '100%' }}
                variant="body1"
              >
                Te queremos conocer mejor, cuéntanos más de ti
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Grid
        container
        direction="column"
        justify="center"
        style={{ padding: '0px 20px' }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid item xs={12}>
            <GmAutoComplete
              label="País de residencia"
              onOptionChange={onCountryChange}
              options={countries}
              style={{ paddingBottom: 20, paddingTop: 20 }}
              value={answers.country}
            />
          </Grid>
          <Grid item xs={12}>
            <GmAutoComplete
              label="Departamento"
              onOptionChange={onStatesChange}
              options={states}
              style={{ paddingBottom: 20 }}
              value={answers.state}
            />
          </Grid>
          <Grid item xs={12}>
            <GmAutoComplete
              label="Ciudad"
              onOptionChange={onCityChange}
              options={cities}
              style={{ paddingBottom: 20 }}
              value={answers.city}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={clsx(classes.margin, classes.textField, classes.addressCustom)}
              error={formik.touched.address && Boolean(formik.errors.address)}
              fullWidth
              id="address"
              label={!formik.values.address ? 'Dirección' : null}
              name="address"
              onChange={formik.handleChange}
              style={{ paddingBottom: 20 }}
              value={formik.values.address}
              variant="outlined"
            />
          </Grid>
          <GmAboutMe1 formik={formik} />
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item style={{ padding: '0px 20px 20px 0px' }} xs={6}>
                <Button
                  fullWidth
                  onClick={handleBack}
                  variant="contained"
                >
                  ATRÁS
                </Button>
              </Grid>
              <Grid item style={{ padding: '0px 0px 20px 0px' }} xs={6}>
                <Button
                  color="primary"
                  fullWidth
                  style={{ color: '#ffffff' }}
                  type="submit"
                  variant="contained"
                >
                  SIGUIENTE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default Step2;
