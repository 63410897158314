import userApi from '../../config/api/user';
import * as actionsTypes from '../action-types';

// constants
export const IS_RESET_CODE_VALID_BEGIN = 'IS_RESET_CODE_VALID_BEGIN';
export const IS_RESET_CODE_VALID_SUCCESSS = 'IS_RESET_CODE_VALID_SUCCESSS';
export const IS_RESET_CODE_VALID_ERROR = 'IS_RESET_CODE_VALID_ERROR';
export const CLEAR_FORGOT_PASSWORD_OK = 'CLEAR_FORGOT_PASSWORD_OK';

const authBegin = () => ({ type: actionsTypes.AUTH_BEGIN });
const checkCodeBegin = () => ({ type: actionsTypes.CHECK_CODE_BEGIN });
const onForgotPasswordBegin = () => ({ type: actionsTypes.ON_FORGOT_PASSWORD_BEGIN });
const onRecoverPasswordBegin = () => ({ type: actionsTypes.ON_RECOVER_PASSWORD_BEGIN });

export const authLogout = () => ({ type: actionsTypes.AUTH_LOGOUT });
export const clearFogotPasswordOk = () => ({ type: CLEAR_FORGOT_PASSWORD_OK });

export const onLogin = ({
  password,
  rememberClient,
  userNameOrEmailAddress,
}) => (dispatch) => {
  dispatch(authBegin());
  return new Promise((resolve, reject) => {
    userApi
      .login({
        password,
        rememberClient,
        userNameOrEmailAddress,
      })
      .then(({ data }) => {
        resolve(data);
        dispatch({
          type: actionsTypes.AUTH_SUCCESS,
          payload: data.result,
        });
      })
      .catch((error) => {
        reject();
        dispatch({ type: actionsTypes.AUTH_ERROR, error });
      });
  });
};

export const onForgotPassword = (emailAddress) => (dispatch) => {
  dispatch(onForgotPasswordBegin());
  return new Promise((resolve) => {
    userApi
      .forgotPassword(emailAddress)
      .then(({ data }) => {
        dispatch({
          type: actionsTypes.ON_FORGOT_PASSWORD_SUCCESS,
          payload: {
            success: data.success,
          },
        });
        resolve();
      })
      .catch((error) => dispatch({ type: actionsTypes.ON_FORGOT_PASSWORD_ERROR, error }));
  });
};

export const onRecoverPassword = (data) => (dispatch) => {
  dispatch(onRecoverPasswordBegin());
  return userApi
    .recoverPassword(data)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.ON_RECOVER_PASSWORD_SUCCESS,
        payload: {
          success: data.success,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.ON_RECOVER_PASSWORD_ERROR, error }));
};

export const checkCode = (codeData) => (dispatch) => {
  dispatch(checkCodeBegin());
  return userApi
    .checkCode(codeData)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.CHECK_CODE_SUCCESS,
        payload: {
          isInvitationCodeOk: data.result,
          emailAddress: codeData.emailAddress,
          invitationCode: codeData.invitationCode,
          password: codeData.password,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.CHECK_CODE_ERROR, error }));
};

export const getUnreadMessages = () => (dispatch) =>
  userApi.getUnreadMessages()
    .then(({ data }) =>
      dispatch({ type: actionsTypes.GET_UNREAD_MESSAGES, payload: data.result }));

export const startReferringFriend = ({ startReferringUserId }) =>
  userApi.startReferringFriend({ startReferringUserId }).then(() => {});

export const stopReferring = ({ stopReferringUserId }) =>
  userApi.stopReferring({ stopReferringUserId }).then(() => {});

export const checkIsResetCodeValid = ({ resetCode, userId }) => (dispatch) => {
  dispatch({ type: IS_RESET_CODE_VALID_BEGIN });
  return new Promise((resolve) => {
    userApi.isResetCodeValid({ resetCode, userId }).then((res) => {
      resolve(res);
      dispatch({ type: IS_RESET_CODE_VALID_SUCCESSS });
    }).catch(() => dispatch({ type: IS_RESET_CODE_VALID_ERROR }));
  });
};
