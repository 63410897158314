export const routes = {
  accountVerify: '/account-verify',
  calendarPayment: '/calendar-payment',
  chat: '/chat-list',
  chatHistory: '/chat/:id',
  chatReferralHistory: '/chat-referral/:id',
  componentPlayground: '/component-playground',
  createHistory: '/create-history/:id?',
  forgotPassword: '/forgot-password',
  histories: '/histories',
  history: '/history/:id',
  home: '/',
  karma: '/karma',
  loans: '/loans',
  login: '/login',
  main: '/main/:userId?',
  newChatHistory: '/new-chat/:id/:historyId',
  newChatReferralHistory: '/new-chat-referral/:id',
  notifications: '/notifications',
  onboarding: '/onboarding',
  paymentTransaction: '/payment-transaction',
  paymentsDetail: '/payments-detail/:transactionId',
  paymentsReport: '/payments-report/:transactionId',
  pqrs: '/pqrs',
  profile: '/profile',
  recoverPassword: '/recover-password',
  referralCode: '/referral-code/:code?',
  referredUser: '/referred-user',
  smsConfirm: '/sms-confirm',
  termsAndConditions: '/terms-and-conditions',
  transactionResult: '/transaction-result/:transactionRecordId',
  wizardForm: '/register',
};
