// http
import calculatorApi from '../../config/api/calculator';

// constants
export const GET_CALCULATE_LOAN_VALUES_BEGIN = 'GET_CALCULATE_LOAN_VALUES_BEGIN';
export const GET_CALCULATE_LOAN_VALUES_ERROR = 'GET_CALCULATE_LOAN_VALUES_ERROR';
export const GET_CALCULATE_LOAN_VALUES_SUCCESS = 'GET_CALCULATE_LOAN_VALUES_SUCCESS';
export const GET_TOTAL_INTEREST_EARNED_BEGIN = 'GET_TOTAL_INTEREST_EARNED_BEGIN';
export const GET_TOTAL_INTEREST_EARNED_ERROR = 'GET_TOTAL_INTEREST_EARNED_ERROR';
export const GET_TOTAL_INTEREST_EARNED_SUCCESS = 'GET_TOTAL_INTEREST_EARNED_SUCCESS';

export const getCalculateLoanValues = ({
  frequency,
  interestRate,
  itsFromFeeValueOption,
  loanAmount,
  loanTime,
}) => (dispatch) => {
  dispatch({ type: GET_CALCULATE_LOAN_VALUES_BEGIN });
  return new Promise((resolve, reject) => calculatorApi
    .calculateLoanValues({
      frequency,
      interestRate,
      itsFromFeeValueOption,
      loanAmount,
      loanTime,
    })
    .then(({ data: { result } }) => {
      resolve(result);
      dispatch({ type: GET_CALCULATE_LOAN_VALUES_SUCCESS });
    })
    .catch((error) => {
      reject(error);
      dispatch({ type: GET_CALCULATE_LOAN_VALUES_ERROR });
    }));
};

export const getTotalInterestEarned = ({
  frequency,
  interestRate,
  itsFromFeeValueOption,
  loanAmount,
  loanTime,
  quotaValue,
}) => (dispatch) => {
  dispatch({ type: GET_TOTAL_INTEREST_EARNED_BEGIN });
  return new Promise((resolve, reject) => calculatorApi
    .getTotalInterestEarned({
      frequency,
      interestRate,
      itsFromFeeValueOption,
      loanAmount,
      loanTime,
      quotaValue,
    })
    .then(({ data: { result } }) => {
      resolve(result);
      dispatch({ type: GET_TOTAL_INTEREST_EARNED_SUCCESS });
    })
    .catch((error) => {
      reject(error);
      dispatch({ type: GET_TOTAL_INTEREST_EARNED_ERROR });
    }));
};
