import React from 'react';
import { useHistory } from 'react-router-dom';

// Material ui
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

import NotFoundImg from '../../static/images/404.svg';

import { useStyles } from './styles';

function NotFound() {
  const classes = useStyles();
  const history = useHistory();

  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <>
      <Paper className={classes.root}>
        <CloseIcon className={classes.closeIconCustom} onClick={() => routeChange('/')} />
        <Container>
          <Grid container justify="center" className={classes.center}>
            <Grid item xs={12}>
              <h1 className={classes.supportTitle}>
                <strong>Error 404</strong>
                No encontramos lo que buscabas.
              </h1>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <img className={classes.notFoundImg} alt="404" src={NotFoundImg} />
    </>
  );
}

export default NotFound;
