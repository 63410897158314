// vendors
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { getChats } from '../../redux/actions/createHistory';

// components
import GmPeopleList from '../../components/people-list';

function GmChatList() {
  const dispatch = useDispatch();
  const { history } = useSelector((state) => state);
  const [searchKeywords, onSetSearchKeywords] = useState(null);

  const handleOnSearchOnThreads = ({ target }) => onSetSearchKeywords(target.value);

  useEffect(() => {
    dispatch(getChats({}));
  }, []);

  useEffect(() => {
    if (!searchKeywords) {
      dispatch(getChats({}));
    }

    if (searchKeywords && searchKeywords.length > 4) {
      dispatch(getChats({ keywords: searchKeywords }));
    }
  }, [searchKeywords]);

  return (
    <Grid
      container
      direction="column"
      justify="center"
    >
      <Grid item style={{ padding: '20px 20px 10px' }} xs={12}>
        <TextField
          fullWidth
          onChange={handleOnSearchOnThreads}
          placeholder="Buscar"
          value={searchKeywords}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <GmPeopleList
          contacts={history.chatsContacts.items}
          unreadMessages={history.unreadMessages}
        />
      </Grid>
    </Grid>
  );
}

export default GmChatList;
