// constants
import env from '../env';

// http
import request from '../request';

const settingsApi = {
  getSettings() {
    return request(
      `${env.mainBaseUrl}/services/app/Configuration/GetUiSettings`,
      'get',
    );
  },
  getAdministrativeExpensesSettings() {
    return request(
      `${env.mainBaseUrl}/services/app/Configuration/GetAdministrativeExpensesSettings`,
      'get'
    );
  },
  getSumaryAdministrativeExpenses() {
    return request(
      `${env.mainBaseUrl}/services/app/Configuration/GetSumaryAdministrativeExpenses`,
      'get'
    );
  }
};

export default settingsApi;
