// vendors
import { createMuiTheme } from '@material-ui/core/styles';

// fonts
import '@fontsource/source-sans-pro';

import overrides from './overrides';
import palette from './palette';

const theme = createMuiTheme({
  palette,
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Source Sans Pro", "Helvetica", "Arial", sans-serif',
  },
  overrides: {
    ...overrides,
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '0px 20px 20px 20px',
      }
    },
    MuiCollapse: {
      entered: {
        overflow: 'hidden',
      }
    },
    MuiStepLabel: {
      alternativeLabel: {
        fontSize: '0.5rem !important'
      }
    },
  },
});

// TODO: We should avoid this kind of overrides and object mutation, the createMuiTheme allows us to do this in a better and proper way.
theme.typography.h1 = {
  fontSize: '1.5rem',
  fontWeight: 'normal',
  letterSpacing: 'normal',
};

theme.typography.h2 = {
  fontSize: '1.25rem',
  fontWeight: 500,
  letterSpacing: '0.15px',
  lineHeight: '1.2',
};

theme.typography.h4 = {
  fontSize: '1 rem',
  fontWeight: 400,
  color: 'red',
  letterSpacing: '0px',
  lineHeight: '1',
};

theme.typography.body2 = {
  letterSpacing: 'normal',
  fontSize: 14,
  textAlign: 'center',
  fontWeight: 'normal',
  color: 'rgba(0, 0, 0, 0.78)',
};

theme.typography.subtitle2 = {
  fontWeight: 700,
  fontSize: 20,
  textAlign: 'center',
};

theme.typography.subtitle1 = {
  fontWeight: 500,
  marginBottom: '0.5rem',
};

theme.typography.caption = {
  fontSize: '0.7rem',
};

theme.typography.lightCaption = {
  color: palette.lightText.main,
  fontSize: '0.563rem'
};

export default theme;
