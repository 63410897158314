import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './styles';

export const Step2 = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.grid2}>
      <Typography style={{ marginBottom: 30 }} className={classes.text1}>Puedes elegir ser:</Typography>
      <Typography className={classes.text2}>UN ÁNGEL</Typography>
      <Typography style={{ marginTop: -25 }} className={classes.text3}>y ganar dinero al</Typography>
      <Typography style={{ marginTop: -5 }} className={classes.text3}>desembalar a otros</Typography>
      <Typography style={{ marginTop: 50 }} className={classes.text5}>UN BUENA PAGA</Typography>
      <Typography style={{ marginTop: -25 }} className={classes.text6}>y obtener un crédito</Typography>
      <Typography style={{ marginTop: -5 }} className={classes.text6}>hecho a tu medida</Typography>
    </Grid>
  );
};

export default Step2;
