/* eslint-disable max-len */

// vendors
import React from 'react';

const LikeDisabled = (
  <>
    <path d="M49.6501 0.660015C37.3301 -1.78999 31.8901 9.32001 31.8901 9.32001C31.8901 9.32001 26.4501 -1.78999 14.1301 0.660015C-0.36989 3.54001 -3.53989 21.03 3.84011 31.11C11.2201 41.19 31.8901 57.4 31.8901 57.4C31.8901 57.4 52.5501 41.19 59.9401 31.11C67.3201 21.03 64.1501 3.54001 49.6501 0.660015Z" fill="#DDDCDC" />
  </>
);

export default LikeDisabled;
