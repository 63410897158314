// http
import env from '../env';
import request from '../request';

const bankApi = {
  addUserBankInformation: ({
    accountHolder,
    accountHolderDocumentNumber,
    accountNumber,
    accountType,
    bankId,
  } = {}) =>
    request(`${env.mainBaseUrl}/services/app/UserProfileService/AddUserBankInformation`, 'post', {
      accountHolder,
      accountHolderDocumentNumber,
      accountNumber,
      accountType,
      bankId,
    }),
  getAllBanks: () => request(`${env.mainBaseUrl}/services/app/Bank/GetAll?SkipCount=0&MaxResultCount=999`, 'get'),
  getUserBankInformation: () => request(`${env.mainBaseUrl}/services/app/UserProfileService/GetUserBankInformation`, 'get')
};

export default bankApi;
