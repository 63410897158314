import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './styles';

export const Step1 = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.grid1}>
        <Typography style={{ marginTop: 20 }} className={classes.text4}>Estás aquí porque</Typography>
        <Typography style={{ marginTop: -5 }} className={classes.text4}>alguien confió en ti</Typography>
        <Typography style={{ marginTop: -15 }} className={classes.yellowText}>ERES UN</Typography>
        <Typography style={{ marginTop: -48 }} className={classes.yellowText}>ELEGIDO</Typography>
        <Typography style={{ marginTop: -25, marginBottom: 15 }} className={classes.text4}>entre muchos</Typography>
    </Grid>
  );
};

export default Step1;
