/* eslint-disable max-len */

// vendors
import React from 'react';

const LikeEnabled = (
  <>
    <path d="M50.65 0.349953C38.32 -2.09005 32.89 9.01995 32.89 9.01995C32.89 9.01995 27.45 -2.09005 15.13 0.349953C0.620011 3.22995 -2.53999 20.72 4.84001 30.8C12.22 40.88 32.89 57.09 32.89 57.09C32.89 57.09 53.55 40.88 60.93 30.8C68.32 20.72 65.15 3.22995 50.65 0.349953Z" fill="#F93549" />
  </>
);

export default LikeEnabled;
