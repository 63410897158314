// vendors
import configureMockStore from 'redux-mock-store';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

// middlewares
import signalrMiddleware from './signalr-middleware';

// reducers
import rootReducer from '../rootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistKey = 'auth';
const persistConfig = {
  blacklist: [
    'common',
    'history',
    'payments',
    'register',
    'transaction',
  ],
  key: persistKey,
  storage: storageSession,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const plainMiddlewares = applyMiddleware(thunk, createLogger(), signalrMiddleware);
// eslint-disable-next-line no-unused-vars
const composedMiddlewares = composeEnhancers(plainMiddlewares);

function configureStore(initialState) {
  const reduxStore = process.env.REACT_APP_ENV === 'unitTest'
    ? configureMockStore([])(initialState)
    : createStore(
      persistedReducer,
      initialState,
      plainMiddlewares,
    );

  const persistorStore = persistStore(reduxStore);

  return { persistorStore, reduxStore };
}

const superStore = configureStore();

export default superStore;
