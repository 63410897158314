// http
import request from '../request';
import env from '../env';

const AUCO_API = {
  validate: ({ country, identification, type }) =>
    request(`${env.aucoApiBaseUrl}/v1/ext/validate`, 'post', { country, identification, type }, { headers: { authorization: env.AUCO_PUK_KEY } })
};

export default AUCO_API;
