// http
import settingsApi from '../../config/api/settings';

// constants
export const GET_ADMINISTRATIVE_EXPENSES_SETTINGS_BEGIN = 'GET_ADMINISTRATIVE_EXPENSES_SETTINGS_BEGIN';
export const GET_ADMINISTRATIVE_EXPENSES_SETTINGS_SUCCESS = 'GET_ADMINISTRATIVE_EXPENSES_SETTINGS_SUCCESS';
export const GET_ADMINISTRATIVE_EXPENSES_SETTINGS_ERROR = 'GET_ADMINISTRATIVE_EXPENSES_SETTINGS_ERROR';
export const GET_SUMMARY_ADMINISTRATIVE_EXPENSES_BEGIN = 'GET_SUMMARY_ADMINISTRATIVE_EXPENSES_BEGIN';
export const GET_SUMMARY_ADMINISTRATIVE_EXPENSES_SUCCESS = 'GET_SUMMARY_ADMINISTRATIVE_EXPENSES_SUCCESS';
export const GET_SUMMARY_ADMINISTRATIVE_EXPENSES_ERROR = 'GET_SUMMARY_ADMINISTRATIVE_EXPENSES_ERROR';

export const getSumaryAdministrativeExpensesBegin = () => ({ type: GET_SUMMARY_ADMINISTRATIVE_EXPENSES_BEGIN });
export const getAdministrativeExpensesSettingsBegin = () => ({ type: GET_ADMINISTRATIVE_EXPENSES_SETTINGS_BEGIN });

export const getSumaryAdministrativeExpenses = () => (dispatch) => new Promise((resolve) => {
  dispatch(getSumaryAdministrativeExpensesBegin());
  return settingsApi.getSumaryAdministrativeExpenses()
    .then(({ data: { result } }) => {
      dispatch({ type: GET_SUMMARY_ADMINISTRATIVE_EXPENSES_SUCCESS, payload: result });
      resolve(result);
    })
    .catch(() => dispatch({ type: GET_SUMMARY_ADMINISTRATIVE_EXPENSES_ERROR }));
});

export const getAdministrativeExpensesSettings = () => (dispatch) => new Promise((resolve) => {
  dispatch(getAdministrativeExpensesSettingsBegin());
  return settingsApi.getAdministrativeExpensesSettings()
    .then(({ data: { result } }) => {
      dispatch({ type: GET_ADMINISTRATIVE_EXPENSES_SETTINGS_SUCCESS, payload: result });
      resolve(result);
    })
    .catch(() => dispatch({ type: GET_ADMINISTRATIVE_EXPENSES_SETTINGS_ERROR }));
});

export const getAdministrativeExpensesSettingsChain = () => (dispatch) => Promise.all([
  dispatch(getSumaryAdministrativeExpenses()),
  dispatch(getAdministrativeExpensesSettings())
]);
