import { makeStyles } from '@material-ui/core/styles';

// styles
const useStyles = makeStyles({
  storyUploadPicturesContainer: {
    textAlign: 'left',
  },
  storyUploadPicturesPicture: {
    border: '2px solid #D9D9D9',
    borderRadius: '8px',
    display: 'grid',
    height: '106px',
    placeItems: 'center',
    width: '106px',
  },
  storyUploadPicturesItem: {
    marginBottom: '25px',
  },
  storyUploadPicturesDescription: {
    color: '#1A2E3C',
    fontSize: '15px',
    fontWeight: 400,
  },
  storyUploadPicturesTitle: {
    color: '#1A2E3C',
    fontSize: '15px',
    fontWeight: 700,
  },
  editIcon: {
    height: '1em',
    width: 'auto'
  }
});

export default useStyles;
