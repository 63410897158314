import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  carouselRoot: {
    display: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '& .MuiButton-contained': {
      display: 'flex',
      margin: '0 auto',
      marginTop: -34,
    },
    '& .makeStyles-yellowBoxText-170': {
      display: 'flex',
      width: '80%',
      fontFamily: 'Bebas Neue'
    }
  },
  root: (props) => ({
    backgroundImage: `url(${props.image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    zIndex: -1,
    fontFamily: 'Bebas Neue'
  }),
  grid3: {
    alignItems: 'center',
    display: 'flex',
    justify: 'center',
    flexDirection: 'column',
    top: '22%',
    position: 'fixed',
  },
  text1: {
    color: '#FFFFFF',
    fontSize: 18,
    align: 'left',
    fontFamily: 'Roboto Slab'
  },
  text2: {
    color: '#FFC600',
    fontSize: 66,
    fontFamily: 'Bebas Neue',
    fontWeight: 'bold'
  },
  blueBoxText: {
    backgroundColor: '#2DCCD3',
    padding: '0px 30px 0px 30px',
  },
  brandLogo: {
    display: 'flex',
    width: '22rem',
    height: '22rem',
    marginTop: -110
  },
  grid1: {
    alignItems: 'center',
    display: 'flex',
    justify: 'center',
    flexDirection: 'column',
    top: '31.2%',
    left: '7.5%',
    position: 'fixed',
    backgroundColor: '#F4364C',
    width: '85%',
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: '50%'
  },
  grid2: {
    alignItems: 'center',
    display: 'relative',
    justify: 'center',
    flexDirection: 'column',
    position: 'fixed',
    width: '100%',
    top: '25%'
  },
  text3: {
    color: '#FFC600',
    fontSize: 22,
    align: 'left',
    fontFamily: 'Roboto Slab',
  },
  text4: {
    color: '#FFFFFF',
    fontSize: 18,
    align: 'left',
    fontFamily: 'Roboto Slab'
  },
  yellowText: {
    color: '#FFC600',
    fontSize: 84,
    fontFamily: 'Bebas Neue',
    fontWeight: 'bold'
  },
  text5: {
    color: '#2DCCD3',
    fontSize: 66,
    fontFamily: 'Bebas Neue',
    fontWeight: 'bold'
  },
  text6: {
    color: '#2DCCD3',
    fontSize: 22,
    fontFamily: 'Roboto Slab'
  },
  nextButton: {
    position: 'relative',
    top: '81%',
    width: '40%',
    fontSize: 16
  },
  pyramid1: {
    display: 'flex',
    width: '11rem',
    marginTop: -5
  },
  text7: {
    color: '#FFFFFF',
    fontSize: 54,
    fontFamily: 'Bebas Neue',
    lineHeight: 0.95,
    justifyContent: 'center',
    paddingTop: 6,
    fontWeight: 'bold'
  },
  text8: {
    color: '#FFFFFF',
    fontSize: 22,
    align: 'left',
    fontFamily: 'Roboto Slab'
  }
});

export { useStyles };
