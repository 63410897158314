// vendors
import Button from '@material-ui/core/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';

// components
import GmSvgIcon from '../../static/svg';
import { routes } from '../../config/routes';

const FeeAndInsuranceButton = ({
  classes,
  disabled,
  eventMessage,
  historyState
}) => {
  const history = useHistory();
  const handleOnRedirectToReportPayment = () => {
    const pathname = routes.paymentTransaction;

    history.push({
      pathname,
      state: {
        amount: eventMessage.administrativeExpenseValue,
        amountToDisplay: eventMessage.administrativeExpenseValue,
        transactionId: eventMessage.TransactionId,
        userImage: historyState.chatsHistory.user.profilePicture,
        userName: historyState.chatsHistory.user.fullName,
        isInsuranceIncluded: eventMessage.IsInsuranceIncluded
      },
    });
  };

  return (
    <Button
      className={classes.signPromissoryNoteButton}
      color="primary"
      disabled={disabled}
      fullWidth
      onClick={handleOnRedirectToReportPayment}
      startIcon={<GmSvgIcon enabled icon="payment-report" />}
      variant="contained"
    >
      PAGAR COSTOS OPERATIVOS
    </Button>
  );
};

export default FeeAndInsuranceButton;
