// vendors
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// actions
import { getPaymentDetail, getPaymentDetailActions } from '../../redux/actions/payments';

// components
import GmMiniStoryImagesCarousel from '../../components/mini-story-images-carousel';
import PaymentDetailActions from './actions';
// import PaymentDetailActivity from './activity';
import PaymentDetailDocuments from './documents';
import PaymentDetailSummary from './summary';

// constants
import { userIsGodfather, userIsGodson } from '../../config/constants';

// routes
import { routes } from '../../config/routes';

// styles
import { useStyles } from './styles';

const PaymentsDetail = () => {
  const [allDocumentsVisible, setAllDocumentsVisible] = useState(false);
  const [currentTab, setTab] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { state } = useLocation();

  const { auth, common: { loading }, payments: { actions, detail } } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getPaymentDetail(params.transactionId));
    dispatch(getPaymentDetailActions({ skip: 0, take: 15, transactionId: params.transactionId }));
  }, [params.transactionId]);

  const handleOnSetTab = (_event, tabIndex) => setTab(tabIndex);
  const handleOnDocumentsHeaderClick = (visibility) => setAllDocumentsVisible(visibility);
  const handleOnGiveBackKarma = () =>
    history.push({
      pathname: routes.paymentsReport.replace(':transactionId', params.transactionId),
      state: {
        amount: detail?.nextPaymentValue,
        amountToDisplay: detail?.nextPaymentValue,
        hasPromissoryNote: detail?.hasPromissoryNote,
        transactionId: params.transactionId,
        userImage: detail?.userInfo?.profilePicture,
        userName: detail?.userInfo?.fullName,
      }
    });

  const handleOnGoBack = () => {
    if (state?.isFromPaymentsReport) {
      history.push(routes.loans);
    } else {
      history.goBack();
    }
  };

  const buildFirstTabContent = () => (
    <>
      {!allDocumentsVisible
        ? (
          <>
            <PaymentDetailSummary
              category={detail?.category}
              debtToDate={detail?.debtToDate || 0}
              isGodfather={userIsGodfather(auth.userInfo.role)}
              isGodson={userIsGodson(auth.userInfo.role)}
              loanAmount={state?.loanAmount}
              nextPaymentDate={detail?.nextPaymentDate}
              nextPaymentValue={detail?.nextPaymentValue || 0}
              onGiveBackKarma={handleOnGiveBackKarma}
              paidPercentage={parseInt(detail?.paidPercentage, 10) || 0}
              paidToDate={detail?.paidToDate}
              visible={!loading}
            />
            {!loading ? <Divider className={classes.divider} /> : null}
          </>
        )
        : null}
      <PaymentDetailDocuments
        allDocumentsVisible={allDocumentsVisible}
        documents={detail?.documents || []}
        onDocumentsHeaderClick={handleOnDocumentsHeaderClick}
        title="Documentos"
        visible={!loading}
      />
    </>
  );

  const renderTabContent = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return buildFirstTabContent();
      case 1:
        return (
          <Grid className={classes.tabContent}>
            <PaymentDetailActions actions={actions || []} />
          </Grid>
        );
      default:
        return null;
    }
  };

  if (allDocumentsVisible) {
    return renderTabContent(0);
  }

  return (
    <Container className={classes.paymentsDetailPage} disableGutters>
      <Grid container>
        <Grid item xs={4}>
          <ArrowBackIcon
            className={classes.arrowBackIcon}
            onClick={handleOnGoBack}
          />
        </Grid>
        <Grid item xs={5}>
          <Grid alignItems="center" className={classes.titleContainer} container justify="space-evenly">
            <Grid item>
              <Avatar
                alt="profile-picture"
                className={classes.headerAvatar}
                src={detail?.userInfo?.profilePicture}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.title}>
                {detail?.userInfo?.fullName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.historyImages} container>
        <GmMiniStoryImagesCarousel images={detail?.historyImages} />
      </Grid>
      <Grid className={classes.tabs} container direction="column">
        <Grid item>
          <Tabs
            className={classes.tabs}
            indicatorColor="primary"
            onChange={handleOnSetTab}
            textColor="primary"
            value={currentTab}
            variant="fullWidth"
          >
            <Tab label={<Typography className={classes.tabLabel}>Buen Karma sembrado</Typography>} />
            <Tab label={<Typography className={classes.tabLabel}>Acciones</Typography>} />
          </Tabs>
        </Grid>
        <Grid className={classes.tabContent} item>
          {renderTabContent(currentTab)}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentsDetail;
