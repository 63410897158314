// http
import profileVisitingApi from '../../config/api/profile-visiting';

// constants
import {
  GET_USER_PROFILE_BEGIN,
  GET_USER_PROFILE_ERROR,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_STORIES_BEGIN,
  GET_USER_STORIES_ERROR,
  GET_USER_STORIES_SUCCESS,
} from '../action-types';

const getUserProfileBegin = () => ({ type: GET_USER_PROFILE_BEGIN });
const getUserProfileError = (payload) => ({ type: GET_USER_PROFILE_ERROR, error: payload });
const getUserProfileSuccess = (payload) => ({ type: GET_USER_PROFILE_SUCCESS, payload });
const getUserStoriesBegin = () => ({ type: GET_USER_STORIES_BEGIN });
const getUserStoriesError = (payload) => ({ type: GET_USER_STORIES_ERROR, error: payload });
const getUserStoriesSuccess = (payload) => ({ type: GET_USER_STORIES_SUCCESS, payload });

export const getUserProfileById = ({ userId }) => (dispatch) => {
  dispatch(getUserProfileBegin());
  return profileVisitingApi
    .getUserProfileById({ userId })
    .then(({ data }) => dispatch(getUserProfileSuccess(data)))
    .catch((error) => dispatch(getUserProfileError(error)));
};

export const getUserStoriesByUserId = ({
  maxResultCount,
  skipCount,
  userId,
}) => (dispatch) => {
  dispatch(getUserStoriesBegin());
  return profileVisitingApi
    .getUserStoriesByUserId({
      maxResultCount,
      skipCount,
      userId,
    })
    .then(({ data }) => dispatch(getUserStoriesSuccess(data)))
    .catch((error) => dispatch(getUserStoriesError(error)));
};
