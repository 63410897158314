// services
import userApi from '../../config/api/user';
import registerApi from '../../config/api/register';

// constants
import * as actionsTypes from '../action-types';

const getCountryBegin = () => ({ type: actionsTypes.GET_COUNTRY_BEGIN });
const getStateBegin = () => ({ type: actionsTypes.GET_STATE_BEGIN });
const getUserInfoBegin = () => ({ type: actionsTypes.GET_USER_INFO_BEGIN });
const saveProfileimgBegin = () => ({ type: actionsTypes.REGISTER_PROFILE_IMG_BEGIN });
const setRoleBegin = () => ({ type: actionsTypes.SET_ROLE_BEGIN });
const saveDocumentImgBegin = () => ({ type: actionsTypes.SAVE_DOC_IMG_BEGIN });

export const getCountries = () => (dispatch) => {
  dispatch(getCountryBegin());
  return registerApi
    .getCountry()
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_COUNTRY_SUCCESS,
        payload: {
          countries: data.result.items,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.GET_COUNTRY_ERROR, error }));
};

export const getCountryPhoneCodes = () => (dispatch) => {
  dispatch(({ type: actionsTypes.GET_COUNTRY_PHONE_CODES_BEGIN }));
  return registerApi
    .getCountryPhoneCodes()
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_COUNTRY_PHONE_CODES_SUCCESSS,
        payload: data.result.items
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.GET_COUNTRY_PHONE_CODES_ERROR, error }));
};

export const getStates = (stateId) => (dispatch) => {
  dispatch(getStateBegin());
  return registerApi
    .getStates(stateId)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_STATE_SUCCESS,
        payload: {
          states: data.result.items,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.GET_STATE_ERROR, error }));
};

export const getCities = (cityId) => (dispatch) => {
  dispatch(getStateBegin());
  return registerApi
    .getCities(cityId)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_CITY_SUCCESS,
        payload: {
          cities: data.result.items,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.GET_STATE_ERROR, error }));
};

export const onRegister = ({ registerData, additionalData }) => (dispatch) => {
  dispatch(getStateBegin());
  return registerApi
    .register({ registerData, additionalData })
    .then(({ data }) => {
      userApi
        .login({
          userNameOrEmailAddress: additionalData.emailAddress,
          password: additionalData.password,
          rememberClient: true,
        })
        .then((loginData) => {
          dispatch({
            type: actionsTypes.AUTH_SUCCESS,
            payload: { ...loginData.data.result },
          });
          dispatch({
            type: actionsTypes.REGISTER_SUCCESS,
            payload: {
              canLogin: data.result.canLogin,
            },
          });
        });
    })
    .catch((error) => dispatch({ type: actionsTypes.REGISTER_ERROR, error }));
};

export const getUserInfo = () => (dispatch) => {
  dispatch(getUserInfoBegin());
  return userApi
    .getProfile()
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_USER_INFO_SUCCESS,
        payload: {
          userInfo: data.result,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.GET_USER_INFO_ERROR, error }));
};

export const onSendRole = (role) => (dispatch) => {
  dispatch(setRoleBegin());
  return registerApi
    .setRole(role)
    .then(({ data }) => {
      dispatch(getUserInfo());
      dispatch({
        type: actionsTypes.SET_ROLE_SUCCESS,
        payload: {
          role,
          setRoleOK: data.success,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.SET_ROLE_ERROR, error }));
};

export const saveProfileImg = (img) => (dispatch) => {
  dispatch(saveProfileimgBegin());

  return registerApi
    .saveProfileImg(img)
    .then(({ data }) => {
      const { result } = data;

      if (result?.wasUpdate) {
        dispatch(getUserInfoBegin());
        userApi
          .getProfile()
          .then(({ data }) => {
            dispatch({
              type: actionsTypes.GET_USER_INFO_SUCCESS,
              payload: {
                userInfo: data.result,
              },
            });
          })
          .catch((error) => dispatch({ type: actionsTypes.GET_USER_INFO_ERROR, error }));
      }
    })
    .catch((error) => dispatch({ type: actionsTypes.REGISTER_PROFILE_IMG_ERROR, error }));
};

export const saveDocumentImg = (img) => (dispatch) => {
  dispatch(saveDocumentImgBegin());

  return registerApi
    .saveDocumentImg(img)
    .then(({ data }) => {
      const { result } = data;

      if (result?.wasUpdate) {
        dispatch({ type: actionsTypes.SAVE_DOC_IMG_SUCCESS });
        dispatch(getUserInfo());
      }
    })
    .catch((error) => dispatch({ type: actionsTypes.SAVE_DOC_IMG_ERROR, error }));
};

export const saveOnlyProfileImg = (img) => (dispatch) => {
  dispatch(saveProfileimgBegin());

  return registerApi
    .saveProfileImg(img)
    .then(() => {
      dispatch({
        type: actionsTypes.SET_USER_SUCCESS,
        payload: {
          roleSucess: 'saveOnlyProfileImg',
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.REGISTER_PROFILE_IMG_ERROR, error }));
};

export const updateProfile = (userData, userInfo) => (dispatch) => {
  dispatch(saveProfileimgBegin());
  return registerApi
    .updateProfile(userData, userInfo)
    .then(() => {
      dispatch({
        type: actionsTypes.SET_USER_SUCCESS,
        payload: {
          roleSucess: 'updateProfile',
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.SET_USER_ERROR, error }));
};

export const changePasswordAction = (data) => (dispatch) => {
  dispatch(saveProfileimgBegin());
  return registerApi
    .changePasswordRequest(data)
    .then(() => {
      dispatch({
        type: actionsTypes.SET_USER_SUCCESS,
        payload: {
          roleSucess: 'resetPassword',
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.SET_USER_ERROR, error }));
};
