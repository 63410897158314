/* eslint-disable max-len */

// vendors
import React from 'react';

const EyesDisabled = (
  <>
    <path d="M14.0122 0H13.0659V2.9764H14.0122V0Z" fill="#CFD2D7" />
    <path d="M21.3767 1.46086L19.8389 4.02295L20.6535 4.49729L22.1913 1.9352L21.3767 1.46086Z" fill="#CFD2D7" />
    <path d="M5.69613 1.45709L4.88135 1.93115L6.41826 4.49384L7.23304 4.01977L5.69613 1.45709Z" fill="#CFD2D7" />
    <path d="M14.0122 22.0234H13.0659V24.9998H14.0122V22.0234Z" fill="#CFD2D7" />
    <path d="M6.41819 20.5058L4.88037 23.0679L5.69497 23.5422L7.23279 20.9801L6.41819 20.5058Z" fill="#CFD2D7" />
    <path d="M20.659 20.5068L19.8442 20.981L21.3814 23.5436L22.1962 23.0695L20.659 20.5068Z" fill="#CFD2D7" />
    <path d="M27 12.6668C27 12.6668 20.9563 19.3356 13.5 19.3356C6.04368 19.3356 0 12.6668 0 12.6668C0 12.6668 6.04368 5.99805 13.5 5.99805C20.9563 5.99805 27 12.6668 27 12.6668Z" fill="#7D7D7D" />
    <path d="M13.509 7.73584C16.2749 7.73584 18.5195 9.94675 18.5195 12.671C18.5195 15.3952 16.2794 17.6062 13.509 17.6062C10.7432 17.6062 8.49854 15.3952 8.49854 12.671C8.50311 9.94225 10.7432 7.73584 13.509 7.73584Z" fill="white" />
    <path d="M13.5092 11.0049C14.4418 11.0049 15.1961 11.7479 15.1961 12.6664C15.1961 13.585 14.4418 14.328 13.5092 14.328C12.5766 14.328 11.8223 13.585 11.8223 12.6664C11.8223 11.7479 12.5812 11.0049 13.5092 11.0049Z" fill="#CFD2D7" />
  </>
);

export default EyesDisabled;
