import { makeStyles } from '@material-ui/core/styles';

// styles
const useStyles = makeStyles({
  informativeTextContainer: (props) => ({
    backgroundColor: props?.backgroundColor || '#FFEDB5',
    borderRadius: '8px',
    fontSize: '15px',
    fontWeight: 400,
    height: 'auto',
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  })
});

export default useStyles;
