import React from 'react';
import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomTimeTableCellBase = ({ classes, startDate, currentMonth }) => {
  const isOtherMonth = startDate.getMonth() !== currentMonth;

  return (
    <TableCell
      className={classes.cell}
      style={{ backgroundColor: isOtherMonth ? '#f0f0f0' : '', color: isOtherMonth ? '#9c9c9c' : '' }}
    >
      {startDate.getDate()}
    </TableCell>
  );
};

const CustomTimeTableCell = withStyles({}, { name: 'CustomTimeTableCell' })(CustomTimeTableCellBase);

export default CustomTimeTableCell;
