// packages
import React from 'react';
import Carousel from 'react-elastic-carousel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

// components
import GmAlertMessage from '../../components/alert';

// styles
import { useStyles } from './styles';

const GmStoryCategoriesSelector = ({
  categories,
  categorySelected,
  error,
  onSelectCategory,
}) => {
  const classes = useStyles();

  const getBackgroundCategoryImgStyle = (currentCategory, img) => ({
    backgroundImage: categorySelected === currentCategory
      ? `linear-gradient(0deg, rgb(33 31 32 / 30%), rgb(64 61 63 / 30%)), url(${img})`
      : `url(${img})`,
    backgroundSize: 'cover'
  });

  const handleOnSelectCategory = (category) => {
    onSelectCategory(category);
  };

  return (
    <>
      <Carousel
        className={classes.customCarousel}
        initialActiveIndex={categorySelected}
        itemPadding={[0, 3]}
        itemsToScroll={3}
        itemsToShow={3}
        showArrows={false}
      >
        {categories.map(({ category, image, value }) => (
          <div
            key={`category-${category}`}
            role="button"
            name={`category-${category}`}
            onClick={() => handleOnSelectCategory(category)}
            className={classes.sliderCard}
            style={getBackgroundCategoryImgStyle(category, image)}
          >
            {categorySelected === category
              ? <CheckCircleOutlineIcon className={classes.checkCircle} />
              : null}
            <div className={categorySelected === category ? classes.titleCategory : classes.notSelectCategory}>
              <strong>{value}</strong>
            </div>
          </div>
        ))}
      </Carousel>
      <GmAlertMessage
        style={{
          marginBottom: 15,
          marginTop: 0,
          padding: '0px 20px',
          textAlign: 'left',
        }}
        visible={error}
        variant="filled"
      >
        <strong>{error}</strong>
      </GmAlertMessage>
    </>
  );
};

export default GmStoryCategoriesSelector;
