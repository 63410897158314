import React from 'react';

// material ui
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// router
import { useHistory } from 'react-router-dom';

// components
import { useStyles } from './styles';

export const HomePage = () => {
  const history = useHistory();
  const classes = useStyles();

  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <Grid
      alignContent="center"
      className={classes.root}
      container
      direction="column"
      justify="flex-end"
    >
      <Button
        classes={{ root: classes.moreInfoButton }}
        color="primary"
        onClick={() => window.location.assign('https://info.givemefive.app')}
        variant="contained"
      >
        IR A PÁGINA WEB
      </Button>
      <Button
        classes={{ root: classes.startButton }}
        color="primary"
        onClick={() => routeChange('/referral-code')}
        variant="contained"
      >
        Soy nuevo acá
      </Button>
      <Button
        classes={{ root: classes.loginButton }}
        onClick={() => routeChange('/login')}
        variant="contained"
      >
        Soy de los de acá
      </Button>
    </Grid>
  );
};
