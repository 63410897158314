// vendors
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';

// actions
import { onSendRole } from '../../redux/actions/register';

// components
import GmGiveSupport from './give-support';
import GmSeekSupport from './seek-support';
import YellowLogo from '../../components/yellow-logo';

// images
import infographic from '../../static/images/inpfographic.jpg';

// styles
import { useStyles } from './styles';

const GmSupportPage = ({
  handleNext,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isInfographicVisible, setInfographicVisibility] = useState(true);

  const handleOnSendRole = (role) => dispatch(onSendRole(role)).then(() => handleNext());

  if (isInfographicVisible) {
    return (
      <Grid container>
        <img
          alt="infographic"
          src={infographic}
          style={{
            height: '100%',
            paddingBottom: 30,
            width: '100%',
          }}
        />
        <Button
          className={classes.continueButton}
          onClick={() => setInfographicVisibility(false)}
          variant="contained"
        >
          Continuar
        </Button>
      </Grid>
    );
  }

  return (
    <>
      <Paper className={classes.root}>
        <Container>
          <Grid container justify="center">
            <div className={classes.brandLogo}>
              <YellowLogo />
            </div>
            <Grid item xs={12}>
              <h1 className={classes.supportTitle}>
                ¿Cuál es tu historia?
              </h1>
              <Typography className={classes.supportDesc} variant="body2">
                Todos tenemos algo que necesitamos y algo que podemos ofrecer
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Grid container direction="column" justify="center">
        <Grid className={classes.supportCard} item>
          <GmSeekSupport sendRole={handleOnSendRole} />
        </Grid>
        <Grid className={classes.supportCard} item>
          <GmGiveSupport sendRole={handleOnSendRole} />
        </Grid>
      </Grid>
    </>
  );
};

export default GmSupportPage;
