// vendors
import React from 'react';

const GoodKarma = (
<svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5028 0L0 12.7156L6.68306 33.2875H28.3169L35 12.7156L17.5028 0Z" fill="#F93549" />
<path d="M26.1708 16.4798H9.49646V26.8046H26.1708V16.4798Z" fill="#F4D3D6" />
<path d="M26.1708 16.4798H9.49646V19.3543H26.1708V16.4798Z" fill="#FF8189" />
<path d="M25.0663 9.81885L8.84546 13.684L9.51177 16.4803L25.7326 12.6152L25.0663 9.81885Z" fill="#FF8189" />
<path d="M17.9978 19.3598H16.4576L17.6753 16.4798H19.2098L17.9978 19.3598Z" fill="#F4D3D6" />
<path d="M14.5171 19.3598H12.9769L14.1946 16.4798H15.7347L14.5171 19.3598Z" fill="#F4D3D6" />
<path d="M11.0366 19.3598H9.49646L10.7141 16.4798H12.2542L11.0366 19.3598Z" fill="#F4D3D6" />
<path d="M21.4726 19.3598H19.9325L21.1501 16.4798H22.6903L21.4726 19.3598Z" fill="#F4D3D6" />
<path d="M24.9532 19.3598H23.4131L24.6307 16.4798H26.1708L24.9532 19.3598Z" fill="#F4D3D6" />
<path d="M17.7642 14.5116L16.2686 14.8674L16.7856 11.7872L18.2813 11.4313L17.7642 14.5116Z" fill="#F4D3D6" />
<path d="M14.3782 15.3177L12.8826 15.6736L13.3996 12.5934L14.8953 12.2375L14.3782 15.3177Z" fill="#F4D3D6" />
<path d="M10.9921 16.1239L9.49646 16.4798L10.0136 13.3996L11.5092 13.0437L10.9921 16.1239Z" fill="#F4D3D6" />
<path d="M21.1501 13.7053L19.6489 14.0612L20.166 10.981L21.6672 10.6251L21.1501 13.7053Z" fill="#F4D3D6" />
<path d="M24.5307 12.8991L23.035 13.255L23.5521 10.1748L25.0477 9.81892L24.5307 12.8991Z" fill="#F4D3D6" />
</svg>

);

export default GoodKarma;
