// vendors
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { useHistory } from 'react-router-dom';

// components
import HistoryCard from './card';

// styles
import { useStyles } from './styles';

const HistoryPage = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <div className={classes.header}>
        <ArrowBackIcon
          onClick={() => history.goBack()}
          className={classes.ArrowBackIconCustom}
        />
        <strong className={classes.titleHeader}>Historias</strong>
      </div>
      <HistoryCard isVisiting />
    </>
  );
};

export default HistoryPage;
