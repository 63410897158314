// vendors
import React from 'react';

const RedPentagon = (
  <>
    <path d="M9.99478 0L0 7.25127L3.82071 19H16.1688L19.9896 7.25127L9.99478 0Z" fill="#F93549" />
  </>

);

export default RedPentagon;
