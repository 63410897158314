// TODO: Migrate this file to story.js
import historyApi from '../../config/api/create-history';
import * as actionsTypes from '../action-types';

export const CLEAR_NEW_CHAT_THREAD_ID = 'CLEAR_NEW_CHAT_THREAD_ID';
export const ADD_NEW_CHAT_THREAD_ID = 'ADD_NEW_CHAT_THREAD_ID';

const getChatsBegin = () => ({ type: actionsTypes.GET_CHATS_BEGIN });
const getGiveLikeBegin = () => ({ type: actionsTypes.SET_LIKE_BEGIN });
const getHistoriesBegin = () => ({ type: actionsTypes.GET_HISTORY_BEGIN });
const getHistoriesByUserBegin = () => ({ type: actionsTypes.GET_MY_HISTORY_BEGIN });
const getHistoryProgressBegin = () => ({ type: actionsTypes.GET_HISTORY_PROGRESS_BEGIN });
const getInterestBegin = () => ({ type: actionsTypes.GET_INTEREST_BEGIN });
const getQuestionsBegin = () => ({ type: actionsTypes.GET_QUESTIONS_BEGIN });
const getQuestionsByIdBegin = () => ({ type: actionsTypes.GET_HISTORY_INFO_BEGIN });
const saveStoryPartialBegin = () => ({ type: actionsTypes.SAVE_STORY_PARTIAL_BEGIN });
const setProposalBegin = () => ({ type: actionsTypes.SET_PROPOSAL_BEGIN });
const deleteProposalBegin = () => ({ type: actionsTypes.DELETE_PROPOSAL_BEGIN });
const editProposalBegin = () => ({ type: actionsTypes.EDIT_PROPOSAL_BEGIN });
const editCreationProposalBegin = () => ({ type: actionsTypes.EDIT_CREATION_PROPOSAL_BEGIN });

export const clearHistories = () => ({ type: actionsTypes.CLEAR_HISTORIES });

export const clearMessages = () => (dispatch) => new Promise((resolve) => {
  dispatch(({
    type: actionsTypes.CLEAR_MESSAGES
  }));
  resolve();
});

export const getHistories = ({ keywords = null, maxResult = null, skipCount = 0 }) => (dispatch) => {
  dispatch(getHistoriesBegin());

  return historyApi
    .getAllStories({ keywords, maxResult, skipCount })
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_HISTORY_SUCCESS,
        payload: {
          histories: data.result.items,
          totalCount: data.result.totalCount,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.GET_HISTORY_ERROR, error }));
};

export const getHistoriesByUser = (authToken) => (dispatch) => {
  dispatch(getHistoriesByUserBegin());

  return historyApi
    .getMyHistories(authToken)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_MY_HISTORY_SUCCESS,
        payload: {
          canCreateNewHistory: data.result.canCreateNewHistory,
          myHistories: data.result.items,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.GET_MY_HISTORY_ERROR, error }));
};

export const getQuestions = (historyId) => (dispatch) => {
  dispatch(getQuestionsBegin());

  return historyApi
    .getQuestions(historyId)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_QUESTIONS_SUCCESS,
        payload: {
          questions: data.result,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.GET_QUESTIONS_ERROR, error }));
};

export const getChats = ({ keywords = null }) => (dispatch) => {
  dispatch(getChatsBegin());

  return historyApi
    .getChats({ keywords })
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_CHATS_SUCCESS,
        payload: {
          chats: data.result,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.GET_CHATS_ERROR, error }));
};

export const getChatHistory = (chatId, isReferral) => async (dispatch) => {
  dispatch(clearMessages());
  dispatch(getChatsBegin());
  const chatData = await historyApi.getChatHistory(chatId);
  const calculatorInfo = !isReferral
    ? await historyApi.getCalculatorInfoRequest(chatData.data.result.historyId)
    : {
      data: {
        result: {}
      }
    };

  dispatch({
    type: actionsTypes.GET_CHATS_HISTORY_SUCCESS,
    payload: {
      history: chatData.data.result,
      calculatorInfo: calculatorInfo.data.result,
    },
  });
};

export const setChatMessage = (chat) => (dispatch) => {
  dispatch({ type: actionsTypes.SET_MESSAGE_BEGIN });
  return historyApi
    .setMessage(chat)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.SET_MESSAGE_SUCCESS,
        payload: { history: data.result },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.SET_MESSAGE_ERROR, error }));
};

export const receiveMessage = (newIncomingMessage) => ({
  type: actionsTypes.RECEIVE_MESSAGE_SUCCESS,
  payload: newIncomingMessage,
});

export const getOrCreateChat = (chatId) => async (dispatch) => {
  try {
    dispatch(({ type: CLEAR_NEW_CHAT_THREAD_ID }));
    dispatch(clearMessages());
    dispatch(getChatsBegin());
    const { data: { result: chatHistory } } = await historyApi.getOrCreateThread(chatId);
    const { data: { result: calculatorInfo } } = await historyApi.getCalculatorInfoRequest(chatHistory.historyId);
    dispatch({
      type: ADD_NEW_CHAT_THREAD_ID,
      payload: chatHistory.threadId,
    });
    dispatch({
      type: actionsTypes.GET_CHATS_HISTORY_SUCCESS,
      payload: {
        history: chatHistory,
        calculatorInfo,
      },
    });
  } catch (error) {
    dispatch({ type: actionsTypes.GET_CHATS_ERROR, error });
  }
};

export const getOrCreateReferralsChat = (chatId) => async (dispatch) => {
  try {
    dispatch(clearMessages());
    dispatch(getChatsBegin());
    const chatData = await historyApi.getOrCreateReferralsThread(chatId);
    dispatch({
      type: actionsTypes.GET_CHATS_HISTORY_SUCCESS,
      payload: {
        history: chatData.data.result,
        calculatorInfo: null
      },
    });
  } catch (error) {
    dispatch({ type: actionsTypes.GET_CHATS_ERROR, error });
  }
};

export const getHistoryById = (id) => (dispatch) => {
  dispatch(getQuestionsByIdBegin());

  return historyApi
    .getHistoryById(id)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_HISTORY_INFO_SUCCESS,
        payload: {
          data: data.result,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.GET_HISTORY_INFO_ERROR, error }));
};

export const setLikeToStory = (historyId) => (dispatch) => {
  dispatch(getGiveLikeBegin());

  return new Promise((resolve) => {
    historyApi
      .setLikes(historyId)
      .then(({ data }) => {
        dispatch({
          type: actionsTypes.SET_LIKE_SUCCESS,
          payload: {
            gaveLike: data.result,
          },
        });
        resolve();
      })
      .catch((error) => dispatch({ type: actionsTypes.SET_LIKE_ERROR, error }));
  });
};

export const getCalculatorInfo = (historyId) => (dispatch) => {
  dispatch(getInterestBegin());

  return historyApi
    .getCalculatorInfoRequest(historyId)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.GET_INTEREST_SUCCESS,
        payload: {
          calculatorInfo: data.result,
        },
      });
    });
  // .catch((error) => dispatch({ type: actionsTypes.GET_PHRASE_ERROR, error }));
};

export const setProposal = (proposal, threadId) => (dispatch) => {
  dispatch(setProposalBegin());

  return historyApi
    .setProposal(proposal, threadId)
    .then(({ data }) => {
      dispatch({
        type: actionsTypes.SET_PROPOSAL_SUCCESS,
        payload: {
          calculatorInfo: data.result,
        },
      });
    });
  // .catch((error) => dispatch({ type: actionsTypes.GET_PHRASE_ERROR, error }));
};

export const editProposal = (proposalEditedData) => (dispatch) => {
  dispatch(editProposalBegin());

  return historyApi
    .editProposal(proposalEditedData)
    .then((response) => {
      dispatch({
        type: actionsTypes.EDIT_PROPOSAL_SUCCESS,
        payload: { ...response },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.DELETE_PROPOSAL_ERROR, error }));
};

export const editCreationProposal = (proposalEditedData) => (dispatch) => {
  dispatch(editCreationProposalBegin());

  return historyApi
    .editCreationProposal(proposalEditedData)
    .then((response) => {
      dispatch({
        type: actionsTypes.EDIT_CREATION_PROPOSAL_SUCCESS,
        payload: { ...response },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.EDIT_CREATION_PROPOSAL_ERROR, error }));
};

export const deleteProposal = (messageId, editedProposalId) => (dispatch) => {
  dispatch(deleteProposalBegin());

  return historyApi
    .deleteProposal(messageId, editedProposalId)
    .then(() => {
      dispatch({
        type: actionsTypes.DELETE_PROPOSAL_SUCCESS,
        payload: {
          editedProposalId,
          messageId,
        },
      });
    })
    .catch((error) => dispatch({ type: actionsTypes.DELETE_PROPOSAL_ERROR, error }));
};

export const getWhoHasReferredMe = ({ userId }) => (dispatch) => historyApi
  .getWhoHasReferredMe({ userId })
  .then(({ data }) => {
    dispatch({
      type: actionsTypes.GET_WHO_REFER_ME_SUCCESS,
      payload: {
        referMe: data.result,
      },
    });
  })
  .catch((error) => dispatch({ type: actionsTypes.GET_WHO_REFER_ME_ERROR, error }));

export const getWhoHasImReferred = ({ userId }) => (dispatch) => historyApi
  .getWhoHasImReferred({ userId })
  .then(({ data }) => {
    dispatch({
      type: actionsTypes.GET_WHO_HAS_IM_REFERRED_SUCCESS,
      payload: {
        whoHasImReferred: data.result,
      },
    });
  })
  .catch((error) => dispatch({ type: actionsTypes.GET_WHO_HAS_IM_REFERRED_ERROR, error }));

export const saveStoryPartial = (story) => (dispatch) => {
  dispatch(saveStoryPartialBegin());

  return new Promise((resolve, reject) => {
    historyApi
      .savePartial(story)
      .then(({ data }) => {
        dispatch({
          type: actionsTypes.SAVE_STORY_PARTIAL_SUCCESS,
          payload: {
            referMe: data.result
          }
        });
        resolve();
      })
      .catch((error) => {
        dispatch({ type: actionsTypes.SAVE_STORY_PARTIAL_ERROR, error });
        reject(error);
      });
  });
};

export const getHistoryProgress = ({ historyId, threadId }) => (dispatch) => {
  dispatch(getHistoryProgressBegin());

  return new Promise((resolve, reject) => {
    historyApi
      .getHistoryProgress(historyId, threadId)
      .then(({ data }) => {
        dispatch({
          type: actionsTypes.GET_HISTORY_PROGRESS_SUCCESS,
          payload: {
            historyProgress: data.result
          }
        });
        resolve();
      })
      .catch((error) => {
        dispatch({ type: actionsTypes.GET_HISTORY_PROGRESS_ERROR, error });
        reject(error);
      });
  });
};
