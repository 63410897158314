// TODO: Migrate this file to story.js
// constants
import env from '../env';

// http
import request from '../request';

const historyApi = {
  getAllStories({ keywords = null, maxResultCount = 10, skipCount = 0 }) {
    const otherUrlParams = keywords
      ? `&Keyword=${keywords}`
      : '';

    return request(`${env.mainBaseUrl}/services/app/History/GetAll?SkipCount=${skipCount}&MaxResultCount=${maxResultCount}&${otherUrlParams}`, 'get');
  },

  getMyHistories() {
    return request(`${env.mainBaseUrl}/services/app/History/MyHistories`, 'post', {
      maxResultCount: 20,
      skipCount: 0,
    });
  },

  getQuestions(historyId) {
    return request(
      `${env.mainBaseUrl}/services/app/History/GetQuestions?historyId=${historyId}`,
      'get'
    );
  },

  getHistoryById(id) {
    return request(`${env.mainBaseUrl}/services/app/History/Get?Id=${id}`, 'get');
  },

  getChats({ keywords = null }) {
    const otherUrlParams = keywords
      ? `&Keyword=${keywords}`
      : '';

    return request(`${env.mainBaseUrl}/services/app/Message/GetThreads?MaxResultCount=1000000${otherUrlParams}`, 'get');
  },

  getChatHistory(chatId) {
    return request(
      `${env.mainBaseUrl}/services/app/Message/GetThread?ThreadId=${chatId}&MaxResultCount=1000000`,
      'get'
    );
  },

  getOrCreateThread(chatId) {
    return request(
      `${env.mainBaseUrl}/services/app/Message/GetOrCreateThread?HistoryId=${chatId}&MaxResultCount=100`,
      'get'
    );
  },

  getOrCreateReferralsThread(chatId) {
    return request(
      `${env.mainBaseUrl}/services/app/Message/GetOrCreateReferralsThread?ReferralId=${chatId}&MaxResultCount=100`,
      'get'
    );
  },

  getCalculatorInfoRequest(HistoryId) {
    return request(
      `${env.mainBaseUrl}/services/app/Transaction/GetInterestRates?HistoryId=${HistoryId}`,
      'get'
    );
  },

  setMessage(chat) {
    return request(`${env.mainBaseUrl}/services/app/Message/Create`, 'post', chat);
  },

  setLikes(historyId) {
    return request(
      `${env.mainBaseUrl}/services/app/History/Like?historyId=${historyId}`,
      'post',
    );
  },

  getWhoHasReferredMe({ userId }) {
    return request(`${env.mainBaseUrl}/services/app/UserProfileService/WhoHasReferredMe?userId=${userId}`, 'post');
  },

  getWhoHasImReferred({ userId }) {
    return request(`${env.mainBaseUrl}/services/app/UserProfileService/WhoHasImReferred?userId=${userId}`, 'post');
  },

  setProposal(proposal, threadId) {
    const data = {
      // ------------------------------------------------------------------------------------------------------------------
      // TODO: IMPORTANT!
      // If godfather modifies quota value this must be FALSE
      // If godfather does not modify quota value this must be TRUE
      // but currently the above expected behavior isn't working that way and this is a MAJOR bug
      // the current behavior here is totally the opposite, API switch the condition in the EP
      // for the API calcs to work properly, this MUST be changed in the near future.
      // ------------------------------------------------------------------------------------------------------------------
      itsFromFeeValueOption: proposal.itsFromFeeValueOption,
      messageThreadId: threadId,
      ...proposal,
    };
    return request(`${env.mainBaseUrl}/services/app/ProposalMessage/CreateProposal`, 'post', data);
  },

  editProposal(proposalEditedData) {
    return request(`${env.mainBaseUrl}/services/app/ProposalMessage/Edit`, 'post', proposalEditedData);
  },

  editCreationProposal(proposalEditedData) {
    return request(`${env.mainBaseUrl}/services/app/ProposalMessage/UpdateProposal`, 'put', proposalEditedData);
  },

  deleteProposal(messageId, proposalId) {
    const editedProposalId = proposalId !== '0' ? `&EditedProposalId=${proposalId}` : '';

    return request(`${env.mainBaseUrl}/services/app/ProposalMessage/Delete?MessageId=${messageId}${editedProposalId}`, 'delete');
  },

  savePartial(data) {
    return request(`${env.mainBaseUrl}/services/app/History/SavePartial`, 'post', data);
  },

  getHistoryProgress(historyId, threadId) {
    const url = `${env.mainBaseUrl}/services/app/History/GetHistoryProgress?historyId=${historyId}&threadId=${threadId}`;
    return request(url, 'get');
  }
};

export default historyApi;
