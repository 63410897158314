// vendors
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import numeral from 'numeral';
import React, { useState, useEffect, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

// actions
import { getQuestions, getHistoryById, setLikeToStory } from '../../redux/actions/createHistory';

// components
import GmStoryReview from './story-review';
import GmStoryCard from '../../components/story-card';

// constants
import {
  LOAN_CATEGORIES,
  LOAN_CATEGORIES_VALUES,
  ROLES,
  STORY_STATUS
} from '../../config/constants';
import { routes } from '../../config/routes';

// utils
import { getLoanAmountFeeService, getLoanAmountFeeInsurance } from '../loan-calculator/utils';

// styles
import { useStyles } from './styles';

const HistoryCard = ({
  disableMessages,
  fullName,
  historyData,
  historyReviews,
  isEdit = true,
  isVisiting = false,
  liked,
  showBadge,
  totalLikes
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    auth,
    history: {
      editData,
      histories,
      historyInfo,
      myHistories,
    },
    settings,
  } = useSelector((state) => state);
  const { id } = useParams();

  const [historyCard, setHistoryCard] = useState(historyData);
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [seeMoreAvailable, toggleSeeMoreAvailable] = useState(false);
  const [isHistoryLiked, setIsHistoryLiked] = useState(liked ?? false);
  const [likesOnCurrentView, setLikesOnCurrentView] = useState(totalLikes ?? 0);

  const currentReviews = historyReviews ?? historyInfo?.reviews;
  const currentId = (historyCard?.id ?? id)?.toString();
  const isThereAnyReview = currentReviews
    && currentReviews?.length > 0
    && currentId === (historyInfo?.id ?? histories?.find((x) => x.id.toString() === currentId))?.id?.toString();

  const callRequest = () => {
    if (id) {
      dispatch(getHistoryById(id));
      dispatch(getQuestions(id));
    }
  };

  useEffect(callRequest, [id]);

  useEffect(() => {
    if (!historyData) {
      const filterHistory = myHistories.find((item) => item.id === +id);
      const myImages = Object.keys(editData).length > 0
        ? editData.flatMap((item) => (item.file && item.file.href && item.file.href !== '' ? [item.file.href] : []))
        : {};
      const myTexts = Object.keys(editData).length > 0
        ? editData.flatMap((item) => (item && item.answer && item.answer !== '' ? [item.answer] : []))
        : {};

      setTexts(myTexts);
      setImages(myImages);
      setHistoryCard(filterHistory);
    } else {
      const { historyQuestions } = historyCard;
      const myImages = historyQuestions.flatMap((item) => (item && item.imageUrl && item.imageUrl !== '' ? [item.imageUrl] : []));
      const myTexts = historyQuestions.flatMap((item) => (item && item.answer && item.answer !== '' ? [item.answer] : []));

      setTexts(myTexts);
      setImages(myImages);
    }
  }, [editData, historyCard, historyInfo]);

  const giveLikeToStory = () => {
    dispatch(setLikeToStory(historyData?.id || id)).then(() => {
      if (isVisiting) {
        dispatch(getHistoryById(id));
      }
      setIsHistoryLiked(!isHistoryLiked);
      setLikesOnCurrentView(isHistoryLiked || historyInfo.likeThis ? likesOnCurrentView - 1 : likesOnCurrentView + 1);
    });
  };

  const goToChat = () => history.push(routes.newChatHistory
    .replace(':id', historyData?.user?.id)
    .replace(':historyId', historyData?.id ?? id));

  const baseLoanValue = useMemo(() => historyCard?.financingValue ?? historyInfo?.financingValue ?? 0,
    [historyCard, historyInfo]);

  const loanTime = useMemo(() => historyCard?.proposal?.loanTime ?? historyInfo?.proposal?.loanTime ?? 0,
    [historyCard, historyInfo]);

  const frequency = useMemo(() => historyCard?.proposal?.frequency ?? historyInfo?.proposal?.frequency ?? 0,
    [historyCard, historyInfo]);

  const loanFeeService = useMemo(() => getLoanAmountFeeService({
    loanAmountWihoutAnyFees: baseLoanValue,
    serviceFeePercentage: settings.administrativeExpensesSettings.FeessGivemeFive
  }), [baseLoanValue, settings]);

  const loanFeeInsurance = useMemo(() => getLoanAmountFeeInsurance({
    loanAmountWihoutAnyFees: baseLoanValue,
    insuranceFeePercentage: settings.administrativeExpensesSettings.Endorsement
  }), [baseLoanValue, settings]);

  const handleOnProfileVisiting = () =>
    history.push(routes.main.replace(':userId?', historyData?.user?.id || historyInfo?.user?.id));

  const categoryValueKey = Object.keys(LOAN_CATEGORIES).find((key) => historyCard
    ? LOAN_CATEGORIES[key] === historyCard?.category
    : LOAN_CATEGORIES[key] === historyInfo?.category);

  const renderReviews = () => {
    if (!isThereAnyReview) {
      return null;
    }

    const name = fullName || historyInfo?.user?.fullName;

    return (
      <>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid className={classes.reviewTittle} item md={12} xs={12}>
            <Typography className={classes.reviewHeader} component="span" variant="body2">
              {`Calificaciones hechas a ${name?.split(' ')[0]}`}
            </Typography>
          </Grid>
          {currentReviews?.map((review, index) => (
            <GmStoryReview key={index} review={review} />
          ))}
        </Grid>
      </>
    );
  };

  const renderImageDesc = () => {
    const seeMore = () => toggleSeeMoreAvailable(!seeMoreAvailable);
    const desc = `${texts[0]} ${texts[texts.length - 1]}`;
    const maxCharactersVisible = 100;

    if (desc?.length < maxCharactersVisible) {
      return (
        <p className={classes.gmStoryContentDescription}>
          {desc}
        </p>
      );
    }

    return (
      <>
        {!seeMoreAvailable ? (
          <p className={classes.gmStoryContentDescription}>
            {`${desc?.substring(0, maxCharactersVisible)}...`}
          </p>
        ) : (
          <>
            <p className={classes.gmStoryContentDescription}>
              {texts[0]}
            </p>
            <p className={classes.gmStoryContentDescription}>
              {texts[texts.length - 1]}
            </p>
          </>
        )}
        <p className={classes.gmStoryContentDescription}>
          <Link onClick={seeMore}>
            {!seeMoreAvailable
              ? 'Ver más'
              : 'Ver menos'}
          </Link>
        </p>
      </>
    );
  };

  return (
    <>
      <GmStoryCard
        disableGoToChat={disableMessages}
        financingValue={numeral(baseLoanValue + loanFeeService + loanFeeInsurance).format('$0,0')}
        frequency={frequency}
        isGodfather={auth.userInfo.role === ROLES.godfather}
        isStoryFinalized={showBadge}
        isVisitingMode={isVisiting}
        like={isHistoryLiked}
        loanTime={loanTime}
        onClickUserStoryCreator={handleOnProfileVisiting}
        onGoToChat={goToChat}
        onStoryLike={giveLikeToStory}
        storiesPaidByCreator={historyCard?.user?.numberOfPaidStories || historyInfo?.user?.numberOfPaidStories}
        storyCategory={LOAN_CATEGORIES_VALUES[categoryValueKey]}
        storyCreatorFullName={historyCard?.user?.fullName || historyInfo?.user?.fullName}
        storyCreatorKarmaScore={historyCard?.user?.karma || historyInfo?.user?.karma}
        storyCreatorProfilePicture={historyCard?.user?.profilePicture || historyInfo?.user?.profilePicture}
        storyDate={historyCard?.date || historyInfo?.date}
        storyDescription={renderImageDesc()}
        storyPictures={images}
        storyTitle={historyCard?.description || historyInfo?.description}
      />
      {isEdit && (historyCard?.status === STORY_STATUS.created || historyCard?.status === STORY_STATUS.published)
        ? (
          <Button
            className={classes.editHistory}
            onClick={() => history.push(`/create-history/${historyCard.id}`)}
          >
            <CreateIcon className={classes.editIcon} />
          </Button>
        )
        : null}
      {renderReviews()}
    </>
  );
};

export default HistoryCard;
