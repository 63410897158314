// vendors
import React from 'react';
import { CircularProgress } from '@material-ui/core';

// components
import HistoryCard from './card';

// constants
import { STORY_STATUS } from '../../config/constants';

// styles
import { useStyles } from './styles';

const HistoriesList = ({
  histories,
  loading,
  onSetLastHistoryRef
}) => {
  const classes = useStyles();

  return (
    <div className={classes.histories}>
        {histories && histories
          .map((item, index) => (
            <div key={item.id} ref={histories.length === index + 1 ? onSetLastHistoryRef : null}>
              <HistoryCard
                disableMessages={item.status === STORY_STATUS.finalized || item.status === STORY_STATUS.funded}
                fullName={item.user.fullName}
                historyData={item}
                historyReviews={item.reviews}
                isEdit={false}
                liked={item.likeThis}
                showBadge={item.status === STORY_STATUS.finalized || item.status === STORY_STATUS.funded}
                totalLikes={item?.totalLikes}
              />
            </div>
          ))}
        {loading ? <CircularProgress style={{ marginTop: 30 }} /> : null}
    </div>
  );
};

export default HistoriesList;
