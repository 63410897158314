// vendors
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// styles
import { useStyles } from './styles';

const SummaryItem = ({ label, value, visible = true }) => {
  const classes = useStyles();

  if (!visible) {
    return null;
  }

  return (
    <Grid className={classes.summaryItemContainer} container direction="column">
      <Grid item>
        <Typography className={classes.summaryItemLabel}>{label}</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.summaryItemValue}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

export default SummaryItem;
