// vendors
import React from 'react';

const CenterActionDisabled = (
  <>
    <path d="M45.15 12.57L28.68 0.609985L0 21.44L10.95 55.16H43.89L32.93 21.44L45.15 12.57Z" fill="#CFD2D7" />
    <path d="M61.61 0.609985L45.15 12.57L57.36 21.44L46.4 55.16H79.34L90.29 21.44L61.61 0.609985Z" fill="#CFD2D7" />
    <path d="M45.15 12.5699L32.93 21.4399L43.89 55.1599H46.4L57.36 21.4399L45.15 12.5699Z" fill="#7D7D7D" />
  </>
);

export default CenterActionDisabled;
