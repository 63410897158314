// vendors
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const Notification = ({
  classes,
  eventMessage,
  messageId
}) => (
  <ListItem className={classes.listMessageNotification} key={messageId}>
    <Typography className={classes.listMessageNotificationContent}>
      {eventMessage?.content?.toUpperCase() || eventMessage?.Message?.toUpperCase() || eventMessage?.Comment}
    </Typography>
  </ListItem>
);

export default Notification;
