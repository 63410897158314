// constants
import {
  GET_USER_PROFILE_SUCCESS,
  GET_USER_STORIES_SUCCESS,
} from '../action-types';

// state
import { profileVisitingInitialState } from '../../config/state/initialState';

function profileVisitingReducer(state = profileVisitingInitialState, action) {
  switch (action.type) {
    case GET_USER_PROFILE_SUCCESS:
      return { ...state, profile: action.payload.result };
    case GET_USER_STORIES_SUCCESS:
      return { ...state, stories: action.payload.result.items };
    default:
      return state;
  }
}

export default profileVisitingReducer;
