// http
import env from '../env';
import request from '../request';

const calculatorApi = {
  getTotalInterestEarned: ({
    interestRate,
    itsFromFeeValueOption,
    loanAmount,
    loanTime,
    quotaValue,
  } = {}) =>
    request(
      // eslint-disable-next-line max-len
      `${env.mainBaseUrl}/services/app/Calculator/GetTheTotalInterestEarned?InterestRate=${interestRate}&ItsFromFeeValueOption=${itsFromFeeValueOption}&LoanAmount=${loanAmount}&LoanTime=${loanTime}&QuotaValue=${quotaValue}`,
      'get',
    ),
  calculateLoanValues: ({
    frequency,
    interestRate,
    itsFromFeeValueOption,
    loanAmount,
    loanTime,
  }) => request(`${env.mainBaseUrl}/services/app/Calculator/CalculateLoanValues`, 'post', {
    frequency,
    interestRate,
    itsFromFeeValueOption,
    loanAmount,
    loanTime,
  })
};

export default calculatorApi;
