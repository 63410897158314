import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#ffff',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    boxShadow: 'none',
  },
  closeIconCustom: {
    position: 'absolute',
    marginTop: 37,
    marginLeft: 22,
    fontSize: 28,
  },
  supportTitle: {
    marginTop: 88,
    fontSize: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  notFoundImg: {
    width: '80%',
    marginTop: '20%',
  },
}));

export { useStyles };
