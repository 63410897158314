/* eslint-disable max-len */

// vendors
import React from 'react';

const ProfileDisabled = (
  <>
    <path d="M63.21 31.95C63.21 15.9 51.24 2.64998 35.74 0.629982C35.68 0.619982 35.61 0.609983 35.55 0.599983C35.05 0.539983 34.55 0.489982 34.04 0.449982C33.24 0.389982 32.43 0.359985 31.61 0.359985H31.6C14.14 0.369985 0 14.51 0 31.95C0 49.4 14.15 63.54 31.6 63.54C49.06 63.55 63.21 49.4 63.21 31.95Z" fill="#CFD2D7" />
    <path d="M20.72 19.21C22.8 19.1 24.5 20.81 24.4 22.89C24.31 24.67 22.87 26.11 21.09 26.2C19.01 26.3 17.31 24.6 17.41 22.52C17.5 20.75 18.94 19.3 20.72 19.21Z" fill="#7D7D7D" />
    <path d="M42.12 19.21C44.2 19.1 45.9 20.81 45.8 22.89C45.71 24.67 44.27 26.11 42.49 26.2C40.41 26.3 38.7 24.6 38.81 22.52C38.9 20.75 40.34 19.3 42.12 19.21Z" fill="#7D7D7D" />
    <path d="M50.5 35.8899C50.29 35.5799 49.98 35.3199 49.61 35.1599C49 34.9099 48.33 34.9899 47.81 35.3299C47.48 35.5399 47.21 35.8499 47.05 36.2399C44.41 42.7099 38.35 46.8999 31.61 46.8999C31.6 46.8999 31.59 46.8999 31.57 46.8999C24.84 46.8799 18.8 42.6999 16.17 36.2399C15.76 35.2299 14.61 34.7499 13.61 35.1599C12.61 35.5699 12.12 36.7199 12.53 37.7199C15.77 45.6799 23.26 50.8299 31.61 50.8299C32.79 50.8299 33.94 50.7299 35.07 50.5299C35.32 50.4899 35.57 50.4399 35.82 50.3799C36.94 50.1399 38.03 49.8099 39.08 49.3899C39.31 49.2999 39.55 49.1999 39.78 49.0999C40.01 48.9999 40.24 48.8899 40.46 48.7799C41.02 48.4999 41.58 48.1999 42.11 47.8799C42.32 47.7499 42.54 47.6199 42.75 47.4799C43.17 47.1999 43.58 46.9099 43.98 46.5999C44.28 46.3699 44.57 46.1299 44.86 45.8799C45.05 45.7099 45.24 45.5499 45.43 45.3699C45.62 45.1999 45.8 45.0199 45.98 44.8399C46.25 44.5699 46.51 44.2899 46.77 44.0099C47.28 43.4399 47.77 42.8399 48.22 42.2099C48.67 41.5799 49.09 40.9199 49.48 40.2299C49.93 39.4299 50.34 38.5899 50.69 37.7199C50.79 37.4699 50.84 37.2099 50.84 36.9499C50.82 36.5799 50.71 36.2099 50.5 35.8899Z" fill="#7D7D7D" />
  </>
);

export default ProfileDisabled;
