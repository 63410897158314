// vendors
import * as yup from 'yup';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

// actions
import { updateProfile, changePasswordAction, saveDocumentImg } from '../../../redux/actions/register';

// icons
import ReviewYellow from '../../../static/images/review-positive.png';

// components
import GmAboutMe from '../about-me';
import GmAboutMe1 from '../about-me-1';
import GmChangePassword from '../change-password';

// constants
import { ROLES } from '../../../config/constants';
import { routes } from '../../../config/routes';

// styles
import { useStyles } from './styles';

// utils
import {
  splitUserFullName,
} from '../../../utils';

// validations
const validationSchema = yup.object({
  birthDate: yup.string('Ingresa tú fecha de nacimiento').required('La fecha de nacimiento es requerida'),
  docType: yup.string('Selecciona el tipo de documento').required('El tipo de documento es requerido'),
  gender: yup.string('Ingrese Genero').required('El genero es requerida'),
  lastName: yup.string('Ingresa tus apellidos').required('Los apellidos son requeridos'),
  name: yup.string('Ingresa tú nombre').required('El nombre es requerido'),
});

export const GmProfileForm = ({ q0img }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth, common } = useSelector((state) => state);

  const userFullName = splitUserFullName(auth.userInfo.fullName);

  const routeChange = (path) => history.push(path);
  const handleChangepassword = (value) => dispatch(changePasswordAction(value)).then(() => history.push(routes.main.replace(':userId?', '')));
  const birthDate = new Date(auth.userInfo.birthDay);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: auth.userInfo.address || '',
      birthDate: moment(auth.userInfo.birthDay).format('DD/MM/YYYY'),
      childCounter: auth.userInfo.childsNumber || 0,
      city: auth.userInfo.city || '',
      country: auth.userInfo.country || '',
      day: birthDate.getUTCDate().toString().padStart(2, '0'),
      docType: auth.userInfo.documentType,
      gender: auth.userInfo?.sex?.toString() || '2',
      id: auth.userInfo.documentNumber,
      lastName: auth.userInfo.surname || `${userFullName.lastName} ${userFullName.surName}`.trim(),
      maritalStatus: auth.userInfo.civilStatus || '',
      month: (birthDate.getUTCMonth() + 1).toString().padStart(2, '0'),
      name: auth.userInfo.name || userFullName.name,
      personalPhrase: auth.userInfo.personalPhrase || '',
      profession: auth.userInfo.profession || '',
      state: auth.userInfo.state || '',
      year: birthDate.getUTCFullYear().toString()
    },
    validationSchema,
    onSubmit: (values) => {
      if (q0img?.content) {
        dispatch(saveDocumentImg(q0img));
      }
      dispatch(updateProfile(values, auth.userInfo));
      history.push(routes.main.replace(':userId?', ''));
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
          >
            <img className={classes.iconTittle} src={ReviewYellow} />
            <Typography>Tus datos personales</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <GmAboutMe
                disableFields
                formik={formik}
                q0Img={q0img}
                editProfile
              />
              <img alt="document" src={auth.userInfo.documentPicture} className={classes.documentPicture} />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
          >
            <img alt="start" className={classes.iconTittle} src={ReviewYellow} />
            <Typography>¿Dónde te encuentras?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid>
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled
                error={formik.touched.country && Boolean(formik.errors.country)}
                fullWidth
                id="country"
                label="País de residencia"
                name="reason"
                onChange={formik.handleChange}
                style={{ paddingBottom: 20, marginTop: 5 }}
                value={formik.values.country}
                variant="outlined"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled
                error={formik.touched.state && Boolean(formik.errors.state)}
                fullWidth
                id="state"
                label="Departamento o estado"
                name="state"
                onChange={formik.handleChange}
                style={{ paddingBottom: 20 }}
                value={formik.values.state}
                variant="outlined"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled
                error={formik.touched.city && Boolean(formik.errors.city)}
                fullWidth
                id="city"
                label="Ciudad"
                name="city"
                onChange={formik.handleChange}
                style={{ paddingBottom: 20 }}
                value={formik.values.city}
                variant="outlined"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled
                error={formik.touched.address && Boolean(formik.errors.address)}
                fullWidth
                id="address"
                label="Dirección"
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                variant="outlined"
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
          >
            <img alt="start" className={classes.iconTittle} src={ReviewYellow} />
            <Typography className={classes.heading}>Cuéntanos un poco más de ti</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid style={{ paddingTop: 5 }} container>
              <GmAboutMe1 formik={formik} />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <img alt="start" className={classes.iconTittle} src={ReviewYellow} />
            <Typography style={{ textAlign: 'left' }}>
              {auth.userInfo.role === ROLES.godfather
                ? 'Define una frase personal que te describa y motive a otros'
                : 'Define una frase personal que motive otros corazones a apoyar tus historias'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              InputLabelProps={{ shrink: true }}
              error={formik.touched.personalPhrase && Boolean(formik.errors.personalPhrase)}
              fullWidth
              id="personalPhrase"
              label=""
              name="personalPhrase"
              onChange={formik.handleChange}
              rows={4}
              value={formik.values.personalPhrase}
              variant="outlined"
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
          >
            <img alt="start" className={classes.iconTittle} src={ReviewYellow} />
            <Typography className={classes.heading}>Configuraciones de la cuenta</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              <GmChangePassword
                handleChangepassword={handleChangepassword}
                loading={common.loading}
                mail={auth?.userInfo?.emailAddress || ''}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Grid container direction="row">
          <Grid item style={{ padding: '20px 20px' }} xs={6}>
            <Button
              fullWidth
              onClick={() => routeChange('/main')}
              variant="contained"
            >
              DESCARTAR
            </Button>
          </Grid>
          <Grid item style={{ padding: '20px 20px 0px 0px' }} xs={6}>
            <Button
              color="primary"
              disabled={common.loading}
              fullWidth
              style={{ color: '#ffffff' }}
              type="submit"
              variant="contained"
            >
              ENVIAR
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default GmProfileForm;
