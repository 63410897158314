// vendors
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import numeral from 'numeral';
import moment from 'moment';

// styles
import useStyles from './styles';

const GmTransferSummary = ({
  date,
  icon,
  iconStyles,
  title,
  valueAmount,
  valueLabel,
  visible,
}) => {
  if (!visible) {
    return null;
  }

  const classes = useStyles();

  return (
    <Grid
      className={classes.gmTransferSummary}
      container
      direction="column"
      justify="center"
    >
      <Grid item>
        {icon
          ? (<img alt="gm-transfer-summary-icon" src={icon} style={{ ...iconStyles }} />)
          : null}
      </Grid>
      <Grid className={classes.gmTransferSummaryTitleContainer} item>
        <Typography className={classes.gmTransferSummaryTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid className={classes.gmTransferSummaryValueLabelContainer} item>
        <Typography className={classes.gmTransferSummaryValueLabel}>
          {valueLabel}
        </Typography>
      </Grid>
      <Grid className={classes.gmTransferSummaryValueAmountContainer} item>
        <Typography className={classes.gmTransferSummaryValueAmount}>
          {numeral(valueAmount).format('$0,0')}
        </Typography>
      </Grid>
      <Grid className={classes.gmTransferSummaryDateContainer} item>
        <Typography className={classes.gmTransferSummaryDate}>
          {moment(date).format('DD MMMM YYYY')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GmTransferSummary;
