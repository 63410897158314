// vendors
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import numeral from 'numeral';

// components
import GmSvgIcon from '../../static/svg';

// constants
import { TRANSACTION_TYPES } from '../../config/constants';

// styles
import { useStyles } from './styles';

const ActionItem = ({ date, type, value }) => {
  const classes = useStyles();

  const getIcon = () => {
    switch (type) {
      case TRANSACTION_TYPES.godfatherTransfer:
        return {
          icon: 'payment-icon',
          viewBox: '-2 0 34 30',
        };
      case TRANSACTION_TYPES.godsonPaymentOnTime:
        return {
          icon: 'payment-report',
          viewBox: '0 0 14 24',
        };
      case TRANSACTION_TYPES.godsonPaymentOutOfTime:
        return {
          icon: 'karma-alert',
          viewBox: '0 10 28 10',
        };
      default:
        return {
          icon: '',
          viewBox: '',
        };
    }
  };

  const getTitle = () => {
    switch (type) {
      case TRANSACTION_TYPES.godfatherTransfer:
        return 'Desembolso realizado';
      case TRANSACTION_TYPES.godsonPaymentOnTime:
        return 'Buen Karma acumulado';
      case TRANSACTION_TYPES.godsonPaymentOutOfTime:
        return 'Mal Karma acumulado';
      default:
        return '';
    }
  };

  const getValueClass = () => {
    switch (type) {
      case TRANSACTION_TYPES.godfatherTransfer:
      case TRANSACTION_TYPES.godsonPaymentOnTime:
        return 'actionItemValueSuccess';
      case TRANSACTION_TYPES.godsonPaymentOutOfTime:
        return 'actionItemValueError';
      default:
        return '';
    }
  };

  return (
    <Grid container direction="row" justify="space-between">
      <Grid item>
        <GmSvgIcon
          enabled
          icon={getIcon().icon}
          style={{ height: 35, width: 35 }}
          viewBox={getIcon()?.viewBox}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography className={classes.subTitle}>
              {getTitle()}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.summaryItemLabel}>
              {moment(date).format('DD MMM YYYY')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography className={classes[getValueClass()]}>
          {numeral(value).format('$0,0')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ActionItem;
