// vendors
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// svg
import ActionCenterDisabled from './action-center-disabled';
import ActionCenterEnabled from './action-center-enabled';
import AddStoryDisabled from './add-story-disabled';
import AddStoryEnabled from './add-story-enabled';
import AlertDisabled from './alert-disabled';
import AlertEnabled from './alert-enabled';
import BadKarma from './bad-karma';
import CalculatorDisabled from './calculator-disabled';
import CalculatorEnabled from './calculator-enabled';
import EyesDisabled from './eyes-disabled';
import EyesEnabled from './eyes-enabled';
import FinalizedStory from './finalized-story';
import FineKarma from './fine-karma';
import GoodKarma from './good-karma';
import InterestRateEnabled from './interest-rate-enabled';
import KarmaAlert from './karma-alert';
import LikeDisabled from './like-disabled';
import LikeEnabled from './like-enabled';
import MessageDisabled from './message-disabled';
import MessageEnabled from './message-enabled';
import NotificationsDisabled from './notifications-disabled';
import NotificationsEnabled from './notifications-enabled';
import PQRSDisabled from './pqrs-disabled';
import PQRSEnabled from './pqrs-enabled';
import PaymentDetail from './payment-detail';
import PaymentDone from './payment-done';
import PaymentIcon from './payment-icon';
import PaymentReportDisabled from './payment-report-disabled';
import PaymentReportEnabled from './payment-report-enabled';
import PentagonReviewDisabled from './pentagon-review-disabled';
import PentagonReviewEnabled from './pentagon-review-enabled';
import ProfileDisabled from './profile-disabled';
import ProfileEnabled from './profile-enabled';
import RedPentagon from './red-pentagon';
import ReferringDisabled from './refering-disabled';
import ReferringEnabled from './referring-enabled';
import SearchDisabled from './search-disabled';
import SearchEnabled from './search-enabled';
import UnreadMessages from './unread-messages';
import VoiceKarma from './voice-karma';

// constants
const SVG_MAPPER = {
  'action-center-disabled': ActionCenterDisabled,
  'action-center-enabled': ActionCenterEnabled,
  'add-story-disabled': AddStoryDisabled,
  'add-story-enabled': AddStoryEnabled,
  'alert-disabled': AlertDisabled,
  'alert-enabled': AlertEnabled,
  'bad-karma': BadKarma,
  'calculator-disabled': CalculatorDisabled,
  'calculator-enabled': CalculatorEnabled,
  'eyes-disabled': EyesDisabled,
  'eyes-enabled': EyesEnabled,
  'finalized-story': FinalizedStory,
  'fine-karma': FineKarma,
  'good-karma': GoodKarma,
  'interest-rate-enabled': InterestRateEnabled,
  'karma-alert': KarmaAlert,
  'like-disabled': LikeDisabled,
  'like-enabled': LikeEnabled,
  'message-disabled': MessageDisabled,
  'message-enabled': MessageEnabled,
  'notifications-disabled': NotificationsDisabled,
  'notifications-enabled': NotificationsEnabled,
  'payment-detail': PaymentDetail,
  'payment-done': PaymentDone,
  'payment-icon': PaymentIcon,
  'payment-report-disabled': PaymentReportDisabled,
  'payment-report-enabled': PaymentReportEnabled,
  'pentagon-review-disabled': PentagonReviewDisabled,
  'pentagon-review-enabled': PentagonReviewEnabled,
  'pqrs-disabled': PQRSDisabled,
  'pqrs-enabled': PQRSEnabled,
  'profile-disabled': ProfileDisabled,
  'profile-enabled': ProfileEnabled,
  'red-pentagon': RedPentagon,
  'referring-disabled': ReferringDisabled,
  'referring-enabled': ReferringEnabled,
  'search-disabled': SearchDisabled,
  'search-enabled': SearchEnabled,
  'unread-messages': UnreadMessages,
  'voice-karma': VoiceKarma,
};

const GmSvgIcon = (props) => {
  const {
    disabled,
    enabled,
    fill,
    icon,
  } = props;

  // This is due the DOM doesn't recognize the attributes disabled and enabled as booleans within true or false statements
  // and there are a lot of other props for the SvgIcon component that cannot be defined one by one.
  const missingProps = { ...props, disabled: 0, enabled: 0 };

  const svgWrapper = (children) => (
    <SvgIcon {...missingProps} htmlColor={fill}>
      {children}
    </SvgIcon>
  );

  const getSvgIcon = () => {
    const enabledIcon = SVG_MAPPER[`${icon}-enabled`];
    const disabledIcon = SVG_MAPPER[`${icon}-disabled`];
    const defaultIcon = SVG_MAPPER[icon];

    if (disabled && Boolean(disabledIcon)) {
      return svgWrapper(disabledIcon);
    }

    if (enabled && Boolean(enabledIcon)) {
      return svgWrapper(enabledIcon);
    }

    return svgWrapper(defaultIcon);
  };

  return getSvgIcon();
};

export default GmSvgIcon;
