export const LOAN_CATEGORIES = {
  capital: 4,
  grocery: 5,
  health: 0,
  rent: 2,
  study: 1,
  transport: 3,
};

export const TRANSACTION_STATE = {
  close: 1,
  open: 0,
  openPendingOfLoan: 2,
};

export const LOAN_CATEGORIES_VALUES = {
  capital: 'Plante',
  grocery: 'Mercado',
  health: 'Salud',
  rent: 'Arriendo',
  study: 'Educación',
  transport: 'Transporte'
};

export const INTEREST_RATE = [
  {
    value: 0,
    rate: 'None',
    label: 'Nada',
  },
  {
    value: 1,
    rate: 'Low',
    label: 'Bajo',
  },
  {
    value: 2,
    rate: 'Medium',
    label: 'Medio',
  },
  {
    value: 3,
    rate: 'High',
    label: 'Alto',
  },
];

export const PAYMENT_FREQUENCY = ['diaria', 'semanal', 'quincenal', 'mensual'];
