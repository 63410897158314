// constants
import { CLEAR_UNREAD_MESSAGES, SET_UNREAD_MESSAGES } from '../action-types';

export const clearUnreadMessages = ({ unreadMessage }) =>
  ({
    type: CLEAR_UNREAD_MESSAGES,
    payload: unreadMessage,
  });

export const setUnreadMessages = ({ unreadMessage }) =>
  ({
    type: SET_UNREAD_MESSAGES,
    payload: unreadMessage,
  });
