import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#33647E',
    backgroundPosition: 'center',
    display: 'grid',
    height: '38vh',
    gridTemplateRows: 'auto 1fr auto',
    borderRadius: 0,
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  brandLogo: {
    margin: 20,
  },
  editIcon: {
    color: 'white',
    margin: '5px 0 10px 5px',
    fontSize: '23px',
  },
  imgProfile: {
    background: '#daa8ab',
    width: '140px',
    height: '140px',
    borderRadius: '100%',
    margin: '15px auto',
  },
  editProfile: {
    width: '33px',
    height: '33px',
    background: '#1DCAD3',
    borderRadius: '100%',
    position: 'relative',
    top: '100px',
    left: '104px',
    cursor: 'pointer',
  },
  selectContainer: {
    width: '90%',
    marginBottom: 32,

    '&:first-of-type': {
      margin: '34px 0 32px 0px',
    },
  },
  contenDoc: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
  },
  formControlDoc: {
    marginRight: 20,
    '& .MuiSelect-select': {
      width: 50,
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: 15,
  },
  inpuLabelStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: '#000',
    width: 37,
  },
  fileInput: {
    width: '90%',
    display: 'flex',
    border: '1px solid #DADADA',
    borderRadius: 4,
    justifyContent: 'center',
    height: 56,
    alignItems: 'flex-end',
  },
  inpuLabelDateStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: '#000',
    width: 127,
  },
  inpuLabelMaritalStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: '#000',
    width: 87,
  },
  inpuLabelFileStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: '#000',
    width: 190,
  },
  buttonProgress: {
    color: '#1DCAD3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  startButton: {
    position: 'relative',
    '& .MuiButton-label': {
      color: 'white',
      fontWeight: 500,
    },
  },
  radioCustom: {
    width: '90%',
    margin: '13px 0 12px 0',
  },
  radioGroupCustom: {
    flexDirection: 'row !important',
    justifyContent: 'center',
  },
  iconTittle: {
    width: 20,
    height: 20,
    marginRight: 9,
  },
  documentPicture: {
    width: '100%',
    borderRadius: 5
  }
}));

export { useStyles };
