// packages
import React from 'react';
import Button from '@material-ui/core/Button';

// components
import GmAlertMessage from '../alert';

// styles
import useStyles from './styles';

export const GmStoryQuestions = ({
  backgroundColor,
  onClick,
  error,
  questionDescriptionKey,
  questionSelected,
  questions,
  style,
}) => {
  const classes = useStyles({ backgroundColor });

  const handleOnClick = (questionId) => {
    if (onClick) {
      onClick(questionId);
    }
  };

  return (
    <div className={classes.storyQuestionsContainer} style={style}>
      {(questions.map((question) => (
        <Button
          className={question.id === questionSelected
            ? classes.storyQuestionButton
            : classes.storyQuestionButtonDisabled}
          key={`${question.id}-${question.name}`}
          onClick={() => handleOnClick(question.id)}
        >
          {question[questionDescriptionKey] || question.description}
        </Button>
      )))}
      <GmAlertMessage
        style={{
          marginTop: 0,
          padding: '0px 20px',
          textAlign: 'left',
        }}
        visible={error}
        variant="filled"
      >
        <strong>{error}</strong>
      </GmAlertMessage>
    </div>
  );
};

export default GmStoryQuestions;
