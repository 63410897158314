// vendors
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  gmMiniStoryImage: {
    height: 50,
    objectFit: 'cover',
    width: '100%',
    maxWidth: 75,
  },
  gmMiniStoryImagesCarousel: {},
}));
