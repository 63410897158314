// vendors
import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

// components
import GmBottomNavigation from '../components/bottom-navigation';
import GmLoadingPage from '../components/loading-page';

// constants
import { routes } from './routes';

// pages
import AccountVerify from '../pages/account-verify';
import CalendarPayment from '../pages/calendar-payments';
import ComponentPlayground from '../pages/component-playground';
import CreateHistory from '../pages/create-history';
import ForgotPassword from '../pages/forgot-password';
import GmChatHistory from '../pages/chat-history';
import GmChatList from '../pages/chat-list';
import HistoriesPage from '../pages/history/histories';
import HistoryPage from '../pages/history';
import Karma from '../pages/karma';
import Loans from '../pages/loans';
import LoginPage from '../pages/login';
import MainPage from '../pages/main';
import NotFound from '../pages/notFound';
import NotificationsPage from '../pages/notifications';
import PQRS from '../pages/pqrs';
import PaymentTransaction from '../pages/payments-report/payment-transaction';
import PaymentsDetail from '../pages/payments-detail';
import PaymentsReport from '../pages/payments-report';
import ProfilePage from '../pages/profile';
import RecoverPassword from '../pages/recover-password';
import ReferredUser from '../pages/referred-user';
import TransactionResult from '../pages/payments-report/transaction-result';
import GmTermsAndConditions from '../pages/terms-and-conditions';

// TODO: This imports should be absolute.
import { HomePage } from '../pages/home';
import { OnBoardingPage } from '../pages/onboarding';

const GmRouter = () => {
  const { auth } = useSelector((state) => state);

  const userIsLoggedIn = Boolean(auth?.userInfo?.accessToken) && Boolean(auth?.userInfo?.accessToken?.length);
  const allowedUrl = window.location.pathname.includes(routes.referralCode.replace('/:code?', '').trim())
    || window.location.pathname.includes(routes.recoverPassword) || window.location.pathname.includes(routes.componentPlayground);

  return (
    <Router>
      {!userIsLoggedIn && !allowedUrl ? <Redirect to={routes.home} /> : null}
      <Suspense fallback={<GmLoadingPage visible />}>
        <Switch>
          <Route exact path={routes.accountVerify} component={AccountVerify} />
          <Route exact path={routes.calendarPayment} component={CalendarPayment} />
          <Route exact path={routes.chatHistory} component={GmChatHistory} />
          <Route exact path={routes.chatReferralHistory} component={GmChatHistory} />
          <Route exact path={routes.chat} component={GmChatList} />
          <Route exact path={routes.componentPlayground} component={ComponentPlayground} />
          <Route exact path={routes.createHistory} component={CreateHistory} />
          <Route exact path={routes.forgotPassword} component={ForgotPassword} />
          <Route exact path={routes.histories} component={HistoriesPage} />
          <Route exact path={routes.history} component={HistoryPage} />
          <Route exact path={routes.home} component={HomePage} />
          <Route exact path={routes.karma} component={Karma} />
          <Route exact path={routes.loans} component={Loans} />
          <Route exact path={routes.login} component={LoginPage} />
          <Route exact path={routes.newChatHistory} component={GmChatHistory} />
          <Route exact path={routes.newChatReferralHistory} component={GmChatHistory} />
          <Route exact path={routes.notifications} component={NotificationsPage} />
          <Route exact path={routes.onboarding} component={OnBoardingPage} />
          <Route exact path={routes.paymentTransaction} component={PaymentTransaction} />
          <Route exact path={routes.paymentsDetail} component={PaymentsDetail} />
          <Route exact path={routes.paymentsReport} component={PaymentsReport} />
          <Route exact path={routes.pqrs} component={PQRS} />
          <Route exact path={routes.profile} component={ProfilePage} />
          <Route exact path={routes.referredUser} component={ReferredUser} />
          <Route exact path={routes.termsAndConditions} component={GmTermsAndConditions} />
          <Route exact path={routes.transactionResult} component={TransactionResult} />
          <Route path={routes.main} component={MainPage} />
          <Route path={routes.recoverPassword} component={RecoverPassword} />
          <Route path={routes.referralCode} component={OnBoardingPage} />
          {/* TODO: Keep the components above alphabetized and keep the NotFound component last. */}
          <Route component={NotFound} path="*" />
        </Switch>
      </Suspense>
      {/* TODO: keep below the cross and commons components by the moment. */}
      <GmBottomNavigation />
      <GmLoadingPage />
    </Router>
  );
};

export default GmRouter;
