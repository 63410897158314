// vendors
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// actions
import { getCountryPhoneCodes } from '../../redux/actions/register';
import { sendSMSCode, validationCode } from '../../redux/actions/transaction';

// components
import BlueLogo from '../../components/blue-logo';
import GmAutoComplete from '../../components/autocomplete';

// styles
import { useStyles } from './styles';

function AccountVerify({
  handleData,
  handleNext,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { phoneCodes } = useSelector((state) => state.register);

  const [verificationData, setVerificationData] = useState({
    countryCode: '57',
    phoneNumber: '',
    verificationCode: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [hasCodeBeenSent, setHasBeenCodeSent] = useState(false);

  useEffect(() => {
    dispatch(getCountryPhoneCodes());
  }, []);

  const handleOnSendCode = () => {
    if (!verificationData.phoneNumber || verificationData.phoneNumber?.length < 10) {
      setErrorMessage('Por favor ingresa un número de celular válido.');
    } else {
      handleData({ data: { countryCode: verificationData.countryCode.trim(), phoneNumber: verificationData.phoneNumber } });
      dispatch(sendSMSCode({ countryCode: verificationData.countryCode.trim(), phoneNumber: verificationData.phoneNumber.trim() }))
        .then((() => setHasBeenCodeSent(true)))
        .catch(() => {
          setHasBeenCodeSent(false);
          setErrorMessage('Por favor ingresa un número de celular válido.');
        });
    }
  };

  const handleOnValidateCode = () => {
    if (!verificationData.verificationCode || verificationData.verificationCode.length < 5) {
      setErrorMessage('Por favor ingrese un código valido');
    } else {
      const payload = {
        code: verificationData.verificationCode.trim(),
        phoneCode: verificationData.countryCode,
        phoneNumber: verificationData.phoneNumber
      };
      dispatch(validationCode(payload))
        .then((isCodeValid) => {
          if (!isCodeValid) {
            setErrorMessage('Por favor ingrese un código valido');
            return;
          }

          handleNext();
        });
    }
  };

  const handleOnChange = ({ target: { name, value } }) => {
    setErrorMessage('');
    setVerificationData({ ...verificationData, [name]: value });
  };

  const handleOnPhoneCodeChange = (country) => {
    if (country) {
      setVerificationData({ ...verificationData, countryCode: country.value });
    }
  };

  return (
    <>
      <Paper className={classes.root}>
        <Container>
          <Grid container justify="center" className={classes.center}>
            <div className={classes.brandLogo}>
              <BlueLogo />
            </div>
            <Grid item xs={12}>
              <h1 className={classes.supportTitle}>
                Verifiquemos tu cuenta
              </h1>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Grid
        className={classes.accountVerifyForm}
        container
        direction="column"
      >
        <form className={classes.formContainer}>
          <Grid item style={{ padding: '0px 25px' }}>
            <Typography gutterBottom className={classes.auxiliaryText}>
              Ingresa tu número de Whatsapp, te enviaremos un código de verificación
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={2}
              style={{ marginBottom: 15 }}
            >
              <Grid item style={{ paddingLeft: 29, paddingTop: 20 }} xs={5}>
                <GmAutoComplete
                  label=""
                  onOptionChange={handleOnPhoneCodeChange}
                  options={phoneCodes}
                  style={{ textAlign: 'left' }}
                  value={verificationData.countryCode}
                />
              </Grid>
              <Grid item style={{ paddingRight: 29, paddingTop: 20 }} xs={7}>
                <TextField
                  className={classes.textFieldInput}
                  id="phoneNumber"
                  label="Número de celular"
                  name="phoneNumber"
                  onChange={handleOnChange}
                  type="numeric"
                  value={verificationData.phoneNumber}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
            >
              <Grid item xs={12}>
                  <Button
                    className={classes.sendCodeButton}
                    color="primary"
                    disabled={hasCodeBeenSent}
                    onClick={handleOnSendCode}
                    style={{ height: '100%' }}
                    variant="contained"
                  >
                    Enviar código
                  </Button>
              </Grid>
              <Grid item style={{ paddingBottom: 20, marginTop: 45 }}>
                <Typography gutterBottom style={{ fontSize: 15 }}>¿No recibiste tu código?</Typography>
                <Button
                  color="primary"
                  onClick={handleOnSendCode}
                  size="small"
                  variant="text"
                  style={{ textTransform: 'none', marginTop: -5 }}
                >
                  Reenviar código
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 10 }}>
                <Typography gutterBottom className={classes.auxiliaryText}>
                  Ingresa el código que recibiste por Whatsapp
                </Typography>
              </Grid>
              <Grid item style={{ padding: '20px 20px 20px' }} xs={12}>
                <TextField
                  className={classes.textFieldInput}
                  disabled={!hasCodeBeenSent}
                  id="verificationCode"
                  label="Código de verificación"
                  name="verificationCode"
                  onChange={handleOnChange}
                  type="numeric"
                  value={verificationData.verificationCode}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
            >
              <Grid item xs={12}>
                <Button
                  className={classes.verifyCodeButton}
                  color="primary"
                  disabled={!hasCodeBeenSent}
                  onClick={handleOnValidateCode}
                  style={{ height: '100%' }}
                  variant="contained"
                >
                  Verificar
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.haveAccount}>
              <span className={classes.checkboxPage}>¿Ya tienes cuenta?</span>
              <Link to="/login" className={classes.haveAccountLink}>
                Inicia sesión
              </Link>
            </Grid>
          </Grid>
          {errorMessage
            && (
                <Grid item style={{ paddingBottom: 20 }}>
                  <Typography className={classes.errorMessage} variant="body1">{errorMessage}</Typography>
                </Grid>
            )}
        </form>
      </Grid>
    </>
  );
}

export default AccountVerify;
