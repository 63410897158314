// vendors
import { makeStyles } from '@material-ui/core/styles';

// images
import Image from '../../static/images/login.png';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffbe38',
    backgroundImage: `url(${Image})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '45vh',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '90%',
    backgroundColor: '#e8f0fe'
  },
  center: {
    textAlign: 'center',
  },
  divider: {
    width: '90%',
  },
  logIn: {
    marginTop: '3vh',
  },
  startButton: {
    position: 'relative',
    marginTop: 15,
    '& .MuiButton-label': {
      color: 'white',
      fontWeight: 500,
    },
  },
  form: {
    input: {
      padding: 11.5,
    },
  },
  buttonProgress: {
    color: '#1DCAD3',
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
    position: 'absolute',
    top: '50%',
  },
  haveAccount: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    justifyContent: 'center',
    marginBottom: 15,
    marginTop: 30,
  },
  register: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    justifyContent: 'center',
    marginTop: 15,
    paddingBottom: 20
  },
  haveAccountLink: {
    color: '#1DCAD3',
    cursor: 'pointer',
    fontWeight: 600,
    marginLeft: 5,
    textDecoration: 'none',
  },
  checkboxPage: {
    marginTop: 18,
  },
  loginFailedText: {
    color: '#FF0000',
    marginLeft: 25,
    textAlign: 'left',
    marginTop: 7.5,
    marginBottom: 0
  },
  visibilityButton: {
    background: '#e8f0fe',

  }
}));
