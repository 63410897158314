import React from 'react';

// styles
import useStyles from './styles';

export const GmInformativeText = ({ backgroundColor, children, style }) => {
  const classes = useStyles({ backgroundColor });
  return (
    <div className={classes.informativeTextContainer} style={style}>
      {children}
    </div>
  );
};

export default GmInformativeText;
