import paymentsApi from '../../config/api/payments';

import {
  CONFIRM_LAST_PAYMENT_BEGIN,
  CONFIRM_LAST_PAYMENT_ERROR,
  CONFIRM_LAST_PAYMENT_SUCCESS,
  CONFIRM_PAYMENT_BEGIN,
  CONFIRM_PAYMENT_ERROR,
  CONFIRM_PAYMENT_SUCCESS,
  GET_ACTIVE_TRANSACTION_BEGIN,
  GET_ACTIVE_TRANSACTION_ERROR,
  GET_ACTIVE_TRANSACTION_SUCCESS,
  GET_IS_LAST_PAYMENT_REPORT_BEGIN,
  GET_IS_LAST_PAYMENT_REPORT_ERROR,
  GET_IS_LAST_PAYMENT_REPORT_SUCCESS,
  GET_PAYMENT_DETAIL_ACTIONS_BEGIN,
  GET_PAYMENT_DETAIL_ACTIONS_ERROR,
  GET_PAYMENT_DETAIL_ACTIONS_SUCCESS,
  GET_PAYMENT_DETAIL_BEGIN,
  GET_PAYMENT_DETAIL_ERROR,
  GET_PAYMENT_DETAIL_SUCCESS,
  GET_REFERRAL_PERIMETER_STATUS_BEGIN,
  GET_REFERRAL_PERIMETER_STATUS_ERROR,
  GET_REFERRAL_PERIMETER_STATUS_SUCCESS,
  GET_TRANSACTION_RECORD_BEGIN,
  GET_TRANSACTION_RECORD_ERROR,
  GET_TRANSACTION_RECORD_SUCCESS,
  REGISTER_LAST_PAYMENT_TRANSFER_REPORT_BEGIN,
  REGISTER_LAST_PAYMENT_TRANSFER_REPORT_ERROR,
  REGISTER_LAST_PAYMENT_TRANSFER_REPORT_SUCCESS,
  REGISTER_LOAN_TRANSFER_BEGIN,
  REGISTER_LOAN_TRANSFER_ERROR,
  REGISTER_LOAN_TRANSFER_SUCCESS,
  REGISTER_PAYMENT_TRANSFER_REPORT_BEGIN,
  REGISTER_PAYMENT_TRANSFER_REPORT_ERROR,
  REGISTER_PAYMENT_TRANSFER_REPORT_SUCCESS,
  START_TRANSACTION_RECORD_BEGIN,
  START_TRANSACTION_RECORD_ERROR,
  START_TRANSACTION_RECORD_SUCCESS
} from '../action-types';

const confirmPaymentBegin = () => ({ type: CONFIRM_PAYMENT_BEGIN });
const confirmPaymentError = (payload) => ({ type: CONFIRM_PAYMENT_ERROR, error: payload });
const confirmPaymentSuccess = (payload) => ({ type: CONFIRM_PAYMENT_SUCCESS, payload });
const confirmLastPaymentBegin = () => ({ type: CONFIRM_LAST_PAYMENT_BEGIN });
const confirmLastPaymentError = (payload) => ({ type: CONFIRM_LAST_PAYMENT_ERROR, error: payload });
const confirmLastPaymentSuccess = (payload) => ({ type: CONFIRM_LAST_PAYMENT_SUCCESS, payload });
const getActiveTransactionBegin = () => ({ type: GET_ACTIVE_TRANSACTION_BEGIN });
const getActiveTransactionError = (payload) => ({ type: GET_ACTIVE_TRANSACTION_ERROR, error: payload });
const getActiveTransactionSuccess = (payload) => ({ type: GET_ACTIVE_TRANSACTION_SUCCESS, payload });
const getReferralPerimeterStatusBegin = () => ({ type: GET_REFERRAL_PERIMETER_STATUS_BEGIN });
const getReferralPerimeterStatusSuccess = (payload) => ({ type: GET_REFERRAL_PERIMETER_STATUS_SUCCESS, payload });
const getReferralPerimeterStatusError = (payload) => ({ type: GET_REFERRAL_PERIMETER_STATUS_ERROR, payload });
const getIsLastPaymentReportBegin = () => ({ type: GET_IS_LAST_PAYMENT_REPORT_BEGIN });
const getPaymentDetailActionsBegin = () => ({ type: GET_PAYMENT_DETAIL_ACTIONS_BEGIN });
const getPaymentDetailActionsError = () => ({ type: GET_PAYMENT_DETAIL_ACTIONS_ERROR });
const getPaymentDetailActionsSuccess = (payload) => ({ type: GET_PAYMENT_DETAIL_ACTIONS_SUCCESS, payload: { actions: payload } });
const getPaymentDetailBegin = () => ({ type: GET_PAYMENT_DETAIL_BEGIN });
const getPaymentDetailError = () => ({ type: GET_PAYMENT_DETAIL_ERROR });
const getPaymentDetailSuccess = (payload) => ({ type: GET_PAYMENT_DETAIL_SUCCESS, payload: { detail: payload } });
const registerLastPaymentTransferReportBegin = () => ({ type: REGISTER_LAST_PAYMENT_TRANSFER_REPORT_BEGIN });
const registerLoanTransferBegin = () => ({ type: REGISTER_LOAN_TRANSFER_BEGIN });
const registerLoanTransferError = (payload) => ({ type: REGISTER_LOAN_TRANSFER_ERROR, error: payload });
const registerLoanTransferSuccess = (payload) => ({ type: REGISTER_LOAN_TRANSFER_SUCCESS, payload });
const registerPaymentTransferReportBegin = () => ({ type: REGISTER_PAYMENT_TRANSFER_REPORT_BEGIN });
export const getIsLastPaymentReportError = (payload) => ({ type: GET_IS_LAST_PAYMENT_REPORT_ERROR, error: payload });
export const getIsLastPaymentReportSuccess = (payload) => ({ type: GET_IS_LAST_PAYMENT_REPORT_SUCCESS, payload });
export const registerLastPaymentTransferReportError = (payload) => ({ type: REGISTER_LAST_PAYMENT_TRANSFER_REPORT_ERROR, error: payload });
export const registerLastPaymentTransferReportSuccess = (payload) => ({ type: REGISTER_LAST_PAYMENT_TRANSFER_REPORT_SUCCESS, payload });
export const registerPaymentTransferReportError = (payload) => ({ type: REGISTER_PAYMENT_TRANSFER_REPORT_ERROR, error: payload });
export const registerPaymentTransferReportSuccess = (payload) => ({ type: REGISTER_PAYMENT_TRANSFER_REPORT_SUCCESS, payload });
export const startTransactionRecordBegin = () => ({ type: START_TRANSACTION_RECORD_BEGIN });
export const startTransactionRecordError = (payload) => ({ type: START_TRANSACTION_RECORD_ERROR, error: payload });
export const startTransactionRecordSuccess = (payload) => ({ type: START_TRANSACTION_RECORD_SUCCESS, payload });
export const getTransactionRecordBegin = () => ({ type: GET_TRANSACTION_RECORD_BEGIN });
export const getTransactionRecordError = (payload) => ({ type: GET_TRANSACTION_RECORD_ERROR, error: payload });
export const getTransactionRecordSuccess = (payload) => ({ type: GET_TRANSACTION_RECORD_SUCCESS, payload });

export const confirmLastPayment = ({
  messageId,
  paymentId,
  review,
  transactionId,
}) => (dispatch) => {
  dispatch(confirmLastPaymentBegin());
  return paymentsApi
    .confirmLastPayment({
      messageId,
      paymentId,
      review,
      transactionId,
    })
    .then(({ data }) => dispatch(confirmLastPaymentSuccess(data.result)))
    .catch((error) => dispatch(confirmLastPaymentError(error)));
};

export const confirmPayment = ({ messageId, paymentId }) => (dispatch) => {
  dispatch(confirmPaymentBegin());
  return paymentsApi
    .confirmPayment({ messageId, paymentId })
    .then(({ data }) => dispatch(confirmPaymentSuccess(data.result)))
    .catch((error) => dispatch(confirmPaymentError(error)));
};

export const getActiveTransactions = () => (dispatch) => {
  dispatch(getActiveTransactionBegin());
  return paymentsApi
    .getAllActiveTransactions()
    .then((response) => dispatch(getActiveTransactionSuccess(response)))
    .catch((error) => dispatch(getActiveTransactionError(error)));
};

export const getReferralPerimeterStatus = () => (dispatch) => {
  dispatch(getReferralPerimeterStatusBegin());

  return paymentsApi
    .getReferralPerimeterStatus()
    .then((response) => dispatch(getReferralPerimeterStatusSuccess(response)))
    .catch((error) => dispatch(getReferralPerimeterStatusError(error)));
};

export const getIsLastPaymentReport = (transactionId) => (dispatch) => {
  dispatch(getIsLastPaymentReportBegin());
  return paymentsApi
    .getIsLastPaymentReport(transactionId)
    .then(({ data }) => dispatch(getIsLastPaymentReportSuccess(data.result)))
    .catch((error) => dispatch(getIsLastPaymentReportError(error)));
};

export const getPaymentDetail = (transactionId) => (dispatch) => {
  dispatch(getPaymentDetailBegin());
  return paymentsApi
    .getDetail(transactionId)
    .then((response) => dispatch(getPaymentDetailSuccess(response.data.result)))
    .catch((error) => dispatch(getPaymentDetailError(error)));
};

export const getPaymentDetailActions = ({ skip, take, transactionId }) => (dispatch) => {
  dispatch(getPaymentDetailActionsBegin());
  return paymentsApi
    .getDetailActions({ skip, take, transactionId })
    .then((response) => dispatch(getPaymentDetailActionsSuccess(response.data.result)))
    .catch((error) => dispatch(getPaymentDetailActionsError(error)));
};

export const registerLastPaymentTransfer = (report) => (dispatch) => {
  dispatch(registerLastPaymentTransferReportBegin());

  return new Promise((resolve, reject) =>
    paymentsApi
      .registerLastPaymentReport(report)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      }));
};

export const registerLoanTransfer = (report) => (dispatch) => {
  dispatch(registerLoanTransferBegin());
  return new Promise((resolve, reject) =>
    paymentsApi
      .registerLoanTransfer(report)
      .then((response) => {
        resolve(response);
        dispatch(registerLoanTransferSuccess(response));
      })
      .catch((error) => {
        reject(error);
        dispatch(registerLoanTransferError(error));
      }));
};

export const registerPaymentTransfer = (report) => (dispatch) => {
  dispatch(registerPaymentTransferReportBegin());
  return new Promise((resolve, reject) =>
    paymentsApi
      .registerPaymentReport(report)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      }));
};

export const startTransactionRecord = () => (dispatch) => {
  dispatch(startTransactionRecordBegin());
  return new Promise((resolve, reject) =>
    paymentsApi
      .startTransactionRecord()
      .then((response) => {
        resolve(response);
        dispatch(startTransactionRecordSuccess(response));
      })
      .catch((error) => {
        reject(error);
        dispatch(startTransactionRecordError(error));
      }));
};

export const getTransactionRecord = (transactionRecordId) => (dispatch) => {
  dispatch(getTransactionRecordBegin());
  return new Promise((resolve, reject) =>
    paymentsApi
      .getTransactionRecord(transactionRecordId)
      .then((response) => {
        resolve(response);
        dispatch(getTransactionRecordSuccess(response.data.result));
      })
      .catch((error) => {
        reject(error);
        dispatch(getTransactionRecordError(error));
      }));
};
