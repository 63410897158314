// constants
import { GET_SUMMARY_ADMINISTRATIVE_EXPENSES_SUCCESS, GET_ADMINISTRATIVE_EXPENSES_SETTINGS_SUCCESS } from '../actions/settings';

// state
import { settingsInitialState } from '../../config/state/initialState';

function settingsReducer(state = settingsInitialState, action) {
  // TODO: The case listener could be improved or refactor with a regexep middleware or something like that. 🐱‍💻
  switch (action.type) {
    case GET_SUMMARY_ADMINISTRATIVE_EXPENSES_SUCCESS:
      return { ...state, administrativeExpensesPercent: action.payload.administrativeExpensesPercent };
    case GET_ADMINISTRATIVE_EXPENSES_SETTINGS_SUCCESS:
      return { ...state, administrativeExpensesSettings: { ...action.payload.settings } };
    default:
      return state;
  }
}

export default settingsReducer;
