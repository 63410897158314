// vendors
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// actions
import {
  getIsLastPaymentReport,
  registerLastPaymentTransfer,
  registerLoanTransfer,
  registerPaymentTransfer,
} from '../../redux/actions/payments';

// components
import GmUploadPicture from '../../components/upload-picture';
import GmImageCrop from '../../components/image-crop';
import GmKarmaReview from '../../components/karma-review';
import GmSuperModal from '../../components/super-modal';

// constants
import { routes } from '../../config/routes';
import { userIsGodfather, userIsGodson, MAX_IMG_SIZE } from '../../config/constants';

// icons
import reviewPositive from '../../static/images/review-positive.png';

// utils
import {
  getAsImg,
  getBase64,
  getCroppedImg
} from '../../utils';

// styles
import { useStyles } from './styles';

const paymentsReportLocalState = {
  amount: '',
  comment: '',
  date: moment().format('YYYY-MM-DD'),
  review: {
    comment: '',
    ranking: 0,
  },
  sharedImage: {
    content: '',
    name: '',
    type: '',
  },
  sharedFiles: [],
  voucher: {
    content: '',
    name: '',
    type: '',
  },
};

const PaymentsReport = () => {
  const [error, setError] = useState(false);
  const [sharedFiles, setSharedFiles] = useState([]);
  const [imgToCrop, setImgToCrop] = useState(null);
  const [uploadingImg, setUploadingImg] = useState(false);
  const [uploadingSharedImg, setUploadingSharedImg] = useState(false);
  const [uploadingLastPaymentImg, setUploadingLastPaymentImg] = useState(false);
  const [isImgTooBig, setIsImgTooBig] = useState(false);
  const [promissoryNoteMessage, togglePromissoryNoteMessage] = useState(false);

  const { auth, payments: { isLastPayment } } = useSelector((state) => state);

  const classes = useStyles({ isLastPayment });
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const isGodfather = userIsGodfather(auth.userInfo.role);
  const isGodson = userIsGodson(auth.userInfo.role);

  useEffect(() => {
    if (isGodson) {
      dispatch(getIsLastPaymentReport(params.transactionId));
    }
  }, [params.transactionId]);

  const {
    state: {
      amount,
      amountToDisplay,
      hasPromissoryNote,
      transactionId,
      userImage,
      userName,
    },
  } = useLocation();

  useEffect(() => {
    if (!hasPromissoryNote) {
      togglePromissoryNoteMessage(true);
    }
  }, [hasPromissoryNote]);

  const [report, setReport] = useState({ ...paymentsReportLocalState, amount, amountToDisplay });
  const title = isGodfather ? 'Pagar a' : 'Retribuir el buen karma';
  const summary = isGodfather ? `Envia un mensaje de motivación a ${userName}*` : '*¿Cómo va tu historia?*';
  const paymentAmountLabel = isGodfather
    ? 'Por el momento solo puedes reportar pagos por el valor acordado.'
    : 'Por el momento solo puedes reportar pagos por el valor acordado; si has pagado varias cuotas debes reportarlas una a la vez.';

  let summaryLabel = isGodfather ? 'Escribe tus buenos deseos' : `Cuéntale a ${userName} tu progreso`;

  if (isLastPayment) {
    summaryLabel = `¿Qué hiciste con el dinero que ${userName} te prestó?*`;
  }

  const reviewPositiveIcon = (
    <img
      alt="review-positive"
      className={classes.reviewPositiveIcon}
      src={reviewPositive}
    />
  );

  const handleFieldOnChange = ({ target: { name, value } }) => {
    setError((!value || value === '') || (!report.voucher));
    setReport((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOnImgUpload = async ({
    content,
    name,
    type,
    size
  }, isLastPaymentImg = false) => {
    if (size > MAX_IMG_SIZE) {
      setIsImgTooBig(true);
      setImgToCrop({});
      setUploadingImg(true);
      return;
    }

    const img = await getAsImg({ content, name, type });

    setImgToCrop({
      name,
      type,
      content: img.src
    });
    setUploadingLastPaymentImg(isLastPaymentImg);
    setUploadingImg(!isLastPaymentImg);
    setIsImgTooBig(false);
  };

  const handleOnFileUpload = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (file.size > MAX_IMG_SIZE) {
        setIsImgTooBig(true);
        setImgToCrop({});
        setUploadingImg(true);
        return;
      }

      const data = await getBase64(file);

      setReport((prevState) => ({
        ...prevState,
        voucher: {
          id: file.id || null,
          content: data,
          name: file.name,
          type: file.type,
          size: file.size
        }
      }));
      setIsImgTooBig(false);
    }
  };

  const handleOnSharedFilesUpload = async ({
    content,
    name,
    type,
    size
  }) => {
    if (size > MAX_IMG_SIZE) {
      setIsImgTooBig(true);
      setImgToCrop({});
      setUploadingImg(true);
      return;
    }

    const img = await getAsImg({ content, name, type });

    setImgToCrop({
      content: img.src,
      id: `${Date.now()}-${name}`,
      name,
      type
    });
    setUploadingSharedImg(true);
    setIsImgTooBig(false);
  };

  const handleOnDeleteSharedFile = (sharedFileId) => {
    const updatedSharedFiles = sharedFiles.filter(({ id }) => id !== sharedFileId);
    setSharedFiles(updatedSharedFiles);
  };

  const handleOnReview = (ranking) =>
    setReport((prevState) => ({ ...prevState, review: { ...prevState.review, ranking } }));

  const handleOnReviewComment = ({ target: { value } }) => {
    setReport((prevState) => ({ ...prevState, review: { ...prevState.review, comment: value } }));
    setError(value === '');
  };

  // TODO: Voucher should not be loaded with a Date in the future, only a Date prior to the day loaded.
  const handleOnSendReport = () => {
    const newReport = {
      amount: numeral(report.amount).value(),
      comment: report.comment,
      date: report.date,
      transactionId,
      voucher: { ...report.voucher },
    };

    if (isGodfather) {
      dispatch(registerLoanTransfer(newReport))
        .then(() => history.push({
          pathname: routes.paymentsDetail.replace(':transactionId', params.transactionId),
          state: {
            transactionId: params.transactionId,
            isFromPaymentsReport: true
          }
        }));
    }

    if (isGodson) {
      if (isLastPayment) {
        dispatch(registerLastPaymentTransfer({
          payment: { ...newReport, sharedImage: { ...report.sharedImage }, voucher: { ...report.voucher } },
          review: { ...report.review }
        })).then(() => history.push({
          pathname: routes.paymentsDetail.replace(':transactionId', params.transactionId),
          state: {
            transactionId: params.transactionId,
            isFromPaymentsReport: true
          }
        }));
      } else {
        dispatch(registerPaymentTransfer({ ...newReport, sharedFiles }))
          .then(() => history.push({
            pathname: routes.paymentsDetail.replace(':transactionId', params.transactionId),
            state: {
              transactionId: params.transactionId,
              isFromPaymentsReport: true
            }
          }));
      }
    }

    if (report.voucher.content === '') {
      setError(true);
    }
  };

  const onConfirmImageCrop = async (crop, image) => {
    const img = await getCroppedImg(crop, image, imgToCrop);
    const data = await getBase64(img);
    const imageReturned = {
      id: imgToCrop.id || null,
      content: data,
      name: img.name,
      type: img.type
    };

    if (imgToCrop.id) {
      setSharedFiles([...sharedFiles, imageReturned]);
    } else if (uploadingLastPaymentImg) {
      setReport((prevState) => ({ ...prevState, sharedImage: imageReturned }));
    }

    setImgToCrop(null);
    setUploadingImg(false);
    setUploadingSharedImg(false);
    return true;
  };

  const onCancelImageCrop = () => {
    setImgToCrop(null);
    setUploadingImg(false);
    setUploadingSharedImg(false);
    setReport((prevState) => ({ ...prevState, voucher: prevState.voucher || null }));
    setReport((prevState) => ({ ...prevState, sharedImage: prevState.sharedImage || null }));
  };

  return (
    <>
      {imgToCrop && uploadingImg && (
        <GmImageCrop
          aspectRatio="16:9"
          isImgTooBig={isImgTooBig}
          onCancelCrop={onCancelImageCrop}
          onConfirmCrop={onConfirmImageCrop}
          src={imgToCrop.content}
          visible={imgToCrop}
        />
      )}
      {imgToCrop && uploadingLastPaymentImg && (
        <GmImageCrop
          aspectRatio="16:9"
          isImgTooBig={isImgTooBig}
          onCancelCrop={onCancelImageCrop}
          onConfirmCrop={onConfirmImageCrop}
          src={imgToCrop.content}
          visible={imgToCrop}
        />
      )}
      {imgToCrop && uploadingSharedImg && (
        <GmImageCrop
          aspectRatio="1:1"
          isImgTooBig={isImgTooBig}
          onCancelCrop={onCancelImageCrop}
          onConfirmCrop={onConfirmImageCrop}
          src={imgToCrop.content}
          visible={imgToCrop}
        />
      )}
      <div className={classes.paymentsReportPage}>
        <GmSuperModal
          confirmLabel="Entendido"
          fullSize={false}
          headerTitle="Aún no se ha firmado el pagaré"
          isContentMedia
          onClose={() => togglePromissoryNoteMessage(false)}
          onConfirm={() => togglePromissoryNoteMessage(false)}
          visible={promissoryNoteMessage && isGodfather}
        >
          <Grid alignContent="center" container justify="center">
            <Grid item xs="12">
              En el momento no puedes realizar el desembolso de este credito ya que el pagaré que lo respalda aun no ha sido firmado por ambas partes.
            </Grid>
          </Grid>
        </GmSuperModal>
        <Paper className={classes.header} classes={{ root: classes.paperRoot }}>
          <Grid className={classes.subHeader} container alignContent="stretch">
            <Grid item xs={4}>
              <ArrowBackIcon
                className={classes.arrowBackIcon}
                onClick={() => history.goBack()}
              />
            </Grid>
            <Grid item xs={4} zeroMinWidth>
              <Typography className={classes.title} noWrap>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={4} />
          </Grid>
          <Grid className={classes.avatarContainer} container>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <img
                className={classes.avatar}
                alt="avatar"
                src={userImage}
              />
            </Grid>
            <Grid item xs={4} />
          </Grid>
          <Grid container>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Typography className={classes.name}>
                {userName}
              </Typography>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Paper>
        <Grid className={classes.form} container direction="column">
          <Grid className={classes.formItem} container direction="row">
            <Grid item>
              {reviewPositiveIcon}
            </Grid>
            <Grid item>
              <Typography className={classes.formLabel}>
                Detalles del pago
              </Typography>
            </Grid>
          </Grid>
          <TextField
            className={classes.textField}
            disabled
            helperText={paymentAmountLabel}
            id="amount"
            style={{ marginBottom: 36 }}
            label="Monto transferido"
            name="amount"
            required
            value={numeral(report.amountToDisplay).format('$0,0')}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            defaultValue={report.date}
            error={error && !report.date}
            helperText={error && !report.date ? 'Campo requerido' : ''}
            id="date"
            label="Fecha de la transferencia"
            name="date"
            onChange={handleFieldOnChange}
            required
            type="date"
            value={report.date.value}
            variant="outlined"
          />
          <Grid item xs={12}>
            <label
              htmlFor="fileUpload"
              className={classes.imgProfile}
            >
              <input
                accept="image/*,.pdf"
                id="fileUpload"
                onChange={handleOnFileUpload}
                style={{ display: 'none' }}
                type="file"
              />
              {!report?.voucher?.content ? (
                <Button component="div" className={classes.voucher}>
                  <Grid container>
                    <Grid item xs={12}>
                      <AttachFileIcon fontSize="large" className={classes.avatarIcon} />
                    </Grid>
                    <Grid item xs={12}>
                      <span className="image-text">Cargar comprobante*</span>
                      {error ? <span className={classes.alert}>Debes cargar el comprobante de pago</span> : null}
                    </Grid>
                  </Grid>
                </Button>
              ) : (
                <Button component="div" className={classes.voucher}>
                  <Grid container>
                    <Grid item xs={12}>
                      <AttachFileIcon fontSize="large" className={classes.avatarIcon} />
                    </Grid>
                    <Grid item xs={12}>
                      <span className="image-text">{report?.voucher?.name}</span>
                    </Grid>
                  </Grid>
                </Button>
              )}
            </label>
          </Grid>
          {isLastPayment
            ? (
              <>
              <Grid
                className={classes.formItem}
                container
                direction="column"
                justify="center"
              >
                <Grid item>
                  <Typography className={classes.lastPaymentTitle}>
                    ¡Este es tu último pago!
                  </Typography>
                  <Typography className={classes.lastPaymentDescription}>
                  Haz llegado a tu último pago y nos gustaría conocer más sobre tu experiencia
                  </Typography>
                </Grid>
              </Grid>
              </>
            )
            : null}
          <Grid className={classes.formItem} container direction="row">
            {!isLastPayment
              ? (
                <Grid item>
                  {reviewPositiveIcon}
                </Grid>
              )
              : null}
            {!isLastPayment
              ? (
                <Grid item>
                  <Typography className={classes.formLabel}>
                    {summary}
                  </Typography>
                </Grid>
              )
              : null}
          </Grid>
          {isLastPayment && (
            <Grid container direction="row">
              <Grid item>
                {reviewPositiveIcon}
              </Grid>
              <Grid item>
                <Typography className={classes.formLabelArea}>
                  {summaryLabel}
                </Typography>
              </Grid>
            </Grid>
          )}
          <TextField
            className={classes.textFieldArea}
            error={error && (!report.comment || report.comment === '')}
            helperText={(!report.comment || report.comment === '') ? '*Campo requerido' : ''}
            id="comment"
            multiline
            name="comment"
            onChange={handleFieldOnChange}
            required
            rows={5}
            value={report.comment}
            variant="outlined"
          />
          {isGodson && isLastPayment
            ? (
              <>
                <Grid container direction="row">
                  <Grid item>
                    {reviewPositiveIcon}
                  </Grid>
                  <Grid item>
                    <Typography className={classes.formLabel}>
                      Comparte una foto
                    </Typography>
                  </Grid>
                </Grid>
                <GmUploadPicture
                  callback={(imgObj) => handleOnImgUpload(imgObj, isLastPayment)}
                  customStyle={{
                    backgroundImage: `url("data:${report?.sharedImage?.type};base64,${report?.sharedImage?.content}")`,
                    marginBottom: 36,
                    width: '100%',
                  }}
                  isImage={report?.sharedImage?.name || report?.sharedImage?.content}
                  nameDocFile="sharedImage"
                  text="Sube una foto emotiva, dando agradecimiento a quién decidió apoyarte"
                />
                <Typography className={classes.lastPaymentTitle}>
                  {`¡Califica a ${userName}!`}
                </Typography>
                <Grid className={classes.karmaReview} container justify="space-between">
                  <Grid item>
                    <Typography className={classes.lastpaymentFormLabel}>
                      ¿Cuál es tu calificación?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <GmKarmaReview onReview={handleOnReview} />
                  </Grid>
                </Grid>
                <TextField
                  className={classes.textField}
                  error={error && !report.review.comment}
                  helperText={(!report.review.comment || report.review.comment === '') ? '*Campo requerido' : ''}
                  id="comment"
                  label="¿Cómo te sentiste con su apoyo?"
                  multiline
                  name="comment"
                  onChange={handleOnReviewComment}
                  required
                  rows={5}
                  value={report.review.comment}
                  variant="outlined"
                />
              </>
            )
            : null}
          {isGodson && !isLastPayment
            ? (
              <>
                <Grid container justify="flex-start">
                  <Grid className={classes.formItem} container direction="row">
                    <Grid item>
                      <Typography className={classes.lastFormLabel}>
                        {`Comparte con ${userName} algunas fotos de tu progreso`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <GmUploadPicture
                    callback={handleOnSharedFilesUpload}
                    customStyle={{
                      height: 100,
                      marginBottom: 36,
                      width: 100,
                    }}
                    nameDocFile="sharedFile"
                    text=""
                  />
                  {sharedFiles?.map((sharedFile) => (
                    <GmUploadPicture
                      customStyle={{
                        backgroundImage: `url("data:${sharedFile?.type};base64,${sharedFile?.content}")`,
                        height: 100,
                        marginBottom: 36,
                        width: 100,
                      }}
                      key={sharedFile.id}
                      onClick={() => handleOnDeleteSharedFile(sharedFile.id)}
                      showCameraIcon={false}
                    />
                  ))}
                </Grid>
              </>
            )
            : null}
          <Grid item>
            <Button
              className={classes.sendButton}
              color="primary"
              disabled={error || !hasPromissoryNote || !report.comment}
              onClick={handleOnSendReport}
              variant="contained"
            >
              ENVIAR
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PaymentsReport;
