// packages
import { makeStyles } from '@material-ui/core/styles';

const commonStoryText = {
  color: 'rgba(0, 0, 0, 0.78)',
  display: 'flex',
  flexDirection: 'column',
  fontSize: 15,
  lineHeight: '1.5',
  textAlign: 'left',
  margin: '0 20px'
};

const boldText = {
  ...commonStoryText,
  fontWeight: 'bold'
};

const useStyles = makeStyles({
  gmStoryCardHeaderContainer: {
    marginTop: 40,
    paddingLeft: 20,
  },
  gmStoryCardCarouselFinalizedStoryBadge: {
    float: 'right',
  },
  gmStoryCardContentFinalizedStoryBadge: {
    paddingTop: 5
  },
  gmStoryCardCarouselContainer: {
    marginBottom: 15,
    '& .rec-slider-container': {
      margin: 1,
    },
    '& .rec-pagination button': {
      backgroundColor: '#A0A0A0',
      boxShadow: 'none',
      width: 6,
      height: 6,
      opacity: '0.4',
      marginTop: 15
    },
    '& .rec-pagination .rec-dot_active': {
      width: 6,
      opacity: 1,
      height: 6,
    },
  },
  gmStoryCardHeaderActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 5,
    '& div': {
      marginRight: 10
    }
  },
  gmStoryCardHeaderStoryCreatorProfilePicture: {
    background: '#c2c2c2',
    borderRadius: '50%',
    float: 'left',
    height: 50,
    width: 50,
  },
  gmStoryCardHeaderStoryCreatorFullName: {
    color: '#0F1E36',
    float: 'left',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    marginLeft: 10,
    '& div': {
      display: 'flex'
    },
    '& strong': {
      color: '#0F1E36',
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: '-20px',
    },
  },
  gmStoryCardHeaderStoryCreatorKarmaScore: {
    marginLeft: 10,
    marginTop: -10
  },
  gmStoryCardCarouselItem: {
    backgroundSize: 'cover',
    margin: '10px 10px 0px',
    height: 238,
    width: '100%',
  },
  gmStoryContentTitle: {
    ...boldText,
  },
  gmStoryContentDate: {
    ...commonStoryText,
    fontSize: 11,
    fontWeight: 400,
  },
  gmStoryContentAmmount: {
    ...commonStoryText,
    fontWeight: 'bold'
  },
  gmStoryContentDescription: {
    ...commonStoryText,
    fontSize: 15
  },
  gmStoryContentLoanTime: {
    ...commonStoryText,
  },
  gmStoryContentMakeProposalButton: {
    backgroundColor: '#1DCAD3',
    color: '#fff',
    float: 'left',
    marginTop: 0,
    marginLeft: 18,
    padding: '1px 5px 1px 5px',
    fontSize: 12,
    marginBottom: 10,
    boxShadow: 'none'
  }
});

export default useStyles;
