// vendors
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import numeral from 'numeral';
import { useSelector } from 'react-redux';

// components
import DisbursementButton from './disbursement-button';
import FeeAndInsuranceButton from './fee-insurance-button';
import LoanConfirmation from './loan-confirmation';
import Notification from './notification';
import PaymentConfirmation from './payment-confirmation';
import Proposal from './proposal';
import ProposalAccepted from './proposal-accepted';
import GmSimpleMessage from './render-simple-message';
import SignPromissoryNote from './sign-promissory-note';
import SystemMessage from './system-message';

// constants
import {
  MESSAGE_TYPES,
  PAYMENT_TYPES,
} from '../../config/constants';

// styles
import { useStyles } from './styles';

const renderTransactionDetails = ({
  classes,
  date,
  debtToDate,
  isConfirmed = false,
  isGodson,
  messageType,
  paymentType = null,
  value,
}) => {
  const getPaymentContainerClass = () => {
    switch (paymentType) {
      case PAYMENT_TYPES.loan:
        return isConfirmed ? classes.paymentDetailItem : classes.loanDetailItem;
      case PAYMENT_TYPES.loanPayment:
        return isConfirmed ? classes.loanDetailItem : classes.paymentDetailItem;
      default:
        return classes.paymentDetailItem;
    }
  };

  return (
    <Paper className={classes.messageContainer} elevation={0}>
      <Grid className={classes.proposalContentContainer} item xs={12}>
        <Grid container justify="center">
          <Grid className={getPaymentContainerClass()} item xs={12}>
            <Typography className={classes.loanAmmountHeader} component="div">
              {isGodson ? 'Haz recibido un desembolso por' : 'Monto pagado'}
            </Typography>
            <Typography className={classes.proposalNumber} component="div">
              {numeral(value).format('$0,0')}
            </Typography>
          </Grid>
          <Grid className={getPaymentContainerClass()} item xs={12}>
            <Typography className={classes.itemTitle} component="div">
              {isGodson ? 'Fecha desembolso' : 'Fecha de pago'}
            </Typography>
            <Typography className={classes.itemTextContent} component="div">
              {moment(date).format('DD MMMM YYYY')}
            </Typography>
          </Grid>
          {messageType === MESSAGE_TYPES.paymentConfirmation ? (
            <Grid className={getPaymentContainerClass()} item xs={12}>
              <Typography className={classes.itemTitle} component="div">
                Valor total adeudado
              </Typography>
              <Typography className={classes.itemTextContent} component="div">
                {numeral(debtToDate).format('$0,0')}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
};

const GmMessage = ({
  hasPromissoryNote,
  hasTransaction,
  isLoanPaymentConfirmed,
  messageValue,
  onAddStory,
  onGoToStories,
  onLastPaymentConfirmation,
  onLoanConfirmation,
  onPaymentConfirmation,
  onRefer,
  toggleShowAUCOSdkIframe,
}) => {
  const classes = useStyles();
  const {
    auth, history, history: {
      historyProgress,
      historyInfo,
      messages,
      myHistories
    }
  } = useSelector((state) => state);

  const filteredProposalAcceptedMsg = messages.filter((message) => message.type === 8);
  const filteredLoanConfirmationAndPaymentConfirmedMsg = messages.filter((message) => (message.type === 5 || message.type === 9));
  const loanAmountForDisbursement = filteredProposalAcceptedMsg.map((message) => JSON.parse(message.content).LoanAmount);
  const isLoanDisbursed = filteredLoanConfirmationAndPaymentConfirmedMsg.length > 0;
  const isProposalAccepted = filteredProposalAcceptedMsg.length > 0;

  const renderMessage = (MessageType) => {
    const handleOnPaymentConfirmation = (data) => {
      if (MessageType === MESSAGE_TYPES.lastPaymentConfirmation) {
        onLastPaymentConfirmation(data);
      } else {
        onPaymentConfirmation(data);
      }
    };

    switch (MessageType) {
      case MESSAGE_TYPES.loanConfirmation:
        return (
          <LoanConfirmation
            auth={auth}
            classes={classes}
            eventMessage={JSON.parse(messageValue.content)}
            isLoanPaymentConfirmed={isLoanPaymentConfirmed}
            messageId={messageValue.id}
            messageType={MessageType}
            onLoanConfirmation={onLoanConfirmation}
            renderDetails={renderTransactionDetails}
          />
        );
      case MESSAGE_TYPES.notification:
        return (
          <Notification
            classes={classes}
            eventMessage={JSON.parse(messageValue.content)}
            messageId={messageValue.id}
          />
        );
      case MESSAGE_TYPES.paymentComment:
        const content = JSON.parse(messageValue.content);
        const postedByCurrentUser = auth.userInfo.userId.toString() === messageValue.postByUserId.toString();
        const paymentCommentClassName = postedByCurrentUser
          ? classes.listMessageReceiver
          : classes.listMessageSender;

        return (
          <ListItem key={messageValue.id} className={paymentCommentClassName}>
            <Typography component="div">{content.Comment}</Typography>
          </ListItem>
        );
      case MESSAGE_TYPES.lastPaymentConfirmation:
      case MESSAGE_TYPES.paymentConfirmation:
      case MESSAGE_TYPES.paymentConfirmed:
        return (
          <PaymentConfirmation
            auth={auth}
            classes={classes}
            confirmButtonVisible={auth.userInfo.userId.toString() !== messageValue.postByUserId.toString()
              && (messageValue.type === MESSAGE_TYPES.paymentConfirmation || messageValue.type === MESSAGE_TYPES.lastPaymentConfirmation)}
            eventMessage={JSON.parse(messageValue.content)}
            histories={myHistories}
            isConfirmed={messageValue.type === MESSAGE_TYPES.paymentConfirmed}
            messageId={messageValue.id}
            messageType={MessageType}
            onAddStory={onAddStory}
            onGoToStories={onGoToStories}
            onPaymentConfirmation={handleOnPaymentConfirmation}
            onRefer={onRefer}
            renderDetails={renderTransactionDetails}
          />
        );

      case MESSAGE_TYPES.proposal:
        return (
          <Proposal
            auth={auth}
            eventMessage={JSON.parse(messageValue.content)}
            hasTransaction={hasTransaction}
            isProposalAccepted={isProposalAccepted}
            messageId={messageValue.id.toString()}
            messageValue={messageValue}
          />
        );
      case MESSAGE_TYPES.proposalAccepted:
        return (
          <ProposalAccepted
            auth={auth}
            classes={classes}
            eventMessage={JSON.parse(messageValue.content)}
            isAccepted
            messageId={messageValue.id}
          />
        );
      case MESSAGE_TYPES.signPromissoryNote:
        return (
          <SignPromissoryNote
            classes={classes}
            hasPromissoryNote={hasPromissoryNote}
            messageId={messageValue.id}
            onSign={toggleShowAUCOSdkIframe}
            visible
          />
        );
      case MESSAGE_TYPES.systemMessage:
        return (
          <SystemMessage
            classes={classes}
            message={JSON.parse(messageValue.content).Message}
            messageId={messageValue.id}
            messageValue={messageValue}
            visible
          />
        );
      case MESSAGE_TYPES.disbursementButton:
        return (
          <DisbursementButton
            amount={historyInfo.financingValue}
            classes={classes}
            eventMessage={JSON.parse(messageValue.content)}
            hasPromissoryNote={hasPromissoryNote}
            historyState={history}
            loanAmount={loanAmountForDisbursement}
            disabled={isLoanDisbursed}
          />
        );
      // TODO: this fix needs to be analized once that new flux has been organized
      // TODO: this fix needs to be analized once that new flux has been organized
      case MESSAGE_TYPES.feeAndInsuranceButton:
        return (
          <FeeAndInsuranceButton
            classes={classes}
            eventMessage={JSON.parse(messageValue.content)}
            historyState={history}
            disabled={historyProgress > 3}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {((messageValue.postByUserId === null) || (auth.userInfo.userId?.toString() === messageValue.postByUserId?.toString()))
        && messageValue.type === MESSAGE_TYPES.message && (
          <ListItem key={messageValue.id} className={classes.listMessageReceiver}>
            <GmSimpleMessage messageValue={messageValue} />
          </ListItem>
      )}
      {((messageValue.postByUserId === null) || (auth.userInfo.userId?.toString() !== messageValue.postByUserId?.toString()))
        && messageValue.type === MESSAGE_TYPES.message && (
          <ListItem key={messageValue.id} className={classes.listMessageSender}>
            <GmSimpleMessage messageValue={messageValue} />
          </ListItem>
      )}
      {renderMessage(messageValue.type)}
    </>
  );
};

export default GmMessage;
