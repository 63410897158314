import { registerInitialState } from '../../config/state/initialState';
import {
  CHECK_CODE_BEGIN,
  CHECK_CODE_ERROR,
  CHECK_CODE_SUCCESS,
  GET_CITY_SUCCESS,
  GET_COUNTRY_BEGIN,
  GET_COUNTRY_ERROR,
  GET_COUNTRY_PHONE_CODES_BEGIN,
  GET_COUNTRY_PHONE_CODES_ERROR,
  GET_COUNTRY_PHONE_CODES_SUCCESSS,
  GET_COUNTRY_SUCCESS,
  GET_STATE_BEGIN,
  GET_STATE_ERROR,
  GET_STATE_SUCCESS,
  GET_USER_INFO_BEGIN,
  GET_USER_INFO_ERROR,
  GET_USER_INFO_SUCCESS,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  SET_ROLE_BEGIN,
  SET_ROLE_ERROR,
  SET_ROLE_SUCCESS,
} from '../action-types';

import {
} from '../actions/register';

function registerReducer(state = registerInitialState, action) {
  switch (action.type) {
    case CHECK_CODE_SUCCESS:
      return {
        ...state,
        error: !action.payload.isInvitationCodeOk,
        isInvitationCodeOk: action.payload.isInvitationCodeOk,
        emailAddress: action.payload.emailAddress,
        invitationCode: action.payload.invitationCode,
        password: action.payload.password,
      };

    case CHECK_CODE_BEGIN:
    case GET_COUNTRY_PHONE_CODES_BEGIN:
    case SET_ROLE_BEGIN:
      return {
        ...state,
        error: false,
      };

    case CHECK_CODE_ERROR:
      return {
        ...state,
        error: true,
        isInvitationCodeOk: false,
      };

    case GET_COUNTRY_BEGIN:
    case GET_STATE_BEGIN:
    case GET_USER_INFO_BEGIN:
      return {
        ...state,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        registerOK: action.payload.canLogin,
        error: false,
      };

    case GET_COUNTRY_ERROR:
    case GET_COUNTRY_PHONE_CODES_ERROR:
    case GET_STATE_ERROR:
    case GET_USER_INFO_ERROR:
    case REGISTER_ERROR:
    case SET_ROLE_ERROR:
      return {
        ...state,
        error: true,
      };

    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        countries: action.payload.countries,
        error: false,
      };

    case GET_STATE_SUCCESS:
      return {
        ...state,
        states: action.payload.states.map((state) =>
          ({ ...state, name: state.name.replace('Department', '').trim() })),
        error: false,
      };
    case GET_CITY_SUCCESS:
      return {
        ...state,
        cities: action.payload.cities,
        error: false,
      };

    case SET_ROLE_SUCCESS:
      return {
        ...state,
        error: false,
        setRoleOK: action.payload.setRoleOK,
        userInfo: { ...state.userInfo, role: action.payload.role },
      };

    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload.userInfo,
        error: false,
      };

    case GET_COUNTRY_PHONE_CODES_SUCCESSS:
      return {
        ...state,
        phoneCodes: action.payload.map((country) => ({ name: `${country.name} +${country.phoneCode}`, value: country.phoneCode })),
      };

    default:
      return state;
  }
}

export default registerReducer;
