// vendors
import React, { useState } from 'react';
import { ListItem } from '@material-ui/core';

// components
import GmNewCalculator from '../loan-calculator/new-calculator';
import GmSimpleMessage from './render-simple-message';

// constants
import {
  INTEREST_RATE,
  PAYMENT_FREQUENCY,
  PROPOSAL_STATUS,
  userIsGodfather,
  userIsGodson,
} from '../../config/constants';

// styles
import { useStyles } from './styles';

const Proposal = ({
  auth,
  eventMessage,
  hasTransaction,
  isProposalAccepted,
  messageId,
  messageValue,
}) => {
  const classes = useStyles();
  const isGodson = userIsGodson(auth.userInfo.role);
  const isGodfather = userIsGodfather(auth.userInfo.role);

  const [isInsuranceIncluded, setIsInsuranceIncluded] = useState(eventMessage.IsInsuranceIncluded ?? true);

  const interestRate = INTEREST_RATE.find(({ value }) => value === eventMessage.InterestRate);

  const MODIFIED_PROPOSAL_LABEL_MAPPER = {
    1: 'Propuesta editada',
    0: 'Propuesta eliminada',
    3: 'Propuesta deshabilitada',
  };

  if (eventMessage.ProposalStatus?.toString() !== PROPOSAL_STATUS.enabled.toString()) {
    return (
      <ListItem key={messageValue.id} className={classes.proposalModified}>
        <GmSimpleMessage optionalContent={MODIFIED_PROPOSAL_LABEL_MAPPER[eventMessage.ProposalStatus]} messageValue={messageValue} />
      </ListItem>
    );
  }

  return (
    <>
      <GmNewCalculator
        frequency={eventMessage.Frequency}
        frequencyLabel={PAYMENT_FREQUENCY[eventMessage.Frequency]}
        hasTransaction={hasTransaction}
        interestRate={interestRate.value}
        interestRateLabel={`${interestRate.label} ${eventMessage.InterestRatePercent * 100}%`}
        isCalculatorOpenFromActionBar={false}
        isGodfather={isGodfather}
        isGodson={isGodson}
        isProposalAccepted={isProposalAccepted}
        isInsuranceIncluded={isInsuranceIncluded}
        handleIsInsuranceIncludedChanged={() => setIsInsuranceIncluded((prevState) => !prevState)}
        loanAmountWithFeeAndAdministrativeExpenses={eventMessage.LoanAmount + eventMessage.InterestRateValue}
        loanAmountWithoutAnyFees={eventMessage.loanAmountWithoutExpensive}
        loanFeeInsurance={eventMessage.LifeInsurangeValue}
        loanFeeService={eventMessage.ServiceCostValue}
        loanTime={eventMessage.LoanTime}
        loanWithAdministrativeExpenses={eventMessage.LoanAmount}
        messageId={messageId}
        messageValue={messageValue}
        proposalId={eventMessage.Id}
        proposalStatus={eventMessage.ProposalStatus}
        quotaValue={eventMessage.Value}
        showAsSummary
        totalInterestEarned={eventMessage.InterestRateValue}
        visible
      />
    </>
  );
};

export default Proposal;
