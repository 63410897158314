// vendors
import React from 'react';
import Grid from '@material-ui/core/Grid';

// styles
import { useStyles } from './styles';

const GmMiniStoryImagesCarousel = ({
  images
}) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.gmMiniStoryImagesCarousel}
      container
      direction="row"
      justify="center"
    >
      {images?.map((image, index) => (
        <Grid item key={`gmMiniStoryImage-${index}`}>
          <img className={classes.gmMiniStoryImage} src={image} />
        </Grid>
      ))}
    </Grid>
  );
};

export default GmMiniStoryImagesCarousel;
