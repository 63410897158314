// vendors
import Grid from '@material-ui/core/Grid';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import {
  getCities,
  getCountries,
  getStates,
  onRegister
} from '../../redux/actions/register';
import { getAUCOValidation, getIsAUCOValidationEnabled } from '../../redux/actions/AUCO';
import { getAllBanks } from '../../redux/actions/bank';

// components
import GmBankAccountInformation from './steps/bank-account-information';
import GmSuperModal from '../../components/super-modal';
import GmVerifyAccount from '../account-verify';
import Step1 from './steps/step1';
import GmSupportPage from '../support';
import Step2 from './steps/step2';

// env
import env from '../../config/env';

// constants
const INITIAL_COMMON_DATA = {
  documentImg: null,
  imgValidations: {
    documentImg: false,
    profileImg: false,
    showWarning: false,
  },
  profileImg: null,
};

export const WizardFormPage = () => {
  const AUCOiFrameRef = useRef(null);

  const dispatch = useDispatch();
  const {
    common: { loading },
    register: {
      cities,
      countries,
      emailAddress,
      invitationCode,
      password,
      registerOK,
      states
    }
  } = useSelector((state) => state);
  const { featureFlags } = useSelector((state) => state.common);

  const [answers, setAnswers] = useState({});
  const [bankList, setBankList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [showAUCOiFrame, setShowAUCOiFrame] = useState(false);
  const [commonData, setCommonData] = useState(INITIAL_COMMON_DATA);
  const [AUCOValidationData, setAUCOValidationData] = useState(false);
  const [AUCOValidationError, setAUCOValidationError] = useState(false);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  useEffect(() => {
    if (featureFlags?.isAUCOValidationEnabled) {
      const onAUCOSdkLoaded = ({ data, origin }) => {
        if (!origin.includes('auco.ai')) {
          // Origin must be from auco.ai url
          return;
        }

        if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'ready') && data.ready) {
          // Set credentials when AUCO Sdk is ready
          AUCOiFrameRef.current?.contentWindow?.postMessage(
            {
              keyPublic: env.AUCO_PUK_KEY,
              code: AUCOValidationData.code
            },
            origin
          );
        }

        if (data && Object.hasOwnProperty.call(data, 'similarity')) {
          if (data.similarity > 85) {
            setShowAUCOiFrame(false);
            handleNext();
            return;
          }

          if (data.similarity < 85) {
            setAUCOValidationError(true);
            setShowAUCOiFrame(false);
          }
        }
      };

      window.addEventListener('message', onAUCOSdkLoaded);

      return () => {
        // Remove event listeners when component is unmounted
        window.removeEventListener('message', onAUCOSdkLoaded);
      };
    }

    return () => {};
  }, [featureFlags?.isAUCOValidationEnabled]);

  useEffect(() => {
    dispatch(getCountries());
    if (activeStep === 3) {
      dispatch(getIsAUCOValidationEnabled());
    }

    if (activeStep === 4) {
      dispatch(getAllBanks()).then(({ items }) => setBankList(items));
    }
  }, [activeStep]);

  const handleData = ({ data = {}, isLastStep = false, isAuthValidation = false }) => {
    const stateAnswers = { ...answers, ...data };
    setAnswers(stateAnswers);

    if (isAuthValidation && !featureFlags?.isAUCOValidationEnabled) {
      handleNext();
      return;
    }

    if (isAuthValidation && featureFlags?.isAUCOValidationEnabled) {
      dispatch(getAUCOValidation({
        // TODO:
        // country code is hard coded due the countries EP isn't returning the codes
        country: 'CO', // stateAnswers.countryCode,
        identification: stateAnswers.id,
        type: stateAnswers.docType === 'Pasaporte'
          ? 'PA'
          : stateAnswers.docType
      })).then(({ data }) => {
        setAUCOValidationData(data);
        setShowAUCOiFrame(true);
      });
      return;
    }

    if (isLastStep) {
      dispatch(onRegister({
        registerData: stateAnswers,
        additionalData: {
          emailAddress,
          invitationCode,
          password
        }
      })).then(() => handleNext());
    }
  };

  const getLocationData = (step, value) => {
    if (step === 'state') {
      dispatch(getStates(value));
    } else {
      dispatch(getCities(value));
    }
  };

  const getStepContent = (stepIndex) => {
    if (showAUCOiFrame) {
      return null;
    }

    switch (stepIndex) {
      // Component to verify account with a phone number and a code sent to it
      case 0:
        return (
          <GmVerifyAccount
            handleData={handleData}
            handleNext={handleNext}
          />
        );

      case 1:
        // Component to gather user personal information such as name, birth date, profile picture, document id, etc...
        return (
          <Step1
            answers={answers}
            commonData={commonData}
            handleBack={handleBack}
            handleData={handleData}
            handleNext={handleNext}
            setCommonData={setCommonData}
          />
        );

      case 2:
        // Component to gather location info about user, such as country, department, city, address, etc...
        return (
          <Step2
            answers={answers}
            cities={cities}
            countries={countries}
            getLocationData={getLocationData}
            handleBack={handleBack}
            handleData={handleData}
            handleNext={handleNext}
            loading={loading}
            states={states}
          />
        );
      // Support component
      case 3:
        return (
          <GmSupportPage
            handleNext={handleNext}
            visible={registerOK}
          />
        );
      case 4:
        return (<GmBankAccountInformation bankList={bankList} visible />);
      default:
        return null;
    }
  };

  return (
    <Grid container direction="column" style={{ overflowX: 'hidden' }}>
      {getStepContent(activeStep)}
      <GmSuperModal
        fullSize={false}
        headerTitle="Hubo un error validando tu identidad"
        onClose={() => setAUCOValidationError(false)}
        onConfirm={() => setAUCOValidationError(false)}
        visible={AUCOValidationError}
      >
        Hubo un error validando tu identidad, por favor, intentalo de nuevo.
      </GmSuperModal>
      <iframe
        allow="camera;microphone"
        height="100%"
        id="SDK"
        ref={AUCOiFrameRef}
        src={`${env.aucoSdkBaseUrl}/?id=${new Date().getTime()}`}
        style={{ display: showAUCOiFrame && featureFlags?.isAUCOValidationEnabled ? 'block' : 'none' }}
        title="AUCO"
        width="100%"
      />
    </Grid>
  );
};

export default WizardFormPage;
