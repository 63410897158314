// actions
import {
  GET_IS_LAST_PAYMENT_REPORT_SUCCESS,
  GET_PAYMENT_DETAIL_ACTIONS_SUCCESS,
  GET_PAYMENT_DETAIL_SUCCESS,
  GET_TRANSACTION_RECORD_SUCCESS,
} from '../action-types';

// state
import { paymentsInitialState } from '../../config/state/initialState';

function paymentsReducer(state = paymentsInitialState, action) {
  switch (action.type) {
    case GET_IS_LAST_PAYMENT_REPORT_SUCCESS:
      return { ...state, isLastPayment: action.payload };
    case GET_PAYMENT_DETAIL_SUCCESS:
      return { ...state, detail: action.payload.detail };
    case GET_PAYMENT_DETAIL_ACTIONS_SUCCESS:
      return { ...state, actions: action.payload.actions.items };
    case GET_TRANSACTION_RECORD_SUCCESS:
      return { ...state, transactionRecord: action.payload };
    default:
      return state;
  }
}

export default paymentsReducer;
