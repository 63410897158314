// Vendors
import Button from '@material-ui/core/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';
// components
import GmSvgIcon from '../../static/svg';
import { routes } from '../../config/routes';

const DisbursementButton = ({
  amount,
  classes,
  disabled,
  eventMessage,
  hasPromissoryNote,
  historyState,
  loanAmount
}) => {
  const history = useHistory();
  const handleOnRedirectToReportPayment = () => {
    const pathname = routes.paymentsReport.replace(':transactionId', eventMessage.TransactionId);

    history.push({
      pathname,
      state: {
        amount: loanAmount,
        amountToDisplay: amount,
        hasPromissoryNote,
        transactionId: eventMessage.TransactionId,
        userImage: historyState.chatsHistory.user.profilePicture,
        userName: historyState.chatsHistory.user.fullName
      },
    });
  };

  return (
    <Button
      className={classes.signPromissoryNoteButton}
      color="primary"
      disabled={disabled}
      fullWidth
      onClick={handleOnRedirectToReportPayment}
      startIcon={<GmSvgIcon enabled icon="payment-report" />}
      variant="contained"
    >
      REALIZAR DESEMBOLSO
    </Button>
  );
};

export default DisbursementButton;
