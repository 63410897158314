// constants
import env from '../env';

// http
import request from '../request';

const registerApi = {
  getCountry() {
    return request(
      `${env.mainBaseUrl}/services/app/Location/GetAllCountries?MaxResultCount=999999999`,
      'get'
    );
  },

  getCountryPhoneCodes() {
    return request(
      `${env.mainBaseUrl}/services/app/Country/GetPhoneCodes?MaxResultCount=999999999&SkipCount=0`,
      'get'
    );
  },

  getStates(stateId) {
    return request(
      `${env.mainBaseUrl}/services/app/Location/GetAllStates?CountryId=${stateId}&MaxResultCount=999999999`,
      'get'
    );
  },

  getCities(cityId) {
    return request(
      `${env.mainBaseUrl}/services/app/Location/GetAllCities?StateId=${cityId}&MaxResultCount=999999999`,
      'get'
    );
  },

  setRole(role) {
    return request(
      `${env.mainBaseUrl}/services/app/UserProfileService/SetRole?name=${role}`,
      'post'
    );
  },

  register({ registerData, additionalData }) {
    const payload = {
      address: registerData?.address,
      birthDay: registerData?.birthDate,
      childsNumber: registerData?.childCounter,
      cityId: registerData?.cityId,
      civilStatus: registerData?.maritalStatus,
      countryId: registerData?.countryId,
      documentNumber: registerData?.id,
      documentPicture: registerData?.docImgFile
        ? {
          name: registerData?.docImgFile.name,
          type: registerData?.docImgFile.type,
          content: registerData?.docImgFile.content,
        }
        : null,
      documentType: registerData?.docType,
      emailAddress: additionalData.emailAddress,
      haveChilds: Boolean(parseInt(registerData?.childCounter, 10)),
      invitationCode: additionalData.invitationCode,
      lastName: registerData?.lastName,
      name: registerData?.name,
      password: additionalData.password,
      profilePicture: registerData?.profilePicture
        ? {
          name: registerData?.profilePicture.name,
          type: registerData?.profilePicture.type,
          content: registerData?.profilePicture.content,
        }
        : null,
      personalPhrase: registerData?.personalPhrase,
      phoneNumber: registerData?.phoneNumber,
      profession: registerData?.profession,
      sex: registerData?.gender,
      stateId: registerData?.stateId,
      surname: registerData?.lastName,
      userName: additionalData.emailAddress,
    };
    return request(`${env.mainBaseUrl}/services/app/Account/RegisterUser`, 'post', payload);
  },

  updateProfile(userData, userInfo) {
    const data = {
      id: userInfo.id,
      userName: userInfo.userName,
      name: userInfo.name,
      surname: userInfo.surname,
      emailAddress: userInfo.emailAddress,
      lastName: userData.lastName,
      birthDay: userInfo.birthDay,
      documentType: userData.docType,
      documentNumber: userInfo.documentNumber,
      civilStatus: userData.maritalStatus,
      profession: userData.profession,
      haveChilds: true,
      childsNumber: userData.childCounter,
      personalPhrase: userData.personalPhrase,
      address: userData.address,
      countryId: userInfo.countryId,
      stateId: userInfo.stateId,
      cityId: userInfo.cityId,
      sex: userData.gender,
    };

    return request(
      `${env.mainBaseUrl}/services/app/UserProfileService/updateProfile`,
      'put',
      data
    );
  },

  changePasswordRequest(data) {
    return request(`${env.mainBaseUrl}/services/app/User/ChangePassword`, 'post', data);
  },

  saveProfileImg(data) {
    return request(
      `${env.mainBaseUrl}/services/app/UserProfileService/UploadProfilePicture`,
      'post',
      data
    );
  },

  saveDocumentImg(data) {
    return request(
      `${env.mainBaseUrl}/services/app/UserProfileService/UploadProfileDocument`,
      'post',
      data
    );
  },
};

export default registerApi;
