// constants
import env from '../env';

// http
import request from '../request';

const profileVisitingApi = {
  getUserProfileById({ userId }) {
    return request(`${env.mainBaseUrl}/services/app/UserProfileService/GetProfileById?Id=${userId}`, 'get');
  },

  getUserStoriesByUserId({
    maxResultCount,
    skipCount,
    userId,
  }) {
    return request(`${env.mainBaseUrl}/services/app/History/UserHistories`, 'post', {
      maxResultCount,
      skipCount,
      userId,
    });
  },
};

export default profileVisitingApi;
