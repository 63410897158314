// vendors
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

// actions
import { clearFogotPasswordOk, onForgotPassword } from '../../redux/actions/user';

// images
import Image from '../../static/images/forgot.png';

// config
import { routes } from '../../config/routes';

// components
import GmLogo from '../../components/logo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#ffbe38',
    backgroundPosition: 'center',
    display: 'grid',
    height: '45vh',
    gridTemplateRows: 'auto 1fr auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  forgotPassword: {
    marginTop: '3vh',
    width: '90%',
  },
  buttonProgress: {
    color: '#1DCAD3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  startButton: {
    position: 'relative',
    '& .MuiButton-label': {
      color: 'white',
      fontWeight: 500,
    },
  },
  textField: {
    width: '90%',
  },
  center: {
    textAlign: 'center',
  },
  textFollowInstruction: {
    textAlign: 'center',
    marginTop: '10vh',
  },
}));

const validationSchema = yup.object({
  emailAddress: yup
    .string('Enter your email')
    .email('ingresa un email valido')
    .required('Ingresa tu email'),
});

function ForgotPassword({
  clearFogotPasswordOk,
  error,
  loading,
  onForgotPassword,
  sendPasswordOk
}) {
  const history = useHistory();
  const classes = useStyles();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailAddress: '',
    },
    validationSchema,
    onSubmit: ({ emailAddress }) => {
      onForgotPassword({ emailAddress }).then(() => {
        setTimeout(() => {
          clearFogotPasswordOk();
          history.push(routes.login);
        }, 3500);
      });
    },
  });

  const sanitizeEmail = () => {
    const sanitizedEmail = formik.values.emailAddress.toLowerCase().trim();
    formik.setFieldValue('emailAddress', sanitizedEmail);
  };

  return (
    <>
      <Paper className={classes.root}>
        <Container>
          <Grid container justify="center" className={classes.center}>
            <Grid item xs={12} className={classes.forgotPassword}>
              <GmLogo />
            </Grid>
            <Grid item xs={12} className={classes.forgotPassword}>
              <Typography variant="body2" gutterBottom>
                ¿Olvidaste tu contraseña?
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Grid container justify="center" className={classes.body}>
        <Grid item className={classes.forgotPassword}>
          {!sendPasswordOk && (
            <Typography variant="body2" gutterBottom>
              Ingresa tu correo electrónico a continuación y te enviaremos un link para restablecer
              tu contraseña
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6} className={classes.center}>
          {!sendPasswordOk ? (
            <form onSubmit={formik.handleSubmit}>
              <TextField
                variant="outlined"
                id="emailAddress"
                name="emailAddress"
                label="Email"
                value={formik.values.emailAddress}
                onBlur={sanitizeEmail}
                onChange={formik.handleChange}
                className={clsx(classes.margin, classes.textField)}
                error={error}
                helperText={error
                  ? 'Por favor ingresa el correo electrónico con el cual creaste tu cuenta, el correo que has ingresado no es válido.'
                  : ''}
              />
              <Grid item className={classes.startButton}>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.sendButton}
                >
                  ENVIAR
                </Button>
              </Grid>
            </form>
          ) : (
            <Grid item xs={12} md={6} className={classes.textFollowInstruction}>
              <Typography variant="body2" gutterBottom>
                Por favor revisa tu correo y sigue las instrucciones, en un momento serás redirigido al inicio de sesión.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ common, auth }) => ({
  loading: common.loading,
  sendPasswordOk: auth.sendPasswordOk,
  error: auth.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearFogotPasswordOk,
      onForgotPassword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
