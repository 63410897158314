// vendors
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// actions
import { getUnreadMessages } from '../../redux/actions/user';
import { getWhoHasReferredMe, getWhoHasImReferred, getHistoriesByUser } from '../../redux/actions/createHistory';
import { getUserProfileById, getUserStoriesByUserId } from '../../redux/actions/profile-visiting';
import { saveProfileImg } from '../../redux/actions/register';
import { getAllBanks, getUserBankInformation, setShowBankInfoPage } from '../../redux/actions/bank';

// components
import GmImageCrop from '../../components/image-crop';
import GmKarmaReview from '../../components/karma-review';
import SideMenu from '../../components/side-menu';
import GmStoriesList from './stories-list';
import GmBankAccountInformation from '../wizard-form/steps/bank-account-information';
// constants
import { routes } from '../../config/routes';

// environment
import { MAX_IMG_SIZE } from '../../config/constants';

// utils
import {
  getAsImg,
  getBase64,
  getCroppedImg
} from '../../utils';

// styles
import { useStyles } from './styles';

const MainPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const historyHook = useHistory();
  const {
    auth,
    auth: { showBankInfoPage },
    common: { loading },
    history,
    profileVisiting,
  } = useSelector((state) => state);
  const { userId: profileVisitingUserId } = useParams();
  const [bankList, setBankList] = useState([]);
  const [imgToCrop, setImgToCrop] = useState(null);
  const [isImgTooBig, setIsImgTooBig] = useState(false);
  const [uploadingImg, setUploadingImg] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const routeChange = (path) => historyHook.push(path);

  useEffect(() => {
    if (profileVisitingUserId) {
      dispatch(getUserProfileById({ userId: profileVisitingUserId }));
      dispatch(getUserStoriesByUserId({
        maxResultCount: 20,
        skipCount: 0,
        userId: profileVisitingUserId,
      }));

      dispatch(getWhoHasReferredMe({ userId: profileVisitingUserId }));
      dispatch(getWhoHasImReferred({ userId: profileVisitingUserId }));
      setProfilePicture(profileVisiting.profile.profilePicture);
    }
  }, [profileVisitingUserId, profileVisiting.profile.profilePicture]);

  useEffect(() => {
    if (!profileVisitingUserId) {
      dispatch(getUnreadMessages());
      setProfilePicture(auth.userInfo.profilePicture);
      dispatch(getHistoriesByUser());
      dispatch(getWhoHasReferredMe({ userId: auth.userInfo.userId }));
      dispatch(getWhoHasImReferred({ userId: auth.userInfo.userId }));
    }
  }, [auth.userInfo.profilePicture, profileVisitingUserId]);

  useEffect(() => {
    if (!profileVisitingUserId && !showBankInfoPage) {
      dispatch(getUserBankInformation()).then((response) => {
        dispatch(setShowBankInfoPage(response && !response.length));
      });
    }

    if (!profileVisitingUserId && showBankInfoPage) {
      dispatch(getAllBanks()).then(({ items }) => setBankList(items));
    }
  }, [profileVisitingUserId, showBankInfoPage]);

  const onChangeImage = async (event) => {
    if (!profileVisitingUserId && event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (file.size > MAX_IMG_SIZE) {
        setIsImgTooBig(true);
        setImgToCrop({});
        setUploadingImg(true);
        return;
      }

      const data = await getBase64(file);
      const img = await getAsImg({
        content: data,
        name: file.name,
        type: file.type
      });

      setImgToCrop({
        base64Data: data,
        content: img.src,
        name: img.id,
        type: img.type
      });
      setUploadingImg(true);
      setIsImgTooBig(false);
    }
  };

  const onConfirmImageCrop = async (crop, image) => {
    const croppedImg = await getCroppedImg(crop, image, imgToCrop);
    const croppedImgData = await getBase64(croppedImg);
    const imageReturned = {
      content: croppedImgData,
      name: croppedImg.name,
      type: croppedImg.type.replace('image/', '')
    };

    dispatch(saveProfileImg(imageReturned));
    setProfilePicture(`data:${croppedImg?.type};base64,${croppedImgData}`);
    setImgToCrop(null);
    setUploadingImg(false);
  };

  const onCancelImageCrop = () => {
    setImgToCrop(null);
    setProfilePicture(profilePicture || null);
    setUploadingImg(false);
  };

  const fullUserName = !profileVisitingUserId
    ? auth.userInfo.fullName ?? `${auth.userInfo.name} ${auth.userInfo.lastName}`
    : profileVisiting.profile.fullName;

  const personalPhrase = !profileVisitingUserId
    ? auth.userInfo.personalPhrase
    : profileVisiting.profile.personalPhrase;

  const storiesToDisplay = !profileVisitingUserId && history.myHistories
    ? history.myHistories
    : profileVisiting.stories;

  if (!loading && showBankInfoPage) {
    return <GmBankAccountInformation bankList={bankList} visible />;
  }

  return (
    <>
      {imgToCrop && uploadingImg && (
        <GmImageCrop
          aspectRatio="1:1"
          isImgTooBig={isImgTooBig}
          onCancelCrop={onCancelImageCrop}
          onConfirmCrop={onConfirmImageCrop}
          src={imgToCrop.content}
          visible={imgToCrop}
        />
      )}
      <Paper className={classes.root}>
        <Grid className={classes.sideMenu}>
          <SideMenu
            disabledReferringButtons={!profileVisitingUserId}
            isProfileVisiting={profileVisitingUserId}
            whoHasImReferredList={history.whoHasImReferred}
            whoRefferedMeList={history.referMe}
            hideCalendarButton
          />
        </Grid>
        <Grid container direction="column" justify="center" style={{ paddingBottom: 20 }}>
          <div role="button" style={{ marginTop: -40 }}>
            <label htmlFor="profile-picture">
              {!profileVisitingUserId
                ? (
                  <input
                    accept="image/*"
                    disabled={profileVisitingUserId}
                    id="profile-picture"
                    onChange={onChangeImage}
                    style={{ display: 'none' }}
                    type="file"
                  />
                )
                : null}
              <Button disabled={profileVisitingUserId} component="div" onClick={() => routeChange(routes.profile)}>
                {profilePicture
                  ? (
                    <>
                      <img src={profilePicture} alt="profile" className={classes.imgProfile} />
                      {!profileVisitingUserId ? <CreateIcon className={classes.editIcon} /> : null}
                    </>
                  )
                  : <CreateIcon className={classes.editIcon} />}
              </Button>
            </label>
          </div>
          <Typography gutterBottom variant="subtitle2">
            {fullUserName}
          </Typography>
          <Typography gutterBottom variant="body1">
            {personalPhrase}
          </Typography>
          <GmKarmaReview
            className={classes.gmKarmaReview}
            readOnly
            value={!profileVisitingUserId
              ? auth.userInfo.karma
              : profileVisiting.profile.karma}
          />
        </Grid>
      </Paper>
      <GmStoriesList
        canCreateNewHistory={history?.canCreateNewHistory}
        loading={loading}
        profileVisitingUserId={profileVisitingUserId}
        stories={storiesToDisplay}
        userRole={auth?.userInfo?.role}
        visible
      />
    </>
  );
};

export default MainPage;
