// constants
import env from '../env';

// http
import request from '../request';

const storySvc = {
  createHistory() {
    return request(`${env.mainBaseUrl}/services/app/History/Create`, 'post', {});
  },

  getCreateStoryQuestions() {
    return request(`${env.mainBaseUrl}/services/app/HistoryQuestionsType/GetQuestionsTypes`, 'get');
  },

  getCreateStoryPhrases() {
    return request(`${env.mainBaseUrl}/services/app/Phrases/GetRandomPhrases`, 'get');
  },

  updateStory(data) {
    return request(`${env.mainBaseUrl}/services/app/History/Update`, 'put', data);
  },

  updateStoryQuestionPictures(data) {
    return request(`${env.mainBaseUrl}/services/app/History/UpdateQuestion`, 'put', data);
  },
};

export default storySvc;
