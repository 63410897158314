// vendors
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  listIcon: {
    marginTop: 6
  },
  listText: {
    marginLeft: -27
  }
});
