// vendors
import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// material ui
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

// constants
import { routes } from '../../config/routes';

// styles
import { useStyles } from './styles';

// constants
const recentActivityContentType = {
  HISTORY_LIKE: 1,
  TRANSACTION: 0
};

const Activities = ({ activities }) => {
  const classes = useStyles();
  const history = useHistory();

  const formatAndRenderDescription = (str, fullName) => (
    <Typography
      className={classes.primaryText}
      component="span"
      variant="body2"
    >
      {str.split(' ').map((word, index) => word.match(/{(\d+)}/g) ? (
        <Typography
          className={classes.name}
          component="span"
          key={index}
          variant="body2"
        >
          {`${fullName} `}
        </Typography>
      ) : `${word} `)}
    </Typography>
  );

  const renderActivityText = (description, fullName, date) => (
    <Grid container>
      <Grid item xs={10}>
        {formatAndRenderDescription(description, fullName)}
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={classes.secondaryText}
          component="span"
          variant="body2"
        >
          {moment(date).format('DD MMMM YYYY, h:mm a')}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      {activities && activities.length
        ? (
          <List className={classes.root}>
            {activities.map((activity, index) => {
              const {
                type,
                dataSource,
                date,
                description,
                thumbnail,
                user
              } = activity;

              let parsedDataSource = null;

              const { HISTORY_LIKE } = recentActivityContentType;
              const { fullName, profilePicture } = user;

              // TODO: This try-catch is made here due there's some cases where dataSource is an empty string such as ""
              try {
                parsedDataSource = JSON.parse(dataSource);
              } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
              }

              const redirectToStory = () => parsedDataSource.HistoryId
                ? history.push(routes.history.replace(':id', parsedDataSource.HistoryId))
                : Function.prototype;

              return (
                <ListItem key={index} alignItems="center">
                  <ListItemAvatar>
                    <Avatar sizes="42" alt={fullName} src={profilePicture} />
                  </ListItemAvatar>
                  <ListItemText secondary={renderActivityText(description, fullName, date)} />
                  <ListItemSecondaryAction
                    className={classes.pictureContainer}
                    onClick={redirectToStory}
                  >
                    {type === HISTORY_LIKE
                      ? (
                        <img
                          alt={thumbnail}
                          className={classes.defaultPicture}
                          src={thumbnail}
                        />
                      ) : (
                        <IconButton size="small" edge="end" aria-label="forward">
                          <ArrowForwardIosIcon />
                        </IconButton>
                      )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Grid item xs={12} md={12} className={classes.noActivitiesText}>
            <Typography variant="body2" gutterBottom>
              Parece que no hay actividades recientes
            </Typography>
          </Grid>
        )}
    </>
  );
};

export default Activities;
