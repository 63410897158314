// vendors
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';

// images
import paymentConfirmImage from '../../static/svg/payment-confirm-image';

// styles
import { useStyles } from './styles';

const GmSuperModal = ({
  children = null,
  closeLabel = 'Cancelar',
  confirmLabel = 'Confirmar',
  fullSize = true,
  headerImage = paymentConfirmImage,
  headerTitle = '',
  isConfirmButtonEnabled = true,
  isContentMedia = false,
  onClose = Function.prototype,
  onConfirm = Function.prototype,
  showConfirmButton = true,
  visible = false
}) => {
  const classes = useStyles();

  if (!visible) {
    return null;
  }

  const renderTypeOfSingleContent = () => isContentMedia ? (
    <CardMedia className={classes.contentMedia}>
      {children}
    </CardMedia>
  ) : (
    <CardContent className={classes.singleContent}>
      {children}
    </CardContent>
  );

  return (
    <Grid
      alignItems="center"
      className={classes.modalContainer}
      container
      justify="center"
    >
      <Card classes={{ root: classes.cardContainer }} className={fullSize ? classes.fullSizeSuperModal : classes.superModal}>
        {fullSize ? (
          <CardActionArea>
            <CloseIcon className={classes.closeIcon} onClick={onClose} />
            {headerTitle && (
              <div className={classes.headerTitleContainer}>
                <Typography className={classes.headerTitle}>
                  {headerTitle}
                </Typography>
              </div>
            )}
            {headerImage && (
              <CardMedia
                className={classes.headerImage}
                image={headerImage}
              />
            )}
          </CardActionArea>
        ) : renderTypeOfSingleContent()}
        {!isContentMedia
          ? (
            <CardContent className={classes.content}>
              {children}
            </CardContent>
          )
          : null}
        <CardActions>
          <Grid alignContent="center" container justify="center">
            {isContentMedia && (
              <Button
                className={classes.closeButton}
                color="secondary"
                onClick={onClose}
              >
                {closeLabel}
              </Button>
            )}
            {showConfirmButton ? (
              <Button
                disabled={!isConfirmButtonEnabled}
                className={classes.confirmButton}
                color="primary"
                onClick={onConfirm}
                variant="contained"
              >
                {confirmLabel}
              </Button>
            ) : null}
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default GmSuperModal;
