// http
import env from '../env';
import request from '../request';

const supportApi = {
  sendMessage: ({ message, subject }) =>
    request(`${env.mainBaseUrl}/services/app/Support/SendMessage`, 'post', { message, subject })
};

export default supportApi;
