// vendors
import * as yup from 'yup';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { connect, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { routes } from '../../config/routes';
import BlueLogo from '../../components/blue-logo';
import { pngItems } from './auxiliary-pqrs-text-and-icons';

// actions
import { sendSupportMessage } from '../../redux/actions/support';

// components
import { useStyles } from './styles';

const validationSchema = yup.object({
  reason: yup.string('Enter your email').required('Ingresa tu email'),
  text: yup.string('Enter your email').required('Ingresa tu email'),
});

function PQRS({ loading }) {
  const [mode, setMode] = useState('pqrs');
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [expandedContentIndex, setExpandedContentIndex] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleItemClick = (index) => {
    if (index === selectedItemIndex) {
      setSelectedItemIndex(null);
      setExpandedContentIndex(null);
    } else {
      setSelectedItemIndex(index);
      setExpandedContentIndex(null);
    }
  };

  const handleContentExpand = (itemIndex, contentIndex) => {
    if (expandedContentIndex === contentIndex) {
      setExpandedContentIndex(null);
    } else {
      setExpandedContentIndex(contentIndex);
      setSelectedItemIndex(itemIndex);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: '',
      text: '',
    },
    validationSchema,
    onSubmit: () => {
      dispatch(sendSupportMessage({ message: formik.values.text, subject: formik.values.reason }));
      setMode('sendOk');
    },
  });

  const handleGoBackToProfile = () => {
    history.push(routes.main.replace(':userId?', ''));
  };

  return (
    <>
      <Container className={classes.root}>
        <ArrowBackIcon onClick={handleGoBackToProfile} className={classes.arrowBackIcon} />
        <Grid container justify="center" className={classes.center}>
          <div className={classes.brandLogo}>
            <BlueLogo />
          </div>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 'bold' }} variant="h1" gutterBottom>
              PQRS
            </Typography>
            <Typography variant="body1" gutterBottom>
              Aquí podrás encontrar respuesta a tus preguntas más frecuentes o contactarnos para quejas, reclamos o sugerencias
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.pqrsIconsContainer}>
          {pngItems.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <div
                onClick={() => handleItemClick(index)}
                role="button"
                style={{
                  padding: '10px',
                  cursor: 'pointer',
                }}
              >
                <img className={classes.pqrsImage} src={item.image} alt={item.title} />
                <Typography variant="body2">{item.title}</Typography>
              </div>
              {selectedItemIndex === index && (
                <Grid item xs={12}>
                  {item.content.map((content, contentIndex) => (
                    <Accordion
                      key={`${index}-${contentIndex}`}
                      expanded={selectedItemIndex === index && expandedContentIndex === contentIndex}
                      onChange={() => handleContentExpand(index, contentIndex)}
                    >
                      <AccordionSummary
                        aria-controls={`panel${index}-${contentIndex}-content`}
                        expandIcon={<ExpandMoreIcon />}
                        id={`panel${index}-${contentIndex}-header`}
                      >
                        <Typography style={{ textAlign: 'left', paddingLeft: '5px' }}>{content.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                        <Typography>
                          <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                            {pngItems[selectedItemIndex]?.content[expandedContentIndex]?.texts.map((text, textIndex) => (
                              <li key={textIndex} style={{ textAlign: 'left', marginBottom: '5px' }}>{text}</li>
                            ))}
                          </ul>
                        </Typography>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
        <Grid container justify="center" className={classes.body}>
          <Grid item className={classes.forgotPassword}>
            {mode === 'pqrs' && (
              <>
                <Grid item className={classes.startButton}>
                  <Button
                    className={classes.whatsappButton}
                    color="primary"
                    disabled={loading}
                    onClick={() => window.open('http://api.whatsapp.com/send?phone=573216247225', 'blank')}
                    variant="contained"
                  >
                    CONTACTAR
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12} md={6} className={clsx(classes.withoutLabel, classes.center)}>
        {mode === 'request' && (
          <form onSubmit={formik.handleSubmit}>
            <TextField
              variant="outlined"
              id="reason"
              name="reason"
              label="Asunto"
              value={formik.values.reason}
              onChange={formik.handleChange}
              className={clsx(classes.margin, classes.textField)}
              error={formik.touched.reason && Boolean(formik.errors.reason)}
              helperText={formik.touched.reason && formik.errors.reason}
            />
            <TextField
              variant="outlined"
              id="text"
              name="text"
              multiline
              rows={4}
              label="PQRS"
              value={formik.values.text}
              onChange={formik.handleChange}
              className={clsx(classes.margin, classes.textField)}
              error={formik.touched.text && Boolean(formik.errors.text)}
              helperText={formik.touched.text && formik.errors.text}
            />
            <Grid item className={classes.startButton}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                type="submit"
                className={classes.sendButton}
              >
                ENVIAR
              </Button>
            </Grid>
          </form>
        )}
        {mode === 'sendOk' && (
          <Grid item xs={12} md={6} className={classes.textFollowInstruction}>
            <Typography className={classes.boldTitle} gutterBottom>
              ¡Mensaje enviado con éxito!
            </Typography>
            <Typography>
              A través de tu correo electrónico nos estaremos comunicando contigo. Esperamos resolver tus dudas en el menor tiempo posible
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}

const mapStateToProps = ({ auth, common }) => ({
  loading: common.loading,
  sendPasswordOk: auth.sendPasswordOk,
  error: auth.error,
});

export default connect(mapStateToProps, null)(PQRS);
