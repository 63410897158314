// vendors
import React from 'react';

const CalculatorDisabled = (
  <>
    <path d="M60.38 0H0.27002V75.44H60.38V0Z" fill="#7D7D7D" />
    <path d="M53.54 6.84998H7.10004V21.81H53.54V6.84998Z" fill="#CFD2D7" />
    <path d="M16.12 29.71H7.10004V36.95H16.12V29.71Z" fill="#CFD2D7" />
    <path d="M28.6 29.71H19.58V36.95H28.6V29.71Z" fill="#CFD2D7" />
    <path d="M41.07 29.71H32.05V36.95H41.07V29.71Z" fill="#CFD2D7" />
    <path d="M53.54 29.71H44.52V36.95H53.54V29.71Z" fill="#CFD2D7" />
    <path d="M16.12 39.87H7.10004V47.11H16.12V39.87Z" fill="#CFD2D7" />
    <path d="M28.6 39.87H19.58V47.11H28.6V39.87Z" fill="#CFD2D7" />
    <path d="M41.07 39.87H32.05V47.11H41.07V39.87Z" fill="#CFD2D7" />
    <path d="M53.54 39.87H44.52V47.11H53.54V39.87Z" fill="#CFD2D7" />
    <path d="M16.12 50.03H7.10004V57.27H16.12V50.03Z" fill="#CFD2D7" />
    <path d="M28.6 50.03H19.58V57.27H28.6V50.03Z" fill="#CFD2D7" />
    <path d="M41.07 50.03H32.05V57.27H41.07V50.03Z" fill="#CFD2D7" />
    <path d="M53.54 50.03H44.52V57.27H53.54V50.03Z" fill="#CFD2D7" />
    <path d="M16.12 60.1899H7.10004V67.4299H16.12V60.1899Z" fill="#CFD2D7" />
    <path d="M28.6 60.1899H19.58V67.4299H28.6V60.1899Z" fill="#CFD2D7" />
    <path d="M41.07 60.1899H32.05V67.4299H41.07V60.1899Z" fill="#CFD2D7" />
    <path d="M53.54 60.1899H44.52V67.4299H53.54V60.1899Z" fill="#CFD2D7" />
  </>
);

export default CalculatorDisabled;
