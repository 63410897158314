import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    boxShadow: 'none',
  },
  margin: {
    margin: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '90%',
    background: '#e8f0fe',
  },
  errorText: {
    fontSize: 11,
    display: 'block',
    color: '#ff0000',
    position: 'relative',
    left: '12%',
    marginBottom: 20,
    textAlign: 'left'
  },
  center: {
    marginBottom: 30,
    marginTop: 27,
    textAlign: 'center',
  },
  divider: {
    width: '90%',
    marginTop: 5
  },
  logIn: {
    marginTop: -15,
  },
  startButton: {
    position: 'relative',
    marginTop: 15,
    '& .MuiButton-label': {
      color: 'white',
      fontWeight: 500,
    },
    padding: '4px 40px'
  },
  form: {
    input: {
      padding: 11.5,
      marginTop: 0
    },
  },
  haveAccount: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 14,
    marginTop: 30,
    marginBottom: 15,
  },
  register: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 14,
    marginTop: 15,
  },
  haveAccountLink: {
    fontWeight: 600,
    cursor: 'pointer',
    color: '#1DCAD3',
    marginLeft: 5,
    textDecoration: 'none',
  },
  buttonProgress: {
    color: '#1DCAD3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  headerTitle: {
    fontSize: 16,
    marginTop: 10,
    fontFamily: 'Open Sans'
  },
  termsAndConditionsText: {
    textAlign: 'left'
  },
  visibilityButton: {
    background: '#e8f0fe',

  }
}));

export { useStyles };
