// vendors
import React from 'react';

const PentagonDisableReview = (
  <>
    <path d="M10.0052 0L0.010376 7.25127L3.83109 19H16.1792L19.9999 7.25127L10.0052 0Z" fill="#DDDCDC" />
  </>
);

export default PentagonDisableReview;
