// vendors
import React from 'react';

const AlertDisabled = (
  <>
    <path d="M6.55005 39.96C6.55005 26.1 17.79 14.86 31.65 14.86C45.51 14.86 56.75 26.1 56.75 39.96" fill="#C5C4C4" />
    <path d="M63.02 38.67H0.280029V49.86H63.02V38.67Z" fill="#DEDDDD" />
    <path d="M33.34 0H29.96V10.77H33.34V0Z" fill="#DEDDDD" />
    <path d="M58.5059 9.85584L50.8904 17.4713L53.2804 19.8613L60.8958 12.2458L58.5059 9.85584Z" fill="#DEDDDD" />
    <path d="M4.84801 9.85032L2.45801 12.2407L10.0746 19.8561L12.4646 17.4658L4.84801 9.85032Z" fill="#DEDDDD" />
  </>
);

export default AlertDisabled;
