const customColors = {
  lightGrey1: '#F1F1F1',
  lightText: '#BABABA',
  primary: '#1DCAD3',
  red: '#ff0000',
  secondary: '#19857b',
  white: '#FFFFFF',
};

export default {
  primary: {
    main: customColors.primary,
  },
  secondary: {
    main: customColors.secondary,
  },
  error: {
    main: customColors.red,
  },
  background: {
    default: customColors.white,
    lightGrey1: customColors.lightGrey1,
  },
  lightText: {
    main: customColors.lightText
  }
};
