// vendors
import React from 'react';

const FineKarma = (
  <svg width="64" height="60" viewBox="0 0 79 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.5188 0L0 27.8861L15.1013 73H63.8987L79 27.8861L39.5188 0Z" fill="#47647C" />
    <path d="M43.2753 32.8499H35.7622V59.5681H43.2753V32.8499Z" fill="white" />
    <path d="M51.9905 35.2952H27.0095L39.5187 23.1405L51.9905 35.2952Z" fill="white" />
  </svg>
);

export default FineKarma;
