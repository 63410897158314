// vendors
import React from 'react';

const MessageEnabled = (
  <>
    <path d="M22.7537 0H0V18H22.7537V0Z" fill="#F93549" />
    <path d="M22.7537 0H0L11.3768 8.72942L22.7537 0Z" fill="#FFC600" />
  </>

);

export default MessageEnabled;
