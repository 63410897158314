// vendors
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';

// components
import GmSvgIcon from '../../static/svg';

// constants
import { ROLES } from '../../config/constants';

const LastPaymentConfirmation = ({
  auth,
  classes,
  eventMessage,
  histories,
  isConfirmed,
  messageId,
  onAddStory,
  onGoToStories,
  onRefer
}) => {
  if (auth.userInfo.role === ROLES.godson && !isConfirmed) {
    return null;
  }

  const isLastHistoryClosed = histories[0].status === 5;

  return (
    <Paper className={classes.paper} elevation={0} key={messageId}>
      <Grid
        container
        justify="center"
        className={classes.lastPaymentDetailContainer}
        spacing={0}
      >
        <Grid item className={classes.proposalAvatar}>
          <Avatar
            alt="gm-user-avatar"
            className={classes.proposalAvatarImg}
            src={auth.userInfo.role === ROLES.godson ? auth.userInfo.profilePicture : eventMessage.User.ProfilePicture}
          />
        </Grid>
        <Paper className={classes.messageContainer} elevation={0}>
          <Grid className={classes.proposalContentContainer} item xs={12}>
            <Grid container justify="center">
              <Grid className={classes.lastPaymentDetailItem} item xs={12}>
                <Typography className={classes.proposalNumber} component="div">
                  Felicitaciones
                </Typography>
                <Typography className={classes.proposalNumber} component="div">
                  {auth.userInfo.fullName}
                </Typography>
              </Grid>
              <Grid className={classes.lastPaymentDetailItem} item xs={12}>
                <Typography className={classes.lastPaymentDetailHeader} component="div">
                  {auth.userInfo.role === ROLES.godson ? 'Realizaste tu último pago' : 'Has confirmado el último pago.'}
                </Typography>
                <Typography component="div">
                  Además de acumular buen karma, ya tienes nuevos beneficios
                </Typography>
              </Grid>
              <Grid className={classes.nextActionContainer} container item xs={12}>
                <Grid item xs={2} />
                {auth.userInfo.role === ROLES.godson ? (
                  <Grid item xs={4}>
                    <div
                      aria-label="gm-add-story-card"
                      className={classes.gmAddStoryCard}
                      onClick={isLastHistoryClosed ? onAddStory : null}
                      role="button"
                    >
                      <GmSvgIcon
                        className={classes.nextActionIcon}
                        enabled
                        disabled={!isLastHistoryClosed}
                        icon="add-story"
                        viewBox="0 0 78 76"
                      />
                      <Typography className={classes.nextActionCaption}>
                        {isLastHistoryClosed ? 'Subir una nueva historia' : 'Ya publicaste una historia'}
                      </Typography>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={4}>
                    <div
                      aria-label="gm-add-story-card"
                      className={classes.gmAddStoryCard}
                      onClick={onGoToStories}
                      role="button"
                    >
                      <GmSvgIcon
                        className={classes.nextActionIcon}
                        enabled
                        icon="eyes"
                        style={{ width: 70 }}
                        viewBox="0 0 24 24"
                      />
                      <Typography className={classes.nextActionCaption}>
                        Apoyar más historias
                      </Typography>
                    </div>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <button
                    type="button"
                    className={classes.refer}
                    onClick={onRefer}
                  >
                    <GmSvgIcon
                      className={classes.nextActionIcon}
                      enabled
                      icon="referring"
                      viewBox="0 -5 77 42"
                    />
                    <Typography className={classes.nextActionCaption}>
                      Referir a un amigo
                    </Typography>
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Paper>
  );
};

export default LastPaymentConfirmation;
