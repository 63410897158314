// vendors
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import React, { memo, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// actions
import {
  clearMessages,
  getCalculatorInfo,
  getChatHistory,
  getHistoryById,
  getHistoryProgress,
  getOrCreateChat,
  getOrCreateReferralsChat,
  setProposal,
} from '../../redux/actions/createHistory';
import { confirmLastPayment, confirmPayment } from '../../redux/actions/payments';
import { getAdministrativeExpensesSettingsChain } from '../../redux/actions/settings';

// components
import ChatContainer from './chat-container';
import GmHistoryProgress from './history-progress';
import GmKarmaReview from '../../components/karma-review';
import GmPeopleCard from '../../components/people-card';
import GmSuperModal from '../../components/super-modal';
import GmTransferSummary from '../../components/transfer-summary';
import GmLoanCalculator from '../loan-calculator';

// constants
import { routes } from '../../config/routes';

// icons
import PaymentIcon from '../../static/svg/icons/payment.svg';

// styles
import { useStyles } from './styles';

const GmChatHistory = memo(() => {
  const [modalContent, setModalContent] = useState({});
  const [calculatorIsOpen, setCalculatorIsOpen] = useState(false);
  const [isModalVisible, toggleModalVisibility] = useState(false);
  const [showAUCOSdkIframe, setShowAUCOSdkIframe] = useState(false);
  const [isConfirmButtonEnabled, setConfirmButtonEnabled] = useState(true);
  const [isCalculatorOpenFromActionBar, setIsCalculatorOpenFromActionBar] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    common: { loading },
    history: {
      chatsHistory,
      historyProgress,
    },
  } = useSelector((state) => state);

  const { id, historyId } = useParams();
  const { pathname } = useLocation();

  const isNew = pathname.includes('new');
  const isReferral = pathname.includes('referral');

  useEffect(() => {
    if (isNew && isReferral) {
      dispatch(getOrCreateReferralsChat(id));
      return;
    }

    if (isNew && !isReferral) {
      dispatch(getOrCreateChat(historyId));
      return;
    }

    dispatch(getChatHistory(id, isReferral));
  }, []);

  useEffect(() => {
    if (chatsHistory?.historyId && chatsHistory?.threadId) {
      dispatch(getCalculatorInfo(chatsHistory.historyId));
      dispatch(getAdministrativeExpensesSettingsChain());
      dispatch(getHistoryById(chatsHistory.historyId));
      dispatch(getHistoryProgress({
        historyId: chatsHistory.historyId,
        threadId: chatsHistory.threadId,
      }));
    }
  }, [chatsHistory.historyId, chatsHistory.threadId]);

  const onPaymentConfirmation = (data) => {
    setModalContent({
      date: data.Date,
      headerTitle: `${data.User.FullName} agradeció tu apoyo`,
      messageId: data.messageId,
      paymentId: data.PaymentId,
      title: data.User.FullName,
      valueAmount: data.Value,
      valueLabel: 'Te transifirió:',
    });
    toggleModalVisibility(!isModalVisible);
  };

  const onLastPaymentConfirmation = (data) => {
    setModalContent({
      headerTitle: 'Último pago',
      isLastPayment: true,
      messageId: data.messageId,
      paymentId: data.PaymentId,
      title: `¡Califica a ${data.User.FullName}!`,
      transactionId: data.TransactionId,
    });
    toggleModalVisibility(!isModalVisible);
  };

  const onConfirmLoanTransfer = () => {
    setConfirmButtonEnabled(false);

    if (!modalContent.isLastPayment) {
      dispatch(confirmPayment({ messageId: modalContent.messageId, paymentId: modalContent.paymentId }))
        .then(() => {
          setConfirmButtonEnabled(true);
          toggleModalVisibility(!isModalVisible);
          dispatch(getChatHistory(id));
        });
    }

    if (modalContent.isLastPayment) {
      dispatch(confirmLastPayment({
        messageId: modalContent.messageId,
        paymentId: modalContent.paymentId,
        review: {
          comment: modalContent.reviewComment,
          ranking: modalContent.reviewKarma,
        },
        transactionId: modalContent.transactionId,
      }))
        .then(() => {
          setConfirmButtonEnabled(true);
          toggleModalVisibility(!isModalVisible);
          dispatch(getChatHistory(id));
        });
    }
  };

  const onLoanConfirmation = (data) => {
    setModalContent({
      date: data.Date,
      headerTitle: `${data.User.FullName} ha decidido apoyarte`,
      messageId: data.messageId,
      paymentId: data.PaymentId,
      title: data.User.FullName,
      valueAmount: data.Value,
      valueLabel: 'Te ha apoyado con:',
    });
    toggleModalVisibility(!isModalVisible);
  };

  const handleOnGoBackToChatList = () => {
    if (showAUCOSdkIframe) {
      setShowAUCOSdkIframe(false);
      return;
    }

    dispatch(clearMessages()).then(() => history.push(routes.chat));
  };

  const onCreateProposal = ({ proposal }) => {
    dispatch(setProposal(proposal, chatsHistory.threadId));
    setCalculatorIsOpen(false);
  };

  const handleOnOpenLoanCalculator = ({ isLoanCalculatorOpen, isLoanCalculatorOpenFromActionBar }) => {
    setCalculatorIsOpen(isLoanCalculatorOpen);
    setIsCalculatorOpenFromActionBar(isLoanCalculatorOpenFromActionBar);
  };

  const handleOnCloseModal = () => {
    setModalContent({});
    toggleModalVisibility(false);
  };

  const handleOnReviewKarma = (value) =>
    setModalContent((prevState) => ({ ...prevState, reviewKarma: value }));

  const handleOnReviewComment = ({ target: { value } }) =>
    setModalContent((prevState) => ({ ...prevState, reviewComment: value }));

  if (calculatorIsOpen) {
    return (
      <GmLoanCalculator
        hasTransaction={chatsHistory.hasTransaction}
        isCalculatorOpenFromActionBar={isCalculatorOpenFromActionBar}
        onCreateProposal={onCreateProposal}
        onOpenLoanCalculator={handleOnOpenLoanCalculator}
        visible={calculatorIsOpen}
      />
    );
  }

  return (
    <>
      <GmSuperModal
        headerTitle={modalContent.headerTitle}
        onClose={handleOnCloseModal}
        isConfirmButtonEnabled={isConfirmButtonEnabled}
        onConfirm={onConfirmLoanTransfer}
        visible={isModalVisible}
      >
        {!modalContent.isLastPayment
          ? (
            <GmTransferSummary
              date={modalContent.date}
              icon={PaymentIcon}
              title={modalContent.title}
              valueAmount={modalContent.valueAmount}
              valueLabel={modalContent.valueLabel}
              visible
            />
          )
          : (
            <Grid container direction="column" justify="center">
              <Grid item>
                <Typography className={classes.lastPaymentModalTitle}>
                  {modalContent.title}
                </Typography>
              </Grid>
              <Grid className={classes.lastPaymentModalReviewContainer} item>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography className={classes.lastPaymentModalReviewTitle}>
                      ¿Cuál es tu calificación?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <GmKarmaReview onReview={handleOnReviewKarma} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  className={classes.lastPaymentModalReviewComment}
                  label="¿Cómo fué apoyar su historia?"
                  multiline
                  onChange={handleOnReviewComment}
                  rows={5}
                  value={modalContent?.reviewComment || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
      </GmSuperModal>
        <Grid container direction="column">
          {!loading && (
            <Grid item xs={12} style={{ borderBottom: '1px solid #D8D8D8' }}>
              <Grid alignItems="center" container justifyContent="center">
                <Grid item xs={2}>
                  <ArrowBackIcon
                    onClick={handleOnGoBackToChatList}
                  />
                </Grid>
                <Grid item xs={10}>
                  <GmPeopleCard contacts={[chatsHistory.user]} visible={Boolean(chatsHistory.images)} />
                </Grid>
                <Grid container justifyContent="center">
                  <GmHistoryProgress activeStep={historyProgress} />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <ChatContainer
              isReferral={isReferral}
              onLastPaymentConfirmation={onLastPaymentConfirmation}
              onLoanConfirmation={onLoanConfirmation}
              onOpenLoanCalculator={handleOnOpenLoanCalculator}
              onPaymentConfirmation={onPaymentConfirmation}
              setShowAUCOSdkIframe={setShowAUCOSdkIframe}
              showAUCOSdkIframe={showAUCOSdkIframe}
            />
          </Grid>
        </Grid>
    </>
  );
});

export default GmChatHistory;
