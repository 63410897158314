// packages
import React from 'react';

// components
import GmStoryCardHeader from './header';
import GmStoryCarousel from './carousel';
import GmStoryContent from './content';

const GmStoryCard = ({
  disableGoToChat,
  financingValue,
  frequency,
  isGodfather,
  isStoryFinalized,
  isVisitingMode,
  like,
  loanTime,
  storiesPaidByCreator,
  onClickUserStoryCreator,
  onGoToChat,
  onStoryLike,
  storyCategory,
  storyCreatorFullName,
  storyCreatorKarmaScore,
  storyCreatorProfilePicture,
  storyDate,
  storyDescription,
  storyPictures,
  storyTitle,
}) => (
  <>
    <GmStoryCardHeader
      isVisitingMode={isVisitingMode}
      like={like}
      onClickUserStoryCreator={onClickUserStoryCreator}
      onStoryLike={onStoryLike}
      storyCreatorFullName={storyCreatorFullName}
      storyCreatorKarmaScore={storyCreatorKarmaScore}
      storyCreatorProfilePicture={storyCreatorProfilePicture}
    />
    <GmStoryCarousel
      isStoryFinalized={isStoryFinalized}
      storyPictures={storyPictures}
    />
    <GmStoryContent
      disableGoToChat={disableGoToChat}
      financingValue={financingValue}
      frequency={frequency}
      isGodfather={isGodfather}
      loanTime={loanTime}
      onGoToChat={onGoToChat}
      storyCategory={storyCategory}
      storyDate={storyDate}
      storiesPaidByCreator={storiesPaidByCreator}
      storyDescription={storyDescription}
      storyTitle={storyTitle}
    />
  </>
);

export default GmStoryCard;
