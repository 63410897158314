// vendors
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FFFFFF',
    backgroundPosition: 'center',
    display: 'grid',
    height: '15vh',
    gridTemplateRows: 'auto 1fr auto',
    borderRadius: 0,
  },
  pqrsIconsContainer: {
    marginTop: 20
  },
  accordionItem: {
    boxShadow: 'none',
    borderRadius: 0,
    border: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  pqrsImage: {
    width: 73,
    height: 60
  },
  brandLogo: {
    margin: 20,
    marginBottom: 30,
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  body: {
    paddingBottom: 40,
  },
  forgotPassword: {
    marginTop: '3vh',
    width: '90%',
  },
  contactLabel: {
    marginTop: '3vh',
  },
  textHeader: {
    color: '#fff',
  },
  pqrList: {
    marginTop: 8,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    textAlign: 'initial',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  ArrowBackIconCustom: {
    position: 'absolute',
    marginTop: 37,
    marginLeft: 22,
    fontSize: 28,
    color: '#fff',
  },
  buttonProgress: {
    color: '#1DCAD3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  sendButton: {
    width: '45%'
  },
  whatsappButton: {
    backgroundColor: '#25d366',
    width: '45%'
  },
  startButton: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: 20,
    '& .MuiButton-label': {
      color: 'white',
      fontWeight: 500,
    },
  },
  textField: {
    width: '90%',
  },
  center: {
    textAlign: 'center',
  },
  textFollowInstruction: {
    fontSize: '14px',
    marginTop: '10vh',
    paddingLeft: '20px',
    paddingRight: '20px',
    textAlign: 'center',
  },
  boldTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  arrowBackIcon: {
    position: 'absolute',
    top: '16px',
    left: '16px',
    color: 'gray'
  },
}));
