// packages
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { useSelector } from 'react-redux';

// components
import GmSvgIcon from '../../static/svg';

// styles
import useStyles from './styles';

// constants
import { PAYMENT_FREQUENCY } from '../../config/constants';

const GmStoryContent = ({
  disableGoToChat,
  financingValue,
  isGodfather,
  loanTime,
  frequency,
  onGoToChat,
  storyDate,
  storyDescription,
  storiesPaidByCreator,
  storyTitle,
}) => {
  const classes = useStyles();
  const { history: { historyInfo } } = useSelector((state) => state);
  const [disableProposalButton, setDisableProposalButton] = useState(disableGoToChat || false);

  useEffect(() => {
    if (disableGoToChat === undefined && historyInfo?.status > 1) {
      setDisableProposalButton(true);
    }
  }, []);

  return (
    <>
      <Grid container direction="row" style={{ marginBottom: 15 }}>
        {isGodfather && (
          <Grid container direction="row" style={{ marginTop: -35 }}>
            <Grid item xs={7}>
              <Button
                className={classes.gmStoryContentMakeProposalButton}
                startIcon={(
                  <GmSvgIcon
                    enabled
                    disabled={disableProposalButton}
                    icon="calculator"
                    viewBox="0 0 61 76"
                  />
                )}
                onClick={onGoToChat}
                role="button"
                size="small"
                variant="contained"
                disabled={disableProposalButton}
              >
                HACER PROPUESTA
              </Button>
            </Grid>
            <Grid style={{ float: 'right' }} item xs={5}>
              <Grid item container direction="row">
                <Grid
                  alignItems="center"
                  container
                  item
                  style={{
                    textAlign: 'right',
                    fontSize: 12,
                    justifyContent: 'flex-end',
                    marginRight: 12,
                    marginTop: -1
                  }}
                  xs={12}
                >
                  {`${storiesPaidByCreator || 0} Historias pagadas`}
                  <GmSvgIcon
                    className={classes.gmStoryCardContentFinalizedStoryBadge}
                    icon="finalized-story"
                    viewBox="0 0 32 42"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container direction="column" style={{ marginTop: -12 }}>
        <Grid item xs={12}>
          <p className={classes.gmStoryContentTitle}>
            {storyTitle}
          </p>
        </Grid>
        <Grid style={{ marginTop: 0 }} item xs={12}>
          <p className={classes.gmStoryContentTitle}>
            {storyDescription}
          </p>
        </Grid>
        <Grid style={{ marginTop: 10 }} item xs={12}>
          <p className={classes.gmStoryContentAmmount}>
            {`Monto a prestar: ${financingValue}`}
          </p>
        </Grid>
        {loanTime
          ? (
              <Grid style={{ marginTop: 0, marginBottom: 5 }} item xs={12}>
                <p className={classes.gmStoryContentLoanTime}>
                  {`- Puede pagar en  ${loanTime} ${loanTime > 1 ? 'cuotas' : 'cuota'} de forma ${PAYMENT_FREQUENCY[frequency]}`}
                </p>
              </Grid>
          )
          : null}
        <Grid item xs={12}>
          <p className={classes.gmStoryContentDate}>
            {moment(storyDate).format('MMMM DD, YYYY')}
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default GmStoryContent;
