// vendor
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
// actions
import { onLogin } from '../../redux/actions/user';

// components
import GmSupportPage from '../support';

// images
import BlueLogo from '../../components/blue-logo';

// styles
import { useStyles } from './styles';

const validationSchema = yup.object({
  email: yup.string('ingresa tu correo electrónico').required('El correo electrónico es requerido'),
  password: yup.string('Ingresa tu contraseña').required('contraseña es requerida'),
});

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const { auth, common } = useSelector((state) => state);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(onLogin({
        password: values.password,
        rememberClient: true,
        userNameOrEmailAddress: values.email,
      })).catch(() => setError(true));
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (auth.isAuthenticated || auth.userInfo.accessToken) {
    if (!auth.userInfo.roles.length) {
      return (
        <GmSupportPage
          handleNext={() => history.push('/main')}
          visible
        />
      );
    }

    history.push('/main');
  }

  return (
    <>
      <Paper className={classes.root}>
        <Container>
          <Grid container justify="center" className={classes.center}>
            <Grid className={classes.logIn} item xs={12}>
              <BlueLogo />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                Nos encanta tenerte de regreso
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Grid container justify="center">
        <Grid item className={classes.logIn}>
          <Typography variant="h6" gutterBottom>
            Inicia Sesión
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.center}>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField
              className={clsx(classes.margin, classes.textField)}
              disabled={common.loading}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              id="email"
              label="Correo Electrónico"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              className={clsx(classes.margin, classes.textField)}
              disabled={common.loading}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              id="password"
              label="Contraseña"
              name="password"
              onChange={formik.handleChange}
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      className={classes.visibilityButton}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid item>
              {error && <p className={classes.loginFailedText}>Valida tu usuario o contraseña</p>}
            </Grid>
            <Grid item className={classes.startButton}>
              <Button disabled={common.loading} variant="contained" color="primary" type="submit">
                INICIAR SESIÓN
              </Button>
            </Grid>
          </form>
          <Grid container justify="center" className={classes.center}>
            <Grid item xs={12} className={classes.haveAccount}>
              <span>¿Olvidaste tu contraseña?</span>
              <Link to="/forgot-password" className={classes.haveAccountLink}>
                Recupérala
              </Link>
            </Grid>
            <Divider variant="middle" className={classes.divider} />
            <Grid item xs={12} className={classes.register}>
              <span>¿Eres nuevo?</span>
              <Link to="/onboarding" className={classes.haveAccountLink}>
                Regístrate
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginPage;
