// vendors
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/CameraAlt';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import PersonIcon from '@material-ui/icons/Person';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';

// components
import GmAboutMe from '../../../components/forms/about-me';
import GmImageCrop from '../../../components/image-crop';

// images
import logo from '../../../static/images/logo3.svg';

// utils
import {
  ONLY_ALPHANUMERIC,
  ONLY_INTEGER_NUMBER,
  getAsDataURL,
  getAsImg,
  getBase64,
  getCroppedImg,
} from '../../../utils';

// styles
import { useStyles } from '../styles';

// constants
import { MAX_IMG_SIZE } from '../../../config/constants';

let minDate = new Date(Date.now());
minDate = new Date(minDate.setFullYear(minDate.getFullYear() - 120));
const minYear = minDate.getFullYear();

const validationSchema = yup.object({
  docImgFile: yup.object.required,
  docType: yup.string('Selecciona el tipo de documento').required('El tipo de documento es requerido'),
  gender: yup.string('Ingrese su género').required('El género es requerido'),
  id: yup.string('Ingrese el número de identificación').required('El número de identifación es requerido'),
  lastName: yup.string('Ingresa tus apellidos').required('Los apellidos son requerido'),
  name: yup.string('Ingresa tú nombre').required('El nombre es requerido'),
  personalPhrase: yup.string('Escribe una frase que inspire a otros').required('La frase es requerida'),
  month: yup
    .string('Mes')
    .required('El mes es requerido')
    .test('valid-month', 'Inserta un mes válido', (value) => {
      const month = parseInt(value, 10);
      return month >= 1 && month <= 12;
    }),
  day: yup
    .string('Día')
    .required('El día es requerido')
    .test('valid-day', 'Inserta un día válido', (value) => {
      const day = parseInt(value, 10);
      return day >= 1 && day <= 31;
    }),
  year: yup
    .string('Año')
    .required('El año es requerido')
    .test('valid-min-year', 'Ingresa una fecha válida', (value) => {
      const year = parseInt(value, 10);
      return year >= minYear;
    })
    .test('valid-min-age', 'Debes ser mayor de 18 años', (value) => {
      const birthYear = parseInt(value, 10);
      const currentYear = new Date().getFullYear();
      return currentYear - birthYear >= 18;
    })
});

const Step1 = ({
  answers,
  commonData,
  handleBack,
  handleData,
  handleNext,
  setCommonData,
}) => {
  const classes = useStyles();
  const [imgToCrop, setImgToCrop] = useState(null);
  const [isImgTooBig, setIsImgTooBig] = useState(false);
  const [uploadingDocumentImg, setUploadingDocumentImg] = useState(false);
  const [uploadingProfileImg, setUploadingProfileImg] = useState(false);

  const formik = useFormik({
    initialValues: {
      birthDate: '',
      day: '',
      docImgFile: commonData.documentImg,
      docType: '',
      gender: '',
      id: '',
      lastName: '',
      month: '',
      name: '',
      profilePicture: commonData.profileImg,
      personalPhrase: '',
      year: '',
      ...answers
    },
    validationSchema,
    onSubmit: (values) => {
      const { imgValidations } = commonData;
      if (imgValidations.documentImg && imgValidations.profileImg) {
        handleData({ data: { ...values, profilePicture: commonData.profileImg } });
        handleNext();
      } else {
        setCommonData({
          ...commonData,
          imgValidations: { ...imgValidations, showWarning: true }
        });
      }
    },
  });

  const handleOnDocTypeChange = ({ target: { name, value } }) => {
    if ((value === 'CC' || value === 'CE') && !formik.values.id.match(ONLY_INTEGER_NUMBER)) {
      formik.setFieldValue('id', '');
    }

    if (value === 'Pasaporte' && !formik.values.id.match(ONLY_ALPHANUMERIC)) {
      formik.setFieldValue('id', '');
    }

    formik.setFieldValue(name, value);
  };

  const handleOnChangeId = ({ target: { name, value } }) => {
    if (!value) {
      formik.setFieldValue(name, value);
    }

    if ((formik.values.docType === 'CC' || formik.values.docType === 'CE') && value.match(ONLY_INTEGER_NUMBER)) {
      formik.setFieldValue(name, value);
    }

    if ((formik.values.docType === 'CC' || formik.values.docType === 'CE') && !value.match(ONLY_INTEGER_NUMBER)) {
      formik.setFieldTouched(name, true);
      formik.setFieldError(name, 'El documento debe ser númerico');
    }

    if (formik.values.docType === 'Pasaporte' && value.match(ONLY_ALPHANUMERIC)) {
      formik.setFieldValue(name, value);
    }

    if (formik.values.docType === 'Pasaporte' && !value.match(ONLY_ALPHANUMERIC)) {
      formik.setFieldTouched(name, true);
      formik.setFieldError(name, 'El documento debe ser alfanumérico');
    }
  };

  useEffect(() => {
    const updateBirthDate = () => {
      const { day, month, year } = formik.values;
      if (day && month && year) {
        const dayNumber = parseInt(day, 10);
        const monthNumber = parseInt(month, 10);
        const yearNumber = parseInt(year, 10);
        if (!Number.isNaN(dayNumber) && !Number.isNaN(monthNumber) && !Number.isNaN(yearNumber)) {
          const formattedDate = `${yearNumber}-${monthNumber.toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`;
          formik.setFieldValue('birthDate', formattedDate);
        }
      }
    };

    updateBirthDate();
  }, [formik.values.day, formik.values.month, formik.values.year]);

  const [q0Img, setq0Img] = useState(commonData?.documentImg || formik?.values?.docImgFile || null);

  const uploadProfileImg = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];

      if (img.size > MAX_IMG_SIZE) {
        setIsImgTooBig(true);
        setImgToCrop({});
        setUploadingDocumentImg(true);
        return;
      }

      const fileData = await getAsDataURL(img);

      setImgToCrop({
        name: img.name,
        type: img.type,
        content: fileData,
        size: img.size
      });
      setUploadingProfileImg(true);
      setIsImgTooBig(false);
    }
  };

  const uploadDocumentImg = async (imgBase64) => {
    if (imgBase64.size > MAX_IMG_SIZE) {
      setIsImgTooBig(true);
      setImgToCrop({});
      setUploadingDocumentImg(true);
      return true;
    }

    const img = await getAsImg(imgBase64);

    setImgToCrop({
      name: img.id,
      type: img.type,
      content: img.src,
      size: imgBase64.size
    });
    setUploadingDocumentImg(true);
    setIsImgTooBig(false);

    return true;
  };

  const onCancelProfileImageCrop = () => {
    const { imgValidations, profileImg } = commonData;
    setImgToCrop(null);
    setUploadingProfileImg(false);
    setCommonData({
      ...commonData,
      profileImg: profileImg || null,
      imgValidations: { ...imgValidations, profileImg: Boolean(profileImg) || false }
    });
  };

  const onCancelDocImageCrop = () => {
    const { imgValidations, documentImg } = commonData;
    setImgToCrop(null);
    setUploadingDocumentImg(false);
    formik.setFieldValue('docImgFile', q0Img || null);
    setq0Img(q0Img || null);
    setCommonData({
      ...commonData,
      documentImg: documentImg || null,
      imgValidations: { ...imgValidations, documentImg: Boolean(documentImg) || false }
    });
  };

  const onConfirmProfileImageCrop = async (crop, image) => {
    const img = await getCroppedImg(crop, image, imgToCrop);
    const data = await getBase64(img);
    const { imgValidations } = commonData;
    const imageReturned = {
      content: data,
      name: img.name,
      type: img.type.replace('image/', '')
    };

    setCommonData({
      ...commonData,
      profileImg: imageReturned,
      imgValidations: { ...imgValidations, profileImg: true }
    });
    formik.setFieldValue('docImgFile', q0Img || null);
    setImgToCrop(null);
    setUploadingProfileImg(false);
  };

  const onConfirmDocumentImageCrop = async (crop, image) => {
    const img = await getCroppedImg(crop, image, imgToCrop);
    const data = await getBase64(img);
    const { imgValidations } = commonData;
    const imageReturned = {
      content: data,
      name: img.name,
      type: img.type.replace('image/', '')
    };

    setq0Img(imageReturned);
    formik.setFieldValue('docImgFile', imageReturned);
    setCommonData({
      ...commonData,
      documentImg: imageReturned,
      imgValidations: { ...imgValidations, documentImg: true }
    });
    setImgToCrop(null);
    setUploadingDocumentImg(false);
  };

  const { imgValidations, profileImg } = commonData;

  return (
    <>
      {imgToCrop && uploadingProfileImg && (
        <GmImageCrop
          isImgTooBig={isImgTooBig}
          onCancelCrop={onCancelProfileImageCrop}
          onConfirmCrop={onConfirmProfileImageCrop}
          src={imgToCrop.content}
          visible={imgToCrop}
        />
      )}
      {imgToCrop && uploadingDocumentImg && (
        <GmImageCrop
          aspectRatio="16:9"
          isImgTooBig={isImgTooBig}
          onCancelCrop={onCancelDocImageCrop}
          onConfirmCrop={onConfirmDocumentImageCrop}
          src={imgToCrop.content}
          visible={imgToCrop}
        />
      )}
      <Paper elevation={0}>
        <Grid container direction="column" justify="center">
          <Grid className={classes.brandLogo} item xs={12}>
            <img src={logo} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Cuéntanos sobre ti
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <label
                className={classes.imgProfile}
                htmlFor="profileUpload"
                style={{
                  backgroundImage: `url("data:${profileImg?.type};base64,${profileImg?.content}")`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              >
                <input
                  accept="image/*"
                  id="profileUpload"
                  onChange={uploadProfileImg}
                  style={{ display: 'none' }}
                  type="file"
                />
                {!profileImg?.content
                  ? (
                    <IconButton className={classes.imgButton} component="div">
                      <PersonIcon fontSize="large" />
                    </IconButton>
                  ) : (
                    <Button className={classes.editProfile} component="div">
                      <CameraIcon className={classes.editIcon} />
                    </Button>
                  )}
              </label>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Grid container direction="column" justify="center" style={{ padding: '0px 20px 0px' }}>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <GmAboutMe
            formik={formik}
            handleOnChangeId={handleOnChangeId}
            handleOnDocTypeChange={handleOnDocTypeChange}
            onUploadDocumentImg={uploadDocumentImg}
            q0Img={q0Img}
          />
          {(!imgValidations.profileImg || !imgValidations.documentImg) && imgValidations.showWarning && (
            <Typography className={classes.errorText} gutterBottom>
              La imagen de perfil y la foto de tu documento son requeridos
            </Typography>
          )}
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item style={{ padding: '20px 20px 20px 0px' }} xs={6}>
                <Button
                  fullWidth
                  onClick={handleBack}
                  variant="contained"
                >
                  ATRÁS
                </Button>
              </Grid>
              <Grid item style={{ padding: '20px 0px 20px 0px' }} xs={6}>
                <Button
                  color="primary"
                  fullWidth
                  style={{ color: '#ffffff' }}
                  type="submit"
                  variant="contained"
                >
                  SIGUIENTE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default Step1;
