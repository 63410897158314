// vendors
import React from 'react';

const MessageDisabled = (
  <>
    <path d="M22.7537 0H0V18H22.7537V0Z" fill="#CFD2D7" />
    <path d="M22.7537 0H0L11.3768 8.72942L22.7537 0Z" fill="#7D7D7D" />
  </>
);

export default MessageDisabled;
