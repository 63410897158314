// TODO: Let's keep consistency with data types.
// It's boolean and should be false by default, then false.
// It's an array and should be empty by default, then [].
// It's an object and should be empty by default, then {} or even null.
// It's a numer or id and should be empty by default, then 0 or even null.
// It's a string and should be empty by default, then '' or even null.

export const commonInitialState = {
  error: false,
  featureFlags: {},
  loading: false,
  loadingHistories: false,
};

export const activityInitialState = {
  ...commonInitialState,
  items: [],
  totalCount: 0
};

export const authInitialState = {
  ...commonInitialState,
  userInfo: {},
  isAuthenticated: false,
  sendPasswordOk: false,
  showBankInfoPage: false,
};

export const bankInitialState = {
  ...commonInitialState,
  bankList: [],
};

export const historyInitialState = {
  ...commonInitialState,
  calculator: {},
  canCreateNewHistory: false,
  chatsContacts: [],
  chatsHistory: {},
  data: {},
  editData: [],
  gaveLike: null,
  histories: [],
  historyId: 0,
  historyInfo: {},
  historyProgress: 0,
  messages: [],
  myHistories: [],
  // This property here is to work around a bug related with the real-time messages for /new-chat/:userId/:historyId url
  newChatMessageThreadId: null,
  referMe: [],
  schemaQuestion: [],
  socketOn: false,
  totalCount: 0,
  unreadMessages: [],
  whoHasImReferred: [],
};

export const registerInitialState = {
  ...commonInitialState,
  cities: [],
  countries: [],
  emailAddress: '',
  invitationCode: '',
  isInvitationCodeOk: false,
  password: '',
  phoneCodes: [],
  registerOK: false,
  setRoleOK: false,
  states: [],
  token: '',
  userInfo: {},
};

export const paymentsInitialState = {
  ...commonInitialState,
  actions: [],
  detail: {},
  isLastPayment: false,
  transactionRecord: {}
};

export const profileVisitingInitialState = {
  profile: {},
  stories: [],
};

export const settingsInitialState = {
  administrativeExpensesSettings: {},
  administrativeExpensesPercent: null
};

export const transactionInitialState = {
  ...commonInitialState,
  data: [],
  transactionId: null,
  transactionsList: [],
  referralPerimeter: []
};
