// vendors
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';

function equalTo(ref, msg) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'confirm your confirm password label',
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}
yup.addMethod(yup.string, 'equalTo', equalTo);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  forgotPassword: {
    marginTop: '3vh',
    width: '90%',
  },
  textField: {
    width: '90%',
  },
  center: {
    textAlign: 'center',
  },
  errorText: {
    color: '#ff382e',
    fontSize: 11,
    display: 'block',
  },
  startButton: {
    position: 'relative',
    '& .MuiButton-label': {
      color: 'white',
      fontWeight: 500,
    },
  },
}));

const validationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .min(5, 'las  deben tener un mínimo de 5 caracteres')
    .required('Password is required'),
  changepassword: yup
    .string()
    .equalTo(yup.ref('password'), 'las Contraseña no coinciden')
    .required('Required'),
});

export const GmChangePassword = ({ handleChangepassword, loading, mail }) => {
  const classes = useStyles();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: mail,
      password: '',
      changepassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleChangepassword({
        currentPassword: values.currentPassword,
        newPassword: values.password,
      });
    },
  });

  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ shrink: true }}
            disabled
            error={formik.touched.address && Boolean(formik.errors.address)}
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            onChange={formik.handleChange}
            style={{ paddingBottom: 20, marginTop: 5 }}
            value={formik.values.email}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Contraseña Actual</InputLabel>
            <OutlinedInput
              id="currentPassword"
              name="currentPassword"
              type={values.showPassword ? 'text' : 'password'}
              value={formik.values.currentPassword}
              disabled={loading}
              onChange={formik.handleChange}
              style={{ marginBottom: 20 }}
              error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
              labelWidth={70}
            />
            {formik.errors.password && (
              <Typography className={classes.errorText} gutterBottom>
                {formik.errors.password}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Contraseña Nueva</InputLabel>
            <OutlinedInput
              id="password"
              name="password"
              type={values.showPassword ? 'text' : 'password'}
              value={formik.values.password}
              disabled={loading}
              onChange={formik.handleChange}
              style={{ marginBottom: 20 }}
              error={formik.touched.password && Boolean(formik.errors.password)}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
              labelWidth={70}
            />
            {formik.errors.password && (
              <Typography className={classes.errorText} gutterBottom>
                {formik.errors.password}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Confirma tu Contraseña</InputLabel>
            <OutlinedInput
              id="changepassword"
              name="changepassword"
              type={values.showPassword ? 'text' : 'password'}
              value={formik.values.changepassword}
              style={{ marginBottom: 20 }}
              disabled={loading}
              onChange={formik.handleChange}
              error={formik.touched.changepassword && Boolean(formik.errors.changepassword)}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
              labelWidth={70}
            />
            {formik.errors.changepassword && (
              <Typography className={classes.errorText} gutterBottom>
                {formik.errors.changepassword}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            disabled={loading}
            fullWidth
            style={{ color: '#ffffffff' }}
            type="submit"
            variant="contained"
          >
            CAMBIA TU CONTRASEÑA
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default GmChangePassword;
