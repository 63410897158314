// packages
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/CameraAlt';
import Typography from '@material-ui/core/Typography';

// components
import GmAlertMessage from '../alert';
import GmImageCrop from '../image-crop';

// styles
import useStyles from './styles';

// utils
import {
  getBase64,
  getAsDataURL,
  getCroppedImg,
  isObjectNullOrEmpty
} from '../../utils';

// constants
import { MAX_IMG_SIZE } from '../../config/constants';

export const GmStoryUploadPictures = ({ onChangePictures, pictures }) => {
  const classes = useStyles();

  const [isImgTooBig, setIsImgTooBig] = useState(false);
  const [imgToCrop, setImgToCrop] = useState({});
  const [uploadingImg, setUploadingImg] = useState(false);

  const handleUploadImg = async (event, pictureId) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];

      if (img.size > MAX_IMG_SIZE) {
        setIsImgTooBig(true);
        setImgToCrop({});
        setUploadingImg(true);
        return;
      }

      const fileData = await getAsDataURL(img);

      setImgToCrop({
        id: pictureId,
        name: img.name,
        type: img.type,
        content: fileData,
        size: img.size
      });
      setUploadingImg(true);
      setIsImgTooBig(false);
    }
  };

  const onConfirmImageCrop = async (crop, image) => {
    const img = await getCroppedImg(crop, image, imgToCrop);
    const data = await getBase64(img);
    const imageReturned = {
      id: imgToCrop.id || null,
      content: data,
      name: img.name,
      type: img.type
    };

    if (imgToCrop.id) {
      const newPictures = pictures.map((picture) => {
        if (picture.id === imgToCrop.id) {
          return {
            ...picture,
            base64: data,
            name: imageReturned.name,
            size: imageReturned.size,
            src: `data:${imageReturned.type};base64,${data}`,
            type: imageReturned.type,
          };
        }

        return picture;
      });

      onChangePictures(newPictures);
    }

    setImgToCrop(null);
    setUploadingImg(false);
  };

  const onCancelImageCrop = () => {
    setImgToCrop(null);
    setUploadingImg(false);
  };

  return (
    <>
      {imgToCrop && uploadingImg ? (
        <GmImageCrop
          aspectRatio="16:9"
          isImgTooBig={isImgTooBig}
          onCancelCrop={onCancelImageCrop}
          onConfirmCrop={onConfirmImageCrop}
          src={imgToCrop?.content}
          visible={!isObjectNullOrEmpty(imgToCrop)}
        />
      ) : null}
      <Grid className={classes.storyUploadPicturesContainer} container direction="column">
        {pictures.map((picture) => (
          <Grid
            className={classes.storyUploadPicturesItem}
            container
            direction="row"
            key={picture.id}
          >
            <Grid item xs={4}>
              <div className={classes.storyUploadPicturesPicture}>
                <label
                  className={classes.imgProfile}
                  htmlFor={picture.id}
                  style={picture?.type
                    ? {
                      backgroundImage: `url("data:${picture?.type};base64,${picture?.base64}")`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      height: '100px',
                      padding: '5px',
                      width: '100px',
                    }
                    : {
                    }}
                >
                  <input
                    accept="image/*"
                    id={picture.id}
                    onChange={(evt) => handleUploadImg(evt, picture.id)}
                    style={{ display: 'none' }}
                    type="file"
                  />
                  {!picture?.content
                    ? (
                      <Button className={classes.editProfile} component="div">
                        {picture?.type ? null : <CameraIcon className={classes.editIcon} />}
                      </Button>
                    ) : null}
                </label>
                {/* <img
                  src={picture?.image?.href || picture?.src || cameraIcon}
                  style={picture?.src || picture?.image?.href
                    ? {
                      height: '100px',
                      padding: '5px',
                      width: '100px',
                    }
                    : {
                      marginBottom: '-100%',
                      width: '25px',
                    }}
                /> */}
                {/* <input
                  accept="image/*"
                  onChange={({ target: { files } }) => handleOnPictureChange(files, picture.id)}
                  type="file"
                  id={picture.id}
                  style={{
                    height: '106px',
                    opacity: 0,
                    width: '106px',
                  }}
                /> */}
              </div>
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.storyUploadPicturesTitle}>{picture.title}</Typography>
              <Typography className={classes.storyUploadPicturesDescription}>{picture.description}</Typography>
              <GmAlertMessage
                style={{
                  padding: '0px 20px',
                  textAlign: 'left',
                }}
                visible={Boolean(picture.error)}
              >
                <strong>{picture.error}</strong>
              </GmAlertMessage>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default GmStoryUploadPictures;
