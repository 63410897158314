// vendors
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import numeral from 'numeral';

// components
import GmSvgIcon from '../../static/svg';

// styles
import useStyles from './styles';

const LatePaymentSummary = ({
  isSimpleTitle = false,
  items,
  latePaymentValue,
  latePaymentValueLabel,
  title,
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.latePaymentSummary} container direction="column">
      <Grid item>
        {isSimpleTitle
          ? (
            <Typography>
              {title}
            </Typography>
          )
          : title}
      </Grid>
      <Grid item>
        {items && items.length
          ? items.map((item) => (
            <Grid container direction="row">
              <Grid item xs={1}>
                <GmSvgIcon icon={item.icon} {...item?.iconConfig} />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.itemLabel}>
                  {item.label}
                </Typography>
              </Grid>
            </Grid>
          ))
          : null}
      </Grid>
      <Grid item>
        <Grid container direction="column" justify="center">
          <Grid item>
            <Typography className={classes.latePaymentValueLabel}>
              {latePaymentValueLabel}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.latePaymentValue}>
              {numeral(latePaymentValue).format('$0,0')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LatePaymentSummary;
