// constants
const development = 'https://giveme5-backend.azurewebsites.net';
const local = `http://localhost:${process.env.REACT_APP_SVC_API_PORT}`;
const production = 'https://api.givemefive.app';
const qa = 'https://giveme5-qa-backend.azurewebsites.net';
const stage = 'https://giveme5-stage-backend.azurewebsites.net';

const AUCO_API_PROD = 'https://api.auco.ai';
const AUCO_API_DEV = 'https://dev.auco.ai';

const AUCO_SDK_PROD = 'https://validate.auco.ai';
const AUCO_SDK_DEV = 'https://validate-stage.auco.ai';

const WOMPI_PUB_KEY_DEV = 'pub_test_KbeUOtQGgyb2CJPtXu62qLmeI7gt5eTo';
const WOMPI_PUB_KEY_PROD = 'pub_prod_FGyzRjsFuxkAqVrtfI3cSw7TErSDjVGG';

const ENV_MAPPER = {
  development,
  local,
  production,
  qa,
  stage,
};

const AUCO_API_MAPPER = {
  development: AUCO_API_DEV,
  production: AUCO_API_PROD
};

const AUCO_SDK_MAPPER = {
  development: AUCO_SDK_DEV,
  production: AUCO_SDK_PROD
};
export const AUCO_SDK_PRV_DEV_KEY = 'prk_HRAsgdwiHshqbL2iVWx1c0rVn28tyoiA';
export const AUCO_SDK_PRV_PROD_KEY = 'prk_i20cI0lrVZPyJ2JGQO0A3BQ1bXBwIEbx';
export const AUCO_SDK_PUB_KEY = 'puk_ocV0ODMlBlCN4NOi4GxVJjmC71o65aIb';
export const AUCO_SDK_QA_PROD_KEY = 'prk_HRAsgdwiHshqbL2iVWx1c0rVn28tyoiA';

const WOMPI_PUB_KEYS_MAPPER = {
  development: WOMPI_PUB_KEY_DEV,
  local: WOMPI_PUB_KEY_DEV,
  production: WOMPI_PUB_KEY_PROD,
  qa: WOMPI_PUB_KEY_DEV
};

const getEnvSettings = (env, AUCO_PUK_KEY) => {
  const AUCO_API_BASE_URL = AUCO_API_MAPPER[env];
  const AUCO_SDK_BASE_URL = AUCO_SDK_MAPPER[env];
  const baseUrl = ENV_MAPPER[env];
  const WOMPI_PUB_KEY = WOMPI_PUB_KEYS_MAPPER[env];

  return {
    AUCO_PUK_KEY,
    WOMPI_PUB_KEY,
    aucoApiBaseUrl: AUCO_API_BASE_URL,
    aucoSdkBaseUrl: AUCO_SDK_BASE_URL,
    mainBaseUrl: `${baseUrl}/api`,
    realTimeBaseUrl: `${baseUrl}/signalr`,
  };
};

const env = getEnvSettings(process.env.REACT_APP_ENV, AUCO_SDK_PUB_KEY);

export default env;
