// vendors
import React from 'react';

const BadKarma = (
  <svg width="64" height="60" viewBox="0 0 79 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.5187 0L0 27.8861L15.1012 73H63.8987L79 27.8861L39.5187 0Z" fill="#D7384A" />
    <path d="M35.7327 49.8496L43.2459 49.8496L43.2459 23.1314L35.7327 23.1314L35.7327 49.8496Z" fill="white" />
    <path d="M27.0093 47.4133H51.9903L39.5185 59.5681L27.0093 47.4133Z" fill="white" />
  </svg>
);

export default BadKarma;
