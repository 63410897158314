// vendors
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import numeral from 'numeral';

// components

// constants
import {
  INTEREST_RATE,
  PAYMENT_FREQUENCY,
  userIsGodson,
} from '../../config/constants';

const ProposalAccepted = ({
  auth,
  classes,
  eventMessage,
  isAccepted = false,
  messageId,
  postByUserId,
}) => {
  const isGodson = userIsGodson(auth.userInfo.role);

  let proposalTitle = 'Te ha enviado una propuesta';

  if (!isAccepted && auth.userInfo.userId === postByUserId) {
    proposalTitle = 'Haz enviado una propuesta';
  }

  if (isAccepted) {
    proposalTitle = isGodson
      ? 'Has aceptado la propuesta'
      : 'Ha aceptado tu propuesta';
  }

  const interestRate = INTEREST_RATE.find((ir) => ir.value === eventMessage.InterestRate);

  return (
    <Paper className={classes.paper} elevation={0} key={messageId}>
      <Grid
        container
        justify="center"
        className={isAccepted ? classes.proposalAcceptedContainer : classes.proposalContainer}
        spacing={0}
      >
        <Grid item xs={12}>
          <Typography className={classes.proposalName} component="div">
            {eventMessage.User.FullName}
          </Typography>
          <Typography className={classes.proposalName} component="div">
            {proposalTitle}
          </Typography>
        </Grid>
        <Paper className={classes.messageContainer} elevation={0}>
          <Grid className={classes.proposalContentContainer} item xs={12}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography className={classes.loanAmmountHeader} component="div">
                  {isAccepted
                    ? 'Monto prestado'
                    : 'Monto a prestar'}
                </Typography>
                <Typography className={classes.proposalNumber} component="div">
                  {numeral(eventMessage.LoanAmount).format('$0,0')}
                </Typography>
              </Grid>
              <Grid className={isAccepted ? classes.itemAcceptedUpperLeft : classes.itemUpperLeft} item xs={6}>
                <Typography className={classes.itemTitle} component="div">
                  Numero de cuotas
                </Typography>
                <Typography className={classes.itemTextContent} component="div">
                  {`${eventMessage.LoanTime} cuotas`}
                </Typography>
              </Grid>
              <Grid className={isAccepted ? classes.itemAcceptedUpperRight : classes.itemUpperRight} item xs={6}>
                <Typography className={classes.itemTitle} component="div">
                  Frecuencia del pago
                </Typography>
                <Typography className={classes.itemTextContent} component="div">
                  {PAYMENT_FREQUENCY[eventMessage.Frequency]}
                </Typography>
              </Grid>
              <Grid className={isAccepted ? classes.itemAcceptedLowerLeft : classes.itemLowerLeft} item xs={6}>
                <Typography className={classes.itemTitle} component="div">
                  Tasa de interés
                </Typography>
                <Typography className={classes.itemTextContent} component="div">
                  {interestRate?.label}
                </Typography>
              </Grid>
              <Grid className={isAccepted ? classes.itemAcceptedLowerRight : classes.itemLowerRight} item xs={6}>
                <Typography className={classes.itemTitle} component="div">
                  Pago por cuota
                </Typography>
                <Typography className={classes.itemTextContent} component="div">
                  {numeral(eventMessage.Value).format('$0,0')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Paper>
  );
};

export default ProposalAccepted;
