import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { useHistory } from 'react-router-dom';

import GmSvgIcon from '../../static/svg';

// components
import { useStyles } from './styles';

export default function Karma() {
  const classes = useStyles();
  const history = useHistory();

  const routeChange = (path) => {
    history.push(path);
  };
  return (
    <>
      <Paper className={classes.root}>
        <ArrowBackIcon
          className={classes.ArrowBackIconCustom}
          onClick={() => routeChange('/main')}
        />
        <Container className={classes.textHeader}>
          <Grid container justify="center" className={classes.center}>
            <Grid item xs={12} className={classes.forgotPassword}>
              <Typography variant="h5" gutterBottom>
                Karma
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Grid container justify="center" className={classes.body}>
        <Grid item className={classes.forgotPassword}>
          <Typography variant="body1" gutterBottom>
            Se basa en la ley de causa y efecto. Todo aquello que das se te retribuye. Si das cosas
            buenas se te retribuyen con cosas buenas que te pasen, si das cosas malas, se te
            retribuye con cosas malas.
          </Typography>
        </Grid>
        <Grid item className={classes.body}>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <GmSvgIcon
                  icon="payment-report"
                  enabled
                  viewBox="0 0 12 22"
                />
              </ListItemIcon>
              <ListItemText
                className={classes.otions}
                secondary={(
                  <>
                    <Typography
                      component="span"
                      variant="body1"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      Cada que pagas oportunamente el apoyo que te dieron acumulas buen Karma
                    </Typography>
                  </>
                )}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <GmSvgIcon icon="payment-icon" viewBox="0 0 30 24" />
              </ListItemIcon>
              <ListItemText
                className={classes.otions}
                secondary={(
                  <>
                    <Typography
                      component="span"
                      variant="body1"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      Cuando decides apoyar a alguien que necesita tu ayuda acumulas Buen Karma
                    </Typography>
                  </>
                )}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <GmSvgIcon icon="referring" enabled viewBox="0 -5 77 42" />
              </ListItemIcon>
              <ListItemText
                className={classes.otions}
                secondary={(
                  <>
                    <Typography
                      component="span"
                      variant="body1"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      Refiriendo amigos en los que confíes, que sepas que necesitan ayuda o que
                      pueden ayudar a alguien que lo necesite, acumulas buen karma
                    </Typography>
                  </>
                )}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <GmSvgIcon icon="good-karma" viewBox="0 0 32 34" />
              </ListItemIcon>
              <ListItemText
                className={classes.otions}
                secondary={(
                  <>
                    <Typography component="span" variant="body1" color="textPrimary">
                      Cada que subes una nueva historia acumulas buen Karma. Sólo puedes subir una
                      historia nueva cuando hayas retribuido el apoyo que te dieron.
                    </Typography>
                  </>
                )}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
}
