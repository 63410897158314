// vendors
import React from 'react';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// icons
import ReviewYellow from '../../static/images/review-positive.png';
import ReviewDisabled from '../../static/images/review-disabled.png';

const historyProgressSteps = [
  'Hacer propuesta',
  'Propuesta aceptada',
  'Costos operativos',
  'Firma pagaré',
  'Hacer desembolso',
  'Recibir pago',
  'Ganaste buen karma!'
];

const useHistoryProgressIconStyles = makeStyles({
  root: {
    alignItems: 'center',
    color: '#eaeaf0',
    display: 'flex',
    marginBottom: -10
  },
  active: {
    color: '#ffc600',
  },
  completed: {
    color: '#ffc600',
  },
  iconTittle: {
    height: 18,
    width: 20,
  },
});

export const HistoryProgressStepIcon = (props) => {
  const classes = useHistoryProgressIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <img className={classes.iconTittle} src={completed || active ? ReviewYellow : ReviewDisabled} />
    </div>
  );
};

export const HistoryProgressConnector = withStyles({
  alternativeLabel: {
    top: 4,
  },
  active: {
    '& $line': {
      backgroundColor: '#ffc600',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#ffc600',
    },
  },
  line: {
    backgroundColor: '#eaeaf0',
    border: 20,
    borderRadius: 20,
    width: 50,
  },
})(StepConnector);

export const GmHistoryProgress = ({ activeStep }) => (
  <Stepper
    activeStep={activeStep - 1}
    alternativeLabel
    connector={<HistoryProgressConnector />}
    style={{ padding: 0, paddingBottom: 10 }}
  >
    {historyProgressSteps.map((label) => (
      <Step key={label}>
        <StepLabel StepIconComponent={HistoryProgressStepIcon}>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
);

export default GmHistoryProgress;
