/* eslint-disable no-multi-spaces */

// vendors
import Badge from '@material-ui/core/Badge';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// components
import GmSvgIcon from '../../static/svg';

// routes
import { routes } from '../../config/routes';

// styles
import { useStyles } from './styles';

function GmBottomNavigation() {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const history = useHistory();
  const { unreadMessages } = useSelector((state) => state.history);
  const { showBankInfoPage } = useSelector((state) => state.auth);
  const transactionRecord = useSelector((state) => state.payments.transactionRecord);
  const { pathname } = useLocation();

  const unreadMessagesCount = unreadMessages.reduce((acc, val) => acc + val.count, 0);
  const isTransactionResultCurrentRoute = history.location.pathname.split('/')[1] === routes.transactionResult.split('/')[1];
  const isMainCurrentRoute = history.location.pathname.split('/')[1] === routes.main.split('/')[1];

  const excludedRoutes = [
    routes.forgotPassword,
    routes.home,
    routes.login,
    routes.onboarding,
    routes.referralCode,
    routes.support,
    routes.wizardForm,
  ];

  const hideBottomNavigation = excludedRoutes.includes(pathname)
    || pathname.includes('/recover-password')
    || pathname.includes('/referral-code')
    || new RegExp('/chat/[0-9]+').test(pathname)                       // Regexp for normal chat
    || new RegExp('/new-chat/[0-9]+/[0-9]+').test(pathname)            // Regexp for new chat
    || new RegExp('/new-chat-referral/[0-9]+').test(pathname)          // Regexp for new referrral chat
    || new RegExp('/chat-referral/[0-9]+').test(pathname)              // Regexp for new referrral chat
    || (!transactionRecord.status && isTransactionResultCurrentRoute)
    || (showBankInfoPage && isMainCurrentRoute)
    || pathname.includes('/termsAndConditions')
    || pathname.includes('/component-playground');                     // Page to have a playground where places and test new components

  const handleOnBottomNavigationItemClick = (path, index) => {
    setValue(index);
    if (path) {
      history.push(path);
    }
  };

  const getChatSvgIcon = () => {
    if (Array.isArray(unreadMessages) && unreadMessages.length) {
      return (
        <Badge
          badgeContent={unreadMessagesCount}
          classes={{ colorPrimary: classes.unreadMessages }}
          color="primary"
        >
          <GmSvgIcon
            disabled={!pathname.includes('/chat')}
            enabled={pathname.includes('chat')}
            icon="message"
            viewBox="0 -4 24 24"
          />
        </Badge>
      );
    }

    return (
      <GmSvgIcon
        disabled={value !== 1}
        enabled={value === 1}
        icon="message"
        viewBox="0 -4 24 24"
      />
    );
  };

  useEffect(() => {
    const path = pathname.includes('chat') ? routes.chat : pathname;

    switch (path) {
      case routes.histories:
        setValue(0);
        break;
      case routes.chat:
        setValue(1);
        break;
      case routes.loans:
        setValue(2);
        break;
      case routes.notifications:
        setValue(3);
        break;
      case routes.main:
      default:
        setValue(4);
        break;
    }
  }, [pathname]);

  if (hideBottomNavigation) {
    return null;
  }

  return (
    <BottomNavigation
      className={classes.bottomNavigation}
      showlabel=""
      value={value}
    >
      <Grid
        alignContent="center"
        alignItems="center"
        container
        direction="row"
        justify="space-evenly"
        style={{ paddingTop: 5 }}
      >
        <Grid className={classes.bottomNavigationItem} item>
          <IconButton onClick={() => handleOnBottomNavigationItemClick(routes.histories, 0)}>
            <GmSvgIcon
              disabled={value !== 0}
              enabled={value === 0}
              icon="eyes"
              style={{ width: 30 }}
              viewBox="0 0 24 24"
            />
          </IconButton>
          <Typography className={classes.bottomNavigationItemLabel} variant="caption">
            Historias
          </Typography>
        </Grid>
        <Grid className={classes.bottomNavigationItem} item>
          <IconButton onClick={() => handleOnBottomNavigationItemClick(routes.chat, 1)}>
            {getChatSvgIcon()}
          </IconButton>
          <Typography className={classes.bottomNavigationItemLabel} variant="caption">
            Chat
          </Typography>
        </Grid>
        <Grid className={classes.bottomNavigationItem} item>
          <IconButton onClick={() => handleOnBottomNavigationItemClick(routes.loans, 2)}>
            <GmSvgIcon
              disabled={value !== 2}
              enabled={value === 2}
              icon="action-center"
              style={{ width: 50 }}
              viewBox={(value === 2)
                ? '-2 0 34 20'
                : '18 0 50 52'}
            />
          </IconButton>
          <Typography className={classes.bottomNavigationItemLabel} variant="caption">
            Karma
          </Typography>
        </Grid>
        <Grid className={classes.bottomNavigationItem} item>
          <IconButton onClick={() => handleOnBottomNavigationItemClick(routes.notifications, 3)}>
            <GmSvgIcon
              disabled={value !== 3}
              enabled={value === 3}
              icon="notifications"
              viewBox={(value === 3)
                ? '0 0 24 22'
                : '3 -2 56 56'}
            />
          </IconButton>
          <Typography className={classes.bottomNavigationItemLabel} variant="caption">
            Notificaciones
          </Typography>
        </Grid>
        <Grid className={classes.bottomNavigationItem} item>
        <IconButton onClick={() => handleOnBottomNavigationItemClick(routes.main.replace('/:userId?', ''), 4)}>
          <GmSvgIcon
            disabled={value !== 4}
            enabled={value === 4}
            icon="profile"
            style={{ height: 30, width: 30 }}
            viewBox={(value === 4)
              ? '0 0 24 24'
              : '0 0 68 68'}
          />
        </IconButton>
        <Typography className={classes.bottomNavigationItemLabel} variant="caption">
          Perfil
        </Typography>
        </Grid>
      </Grid>
    </BottomNavigation>
  );
}

export default GmBottomNavigation;
