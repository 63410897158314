import { makeStyles } from '@material-ui/core/styles';

const commonStoryText = {
  color: 'rgba(0, 0, 0, 0.78)',
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  lineHeight: '1.5',
  textAlign: 'left',
  margin: '0 20px'
};

const boldText = {
  ...commonStoryText,
  fontWeight: 'bold'
};

const useStyles = makeStyles({
  bottomNavigation: {
    borderTop: '1px solid #CFD2D7',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  ArrowBackIconCustom: {
    width: '10%',
    fontWeight: 'bold',
    color: '#7D7D7D',
    marginLeft: 20,
  },
  titleHeader: {
    width: '70%',
    fontSize: 16,
    color: '#0F1E36',
    fontWeight: 'bold',
  },
  contentActionBottom: {
    display: 'flex',
    flexDirection: 'row',
    gap: 50,
    alignItems: 'center',
    marginTop: '2px',
  },
  storiesHeader: {
    height: 100,
    maxHeight: 100,
    padding: 20,
  },
  histories: {
    height: '85vh',
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: 90,
    width: '100%',
  },
  editHistory: {
    position: 'absolute',
    zIndex: 1,
    background: '#7D7D7D !important',
    color: 'white',
    borderRadius: '100%',
    right: 15,
    top: 135,
    marginTop: 10,
    cursor: 'pointer',
    minWidth: 12,
    '& svg': {
      fontSize: 16,
    },
  },
  divider: {
    margin: '1.375rem'
  },
  reviewContainer: {
    marginLeft: '1.375rem',
    marginRight: '1.375rem',
    marginBottom: '1.375rem'
  },
  reviewTittle: {
    marginBottom: '1.938rem',
    marginLeft: '1.375rem',
    textAlign: 'initial'
  },
  reviewer: {
    textAlign: 'initial'
  },
  reviewerName: {
    fontWeight: 'bold'
  },
  reviewHeader: {
    fontWeight: 'bold'
  },
  commentPic: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  comment: {
    marginTop: -10,
    textAlign: 'initial'
  },
  ranking: {
    display: 'block',
    float: 'right'
  },
  image: {
    paddingLeft: '1.375rem'
  },
  financingValue: {
    ...boldText,
    marginTop: 10,
    marginBottom: 15
  },
  likes: {
    ...boldText,
    marginTop: 5,
    marginBottom: 10
  },
  title: {
    ...boldText
  },
  gmStoryContentDescription: {
    ...commonStoryText,
    fontSize: 15,
    fontWeight: 400,
    margin: 0,
    overflow: 'hidden'
  },
});

export { useStyles };
