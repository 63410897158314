// vendors
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CreateIcon from '@material-ui/icons/Create';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { getUserInfo, saveOnlyProfileImg } from '../../redux/actions/register';

// components
import GmImageCrop from '../../components/image-crop';
import GmProfileForm from '../../components/forms/profile';

// utils
import {
  getAsDataURL,
  getAsImg,
  getBase64,
  getCroppedImg
} from '../../utils';

// images
import GmLogo from '../../components/logo';

// styles
import { useStyles } from './styles';

// constants
import { MAX_IMG_SIZE } from '../../config/constants';

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const { auth, register } = useSelector((state) => state);

  const [q0img, setQ0img] = useState(null);
  const [imgToCrop, setImgToCrop] = useState(null);
  const [isImgTooBig, setIsImgTooBig] = useState(false);
  const [uploadingImg, setUploadingImg] = useState(false);
  const [uploadingDocImg, setUploadingDocImg] = useState(false);
  const [profilePicture, setProfilePicture] = useState(auth?.userInfo?.profilePicture || null);

  useEffect(() => {
    dispatch(getUserInfo());
    const documentPicUrl = auth?.userInfo?.documentPicture;
    const docImg = {
      name: documentPicUrl,
      type: `image/${documentPicUrl?.split('.').pop()}`
    };
    setQ0img(docImg);
  }, []);

  const classes = useStyles();

  const onChangeImage = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];

      if (img.size > MAX_IMG_SIZE) {
        setIsImgTooBig(true);
        setImgToCrop({});
        setUploadingImg(true);
        return;
      }

      const fileData = await getAsDataURL(img);

      setImgToCrop({
        name: img.name,
        type: img.type,
        content: fileData
      });
      setUploadingImg(true);
      setIsImgTooBig(false);
    }
  };

  const onUploadDocImg = async (imgBase64) => {
    if (imgBase64.size > MAX_IMG_SIZE) {
      setIsImgTooBig(true);
      setImgToCrop({});
      setUploadingDocImg(true);
      return true;
    }

    const img = await getAsImg(imgBase64);

    setImgToCrop({
      name: img.id,
      type: img.type,
      content: img.src
    });
    setIsImgTooBig(false);
    setUploadingDocImg(true);
    return true;
  };

  const onCancelImageCrop = () => {
    setIsImgTooBig(false);
    setImgToCrop(imgToCrop || null);
    setUploadingImg(false);
    setUploadingDocImg(false);
  };

  const onConfirmImageCrop = async (crop, image) => {
    const img = await getCroppedImg(crop, image, imgToCrop);
    const data = await getBase64(img);
    const imageReturned = {
      content: data,
      name: img.name,
      type: img.type.replace('image/', '')
    };

    if (uploadingDocImg) {
      setQ0img(imageReturned);
    } else {
      dispatch(saveOnlyProfileImg(imageReturned));
      setProfilePicture(`data:${img?.type};base64,${data}`);
    }

    setIsImgTooBig(false);
    setImgToCrop(null);
    setUploadingImg(false);
    setUploadingDocImg(false);
  };

  return (
    <>
      {imgToCrop && uploadingImg && (
        <GmImageCrop
          isImgTooBig={isImgTooBig}
          onCancelCrop={onCancelImageCrop}
          onConfirmCrop={onConfirmImageCrop}
          src={imgToCrop.content}
          visible={imgToCrop}
        />
      )}
      {imgToCrop && uploadingDocImg && (
        <GmImageCrop
          aspectRatio="16:9"
          isImgTooBig={isImgTooBig}
          onCancelCrop={onCancelImageCrop}
          onConfirmCrop={onConfirmImageCrop}
          src={imgToCrop.content}
          visible={imgToCrop}
        />
      )}
      <Paper className={classes.root}>
        <Container>
          <Grid container justify="center" className={classes.center}>
            <div className={classes.brandLogo}>
              <GmLogo />
            </div>
            <Grid item xs={12}>
              <Typography gutterBottom style={{ color: '#ffffffff' }} variant="subtitle2">
                Editar perfil
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {profilePicture ? (
                <div role="button">
                  <label htmlFor="mainPicture">
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="mainPicture"
                      type="file"
                      onChange={onChangeImage}
                    />
                    <Button component="div">
                      <img src={profilePicture} alt="profile" className={classes.imgProfile} />
                    </Button>
                  </label>
                </div>
              ) : (
                <div className={classes.imgProfile} role="button">
                  <label htmlFor="mainPicture">
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="mainPicture"
                      type="file"
                      onChange={onChangeImage}
                    />
                    <Button component="div">
                      <div className={classes.editProfile}>
                        <CreateIcon className={classes.editIcon} />
                      </div>
                    </Button>
                  </label>
                </div>
              )}
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Grid container style={{ height: '100vh' }}>
        <Grid item xs={12}>
          <GmProfileForm
            onUploadDocImg={onUploadDocImg}
            q0img={q0img}
            userInfo={register.userInfo}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProfilePage;
