// vendors
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';

import GmSvgIcon from '../../static/svg';

// styles
import { useStyles } from './styles';

export const GmMessageActionBar = ({
  isReferral,
  message,
  onKeyPress,
  onOpenLoanCalculator,
  onSendMessage,
  onSetMessage,
  visible,
}) => {
  const classes = useStyles();

  if (!visible) {
    return null;
  }

  return (
    <Grid
      alignItems="center"
      container
      direction="row"
      justify="center"
      style={{
        backgroundColor: '#ffffff',
        borderTop: '1px solid #D8D8D8',
        bottom: 0,
        height: 60,
        maxHeight: 60,
        position: 'absolute',
      }}
    >
      {!isReferral && (
        <Grid item xs={2}>
          <IconButton
            onClick={() => onOpenLoanCalculator({
              isLoanCalculatorOpen: true,
              isLoanCalculatorOpenFromActionBar: true,
            })}
            role="button"
          >
            <GmSvgIcon enabled icon="calculator" viewBox="0 0 61 76" />
          </IconButton>
        </Grid>
      )}
      <Grid item xs={8}>
        <TextField
          className={classes.newMessageTextField}
          fullWidth
          onChange={onSetMessage}
          onKeyDown={onKeyPress}
          placeholder="Escribe tu mensaje"
          value={message}
          variant="standard"
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={onSendMessage} role="button">
          <SendIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default GmMessageActionBar;
