import React from 'react';
import { useStyles } from './styles';

const MonthHeader = ({ currentDate }) => {
  const classes = useStyles();
  const monthNames = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
  const month = monthNames[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  return (
    <div className={classes.monthHeader}>
      {`${month} ${year}`}
    </div>
  );
};

export default MonthHeader;
