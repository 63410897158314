/* eslint-disable max-len */

// vendors
import React from 'react';

const ReportPaymentDisabled = (
  <>
    <g clipPath="url(#clip0)">
      <path d="M0.0192371 14.651L0.0646055 6.35343C0.0806179 5.56516 0.699763 4.93178 1.46035 4.92625C2.19692 4.92625 2.80005 5.53197 2.8214 6.29535L2.79472 11.3734C2.79472 11.6168 2.98153 11.8132 3.21638 11.8187C3.21638 11.8187 3.21638 11.8187 3.21904 11.8187C3.45122 11.8215 3.64337 11.6251 3.64337 11.3845L3.68607 3.54886C3.69942 2.75782 4.31856 2.12444 5.08182 2.11891C5.82106 2.11891 6.42686 2.73293 6.44287 3.49907L6.41351 8.56887C6.41351 8.81227 6.60032 9.00864 6.83517 9.01141H6.83784C7.07002 9.01141 7.2595 8.8178 7.26217 8.57717L7.29686 2.12444C7.31021 1.33618 7.92935 0.700031 8.69261 0.697266C9.43185 0.697266 10.0376 1.30852 10.0537 2.07742L10.0403 4.35925V4.38138L10.0136 8.98928C10.0163 9.22438 10.1978 9.41799 10.4246 9.42629C10.6595 9.43458 10.8543 9.24374 10.8623 9.00035C10.8916 3.78119 10.8863 4.43393 10.889 4.37308C10.913 3.59034 11.5295 2.96803 12.2847 2.9625C13.024 2.9625 13.6298 3.57375 13.6458 4.34266L13.5951 13.4921C13.5951 13.6083 13.6378 13.7217 13.7178 13.8046C13.8833 13.9789 14.1528 13.9816 14.3183 13.8102L16.0823 11.9958C16.6161 11.4371 17.4834 11.426 18.0332 11.9681C18.0412 11.9764 18.0492 11.9847 18.0572 11.993C18.5669 12.5434 18.5482 13.4202 18.0172 13.9457L12.1406 19.9891C10.7742 21.2337 9.02353 21.9307 7.20346 21.9556C2.2503 22.0413 -0.180918 18.7444 0.0192371 14.651Z" fill="#C5C4C4" />
      <path d="M10.8252 9.75537L5.25293 13.9512L7.37991 20.7441H14.2706L16.4002 13.9512L10.8252 9.75537Z" fill="#DEDDDD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.41113 14.5845C9.41113 13.7752 10.0441 13.1191 10.825 13.1191C11.6058 13.1191 12.2389 13.7752 12.2389 14.5845H11.402C11.402 14.2542 11.1437 13.9865 10.825 13.9865C10.5063 13.9865 10.248 14.2542 10.248 14.5845C10.248 14.9147 10.5063 15.1825 10.825 15.1825C11.6058 15.1825 12.2389 15.8385 12.2389 16.6478C12.2389 17.457 11.6058 18.1131 10.825 18.1131C10.0441 18.1131 9.41113 17.457 9.41113 16.6478H10.248C10.248 16.978 10.5063 17.2458 10.825 17.2458C11.1437 17.2458 11.402 16.978 11.402 16.6478C11.402 16.3175 11.1437 16.0498 10.825 16.0498C10.0441 16.0498 9.41113 15.3937 9.41113 14.5845Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4067 13.5502V12.5186H11.2436V13.5502H10.4067Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4067 18.7138V17.6821H11.2436V18.7138H10.4067Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18.4196" height="21.2611" fill="white" transform="translate(0.00878906 0.697266)" />
      </clipPath>
    </defs>
  </>
);

export default ReportPaymentDisabled;
