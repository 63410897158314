import { makeStyles } from '@material-ui/core/styles';

const itemTextContent = {
  color: '#33647E',
  fontSize: 18,
  fontWeight: 700
};

const proposalItem = {
  padding: 5
};

const proposalContainer = {
  borderRadius: 10,
  padding: '25px 10px'
};

const proposalButton = {
  backgroundColor: '#FFC600',
  color: '#47657C',
  height: 34,
  '&:hover': {
    backgroundColor: '#FFC600',
  },
};

const proposalClasses = {
  proposalContainer: {
    ...proposalContainer,
    backgroundColor: '#AFE2E3'
  },
  proposalAcceptedContainer: {
    ...proposalContainer,
    backgroundColor: '#F4D3D6'
  },
  lastPaymentDetailContainer: {
    ...proposalContainer,
    backgroundColor: '#FFC600'
  },
  proposalName: {
    color: '#0F1E36',
    fontSize: 20
  },
  proposalNumber: {
    color: '#33647E',
    fontSize: 32,
    fontWeight: 700,
  },
  proposalAvatar: {
    marginBottom: 10,
    marginTop: 5,
  },
  proposalAvatarImg: {
    height: 70,
    width: 70
  },
  proposalContentContainer: {
    margin: 25,
    marginTop: 15
  },
  auxProposalButton: {
    ...proposalButton,
  },
  loanAmmountHeader: {
    textTransform: 'uppercase',
    color: '#33647E',
    fontSize: 15
  },
  itemTitle: {
    textTransform: 'uppercase',
    color: '#33647E',
    fontSize: 11
  },
  itemUpperLeft: {
    ...proposalItem,
    borderTop: '1px solid #AFE2E3',
    borderBottom: '1px solid #AFE2E3',
    borderRight: '1px solid #AFE2E3'
  },
  itemUpperRight: {
    ...proposalItem,
    borderTop: '1px solid #AFE2E3',
    borderBottom: '1px solid #AFE2E3'
  },
  itemLowerLeft: {
    ...proposalItem,
    borderRight: '1px solid #AFE2E3',
    borderBottom: '1px solid #AFE2E3'
  },
  itemLowerRight: {
    ...proposalItem,
    borderBottom: '1px solid #AFE2E3'
  },
  itemAcceptedUpperLeft: {
    ...proposalItem,
    borderTop: '1px solid #F4D3D6',
    borderBottom: '1px solid #F4D3D6',
    borderRight: '1px solid #F4D3D6'
  },
  itemAcceptedUpperRight: {
    ...proposalItem,
    borderTop: '1px solid #F4D3D6',
    borderBottom: '1px solid #F4D3D6'
  },
  itemAcceptedLowerLeft: {
    ...proposalItem,
    borderRight: '1px solid #F4D3D6',
    borderBottom: '1px solid #F4D3D6'
  },
  itemAcceptedLowerRight: {
    ...proposalItem,
    borderBottom: '1px solid #F4D3D6'
  },
  itemTextContent,
  proposalButton
};

const paymentConfirmationClasses = {
  paymentBlueContainer: {
    ...proposalContainer,
    backgroundColor: '#AFE2E3'
  },
  paymentRedContainer: {
    ...proposalContainer,
    backgroundColor: '#F4D3D6'
  },
  paymentDetailItem: {
    borderBottom: '1px solid #F4D3D6',
    marginBottom: 5,
    paddingBottom: 5
  },
  loanDetailItem: {
    borderBottom: '1px solid #AFE2E3',
    marginBottom: 5,
    paddingBottom: 5
  }
};

const signPromissoryNoteClasses = {
  signPromissoryNoteContainer: {
    alignItems: 'center',
    backgroundColor: '#FFDD65',
    borderRadius: 10,
    marginBottom: 15,
    padding: '25px 10px',
  },
  signPromissoryNoteButton: {
    backgroundColor: '#FFC600',
    color: '#47657C',
    fontWeight: 700,
    marginBottom: 20,
    marginTop: 5,
  },
  signPromissoryNoteTitle: {
    fontSize: '1rem',
  },
};

const systemMessageClasses = {
  systemMessage: {
    background: '#FFEDB5',
    borderRadius: 10,
    color: '#33647E',
    padding: '14px 16px',
    marginBottom: 15,
    width: '100%',
  },
  systemMessageContent: {
    width: '100%',
    textAlign: 'justify',
  },
};

export const useStyles = makeStyles((theme) => ({
  listMessageNotification: {
    background: '#AFE2E3',
    borderRadius: 10,
    border: '1px solid #D8D8D8',
    color: '#0F1E36',
    marginBottom: 15,
    width: '100%',
  },
  listMessageNotificationContent: {
    fontWeight: 600,
    width: '100%',
    textAlign: 'center',
  },
  paymentConfirmed: {
    color: '#0F1E36',
    fontSize: 18,
    fontWeight: 700,
  },
  imagesContainer: {
    alignItems: 'end',
    maxHeight: 60,
    overflow: 'hidden',
  },
  image: {
    display: 'block',
    height: '15vh',
    objectFit: 'cover',
    width: '15vh',
  },
  listMessageSender: {
    border: '1px solid #D8D8D8',
    borderRadius: '10px 10px 10px 0',
    background: '#FFFFFF',
    color: '#676767',
    marginBottom: 15,
    fontSize: '14px !important',
    width: '90%',
  },
  listMessageReceiver: {
    border: '1px solid #D8D8D8',
    borderRadius: '10px 10px 0px 10px',
    background: 'rgba(221, 221, 221, 0.4)',
    color: '#676767',
    marginBottom: 15,
    fontSize: '14px !important',
    width: '90%',
    float: 'right',
  },
  proposalModified: {
    background: 'rgba(221, 221, 221, 0.2)',
    border: '1px solid #D8D8D8',
    borderRadius: 10,
    color: '#676767',
    fontSize: '14px !important',
    fontStyle: 'italic',
    marginBottom: 15,
    width: '100%',
  },
  contentAvatar: {
    marginTop: 20,
    marginBottom: 20,
  },
  paper: {
    marginBottom: 15,
  },
  buttonCalculator: {
    marginLeft: -24,
    width: '49%',
  },
  lastPaymentModalTitle: {
    color: '#0F1E36',
    fontSize: 16,
    fontWeight: 700,
  },
  lastPaymentModalReviewTitle: {
    fontSize: 14,
    fontWeight: 400,
  },
  lastPaymentModalReviewComment: {
    width: '100%',
  },
  lastPaymentModalReviewContainer: {
    paddingTop: 25,
    paddingBottom: 20,
  },
  messageDate: {
    display: 'flex',
    textAlign: 'end',
    ...theme.typography.lightCaption
  },
  messageContainer: {
    margin: 15,
    width: '100%'
  },
  lastPaymentDetailHeader: {
    fontSize: 16,
    fontWeight: 700,
  },
  lastPaymentDetailItem: {
    borderBottom: '1px solid #FFC600',
    marginBottom: 5,
    paddingBottom: 5
  },
  refer: {
    border: 'none',
    background: '#fff',
    outline: 'none',
  },
  nextActionContainer: {
    marginTop: 10
  },
  nextActionCaption: {
    fontSize: 12
  },
  nextActionIcon: {
    width: 40,
    height: 40
  },
  confirmButtonBlue: {
    backgroundColor: '#009fa3',
    color: 'white',
    cursor: 'pointer',
    fontSize: 15,
    fontWeight: 'normal',
    '&.active': {
      color: '#1DCAD3',
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: '#00888c',
      outline: 'none',
    },
  },
  confirmButtonRed: {
    backgroundColor: '#f5abb2',
    color: '#89373f',
    cursor: 'pointer',
    fontSize: 15,
    fontWeight: 'normal',
    '&.active': {
      color: '#1DCAD3',
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: '#e58e97',
      outline: 'none',
    },
  },
  ...proposalClasses,
  ...paymentConfirmationClasses,
  ...signPromissoryNoteClasses,
  ...systemMessageClasses
}));
