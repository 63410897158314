/* eslint-disable no-multi-spaces */
// packages
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  MenuItem,
  TextField
} from '@material-ui/core';
import numeral from 'numeral';

// actions
import {
  createNewStory,
  getCreateStoryPhrases,
  getCreateStoryQuestions,
  updateStoryCreation,
  updateStoryQuestionPictures,
} from '../../redux/actions/story';
import { getCalculatorInfo, setProposal, editCreationProposal } from '../../redux/actions/createHistory';
import { getAdministrativeExpensesSettingsChain } from '../../redux/actions/settings';

// components
import GmAlertMessage from '../../components/alert';
import GmCaptionText from '../../components/caption';
import GmFormStoryQuestionsSelector from './questions';
import GmInformativeText from '../../components/informative-text';
import GmStoryCategoriesSelector from './categories';
import GmStoryQuestions from '../../components/story-questions';
import GmStoryTextField from '../../components/story-text-field';
import GmStoryUploadPictures from '../../components/story-upload-pictures';
import GmYellowLogo from '../../components/yellow-logo';

// constants
import { CATEGORIES, ERRORS_HANDLER, STORY_PICTURES } from './constants';

// routes
import { routes } from '../../config/routes';

// styles
import { useStyles } from './styles';

// utils
import { allValuesAreValid } from './utils';
import { generateProposal } from './proposal-utils';

const CreateHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.common);
  // const {  history: { editData, historyInfo } } = useSelector((state) => state);
  const {  history: { historyInfo } } = useSelector((state) => state);
  const { settings: { administrativeExpensesPercent, administrativeExpensesSettings } } = useSelector((state) => state);
  const classes = useStyles();

  // error object handler
  const [errors, setErrors] = useState(ERRORS_HANDLER);
  // story id given in useEffect
  const [storyId, setStoryId] = useState(null);
  // story category
  const [category, setCategory] = useState(null);
  // story description
  const [storyDescription, setStoryDescription] = useState('');
  // form story questions
  const [formStoryQuestions, setFormStoryQuestions] = useState({});
  const [initialFormStoryQuestions, setInitialFormStoryQuestions] = useState([]);
  // story images and pictures
  const [storyPictures, setStoryPictures] = useState(STORY_PICTURES);
  // random question phrases to evaluate user risk
  const [storyQuestions, setStoryQuestions] = useState([]);
  const [storyQuestionSelected, setStoryQuestionSelected] = useState('');
  const [loanAmount, setLoanAmount] = useState(0);
  const [frequency, setFrequency] = useState('');
  const [installments, setInstallments] = useState('');
  // const [existingTexts, setExistingTexts] = useState([]);
  // const [existingImages, setExistingImages] = useState([]);

  useEffect(() => {
    if (historyInfo?.category) {
      setCategory(historyInfo.category);
    }

    /* if (editData.length > 0) {
      const myImages = Object.keys(editData).length > 0
        ? editData.flatMap((item) => (item.file && item.file.href && item.file.href !== '' ? [item.file] : []))
        : {};
      const myTexts = Object.keys(editData).length > 0
        ? editData.flatMap((item) => (item && item.answer && item.answer !== '' ? [item.answer] : []))
        : {};

      const imagesWithDescriptions = myImages?.map((image, index) => ({
        image,
        ...STORY_PICTURES[index]
      }));

      setExistingTexts(myTexts);
      setExistingImages(imagesWithDescriptions);
    }

    if (existingImages.length) {
      setStoryPictures(existingImages);
    } */

    dispatch(getAdministrativeExpensesSettingsChain());

    const neededOperationsRequests = [dispatch(getCreateStoryPhrases()), dispatch(getCreateStoryQuestions())];
    Promise.all(neededOperationsRequests).then((values) => {
      const [storyPhrases, storyFormQuestions] = values;
      setStoryQuestions(storyPhrases);
      setInitialFormStoryQuestions(storyFormQuestions.filter(
        (question) => question.keyname === 'Obstacle' || question.keyname === 'ActionToOvercomeObstacle'
      ));
      // Create new story after story questions and story phrases were populated into the local state
      dispatch(createNewStory()).then(({ questionPicturesIds, storyId }) => {
        const newStoryPicturesWithMatchedIds = STORY_PICTURES.map((picture, index) => ({ ...picture, id: questionPicturesIds[index] }));
        setStoryPictures(newStoryPicturesWithMatchedIds);
        setStoryId(storyId);
        dispatch(getCalculatorInfo(storyId));
      });
    });
  }, []);

  const handleOnGoBack = () => history.goBack();

  const handleOnLoanAmountChange = ({ target: { value } }) => {
    let loanValue = numeral(value).value() ?? 0;
    // TODO: this will change to a million COP.
    if (loanValue > 1000000) {
      loanValue = 1000000;
    } else if (loanValue < 0) {
      loanValue = 0;
    }
    setLoanAmount(numeral(loanValue).format('$0,0'));
  };

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  const handleInstallmentsChange = ({ target: { value } }) => {
    let installmentsValue = value;
    if (installmentsValue > 12) {
      installmentsValue = 12;
    }
    setInstallments(installmentsValue);
  };

  const handleStoryTitleChange = (event) => {
    const { value } = event.target;
    if (value.length <= 100) {
      setStoryDescription(value);
    }
  };

  const handleGenerateProposal = async (sanitizedLoanAmount) => {
    const proposalId = historyInfo?.proposal?.id;
    try {
      const proposal = await generateProposal(
        frequency,
        sanitizedLoanAmount,
        installments,
        administrativeExpensesPercent,
        administrativeExpensesSettings,
        proposalId
      );

      if (proposalId) {
        dispatch(editCreationProposal({ ...proposal, historyId: storyId, id: proposal.id }));
      } else {
        dispatch(setProposal({ ...proposal, historyId: storyId }));
      }
    } catch (error) {
    }
  };

  // function to submit story
  const handleOnSubmit = () => {
    const filteredFormStoryQuestions = storyPictures.filter((item, index) => {
      const answer = formStoryQuestions[index];
      return typeof answer === 'string';
    });
    const processedFormStoryQuestions = filteredFormStoryQuestions.map((item, index) =>
      ({ id: item.id, answer: formStoryQuestions[Object.keys(formStoryQuestions)[index]] }));
    const formStoryValuesValidated = allValuesAreValid({
      category,
      errors,
      frequency,
      initialFormStoryQuestions,
      installments,
      loanAmount,
      processedFormStoryQuestions,
      setErrors,
      setInitialFormStoryQuestions,
      setStoryPictures,
      storyDescription,
      storyPictures,
      storyQuestionSelected,
    });

    if (!formStoryValuesValidated) {
      return;
    }

    // "history" word used to keep consistency with was expected in the request
    const historyPictures = storyPictures.map(({
      base64,
      id,
      name,
      type,
    }) => ({
      id,
      historyId: storyId,
      file: {
        name,
        type,
        content: base64
      }
    }));
    const sanitizedLoanAmount = loanAmount.replace(/[^0-9.-]+/g, '');
    handleGenerateProposal(parseFloat(sanitizedLoanAmount));

    const storyData = {
      category,
      description: storyDescription,
      // TODO: Below value property comes from calculator
      financingValue: parseFloat(sanitizedLoanAmount),
      id: storyId,
      risk: storyQuestionSelected,
      historyQuestions: processedFormStoryQuestions,
    };

    const storyCreationPromises = [
      dispatch(updateStoryCreation(storyData)),
      ...historyPictures.map((historyPicture) => dispatch(updateStoryQuestionPictures(historyPicture)))
    ];

    Promise.all(storyCreationPromises).then((values) => {
      // all requests were fulfilled successfully
      // 1 from update storyh creation
      // 4 from story pictures
      // 1 from set proposal
      if (values.length === 5) {
        history.push(routes.main.replace(':userId?', ''));
      }
    });
  };

  return (
    <div className={classes.createHistoryPage}>
      <Grid container justify="center">
        <Grid item>
          <GmYellowLogo style={{ marginBottom: 25, width: 80 }} />
        </Grid>
      </Grid>
        <>
          <>
            <GmCaptionText>
              Sube una historia que sea bien calidos
            </GmCaptionText>
            <GmInformativeText style={{ marginBottom: 15 }}>
              Es tu momento para crear una historia que enamore.
              Recuerda, los ángeles aún no te conocen y esta es tu oportunidad para que se conecten contigo.
            </GmInformativeText>
          </>
          <>
            <GmStoryTextField
              error={errors.description}
              id="description"
              name="description"
              normalText
              onChange={handleStoryTitleChange}
              placeholder="Escribe algo corto que los ángeles puedan entender para qué necesitas la plata"
              rows={2}
              style={{ marginBottom: 15 }}
              title="Ponle un título a tu historia"
              value={storyDescription}
            />
          </>
          <>
            <GmCaptionText textAlign="left">
              Define la categoría de tu historia
            </GmCaptionText>
            <GmStoryCategoriesSelector
              categories={CATEGORIES}
              categorySelected={category}
              error={errors.category}
              onSelectCategory={(categorySelected) => {
                setCategory(categorySelected);
              }}
            />
          </>
          <>
            <GmFormStoryQuestionsSelector
              questions={initialFormStoryQuestions}
              onQuestionsChange={(answers) => setFormStoryQuestions(answers)}
            />
          </>
          <>
            <GmCaptionText textAlign="left">
              Sube tus fotos
            </GmCaptionText>
            <GmInformativeText style={{ marginBottom: 15 }}>
              Metésela toda a tomarte unas fotos bien calidosas. Éstas son clave para que los ángeles te conozcan mejor.
              Fijate que tengan muy buena calidad y enfocate en contar una buena historia. Tomátelas en formato horizontal.
            </GmInformativeText>
          </>
          <>
            <GmStoryUploadPictures
              onChangePictures={(newStoryPictures) => setStoryPictures(newStoryPictures)}
              pictures={storyPictures}
              // pictures={existingImages.length > 0 ? existingImages : storyPictures}
            />
          </>
          <>
            <GmCaptionText textAlign="left">
              Financiación
            </GmCaptionText>
            <GmInformativeText style={{ marginBottom: 30 }}>
              Tu ángel te va a hacer el préstamo por el monto de tu historia y adicional te va a prestar para que puedas
              pagar los costos del servicio, por esto, en la historia sale el monto de la historia + dichos costos.
            </GmInformativeText>
            <TextField
              className={classes.margin}
              id="financing-value"
              label="¿Qué monto necesitas?"
              onChange={handleOnLoanAmountChange}
              value={loanAmount}
              variant="outlined"
            />
            {errors.loanAmount
              ? (
                <GmAlertMessage
                  style={{
                    marginBottom: 15,
                    marginTop: 0,
                    padding: '0px 20px',
                    textAlign: 'left',
                  }}
                  visible={errors.loanAmount}
                  variant="filled"
                >
                  <strong>{errors.loanAmount}</strong>
                </GmAlertMessage>
              )
              : null}
            <TextField
              select
              label="¿Con qué frecuencia puedes pagar?"
              value={frequency}
              onChange={handleFrequencyChange}
              variant="outlined"
              type="number"
              fullWidth
              style={{ marginTop: '20px', textAlign: 'left' }}
            >
              <MenuItem value={0}>Diario</MenuItem>
              <MenuItem value={1}>Semanal</MenuItem>
              <MenuItem value={2}>Quincenal</MenuItem>
              <MenuItem value={3} className={classes.menuItem}>Mensual</MenuItem>
            </TextField>
            {errors.frequency
              ? (
                <GmAlertMessage
                  style={{
                    marginBottom: 15,
                    marginTop: 0,
                    padding: '0px 20px',
                    textAlign: 'left',
                  }}
                  visible={errors.frequency}
                  variant="filled"
                >
                  <strong>{errors.frequency}</strong>
                </GmAlertMessage>
              )
              : null}
            <TextField
              label="¿En cuántas cuotas puedes pagar?"
              value={installments}
              onChange={handleInstallmentsChange}
              type="number"
              variant="outlined"
              fullWidth
              style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'left' }}
            />
            {errors.installments
              ? (
                <GmAlertMessage
                  style={{
                    marginBottom: 15,
                    marginTop: -20,
                    padding: '0px 20px',
                    textAlign: 'left',
                  }}
                  visible={errors.installments}
                  variant="filled"
                >
                <strong>{errors.installments}</strong>
                </GmAlertMessage>
              )
              : null}
          </>
          <>
            <GmCaptionText>
              Elige una frase
            </GmCaptionText>
            <GmCaptionText>
              {`De estas ${storyQuestions.length} frases con cuál es con la que más te identificas:`}
            </GmCaptionText>
            <GmStoryQuestions
              error={errors.phrase}
              onClick={(questionId) => setStoryQuestionSelected(questionId)}
              questionDescriptionKey="phrase"
              questionSelected={storyQuestionSelected}
              questions={storyQuestions}
              style={{ marginBottom: 15 }}
            />
          </>
        </>
      <>
        <Grid container direction="row">
          <Grid item xs={6}>
            <Button
              disabled={loading}
              onClick={handleOnGoBack}
              variant="text"
            >
              REGRESAR
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              disabled={loading}
              onClick={handleOnSubmit}
              variant="contained"
            >
              CONTINUAR
            </Button>
          </Grid>
        </Grid>
      </>
    </div>
  );
};

export default CreateHistory;
