// vendors
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';

// constants
import { routes } from '../../config/routes';

// components
import BlueLogo from '../../components/blue-logo';

// images
import Icon from '../../static/images/ReferralConfirmationIcon.svg';

// styles
import { useStyles } from './styles';

const ReferralConfirmation = ({ name }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.confirmationScreen}>
      <Paper className={classes.confirmationMessageHeader}>
        <ArrowBackIcon
          className={classes.ArrowBackIconCustom}
          onClick={() => history.push(routes.main.replace('/:userId?', ''))}
        />
        <Container>
          <Grid container justify="center" className={classes.center} style={{ marginLeft: 5 }}>
            <Grid className={classes.logIn} item xs={12}>
              <BlueLogo />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{
                fontSize: 16,
                fontFamily: 'Open Sans',
                fontWeight: 'bold',
                marginTop: 10
              }}
              >
                Referir a un amigo
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Grid container justify="center" style={{ margin: '10px' }}>
        <Grid item xs={12}>
          <Grid
            container
            justify="center"
            className={classes.confirmationMessageBox}
            style={{ maxWidth: '94.5%', overflow: 'hidden' }}
          >
            <img className={classes.confirmationMessageIcon} src={Icon} />
            <Grid item xs={12}>
              <Typography className={classes.confirmationMessageTitle}>¡PURO PODER!</Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20, marginTop: 10 }}>
              <Typography className={classes.confirmationMessageText} style={{ fontWeight: 'bold' }}>Gracias por compartir GiVEmeFiVE</Typography>
              <Typography className={classes.confirmationMessageText}>
                {`Tu invitación con el código de referido le llegará a ${name}, 
                dile que revise su correo y con este código de referido podrá crear su cuenta.`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReferralConfirmation;
