// vendors
import { makeStyles } from '@material-ui/core/styles';

// images
import location from '../../static/images/location.png';

export const useStyles = makeStyles((theme) => ({
  root2: {
    backgroundImage: `url(${location})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'grid',
    height: '40vh',
    gridTemplateRows: 'auto 1fr auto',
  },
  brandLogo: {
    marginTop: 20,
  },
  editIcon: {
    color: 'white',
    margin: '7px 0 10px 1px',
    fontSize: '16px',
  },
  imgProfile: {
    background: '#FFFFFF',
    width: '140px',
    height: '140px',
    borderRadius: '100%',
    margin: '15px auto'
  },
  editProfile: {
    width: '33px',
    height: '33px',
    background: '#1DCAD3 !important',
    borderRadius: '100%',
    position: 'relative',
    top: '100px',
    left: '54px',
    cursor: 'pointer',
    minWidth: 33,
  },
  selectContainer: {
    width: '90%',
    marginBottom: 32,

    '&:first-of-type': {
      margin: '34px 0 32px 0px',
    },
  },
  formControlDoc: {
    marginRight: 20,
    '& .MuiSelect-select': {
      width: 50,
    },
  },
  inpuLabelStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: '#000',
    width: 37,
  },
  fileInput: {
    width: '90%',
    display: 'flex',
    border: '1px solid #DADADA',
    borderRadius: 4,
    justifyContent: 'center',
    height: 56,
    alignItems: 'flex-end',
  },
  inpuLabelDateStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: '#000',
    width: 127,
  },
  inpuLabelMaritalStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: '#000',
    width: 87,
  },
  inpuLabelFileStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: '#000',
    width: 190,
  },
  radioCustom: {
    width: '90%',
    margin: '13px 0 12px 0',
  },
  radioGroupCustom: {
    flexDirection: 'row !important',
    justifyContent: 'center',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.75rem'
  },
  cameraIcon: {
    top: '50%',
    left: '50%'
  },
  container: {
    position: 'relative',
  },
  imgButton: {
    background: theme.palette.background,
    width: '140px',
    height: '140px',
    borderRadius: '100%',
    border: '1px solid #000',
    backgroundSize: 'cover',
    '&:hover, &.Mui-focusVisible': {
      background: '#fafafa'
    }
  },
}));
