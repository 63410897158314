// packages
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

// components
import GmKarmaReview from '../karma-review';
import GmSvgIcon from '../../static/svg';

// styles
import useStyles from './styles';

const GmStoryCardHeader = ({
  isVisitingMode,
  like,
  onClickUserStoryCreator,
  onStoryLike,
  storyCreatorFullName,
  storyCreatorKarmaScore,
  storyCreatorProfilePicture,
}) => {
  const classes = useStyles();
  const { historyInfo } = useSelector((state) => state.history);

  const isStoryLikeDisabled = isVisitingMode ? !historyInfo.likeThis : !like;
  const isStoryLikeEnabled = isVisitingMode ? historyInfo.likeThis : like;

  return (
    <Grid className={classes.gmStoryCardHeaderContainer} container>
      <Grid item onClick={onClickUserStoryCreator} xs={11}>
        <Grid item xs={2}>
          <img
            className={classes.gmStoryCardHeaderStoryCreatorProfilePicture}
            src={storyCreatorProfilePicture}
          />
        </Grid>
        <Grid container direction="column" xs={10}>
          <Grid item>
            <Typography className={classes.gmStoryCardHeaderStoryCreatorFullName}>
              {storyCreatorFullName}
            </Typography>
          </Grid>
          <Grid item>
            <GmKarmaReview
              className={classes.gmStoryCardHeaderStoryCreatorKarmaScore}
              readOnly
              value={storyCreatorKarmaScore}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <div className={classes.gmStoryCardHeaderActionButtons}>
          <div role="button" onClick={onStoryLike}>
            <GmSvgIcon
              disabled={isStoryLikeDisabled}
              enabled={isStoryLikeEnabled}
              icon="like"
              viewBox="0 0 65 58"
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default GmStoryCardHeader;
