// vendors
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

// actions
import { addUserBankInformation } from '../../../redux/actions/bank';

// config
import { routes } from '../../../config/routes';
import { userIsGodfather, userIsGodson } from '../../../config/constants';

// components
import GmAutoComplete from '../../../components/autocomplete';

// images
import logo from '../../../static/images/logo3.svg';

// styles
import { useStyles } from '../styles';

const validationSchema = yup.object({
  accountHolder: yup.string().required('El nombre del títular es requerido.'),
  accountHolderDocumentNumber: yup.string().required('El documento de identidad del títular es requerido.'),
  accountNumber: yup.string().required('El número de cuenta es requerido.'),
  accountType: yup.string().required('El tipo de cuenta es requerido.'),
  bankId: yup.string().required('El banco es requerido.'),
});

export const GmBankAccountInformation = ({ bankList, visible = true }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth: { userInfo } } = useSelector((state) => state);

  const formik = useFormik({
    initialValues: {
      accountHolder: '',
      accountHolderDocumentNumber: '',
      accountNumber: '',
      accountType: '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(addUserBankInformation({
        ...values
      })).then(() => {
        const routeToRedirect = userIsGodfather(userInfo.role)
          ? routes.histories
          : routes.main.replace('/:userId?', '');
        history.push(routeToRedirect);
      });
    },
  });

  if (!visible) {
    return null;
  }

  const handleOnBankChange = (bank) => {
    formik.setFieldValue('bankId', bank.id);
  };

  return (
    <>
      <Paper elevation={0}>
        <Grid container direction="column" justify="center">
          <Grid className={classes.brandLogo} item xs={12}>
            <img src={logo} />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom style={{ padding: '10px 50px 20px' }} variant="subtitle2">
              {userIsGodfather(userInfo.role) && 'Cuando apoyes una historia: a qué número de cuenta te harán los pagos.'}
              {userIsGodson(userInfo.role) && 'Cuando recibas el apoyo, a qué número de cuenta te harán el desembolso.'}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Grid container direction="column" justify="center" style={{ padding: '0px 20px 0px' }}>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <Grid item xs={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                error={formik.touched.accountHolder && Boolean(formik.errors.accountHolder)}
                fullWidth
                helperText={formik.errors.accountHolder}
                id="accountHolder"
                label={!formik.values.accountHolder ? 'Nombre del títular' : null}
                name="accountHolder"
                onChange={formik.handleChange}
                placeholder="Nombre del títular"
                style={{ paddingBottom: 20 }}
                value={formik.values.accountHolder}
                variant="outlined"
                type="text"
              />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
              fullWidth
              helperText={formik.errors.accountNumber}
              id="accountNumber"
              type="number"
              label={!formik.values.accountNumber ? 'Número de cuenta' : null}
              name="accountNumber"
              onChange={formik.handleChange}
              placeholder="Número de cuenta"
              style={{ paddingBottom: 20 }}
              value={formik.values.accountNumber}
              variant="outlined"
            />
          </Grid>
          <FormControl style={{ width: '100%', paddingBottom: 20 }}>
            <InputLabel
              id="accountType"
              style={{
                background: '#fff',
                left: 12,
                padding: '0px 5px',
                position: 'absolute',
                top: '-6px',
                width: 'auto',
                zIndex: 1,
              }}
            >
              Tipo de cuenta
            </InputLabel>
            <Select
              error={formik.touched.accountType && Boolean(formik.errors.accountType)}
              id="accountType"
              label="Tipo de cuenta"
              labelId="accountType"
              name="accountType"
              onChange={formik.handleChange}
              placeholder="Tipo de cuenta"
              style={{ textAlign: 'left' }}
              value={formik.values.accountType}
              variant="outlined"
            >
              <MenuItem value="0">Ahorros</MenuItem>
              <MenuItem value="1">Corriente</MenuItem>
              <MenuItem value="2">Otro</MenuItem>
            </Select>
          </FormControl>
          <Grid item xs={12}>
            <GmAutoComplete
              error={formik.touched.bankId && Boolean(formik.errors.bankId)}
              helperText={formik.errors.bankId}
              label="Banco"
              onOptionChange={handleOnBankChange}
              options={bankList && bankList.length ? bankList : []}
              style={{ paddingBottom: 20 }}
              value={bankList && bankList.length && formik.values.bankId
                ? bankList.find((bankItem) => bankItem.id === formik.values.bankId).name
                : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              error={formik.touched.accountHolderDocumentNumber && Boolean(formik.errors.accountHolderDocumentNumber)}
              fullWidth
              helperText={formik.errors.accountHolderDocumentNumber}
              id="accountHolderDocumentNumber"
              label={!formik.values.accountHolderDocumentNumber ? 'Documento de identidad' : null}
              name="accountHolderDocumentNumber"
              onChange={formik.handleChange}
              placeholder="Documento de identidad"
              style={{ paddingBottom: 20 }}
              value={formik.values.accountHolderDocumentNumber}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item style={{ padding: '0px 0px 20px 0px' }} xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  style={{ color: '#ffffff' }}
                  type="submit"
                  variant="contained"
                >
                  FINALIZAR
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default GmBankAccountInformation;
