// Auth
export const AUTH_BEGIN = 'AUTH_BEGIN';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// Register
export const REGISTER_BEGIN = 'REGISTER_BEGIN';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const REGISTER_PROFILE_IMG_BEGIN = 'REGISTER_PROFILE_IMG_BEGIN';
export const REGISTER_PROFILE_IMG_SUCCESS = 'REGISTER_PROFILE_IMG_SUCCESS';
export const REGISTER_PROFILE_IMG_ERROR = 'REGISTER_PROFILE_IMG_ERROR';

export const SAVE_DOC_IMG_BEGIN = 'SAVE_DOC_IMG_BEGIN';
export const SAVE_DOC_IMG_SUCCESS = 'SAVE_DOC_IMG_SUCCESS';
export const SAVE_DOC_IMG_ERROR = 'SAVE_DOC_IMG_ERROR';

export const GET_COUNTRY_PHONE_CODES_BEGIN = 'GET_COUNTRY_PHONE_CODES_BEGIN';
export const GET_COUNTRY_PHONE_CODES_ERROR = 'GET_COUNTRY_PHONE_CODES_ERROR';
export const GET_COUNTRY_PHONE_CODES_SUCCESSS = 'GET_COUNTRY_PHONE_CODES_SUCCESSS';

// Forgot Password
export const ON_FORGOT_PASSWORD_BEGIN = 'ON_FORGOT_PASSWORD_BEGIN';
export const ON_FORGOT_PASSWORD_SUCCESS = 'ON_FORGOT_PASSWORD_SUCCESS';
export const ON_FORGOT_PASSWORD_ERROR = 'ON_FORGOT_PASSWORD_ERROR';

// Recover Password
export const ON_RECOVER_PASSWORD_BEGIN = 'ON_RECOVER_PASSWORD_BEGIN';
export const ON_RECOVER_PASSWORD_ERROR = 'ON_RECOVER_PASSWORD_ERROR';
export const ON_RECOVER_PASSWORD_SUCCESS = 'ON_RECOVER_PASSWORD_SUCCESS';

// Check Code
export const CHECK_CODE_BEGIN = 'CHECK_CODE_BEGIN';
export const CHECK_CODE_SUCCESS = 'CHECK_CODE_SUCCESS';
export const CHECK_CODE_ERROR = 'CHECK_CODE_ERROR';

// Get Country
export const GET_COUNTRY_BEGIN = 'GET_COUNTRY_BEGIN';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_ERROR = 'GET_COUNTRY_ERROR';

// Get State
export const GET_STATE_BEGIN = 'GET_STATE_BEGIN';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_ERROR = 'GET_STATE_ERROR';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';

// Get Country
export const SET_ROLE_BEGIN = 'SET_ROLE_BEGIN';
export const SET_ROLE_SUCCESS = 'SET_ROLE_SUCCESS';
export const SET_ROLE_ERROR = 'SET_ROLE_ERROR';

// History
export const GET_HISTORY_BEGIN = 'GET_HISTORY_BEGIN';
export const GET_HISTORY_ERROR = 'GET_HISTORY_ERROR';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';

export const GET_MY_HISTORY_BEGIN = 'GET_MY_HISTORY_BEGIN';
export const GET_MY_HISTORY_ERROR = 'GET_MY_HISTORY_ERROR';
export const GET_MY_HISTORY_SUCCESS = 'GET_MY_HISTORY_SUCCESS';

export const GET_USER_INFO_BEGIN = 'GET_USER_INFO_BEGIN';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';

export const GET_QUESTIONS_BEGIN = 'GET_QUESTIONS_BEGIN';
export const GET_QUESTIONS_ERROR = 'GET_QUESTIONS_ERROR';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';

export const GET_HISTORY_INFO_BEGIN = 'GET_HISTORY_INFO_BEGIN';
export const GET_HISTORY_INFO_ERROR = 'GET_HISTORY_INFO_ERROR';
export const GET_HISTORY_INFO_SUCCESS = 'GET_HISTORY_INFO_SUCCESS';

export const SAVE_STORY_PARTIAL_BEGIN = 'SAVE_STORY_PARTIAL_BEGIN';
export const SAVE_STORY_PARTIAL_SUCCESS = 'SAVE_STORY_PARTIAL_SUCCESS';
export const SAVE_STORY_PARTIAL_ERROR = 'SAVE_STORY_PARTIAL_ERROR';

export const GET_HISTORY_PROGRESS_BEGIN = 'GET_HISTORY_PROGRESS_BEGIN';
export const GET_HISTORY_PROGRESS_SUCCESS = 'GET_HISTORY_PROGRESS_SUCCESS';
export const GET_HISTORY_PROGRESS_ERROR = 'GET_HISTORY_PROGRESS_ERROR';

export const CLEAR_HISTORIES = 'CLEAR_HISTORIES';

// Chat
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const CLEAR_UNREAD_MESSAGES = 'CLEAR_UNREAD_MESSAGES';
export const GET_CHATS_BEGIN = 'GET_CHATS_BEGIN';
export const GET_CHATS_ERROR = 'GET_CHATS_ERROR';
export const GET_CHATS_HISTORY_SUCCESS = 'GET_CHATS_HISTORY_SUCCESS';
export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS';
export const RECEIVE_MESSAGE_SUCCESS = 'RECEIVE_MESSAGE_SUCCESS';
export const SET_MESSAGE_BEGIN = 'SET_MESSAGE_BEGIN';
export const SET_MESSAGE_ERROR = 'SET_MESSAGE_ERROR';
export const SET_MESSAGE_SUCCESS = 'SET_MESSAGE_SUCCESS';
export const SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';

export const SET_LIKE_BEGIN = 'SET_LIKE_BEGIN';
export const SET_LIKE_SUCCESS = 'SET_LIKE_SUCCESS';
export const SET_LIKE_ERROR = 'SET_LIKE_ERROR';

// Transaction
export const SET_SEND_SMS_CODE_BEGIN = 'SET_SEND_SMS_CODE_BEGIN';
export const SET_SEND_SMS_CODE_SUCCESS = 'SET_SEND_SMS_CODE_SUCCESS';
export const SET_SEND_SMS_CODE_ERROR = 'SET_SEND_SMS_CODE_ERROR';

export const SET_VALIDATE_CODE_BEGIN = 'SET_VALIDATE_CODE_BEGIN';
export const SET_VALIDATE_CODE_SUCCESS = 'SET_VALIDATE_CODE_SUCCESS';
export const SET_VALIDATE_CODE_ERROR = 'SET_VALIDATE_CODE_ERROR';

export const SET_CREATE_TRANSACTION_BEGIN = 'SET_CREATE_TRANSACTION_BEGIN';
export const SET_CREATE_TRANSACTION_SUCCESS = 'SET_CREATE_TRANSACTION_SUCCESS';
export const SET_CREATE_TRANSACTION_ERROR = 'SET_CREATE_TRANSACTION_ERROR';

// Transaction Record
export const START_TRANSACTION_RECORD_BEGIN = 'START_TRANSACTION_RECORD_BEGIN';
export const START_TRANSACTION_RECORD_SUCCESS = 'START_TRANSACTION_RECORD_SUCCESS';
export const START_TRANSACTION_RECORD_ERROR = 'START_TRANSACTION_RECORD_ERROR';
export const GET_TRANSACTION_RECORD_BEGIN = 'GET_TRANSACTION_RECORD_BEGIN';
export const GET_TRANSACTION_RECORD_SUCCESS = 'GET_TRANSACTION_RECORD_SUCCESS';
export const GET_TRANSACTION_RECORD_ERROR = 'GET_TRANSACTION_RECORD_ERROR';

// Calculator
export const GET_INTEREST_BEGIN = 'GET_INTEREST_BEGIN';
export const GET_INTEREST_SUCCESS = 'GET_INTEREST_SUCCESS';
export const GET_INTEREST_ERROR = 'GET_INTEREST_ERROR';

// Set proposal
export const SET_PROPOSAL_BEGIN = 'SET_PROPOSAL_BEGIN';
export const SET_PROPOSAL_SUCCESS = 'SET_PROPOSAL_SUCCESS';

// Edit Proposal
export const EDIT_PROPOSAL_BEGIN = 'EDIT_PROPOSAL_BEGIN';
export const EDIT_PROPOSAL_SUCCESS = 'EDIT_PROPOSAL_SUCCESS';
export const EDIT_PROPOSAL_ERROR = 'EDIT_PROPOSAL_ERROR';
export const EDIT_CREATION_PROPOSAL_BEGIN = 'EDIT_CREATION_PROPOSAL_BEGIN';
export const EDIT_CREATION_PROPOSAL_SUCCESS = 'EDIT_CREATION_PROPOSAL_SUCCESS';
export const EDIT_CREATION_PROPOSAL_ERROR = 'EDIT_CREATION_PROPOSAL_ERROR';

// Delete Proposal
export const DELETE_PROPOSAL_BEGIN = 'DELETE_PROPOSAL_BEGIN';
export const DELETE_PROPOSAL_SUCCESS = 'DELETE_PROPOSAL_SUCCESS';
export const DELETE_PROPOSAL_ERROR = 'DELETE_PROPOSAL_ERROR';

// User Update
export const SET_USER_ERROR = 'SET_USER_ERROR';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';

// Payments Report
export const GET_IS_LAST_PAYMENT_REPORT_BEGIN = 'GET_IS_LAST_PAYMENT_REPORT_BEGIN';
export const GET_IS_LAST_PAYMENT_REPORT_ERROR = 'GET_IS_LAST_PAYMENT_REPORT_ERROR';
export const GET_IS_LAST_PAYMENT_REPORT_SUCCESS = 'GET_IS_LAST_PAYMENT_REPORT_SUCCESS';
export const REGISTER_LOAN_TRANSFER_BEGIN = 'REGISTER_LOAN_TRANSFER_BEGIN';
export const REGISTER_LOAN_TRANSFER_ERROR = 'REGISTER_LOAN_TRANSFER_ERROR';
export const REGISTER_LOAN_TRANSFER_SUCCESS = 'REGISTER_LOAN_TRANSFER_SUCCESS';
export const REGISTER_PAYMENT_TRANSFER_REPORT_BEGIN = 'REGISTER_PAYMENT_TRANSFER_REPORT_BEGIN';
export const REGISTER_PAYMENT_TRANSFER_REPORT_ERROR = 'REGISTER_PAYMENT_TRANSFER_REPORT_ERROR';
export const REGISTER_PAYMENT_TRANSFER_REPORT_SUCCESS = 'REGISTER_PAYMENT_TRANSFER_REPORT_SUCCESS';
export const REGISTER_LAST_PAYMENT_TRANSFER_REPORT_BEGIN = 'REGISTER_LAST_PAYMENT_TRANSFER_REPORT_BEGIN';
export const REGISTER_LAST_PAYMENT_TRANSFER_REPORT_ERROR = 'REGISTER_LAST_PAYMENT_TRANSFER_REPORT_ERROR';
export const REGISTER_LAST_PAYMENT_TRANSFER_REPORT_SUCCESS = 'REGISTER_LAST_PAYMENT_TRANSFER_REPORT_SUCCESS';

// Get active transaction
export const GET_ACTIVE_TRANSACTION_BEGIN = 'GET_ACTIVE_TRANSACTION_BEGIN';
export const GET_ACTIVE_TRANSACTION_ERROR = 'GET_ACTIVE_TRANSACTION_ERROR';
export const GET_ACTIVE_TRANSACTION_SUCCESS = 'GET_ACTIVE_TRANSACTION_SUCCESS';

// Payments Detail
export const GET_PAYMENT_DETAIL_ACTIONS_BEGIN = 'GET_PAYMENT_DETAIL_ACTIONS_BEGIN';
export const GET_PAYMENT_DETAIL_ACTIONS_ERROR = 'GET_PAYMENT_DETAIL_ACTIONS_ERROR';
export const GET_PAYMENT_DETAIL_ACTIONS_SUCCESS = 'GET_PAYMENT_DETAIL_ACTIONS_SUCCESS';
export const GET_PAYMENT_DETAIL_BEGIN = 'GET_PAYMENT_DETAIL_BEGIN';
export const GET_PAYMENT_DETAIL_ERROR = 'GET_PAYMENT_DETAIL_ERROR';
export const GET_PAYMENT_DETAIL_SUCCESS = 'GET_PAYMENT_DETAIL_SUCCESS';

// Calculator
export const GET_PAYMENT_CALENDAR_BEGIN = 'GET_PAYMENT_CALENDAR_BEGIN';
export const GET_PAYMENT_CALENDAR_SUCCESS = 'GET_PAYMENT_CALENDAR_SUCCESS';
export const GET_PAYMENT_CALENDAR_ERROR = 'GET_PAYMENT_CALENDAR_ERROR';

// Calculator
export const SET_REFERRED_USER_BEGIN = 'SET_REFERRED_USER_BEGIN';
export const SET_REFERRED_USER_SUCCESS = 'SET_REFERRED_USER_SUCCESS';
export const SET_REFERRED_USER_ERROR = 'SET_REFERRED_USER_ERROR';

// Activities
export const GET_MY_ACTIVITIES_BEGIN = 'GET_MY_ACTIVITIES_BEGIN';
export const GET_MY_ACTIVITIES_ERROR = 'GET_MY_ACTIVITIES_ERROR';
export const GET_MY_ACTIVITIES_SUCCESS = 'GET_MY_ACTIVITIES_SUCCESS';

// WhoHasReferredMe
export const GET_WHO_REFER_ME_SUCCESS = 'GET_WHO_REFER_ME_SUCCESS';
export const GET_WHO_REFER_ME_ERROR = 'GET_WHO_REFER_ME_ERROR';

// WhoHasImReferred
export const GET_WHO_HAS_IM_REFERRED_SUCCESS = 'GET_WHO_HAS_IM_REFERRED_SUCCESS';
export const GET_WHO_HAS_IM_REFERRED_ERROR = 'GET_WHO_HAS_IM_REFERRED_ERROR';

// Payments Confirmation
export const CONFIRM_LAST_PAYMENT_BEGIN = 'CONFIRM_LAST_PAYMENT_BEGIN';
export const CONFIRM_LAST_PAYMENT_ERROR = 'CONFIRM_LAST_PAYMENT_ERROR';
export const CONFIRM_LAST_PAYMENT_SUCCESS = 'CONFIRM_LAST_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_BEGIN = 'CONFIRM_PAYMENT_BEGIN';
export const CONFIRM_PAYMENT_ERROR = 'CONFIRM_PAYMENT_ERROR';
export const CONFIRM_PAYMENT_SUCCESS = 'CONFIRM_PAYMENT_SUCCESS';

// Profile Visiting
export const GET_USER_PROFILE_BEGIN = 'GET_USER_PROFILE_BEGIN';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_STORIES_BEGIN = 'GET_USER_STORIES_BEGIN';
export const GET_USER_STORIES_ERROR = 'GET_USER_STORIES_ERROR';
export const GET_USER_STORIES_SUCCESS = 'GET_USER_STORIES_SUCCESS';

// Referral perimeter status
export const GET_REFERRAL_PERIMETER_STATUS_BEGIN = 'GET_REFERRAL_PERIMETER_STATUS_BEGIN';
export const GET_REFERRAL_PERIMETER_STATUS_ERROR = 'GET_REFERRAL_PERIMETER_STATUS_ERROR';
export const GET_REFERRAL_PERIMETER_STATUS_SUCCESS = 'GET_REFERRAL_PERIMETER_STATUS_SUCCESS';

export const GET_UNREAD_MESSAGES = 'GET_UNREAD_MESSAGES';

// Support
export const SEND_SUPPORT_MESSAGE = 'SEND_SUPPORT_MESSAGE';
