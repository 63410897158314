// constants
import * as actionsTypes from '../action-types';
import {
  AUCO_ID_VALIDATION_BEGIN,
  AUCO_ID_VALIDATION_ERROR,
  AUCO_ID_VALIDATION_SUCCESS,
  GET_IS_AUCO_VALIDATION_ENABLED_BEGIN,
  GET_IS_AUCO_VALIDATION_ENABLED_ERROR,
  GET_IS_AUCO_VALIDATION_ENABLED_SUCCESS,
} from '../actions/AUCO';

import {
  GET_ADMINISTRATIVE_EXPENSES_SETTINGS_BEGIN,
  GET_ADMINISTRATIVE_EXPENSES_SETTINGS_ERROR,
  GET_ADMINISTRATIVE_EXPENSES_SETTINGS_SUCCESS,
  GET_SUMMARY_ADMINISTRATIVE_EXPENSES_BEGIN,
  GET_SUMMARY_ADMINISTRATIVE_EXPENSES_ERROR,
  GET_SUMMARY_ADMINISTRATIVE_EXPENSES_SUCCESS,
} from '../actions/settings';

import {
  IS_RESET_CODE_VALID_BEGIN,
  IS_RESET_CODE_VALID_ERROR,
  IS_RESET_CODE_VALID_SUCCESSS,
} from '../actions/user';

import {
  GET_ALL_BANKS_BEGIN,
  GET_ALL_BANKS_ERROR,
  GET_ALL_BANKS_SUCCESS,
  SAVE_BANK_USER_INFORMATION_BEGIN,
  SAVE_BANK_USER_INFORMATION_ERROR,
  SAVE_BANK_USER_INFORMATION_SUCCESS,
} from '../actions/bank';

import {
  GET_CALCULATE_LOAN_VALUES_BEGIN,
  GET_CALCULATE_LOAN_VALUES_ERROR,
  GET_CALCULATE_LOAN_VALUES_SUCCESS,
  GET_TOTAL_INTEREST_EARNED_BEGIN,
  GET_TOTAL_INTEREST_EARNED_ERROR,
  GET_TOTAL_INTEREST_EARNED_SUCCESS,
} from '../actions/calculator';

import {
  CREATE_NEW_STORY_BEGIN,
  CREATE_NEW_STORY_ERROR,
  CREATE_NEW_STORY_SUCCESS,
  GET_CREATE_STORY_PHRASES_BEGIN,
  GET_CREATE_STORY_PHRASES_ERROR,
  GET_CREATE_STORY_PHRASES_SUCCESS,
  GET_CREATE_STORY_QUESTIONS_BEGIN,
  GET_CREATE_STORY_QUESTIONS_ERROR,
  GET_CREATE_STORY_QUESTIONS_SUCCESS,
  UPDATE_STORY_CREATION_BEGIN,
  UPDATE_STORY_CREATION_ERROR,
  UPDATE_STORY_CREATION_SUCCESS,
  UPDATE_STORY_QUESTION_PICTURE_BEGIN,
  UPDATE_STORY_QUESTION_PICTURE_ERROR,
  UPDATE_STORY_QUESTION_PICTURE_SUCCESS,
} from '../actions/story';

// state
import { commonInitialState } from '../../config/state/initialState';

function commonReducer(state = commonInitialState, action) {
  // TODO: The case listener could be improved or refactor with a regexep middleware or something like that. 🐱‍💻
  switch (action.type) {
    // ____________________________________________
    // ____________________________________________
    case AUCO_ID_VALIDATION_BEGIN:
    case CREATE_NEW_STORY_BEGIN:
    case GET_ADMINISTRATIVE_EXPENSES_SETTINGS_BEGIN:
    case GET_ALL_BANKS_BEGIN:
    case GET_CALCULATE_LOAN_VALUES_BEGIN:
    case GET_CREATE_STORY_QUESTIONS_BEGIN:
    case GET_CREATE_STORY_PHRASES_BEGIN:
    case GET_IS_AUCO_VALIDATION_ENABLED_BEGIN:
    case GET_SUMMARY_ADMINISTRATIVE_EXPENSES_BEGIN:
    case GET_TOTAL_INTEREST_EARNED_BEGIN:
    case IS_RESET_CODE_VALID_BEGIN:
    case SAVE_BANK_USER_INFORMATION_BEGIN:
    case UPDATE_STORY_CREATION_BEGIN:
    case UPDATE_STORY_QUESTION_PICTURE_BEGIN:
    case actionsTypes.AUTH_BEGIN:
    case actionsTypes.CHECK_CODE_BEGIN:
    case actionsTypes.CONFIRM_LAST_PAYMENT_BEGIN:
    case actionsTypes.CONFIRM_PAYMENT_BEGIN:
    case actionsTypes.DELETE_PROPOSAL_BEGIN:
    case actionsTypes.GET_ACTIVE_TRANSACTION_BEGIN:
    case actionsTypes.GET_CHATS_BEGIN:
    case actionsTypes.GET_COUNTRY_BEGIN:
    case actionsTypes.GET_COUNTRY_PHONE_CODES_BEGIN:
    case actionsTypes.GET_HISTORY_INFO_BEGIN:
    case actionsTypes.GET_HISTORY_PROGRESS_BEGIN:
    case actionsTypes.GET_INTEREST_BEGIN:
    case actionsTypes.GET_IS_LAST_PAYMENT_REPORT_BEGIN:
    case actionsTypes.GET_MY_ACTIVITIES_BEGIN:
    case actionsTypes.GET_MY_HISTORY_BEGIN:
    case actionsTypes.GET_PAYMENT_DETAIL_BEGIN:
    case actionsTypes.GET_QUESTIONS_BEGIN:
    case actionsTypes.GET_STATE_BEGIN:
    case actionsTypes.GET_USER_INFO_BEGIN:
    case actionsTypes.GET_USER_PROFILE_BEGIN:
    case actionsTypes.GET_USER_STORIES_BEGIN:
    case actionsTypes.ON_FORGOT_PASSWORD_BEGIN:
    case actionsTypes.ON_RECOVER_PASSWORD_BEGIN:
    case actionsTypes.REGISTER_BEGIN:
    case actionsTypes.REGISTER_LAST_PAYMENT_TRANSFER_REPORT_BEGIN:
    case actionsTypes.REGISTER_LOAN_TRANSFER_BEGIN:
    case actionsTypes.REGISTER_PAYMENT_TRANSFER_REPORT_BEGIN:
    case actionsTypes.REGISTER_PROFILE_IMG_BEGIN:
    case actionsTypes.SET_CREATE_TRANSACTION_BEGIN:
    case actionsTypes.SET_LIKE_BEGIN:
    case actionsTypes.SET_MESSAGE_BEGIN:
    case actionsTypes.SET_PROPOSAL_BEGIN:
    case actionsTypes.SET_REFERRED_USER_BEGIN:
    case actionsTypes.SET_ROLE_BEGIN:
    case actionsTypes.SET_SEND_SMS_CODE_BEGIN:
    case actionsTypes.SET_VALIDATE_CODE_BEGIN:
      return { error: false, loading: true, featureFlags: state.featureFlags };
    // ____________________________________________
    // ____________________________________________
    case AUCO_ID_VALIDATION_ERROR:
    case CREATE_NEW_STORY_ERROR:
    case GET_ADMINISTRATIVE_EXPENSES_SETTINGS_ERROR:
    case GET_ALL_BANKS_ERROR:
    case GET_CALCULATE_LOAN_VALUES_ERROR:
    case GET_CREATE_STORY_PHRASES_ERROR:
    case GET_CREATE_STORY_QUESTIONS_ERROR:
    case GET_IS_AUCO_VALIDATION_ENABLED_ERROR:
    case GET_SUMMARY_ADMINISTRATIVE_EXPENSES_ERROR:
    case GET_TOTAL_INTEREST_EARNED_ERROR:
    case IS_RESET_CODE_VALID_ERROR:
    case SAVE_BANK_USER_INFORMATION_ERROR:
    case UPDATE_STORY_CREATION_ERROR:
    case UPDATE_STORY_QUESTION_PICTURE_ERROR:
    case actionsTypes.AUTH_ERROR:
    case actionsTypes.CHECK_CODE_ERROR:
    case actionsTypes.CONFIRM_LAST_PAYMENT_ERROR:
    case actionsTypes.CONFIRM_PAYMENT_ERROR:
    case actionsTypes.DELETE_PROPOSAL_ERROR:
    case actionsTypes.GET_ACTIVE_TRANSACTION_ERROR:
    case actionsTypes.GET_CHATS_ERROR:
    case actionsTypes.GET_COUNTRY_ERROR:
    case actionsTypes.GET_COUNTRY_PHONE_CODES_ERROR:
    case actionsTypes.GET_HISTORY_ERROR:
    case actionsTypes.GET_HISTORY_INFO_ERROR:
    case actionsTypes.GET_HISTORY_PROGRESS_ERROR:
    case actionsTypes.GET_INTEREST_ERROR:
    case actionsTypes.GET_IS_LAST_PAYMENT_REPORT_ERROR:
    case actionsTypes.GET_MY_ACTIVITIES_ERROR:
    case actionsTypes.GET_MY_HISTORY_ERROR:
    case actionsTypes.GET_PAYMENT_DETAIL_ERROR:
    case actionsTypes.GET_QUESTIONS_ERROR:
    case actionsTypes.GET_STATE_ERROR:
    case actionsTypes.GET_USER_INFO_ERROR:
    case actionsTypes.GET_USER_PROFILE_ERROR:
    case actionsTypes.GET_USER_STORIES_ERROR:
    case actionsTypes.ON_FORGOT_PASSWORD_ERROR:
    case actionsTypes.ON_RECOVER_PASSWORD_ERROR:
    case actionsTypes.REGISTER_ERROR:
    case actionsTypes.REGISTER_LAST_PAYMENT_TRANSFER_REPORT_ERROR:
    case actionsTypes.REGISTER_LOAN_TRANSFER_ERROR:
    case actionsTypes.REGISTER_PAYMENT_TRANSFER_REPORT_ERROR:
    case actionsTypes.REGISTER_PROFILE_IMG_ERROR:
    case actionsTypes.SET_CREATE_TRANSACTION_ERROR:
    case actionsTypes.SET_LIKE_ERROR:
    case actionsTypes.SET_MESSAGE_ERROR:
    case actionsTypes.SET_REFERRED_USER_ERROR:
    case actionsTypes.SET_ROLE_ERROR:
    case actionsTypes.SET_SEND_SMS_CODE_ERROR:
    case actionsTypes.SET_USER_ERROR:
    case actionsTypes.SET_VALIDATE_CODE_ERROR:
      return { error: true, loading: false, featureFlags: state.featureFlags };
    // ____________________________________________
    // ____________________________________________
    case AUCO_ID_VALIDATION_SUCCESS:
    case CREATE_NEW_STORY_SUCCESS:
    case GET_ADMINISTRATIVE_EXPENSES_SETTINGS_SUCCESS:
    case GET_ALL_BANKS_SUCCESS:
    case GET_CALCULATE_LOAN_VALUES_SUCCESS:
    case GET_CREATE_STORY_PHRASES_SUCCESS:
    case GET_CREATE_STORY_QUESTIONS_SUCCESS:
    case GET_SUMMARY_ADMINISTRATIVE_EXPENSES_SUCCESS:
    case GET_TOTAL_INTEREST_EARNED_SUCCESS:
    case IS_RESET_CODE_VALID_SUCCESSS:
    case SAVE_BANK_USER_INFORMATION_SUCCESS:
    case UPDATE_STORY_CREATION_SUCCESS:
    case UPDATE_STORY_QUESTION_PICTURE_SUCCESS:
    case actionsTypes.AUTH_SUCCESS:
    case actionsTypes.CHECK_CODE_SUCCESS:
    case actionsTypes.CONFIRM_LAST_PAYMENT_SUCCESS:
    case actionsTypes.CONFIRM_PAYMENT_SUCCESS:
    case actionsTypes.DELETE_PROPOSAL_SUCCESS:
    case actionsTypes.GET_ACTIVE_TRANSACTION_SUCCESS:
    case actionsTypes.GET_CHATS_HISTORY_SUCCESS:
    case actionsTypes.GET_CHATS_SUCCESS:
    case actionsTypes.GET_CITY_SUCCESS:
    case actionsTypes.GET_COUNTRY_PHONE_CODES_SUCCESSS:
    case actionsTypes.GET_COUNTRY_SUCCESS:
    case actionsTypes.GET_HISTORY_INFO_SUCCESS:
    case actionsTypes.GET_HISTORY_PROGRESS_SUCCESS:
    case actionsTypes.GET_INTEREST_SUCCESS:
    case actionsTypes.GET_IS_LAST_PAYMENT_REPORT_SUCCESS:
    case actionsTypes.GET_MY_ACTIVITIES_SUCCESS:
    case actionsTypes.GET_MY_HISTORY_SUCCESS:
    case actionsTypes.GET_PAYMENT_DETAIL_SUCCESS:
    case actionsTypes.GET_QUESTIONS_SUCCESS:
    case actionsTypes.GET_STATE_SUCCESS:
    case actionsTypes.GET_USER_INFO_SUCCESS:
    case actionsTypes.GET_USER_PROFILE_SUCCESS:
    case actionsTypes.GET_USER_STORIES_SUCCESS:
    case actionsTypes.ON_FORGOT_PASSWORD_SUCCESS:
    case actionsTypes.ON_RECOVER_PASSWORD_SUCCESS:
    case actionsTypes.REGISTER_LAST_PAYMENT_TRANSFER_REPORT_SUCCESS:
    case actionsTypes.REGISTER_LOAN_TRANSFER_SUCCESS:
    case actionsTypes.REGISTER_PAYMENT_TRANSFER_REPORT_SUCCESS:
    case actionsTypes.REGISTER_PROFILE_IMG_SUCCESS:
    case actionsTypes.REGISTER_SUCCESS:
    case actionsTypes.SET_CREATE_TRANSACTION_SUCCESS:
    case actionsTypes.SET_LIKE_SUCCESS:
    case actionsTypes.SET_MESSAGE_SUCCESS:
    case actionsTypes.SET_PROPOSAL_SUCCESS:
    case actionsTypes.SET_REFERRED_USER_SUCCESS:
    case actionsTypes.SET_ROLE_SUCCESS:
    case actionsTypes.SET_SEND_SMS_CODE_SUCCESS:
    case actionsTypes.SET_USER_SUCCESS:
    case actionsTypes.SET_VALIDATE_CODE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        featureFlags: state.featureFlags
      };
    // ____________________________________________
    // ____________________________________________
    case GET_IS_AUCO_VALIDATION_ENABLED_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        featureFlags: { ...state.featureFlags, isAUCOValidationEnabled: action.payload.enableUserIdentiyAuthentication }
      };
    case actionsTypes.GET_HISTORY_BEGIN:
      return {
        ...state,
        loadingHistories: true,
      };
    case actionsTypes.GET_HISTORY_SUCCESS:
      return {
        ...state,
        loadingHistories: false,
      };
    default:
      return state;
  }
}

export default commonReducer;
