/* eslint-disable dot-notation */

// vendors
import axios from 'axios';

// store
import superStore from '../redux/store/redux-store';

// http request interceptors
axios.interceptors.request.use((config) => {
  const authToken = superStore.reduxStore.getState().auth.userInfo.accessToken;

  if (authToken) {
    config.headers.common['Authorization'] = `Bearer ${authToken}`;
  }

  // Below headers should only be included if request origin isn't related to AUCO
  if (!config.url.includes('auco')) {
    config.headers.common['Abp.TenantId'] = '1';
    config.headers.common['Accept'] = 'application/json';
    // config.headers.common['Accept-Language'] = 'c=es|uic=es-CO';
    config.headers.common['Content-Type'] = 'application/json';
  }

  return config;
}, (error) => Promise.reject(error));

const request = (url, method, data, options = {}) =>
  axios({
    ...options,
    url,
    method,
    data,
  });

export default request;
