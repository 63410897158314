// vendors
import { makeStyles } from '@material-ui/core/styles';

// images
import Image from '../../static/images/karma.png';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#ffbe38',
    backgroundPosition: 'center',
    display: 'grid',
    height: '40vh',
    gridTemplateRows: 'auto 1fr auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  body: {
    marginBottom: 30,
  },
  forgotPassword: {
    marginTop: '3vh',
    width: '90%',
  },
  textHeader: {
    color: '#fff',
  },
  otions: {
    '& .MuiListItemText-secondary': {
      textAlign: 'left',
    },
  },
  pqrList: {
    marginTop: 8,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    textAlign: 'initial',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  ArrowBackIconCustom: {
    position: 'absolute',
    marginTop: 37,
    marginLeft: 22,
    fontSize: 28,
    color: '#fff',
  },
  buttonProgress: {
    color: '#1DCAD3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  startButton: {
    position: 'relative',
    margin: 20,
    '& .MuiButton-label': {
      color: 'white',
      fontWeight: 500,
    },
  },
  textField: {
    width: '90%',
  },
  center: {
    textAlign: 'center',
  },
  textFollowInstruction: {
    textAlign: 'center',
    marginTop: '10vh',
  },
}));
