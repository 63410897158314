// vendors
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

// actions
import { getAdministrativeExpensesSettingsChain } from '../../redux/actions/settings';
import { clearHistories, getHistories } from '../../redux/actions/createHistory';

// components
import GmSvgIcon from '../../static/svg';

// images
import YellowLogo from '../../components/yellow-logo';
import HistoriesList from './histories-list';

// styles
import { useStyles } from './styles';
import SideMenu from '../../components/side-menu';

const HistoriesPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchInputValue, setSearchInputValue] = useState(null);
  const [isSearchInputEnabled, setSearchInputEnabled] = useState(false);
  const { histories, totalCount } = useSelector((state) => state.history);
  const { loadingHistories } = useSelector((state) => state.common);

  const [skipCount, setSkipCount] = useState(0);
  const [lastHistoryRef, setLastHistoryRef] = useState(null);
  const maxResult = 10;

  const hasMore = useMemo(() =>
    skipCount < totalCount || skipCount === 0,
  [totalCount, skipCount]);

  const lastHistoryObserver = useRef(
    new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasMore) {
          setSkipCount((prev) => prev + maxResult);
          entry.target.removeEventListener('load', entry.preventDefault);
        }
      });
    }, { threshold: 0.5 })
  );

  useEffect(() => {
    if (lastHistoryRef) {
      lastHistoryObserver.current.observe(lastHistoryRef);
    }
    return () => {
      if (lastHistoryRef) {
        lastHistoryObserver.current.unobserve(lastHistoryRef);
      }
    };
  }, [lastHistoryRef]);

  const handleSetLastHistoryRef = useCallback((node) => {
    if (node !== null) {
      setLastHistoryRef(node);
    }
  }, []);

  useEffect(() => {
    if (searchInputValue && searchInputValue.length > 4) {
      dispatch(clearHistories());
      dispatch(getHistories({ keywords: searchInputValue, maxResult }));
    }
  }, [searchInputValue]);

  useEffect(() => {
    if (hasMore) {
      dispatch(getHistories({ keywords: searchInputValue, maxResult, skipCount }));
    }
  }, [skipCount, hasMore]);

  useEffect(() => {
    dispatch(getAdministrativeExpensesSettingsChain());
  }, []);

  const handleOnSearchInputChange = ({ target }) => setSearchInputValue(target.value);
  const handleOnEnableSearchInput = () => setSearchInputEnabled(!isSearchInputEnabled);

  const renderSearchInputcontent = () => {
    if (!isSearchInputEnabled) {
      return (
        <>
          <YellowLogo />
        </>
      );
    }

    return (
      <TextField
        fullWidth
        onChange={handleOnSearchInputChange}
        placeholder="Buscar historias"
        type="text"
        value={searchInputValue}
        variant="outlined"
      />
    );
  };

  return (
    <>
      <Grid
        alignItems="center"
        className={classes.storiesHeader}
        container
      >
        <Grid item xs={2}>
          <GmSvgIcon
            disabled={!isSearchInputEnabled}
            enabled={isSearchInputEnabled}
            onClick={handleOnEnableSearchInput}
            icon="search"
            viewBox="0 0 121 53"
          />
        </Grid>
        <Grid item xs={8}>
          {renderSearchInputcontent()}
        </Grid>
        <Grid item xs={2}>
          <SideMenu
            disabledReferringButtons={false}
            isProfileVisiting={false}
            whoHasImReferredList={[]}
            whoRefferedMeList={[]}
            hideReferralButton
            hideCalendarButton
          />
        </Grid>
      </Grid>
      <HistoriesList
        histories={histories}
        loading={loadingHistories}
        observer={lastHistoryObserver}
        onSetLastHistoryRef={handleSetLastHistoryRef}
      />
    </>
  );
};

export default HistoriesPage;
