import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#33647E',
    backgroundPosition: 'center',
    display: 'grid',
    height: '15vh',
    gridTemplateRows: 'auto 1fr auto',
    borderRadius: 0,
  },
  arrowBackIcon: {
    position: 'absolute',
    top: '16px',
    left: '16px',
    color: 'white'
  },
  brandLogo: {
    margin: 20,
  },
  listItemText: {
    textAlign: 'justify',
  }
});

export { useStyles };
