// http
import bankApi from '../../config/api/bank';

// constants
export const GET_ALL_BANKS_BEGIN = 'GET_ALL_BANKS_BEGIN';
export const GET_ALL_BANKS_ERROR = 'GET_ALL_BANKS_ERROR';
export const GET_ALL_BANKS_SUCCESS = 'GET_ALL_BANKS_SUCCESS';
export const SAVE_BANK_USER_INFORMATION_BEGIN = 'SAVE_BANK_USER_INFORMATION_BEGIN';
export const SAVE_BANK_USER_INFORMATION_ERROR = 'SAVE_BANK_USER_INFORMATION_ERROR';
export const SAVE_BANK_USER_INFORMATION_SUCCESS = 'SAVE_BANK_USER_INFORMATION_SUCCESS';
export const GET_USER_BANK_INFORMATION_BEGIN = 'GET_USER_BANK_INFORMATION_BEGIN';
export const GET_USER_BANK_INFORMATION_ERROR = 'GET_USER_BANK_INFORMATION_ERROR';
export const GET_USER_BANK_INFORMATION_SUCCESS = 'GET_USER_BANK_INFORMATION_SUCCESS';
export const SHOW_BANK_USER_INFO_PAGE = 'SHOW_BANK_USER_INFO_PAGE';

// action creators
const getAllBanksBegin = () => ({ type: GET_ALL_BANKS_BEGIN });
const addUserBankInformationBegin = () => ({ type: SAVE_BANK_USER_INFORMATION_BEGIN });
const getUserBankInformationBegin = () => ({ type: GET_USER_BANK_INFORMATION_BEGIN });

export const getAllBanks = () => (dispatch) => {
  dispatch(getAllBanksBegin());
  return new Promise((resolve, reject) => bankApi.getAllBanks()
    .then(({ data: { result } }) => {
      resolve(result);
      dispatch({ type: GET_ALL_BANKS_SUCCESS, payload: result.items });
    })
    .catch((error) => {
      reject(error);
      dispatch({ type: GET_ALL_BANKS_ERROR });
    }));
};

export const setShowBankInfoPage = (show) => ({
  type: SHOW_BANK_USER_INFO_PAGE,
  payload: show,
});

export const addUserBankInformation = ({
  accountHolder,
  accountHolderDocumentNumber,
  accountNumber,
  accountType,
  bankId,
}) => (dispatch) => {
  dispatch(addUserBankInformationBegin());
  return new Promise((resolve, reject) => bankApi
    .addUserBankInformation({
      accountHolder,
      accountHolderDocumentNumber,
      accountNumber,
      accountType,
      bankId,
    })
    .then(({ data: { result } }) => {
      resolve(result);
      dispatch({ type: SAVE_BANK_USER_INFORMATION_SUCCESS });
      dispatch(setShowBankInfoPage(false));
    })
    .catch((error) => {
      reject(error);
      dispatch({ type: SAVE_BANK_USER_INFORMATION_ERROR });
    }));
};

export const getUserBankInformation = () => (dispatch) => {
  dispatch(getUserBankInformationBegin());
  return new Promise((resolve, reject) => bankApi.getUserBankInformation()
    .then(({ data: { result } }) => {
      resolve(result);
      dispatch({
        type: GET_USER_BANK_INFORMATION_SUCCESS,
        payload: result,
      });
    })
    .catch((error) => {
      reject(error);
      dispatch({ type: GET_USER_BANK_INFORMATION_ERROR });
    }));
};
