// vendors
import { makeStyles } from '@material-ui/core/styles';

// images
import Image from '../../static/images/landing-page.png';

export const useStyles = makeStyles({
  root: {
    backgroundAttachment: 'fixed',
    backgroundImage: `url(${Image})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    height: '100vh',
  },
  startButton: {
    color: '#ffffff',
    fontWeight: 500,
    marginBottom: 15,
    backgroundColor: '#18c9d3',
    width: 200,

    '&:hover': {
      backgroundColor: '#41afb5',
    }
  },
  moreInfoButton: {
    color: '#ffffff',
    fontWeight: 500,
    marginBottom: 15,
    backgroundColor: '#f93448',
    width: 200,
    '&:hover': {
      backgroundColor: '#cf2d3e',
    }
  },
  loginButton: {
    color: '#ffffff',
    backgroundColor: '#32667e',
    fontWeight: 500,
    marginBottom: 60,
    width: 200,
    '&:hover': {
      backgroundColor: '#2b5569',
    }
  },
});
