import env from '../config/env';

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let fileData = '';
    reader.readAsBinaryString(file);
    reader.onload = () => {
      fileData = reader.result;
      const parsedFile = btoa(fileData);
      resolve(parsedFile);
    };

    reader.onerror = (error) => reject(error);
  });
}

export function getAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let fileData = '';
    reader.readAsDataURL(file);
    reader.onload = () => {
      fileData = reader.result;
      resolve(fileData);
    };

    reader.onerror = (error) => reject(error);
  });
}

export function getAsImg({ type, content, name }) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = `data:${type};base64,${content}`;
    img.id = name;

    img.onload = () => {
      resolve(img);
    };

    img.onerror = (error) => reject(error);
  });
}

export function getCroppedImg(crop, image, imgToCrop) {
  const canvas = document.createElement('canvas');
  const cropWidth = image.width * (crop.width / 100);
  const cropHeight = image.height * (crop.height / 100);
  const cropX = image.width * (crop.x / 100);
  const cropY = image.height * (crop.y / 100);
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = cropWidth;
  canvas.height = cropHeight;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    cropX * scaleX,
    cropY * scaleY,
    cropWidth * scaleX,
    cropHeight * scaleY,
    0,
    0,
    cropWidth,
    cropHeight
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        return;
      }
      blob.name = imgToCrop.name;
      resolve(blob);
    }, imgToCrop.type);
  });
}

export function getDownloadComponent(url) {
  const link = document.createElement('a');
  link.download = url.substring(url.lastIndexOf('/') + 1);
  link.href = url;
  link.click();
}

export function createWompiWidgetCheckout({
  payment,
  redirectUrl,
  reference
}) {
  return new window.WidgetCheckout({
    currency: 'COP',
    amountInCents: payment,
    reference,
    publicKey: env.WOMPI_PUB_KEY,
    redirectUrl,
  });
}
