import { makeStyles } from '@material-ui/core/styles';

const storyQuestionButton = {
  fontSize: '14px',
  fontWeight: 600,
  marginBottom: 15,
  width: '100%',
};

const disabledStoryQuestionButtonState = {
  backgroundColor: '#47657CB2',
  color: '#FFFFFF',
};

// styles
const useStyles = makeStyles({
  storyQuestionsContainer: {
    height: 'auto',
    width: '100%',
  },
  storyQuestionButton: (props) => ({
    ...storyQuestionButton,
    backgroundColor: props?.backgroundColor || '#47657C',
    color: props?.color || '#FFC600',
    '&:hover': {
      backgroundColor: props?.backgroundColor || '#47657C',
      color: props?.color || '#FFC600',
    },
  }),
  storyQuestionButtonDisabled: {
    ...storyQuestionButton,
    ...disabledStoryQuestionButtonState,
  },
});

export default useStyles;
