// services
import storySvc from '../../config/api/story';

// constants
// create new story constants type
export const CREATE_NEW_STORY_BEGIN = 'CREATE_NEW_STORY_BEGIN';
export const CREATE_NEW_STORY_ERROR = 'CREATE_NEW_STORY_ERROR';
export const CREATE_NEW_STORY_SUCCESS = 'CREATE_NEW_STORY_SUCCESS';
// get story phrases constants type
export const GET_CREATE_STORY_PHRASES_BEGIN = 'GET_CREATE_STORY_PHRASES_BEGIN';
export const GET_CREATE_STORY_PHRASES_ERROR = 'GET_CREATE_STORY_PHRASES_ERROR';
export const GET_CREATE_STORY_PHRASES_SUCCESS = 'GET_CREATE_STORY_PHRASES_SUCCESS';
// get story questions constants type
export const GET_CREATE_STORY_QUESTIONS_BEGIN = 'GET_CREATE_STORY_QUESTIONS_BEGIN';
export const GET_CREATE_STORY_QUESTIONS_ERROR = 'GET_CREATE_STORY_QUESTIONS_ERROR';
export const GET_CREATE_STORY_QUESTIONS_SUCCESS = 'GET_CREATE_STORY_QUESTIONS_SUCCESS';
// update story question pictures constants type
export const UPDATE_STORY_QUESTION_PICTURE_BEGIN = 'UPDATE_STORY_QUESTION_PICTURE_BEGIN';
export const UPDATE_STORY_QUESTION_PICTURE_ERROR = 'UPDATE_STORY_QUESTION_PICTURE_ERROR';
export const UPDATE_STORY_QUESTION_PICTURE_SUCCESS = 'UPDATE_STORY_QUESTION_PICTURE_SUCCESS';
// update story creation constants type
export const UPDATE_STORY_CREATION_BEGIN = 'UPDATE_STORY_CREATION_BEGIN';
export const UPDATE_STORY_CREATION_ERROR = 'UPDATE_STORY_CREATION_ERROR';
export const UPDATE_STORY_CREATION_SUCCESS = 'UPDATE_STORY_CREATION_SUCCESS';

export const createNewStory = () => (dispatch) => {
  dispatch({ type: CREATE_NEW_STORY_BEGIN });

  return new Promise((resolve, reject) => {
    storySvc
      .createHistory()
      .then(({ data: { result } }) => {
        const payload = {
          storyId: result.id,
          questionPicturesIds: result.historyQuestions.map(({ id }) => id),
        };
        resolve(payload);
        dispatch({
          type: CREATE_NEW_STORY_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        reject(error);
        dispatch({ type: CREATE_NEW_STORY_ERROR, error });
      });
  });
};

export const updateStoryQuestionPictures = (data) => (dispatch) => {
  dispatch({ type: UPDATE_STORY_QUESTION_PICTURE_BEGIN });

  return new Promise((resolve, reject) => {
    storySvc
      .updateStoryQuestionPictures(data)
      .then(({ data }) => {
        resolve(data.result);
        dispatch({
          type: UPDATE_STORY_QUESTION_PICTURE_SUCCESS,
          payload: data.result,
        });
      })
      .catch((error) => {
        reject(error);
        dispatch({ type: UPDATE_STORY_QUESTION_PICTURE_ERROR, error });
      });
  });
};

export const updateStoryCreation = (data) => (dispatch) => {
  dispatch({ type: UPDATE_STORY_CREATION_BEGIN });

  return new Promise((resolve, reject) => {
    storySvc
      .updateStory(data)
      .then(({ data }) => {
        resolve(data.result);
        dispatch({
          type: UPDATE_STORY_CREATION_SUCCESS,
          payload: data.result,
        });
      })
      .catch((error) => {
        reject(error);
        dispatch({ type: UPDATE_STORY_CREATION_ERROR, error });
      });
  });
};

export const getCreateStoryQuestions = () => (dispatch) => {
  dispatch({ type: GET_CREATE_STORY_QUESTIONS_BEGIN });

  return new Promise((resolve, reject) => {
    storySvc
      .getCreateStoryQuestions()
      .then(({ data }) => {
        resolve(data.result);
        dispatch({
          type: GET_CREATE_STORY_QUESTIONS_SUCCESS,
          payload: data.result,
        });
      })
      .catch((error) => {
        reject(error);
        dispatch({ type: GET_CREATE_STORY_QUESTIONS_ERROR, error });
      });
  });
};

export const getCreateStoryPhrases = () => (dispatch) => {
  dispatch({ type: GET_CREATE_STORY_PHRASES_BEGIN });

  return new Promise((resolve, reject) => {
    storySvc
      .getCreateStoryPhrases()
      .then(({ data }) => {
        resolve(data.result);
        dispatch({
          type: GET_CREATE_STORY_PHRASES_SUCCESS,
          payload: data.result,
        });
      })
      .catch((error) => {
        reject(error);
        dispatch({ type: GET_CREATE_STORY_PHRASES_ERROR, error });
      });
  });
};
