// vendors
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

// components
import GmUploadPicture from '../../upload-picture';

// styles
import { useStyles } from './styles';

export const GmAboutMe = ({
  disableFields,
  formik,
  handleOnChangeId,
  handleOnDocTypeChange,
  onUploadDocumentImg,
  q0Img,
  editProfile = false
}) => {
  const classes = useStyles();

  return (
    <>
      {!editProfile
        ? (
            <Grid item xs={12}>
              <TextField
                disabled={disableFields}
                error={formik.touched.personalPhrase && Boolean(formik.errors.personalPhrase)}
                helperText={formik.touched.personalPhrase && formik.errors.personalPhrase}
                id="personalPhrase"
                label="Escribe una frase que inspire a otros"
                name="personalPhrase"
                onChange={formik.handleChange}
                style={{ paddingBottom: 20, marginTop: 5 }}
                fullWidth
                value={formik.values.personalPhrase}
                variant="outlined"
              />
            </Grid>
        )
        : null}
      <Grid item xs={12}>
        <TextField
          disabled={disableFields}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          id="name"
          label="Nombre"
          name="name"
          onChange={(event) => {
            if (/^[a-zA-Z ]*$/.test(event.target.value)) {
              formik.handleChange(event);
            }
          }}
          style={{ paddingBottom: 20, marginTop: editProfile ? 5 : 0 }}
          fullWidth
          value={formik.values.name}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={disableFields}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          id="lastName"
          label="Apellidos"
          name="lastName"
          onChange={(event) => {
            if (/^[a-zA-Z ]*$/.test(event.target.value)) {
              formik.handleChange(event);
            }
          }}
          style={{ paddingBottom: 20 }}
          fullWidth
          value={formik.values.lastName}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={disableFields}
          error={formik.touched.month && Boolean(formik.errors.month)}
          helperText={(formik.touched.month && formik.errors.month)}
          id="month"
          InputLabelProps={{
            shrink: true,
          }}
          label="Fecha de nacimiento"
          name="month"
          placeholder="Mes"
          onChange={formik.handleChange}
          style={{
            width: '45%',
            paddingBottom: 20,
            marginRight: 8.5,
            paddingRight: 5
          }}
          value={formik.values.month}
          variant="outlined"
          type="text"
          item
          xs={6}
        />
        <TextField
          disabled={disableFields}
          error={formik.touched.day && Boolean(formik.errors.day)}
          helperText={formik.touched.day && formik.errors.day}
          id="day"
          name="day"
          placeholder="Día"
          onChange={formik.handleChange}
          style={{
            width: '25%',
            paddingBottom: 20,
            marginRight: 8.5,
            paddingRight: 5
          }}
          value={formik.values.day}
          variant="outlined"
          type="text"
          item
          xs={3}
        />
        <TextField
          disabled={disableFields}
          error={formik.touched.year && Boolean(formik.errors.year)}
          helperText={formik.touched.year && formik.errors.year}
          id="year"
          name="year"
          placeholder="Año"
          onChange={formik.handleChange}
          style={{ width: '25%', paddingBottom: 20 }}
          value={formik.values.year}
          variant="outlined"
          type="text"
          item
          xs={3}
        />
      </Grid>
      <FormControl style={{ width: '100%', paddingBottom: 20 }}>
        <InputLabel id="gender" className={classes.inpuLabelStyle}>
          Género
        </InputLabel>
        <Select
          disabled={disableFields}
          error={formik.touched.gender && Boolean(formik.errors.gender)}
          id="gender"
          label="Género"
          labelId="gender"
          name="gender"
          onChange={formik.handleChange}
          placeholder="Género"
          style={{ textAlign: 'left' }}
          value={formik.values.gender}
          variant="outlined"
        >
          <MenuItem value="0">Masculino</MenuItem>
          <MenuItem value="1">Femenino</MenuItem>
          <MenuItem value="2">No binario</MenuItem>
        </Select>
      </FormControl>
      <FormControl style={{ width: '30%', paddingBottom: 20, paddingRight: 20 }}>
        <InputLabel id="docType" className={classes.inpuLabelStyle}>
          Tipo
        </InputLabel>
        <Select
          disabled={disableFields}
          error={formik.touched.docType && Boolean(formik.errors.docType)}
          id="docType"
          label="Tipo"
          labelId="docType"
          name="docType"
          onChange={handleOnDocTypeChange}
          value={formik.values.docType}
          variant="outlined"
        >
          <MenuItem value="CC">C.C.</MenuItem>
          <MenuItem value="Pasaporte">Pasaporte</MenuItem>
          <MenuItem value="CE">C. Extranjeria</MenuItem>
        </Select>
      </FormControl>
      <TextField
        disabled={disableFields}
        error={formik.touched.id && Boolean(formik.errors.id)}
        helperText={formik.touched.id && formik.errors.id}
        id="id"
        label="Número de identificación"
        name="id"
        onChange={handleOnChangeId}
        style={{ width: '70%' }}
        value={formik.values.id}
        variant="outlined"
      />
      {!editProfile
        ? (
          <GmUploadPicture
            callback={(imgBase64) => onUploadDocumentImg(imgBase64)}
            customStyle={{
              width: '100%',
              backgroundImage: q0Img?.content
                ? `url("data:${q0Img?.type};base64,${q0Img?.content}")`
                : `url("${q0Img?.name}")`
            }}
            isImage={q0Img?.content || q0Img?.name}
            nameDocFile="docImgFileq1"
            text="Sube una foto de tu documento"
          />
        )
        : null}
    </>
  );
};

export default GmAboutMe;
