// vendors
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

// actions
import { checkCode } from '../../redux/actions/user';

// components
import BlueLogo from '../../components/blue-logo';
import AlertMessage from '../../components/alert';
import WizardFormPage from '../wizard-form';

// styles
import { useStyles } from './styles';

const validationSchema = yup.object({
  acceptTerms: yup.boolean().oneOf([true], 'Por favor acepta terminos y condiciones'),
  email: yup
    .string('Ingresa tú correo electrónico')
    .email('Ingresa tú correo electrónico')
    .required('El correo electrónico es requerido')
    .email('Ingresa tu correo electrónico'),
  password: yup
    .string('Ingresa tú contraseña')
    .min(8, 'Tú contraseña debe tener mínimo 8 caracteres de longitud')
    .required('contraseña es requerida'),
  referral: yup.string('Ingresa tu código de referido').required('este código es requerido'),
});

function ReferralCodePage() {
  const classes = useStyles();
  const { code } = useParams();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const {
    common: { loading },
    register: { error, isInvitationCodeOk },
  } = useSelector((state) => state);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
      referral: code || '',
      acceptTerms: false,
    },
    validationSchema,
    onSubmit: (values) => {
      setSubmitAttempted(true);
      if (formik.isValid) {
        dispatch(checkCode({
          invitationCode: values.referral,
          emailAddress: values.email,
          password: values.password,
        }));
      }
    },
  });

  const onSubmitHandler = () => {
    setSubmitAttempted(true);
  };

  const handleChange = (event) => {
    formik.setFieldValue('acceptTerms', event.target.checked);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (isInvitationCodeOk) {
    return <WizardFormPage />;
  }

  return (
    <>
      <Paper className={classes.root}>
        <Container>
          <Grid container justify="center" className={classes.center}>
            <Grid className={classes.logIn} item xs={12}>
              <BlueLogo />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.headerTitle} variant="subtitle2">
                Un amigo ha confiado en ti
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Grid container style={{ marginTop: -30 }} justify="center">
          {error && <AlertMessage text="Por favor valida tu código de referido" />}
          <Grid item xs={12} md={6} className={classes.center}>
            <form className={classes.form} onSubmit={formik.handleSubmit}>
              <TextField
                className={clsx(classes.margin, classes.textField)}
                disabled={loading}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                id="email"
                label="Correo electrónico"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                variant="outlined"
              />
              <TextField
                className={clsx(classes.margin, classes.textField)}
                disabled={loading}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                id="password"
                label="Nueva contraseña"
                name="password"
                onChange={formik.handleChange}
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        className={classes.visibilityButton}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                id="referral"
                name="referral"
                label="Código de referido"
                disabled={loading}
                value={formik.values.referral}
                onChange={formik.handleChange}
                className={clsx(classes.margin, classes.textField)}
                error={formik.touched.referral && Boolean(formik.errors.referral)}
                helperText={formik.touched.referral && formik.errors.referral}
              />
              <FormControlLabel
                className={classes.divider}
                control={(
                  <Checkbox
                    name="acceptTerms"
                    color="primary"
                    id="acceptTerms"
                    onChange={handleChange}
                    checked={formik.values.acceptTerms}
                    style={{ top: -9 }}
                  />
                )}
                label={(
                  <div className={classes.termsAndConditionsText}>
                    <span>Aceptar</span>
                    <a
                      className={classes.haveAccountLink}
                      href={`${process.env.PUBLIC_URL}/pdf/GiVEmeFiVE-TérminosYCondiciones.pdf`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      términos y condiciones
                    </a>
                    <span> y</span>
                    <a
                      className={classes.haveAccountLink}
                      href={`${process.env.PUBLIC_URL}/pdf/GiVEmeFiVE-AutorizaciónYTratamientoDatos.pdf`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      política de privacidad.
                    </a>
                  </div>
                )}
              />
              {submitAttempted && formik.errors.acceptTerms && (
                <Typography className={classes.errorText} gutterBottom>
                Por favor acepta términos y condiciones
                </Typography>
              )}
              <Grid item className={classes.startButton}>
                <Button disabled={loading} onClick={onSubmitHandler} variant="contained" color="primary" type="submit">
                  EMPECEMOS
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.haveAccount}>
                <span className={classes.checkboxPage}>¿Ya tienes cuenta?</span>
                <Link to="/login" className={classes.haveAccountLink}>
                  Inicia sesión
                </Link>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default ReferralCodePage;
