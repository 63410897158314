/* eslint-disable max-len */

// vendors
import React from 'react';

const ReferringDisabled = (
  <>
    <path d="M7.22183 12.9419H0.949097V29.0768H7.22183V12.9419Z" fill="#7D7D7D" />
    <path d="M59.7453 0H53.4728V42H59.7453V0Z" fill="#7D7D7D" />
    <path d="M53.4728 42L7.22186 29.058V12.942L53.4728 0V42Z" fill="black" fillOpacity="0.3" />
    <path d="M76.0693 20.1405H67.1706H66.6982V20.6128V22.3888V22.8423H67.1706H76.0693H76.5227V22.3888V20.6128V20.1405H76.0693Z" fill="#7D7D7D" />
    <path d="M68.0963 11.5438L68.4174 11.865L68.7575 11.5438L74.9735 5.3279L75.3135 4.98782L74.9735 4.66663L73.7265 3.40078L73.3863 3.07959L73.0653 3.40078L66.8493 9.63559L66.5093 9.95678L66.8493 10.2969L68.0963 11.5438Z" fill="#7D7D7D" />
    <path d="M68.7575 31.4385L68.4174 31.1173L68.0963 31.4385L66.8493 32.7044L66.5093 33.0255L66.8493 33.3467L73.0653 39.5815L73.3863 39.9027L73.7265 39.5815L74.9735 38.3157L75.3135 37.9945L74.9735 37.6733L68.7575 31.4385Z" fill="#7D7D7D" />
  </>

);

export default ReferringDisabled;
