// initial state
import { bankInitialState } from '../../config/state/initialState';

// actions
import { GET_ALL_BANKS_SUCCESS } from '../actions/bank';

const bankReducer = (state = bankInitialState, action) => {
  switch (action.type) {
    case GET_ALL_BANKS_SUCCESS:
      return {
        ...state,
        bankList: action.payload,
      };

    default:
      return state;
  }
};

export default bankReducer;
