import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/**
 *
 * @param {object} props
 * @param {string} props.title The title of the accordion item.
 * @param {string[]} props.content An array of strings representing the content of the accordion item to fit into the mapping function.
 * @param {Object} props.classes - Custom CSS classes to style the component (note that this component has not predefined style).
 * @param {string} props.id Unique identifier, ussually title.
 * @returns {JSX.Element}
 */
const AccordionItem = ({
  title, content, classes, id
}) => (
    <Accordion>
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<ExpandMoreIcon />}
        id={id}
        style={{ align: 'left' }}
      >
        <Typography style={{ textAlign: 'left' }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <List>
            {content.map((item) => (
              <ListItem className={classes.listItemText} key={item}>
                {item}
              </ListItem>
            ))}
          </List>
        </Grid>
      </AccordionDetails>
    </Accordion>
);

export default AccordionItem;
