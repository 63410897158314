import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// components
import GmAlertMessage from '../alert';

// styles
import useStyles from './styles';

/**
 * @param {object} props
 * @param {string} props.backgroundColor
 * @param {string} props.title
 * @param {(value: string) => void} props.onChange
 * @param {string} props.placeholder
 * @param {number} props.rows
 * @param {string} props.value
 * @returns
 */
export const GmStoryTextField = ({
  backgroundColor,
  id,
  name,
  error,
  normalText,
  onChange,
  placeholder,
  rows,
  style,
  title,
  value,
}) => {
  const classes = useStyles({ backgroundColor, normalText, value });

  return (
    <div style={style}>
      <Typography className={classes.storyTextFieldTitle}>
        {title}
      </Typography>
      <TextField
        InputProps={{
          classes: {
            input: classes.storyTextFieldPlaceholder
          },
          disableUnderline: true
        }}
        className={classes.storyTextFieldContainer}
        id={id}
        multiline
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows || 13}
        value={value}
        variant="standard"
      />
      <GmAlertMessage
        style={{
          marginTop: 0,
          padding: '0px 20px',
          textAlign: 'left',
        }}
        visible={Boolean(error)}
        variant="filled"
      >
        <strong>{error}</strong>
      </GmAlertMessage>
    </div>
  );
};

export default GmStoryTextField;
