// vendors
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { useSelector } from 'react-redux';

// @styles
import { useStyles } from './styles';

const LoadingPage = ({ visible = false }) => {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.common);

  if (!loading && !visible) {
    return null;
  }

  return (
    <div className={classes.loadingPage}>
      <div className={classes.background} />
      <div className={classes.centerPanel}>
        <CircularProgress
          className={classes.loadingColor}
          size={50}
        />
      </div>
    </div>
  );
};

export default LoadingPage;
