// vendors
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const GmLoanCalculatorFeeDisclaimer = ({
  isGodfather,
  isGodson,
  visible,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <>
      <Grid item style={{ marginRight: 35, marginLeft: 35 }} xs={12}>
        <Grid container>
          <Grid item style={{ textAlign: 'justify' }} xs={12}>
            <Typography style={{ color: '#676767', fontSize: '12px' }}>
              <b>Sobre los costos del servicio.</b>
              {' '}
              {isGodson && '­­­­­­­­Antes de aceptar la propuesta ten en cuenta:'}
              {isGodfather && 'Antes de enviar la propuesta, ten en cuenta:'}
            </Typography>
            <Typography style={{ color: '#676767', fontSize: '12px' }}>
              <ul style={{ listStyleType: 'auto' }}>
                <li style={{ paddingLeft: 5 }}>
                  {isGodson && 'Una vez aceptes la propuesta, debes esperar 48 horas a que te hagan el desembolso.'}
                  {isGodfather && 'Una vez te acepten la propuesta, debes hacer el desembolso en las siguientes 36 horas'}
                </li>
                <li style={{ paddingLeft: 5 }}>
                  {/* eslint-disable-next-line max-len */}
                  {isGodson && 'Como  sabemos que estás embalado, te diferimos los costos del servicio en las cuotas que vas a pagar.'}
                  {/* eslint-disable-next-line max-len */}
                  {isGodfather && 'Como tu Buena Paga está embalado, tú pagas por adelantado los costos del servicio, sin embargo, estos se te devuelven en el pago de las cuotas.'}
                </li>
                <li style={{ paddingLeft: 5 }}>
                  Estos valores tienen el IVA incluido.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GmLoanCalculatorFeeDisclaimer;
