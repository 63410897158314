/* eslint-disable no-multi-spaces */
// vendors
import { makeStyles } from '@material-ui/core/styles';

export const suggestedFeeMapper = {
  None: '25%',   // None
  Low: '47%',    // Low
  Medium: '69%', // Medium
  High: '90%',   // High
};

const useStyles = makeStyles(() => ({
  taxesTitle: {
    display: 'flex',
    marginLeft: 50,
  },
  summaryContainer: {
    borderLeft: '30px solid #BDE1E2',
    borderRadius: 15,
    borderRight: '30px solid #BDE1E2',
    borderTop: '30px solid #BDE1E2',
    marginBottom: 24,
    textAlign: 'start',
    width: '100%',
  },
  summaryContainerRow: {
    textAlign: 'center'
  },
  summaryContainerRowItem: {
    padding: 20,
    textAlign: 'center',
  },
  summaryContainerRowItemHeaderContainer: {
    backgroundColor: '#BDE1E2',
    margin: '0px 20px 0px',
  },
  summaryContainerRowItemHeaderTitle: {
    color: '#33647E',
    fontSize: '8px',
    fontWeight: '600',
  },
  summaryContainerRowItemTitle: {
    color: '#47657C',
    fontSize: '7px',
    fontWeight: '600',
  },
  summaryContainerRowItemValue: {
    color: '#000',
    fontSize: '11px',
    fontWeight: '700',
  },
  summaryContainerRowItemCaption: {
    color: '#676767',
    fontSize: '8px',
    fontWeight: '400',
  },
  godfatherSummaryContainerRowLastItem: {
    paddingTop: '10px',
    '& span': {
      fontSize: '0.75rem',
    },
    '& strong': {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  },
  suggestedInterest: {
    color: '#EA8087',
    flexDirection: 'column',
    fontWeight: 700
  },
  suggestedInterestLabel: {
    fontSize: '7px',
    fontWeight: 600
  },
  interestRateSlider: {
    marginTop: -30,
    width: '100%',
    '& .MuiSlider-root': {
      color: '#F93549',
      height: 5,
      '& .MuiSlider-rail': {
        height: 5,
      },
      '& .MuiSlider-track': {
        height: 5,
      },
      '& .MuiSlider-mark': {
        backgroundColor: '#FF8189',
        borderRadius: '100%',
        height: 5,
        width: 6,
      },
      '& .MuiSlider-markLabel': {
        fontSize: 12,
        paddingTop: 5,
      },
      '& .MuiSlider-thumb': {
        width: 15,
        height: 15,
      },
    },
  },
  inpuLabelStyle: {
    position: 'absolute',
    top: '-6px',
    background: '#fff',
    zIndex: 1,
    left: 12,
    color: '#000',
    width: 137,
  },
  deleteProposalButton: {
    backgroundColor: '#33647E',
    color: '#ffffffff',
    height: 24,
    marginTop: 22,
    width: 79,
  },
  editProposalButton: {
    backgroundColor: '#33647E',
    color: '#ffffffff',
    height: 24,
    marginTop: 22,
    width: 79,
  },
  listMessageSender: {
    border: '1px solid #D8D8D8',
    borderRadius: '10px 10px 10px 0',
    background: '#FFFFFF',
    color: '##33647E',
    marginBottom: 15,
    fontSize: '14px !important',
    fontStyle: 'italic',
    width: '90%',
  },
  listMessageReceiver: {
    border: '1px solid #D8D8D8',
    borderRadius: '10px 10px 0px 10px',
    background: 'rgba(221, 221, 221, 0.4)',
    color: '#676767',
    marginBottom: 15,
    fontSize: '14px !important',
    fontStyle: 'italic',
    width: '90%',
    float: 'right',
  },
}));

export { useStyles };
