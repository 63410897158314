// vendors
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  actionItem: {
    paddingBottom: 40,
  },
  actionItemValueError: {
    color: '#F93549',
    fontSize: 18,
    fontWeight: 700,
  },
  actionItemValueSuccess: {
    color: '#1DCAD3',
    fontSize: 18,
    fontWeight: 700,
  },
  activityContent: {
    paddingBottom: 36,
    paddingTop: 25,
  },
  activityTitle: {
    color: '#0F1E36',
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'left',
  },
  arrowBackIcon: {
    color: '#7D7D7D',
    float: 'left',
    margin: '24px 0px 0px 22px',
  },
  arrowForwardIcon: {
    color: '#7D7D7D',
    float: 'right',
  },
  divider: {
    marginTop: 35,
    marginBottom: 25,
  },
  documentsList: {
    // TODO:
    marginTop: '20px !important',
  },
  documentsListGodfather: {
    borderBottom: '0.5px solid rgba(0, 0, 0, 0.38)',
    paddingBottom: 25,
    marginTop: '20px !important',
  },
  godfatherPaymentsDetail: {
    paddingTop: 25,
  },
  godsonSummaryItem: {
    marginLeft: -30,
  },
  godsonSummaryItem2: {
    marginLeft: 15,
  },
  headerAvatar: {
    height: 25,
    width: 25,
  },
  historyImages: {
    marginTop: 30,
    marginBottom: 35,
  },
  paidPercentage: {
    color: '#FFC600'
  },
  paymentsDetailPage: {
    paddingBottom: 90,
  },
  subTitle: {
    color: '#0F1E36',
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'left',
  },
  summaryItemContainer: {
    marginBottom: 20,
  },
  summaryItemsContent: {
    padding: 15,
  },
  summaryItemLabel: {
    color: '#676767',
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left',
  },
  summaryItemValue: {
    color: '#0F1E36',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  summaryPercentage: {
    height: 140,
    padding: 15,
    width: 140,
  },
  summaryPercentageLabel: {
    color: '#0F1E36',
    fontSize: 14,
    fontWeight: 600,
    marginTop: 15,
  },
  supportMoreStoriesButton: {
    backgroundColor: '#33647E',
    color: '#FFFFFF',
    width: '75%',
  },
  supportMoreStoriesButtonGodfatherContainer: {
    borderBottom: '0.5px solid rgba(0, 0, 0, 0.38)',
  },
  supportMoreStoriesButtonGodfather: {
    backgroundColor: '#33647E',
    color: '#FFFFFF',
    marginTop: 25,
    width: '75%',
    marginBottom: 25,
  },
  tabs: {
    width: '100%',
  },
  tabContent: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    width: '100%',
  },
  tabLabel: {
    color: '#0F1E36',
    fontWeight: 700,
    textTransform: 'none',
  },
  titleContainer: {
    marginTop: 22,
  },
  title: {
    color: '#0F1E36',
    fontSize: 16,
    fontWeight: 700,
  },
  documentText: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  docContainer: {
    backgroundColor: '#f7f7f7',
    border: '1px solid #1DCAD3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  }
}));
