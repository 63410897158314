// constants
import env from '../env';

// http
import request from '../request';

const transactionApi = {
  sendSMSCode({ phoneCode, phoneNumber }) {
    return request(`${env.mainBaseUrl}/services/app/Transaction/SendSMSCode`, 'post', { phoneCode, phoneNumber });
  },

  validateCode({ code, phoneCode, phoneNumber }) {
    return request(`${env.mainBaseUrl}/services/app/Transaction/ValidateCode`, 'post', { code, phoneCode, phoneNumber });
  },

  dummySendSMS(data) {
    return request(`${env.mainBaseUrl}/services/app/Transaction/DummySendSMS`, 'post', data);
  },

  createTransaction(data) {
    return request(`${env.mainBaseUrl}/services/app/Transaction/Create`, 'post', {
      ...data,
      proposal: {
        ...data.proposal,
        frequency: data.proposal.frequency,
        Id: data.proposal.id
      },
    });
  },

  getPaymentCalendar(data) {
    return request(`${env.mainBaseUrl}/services/app/PaymentCalendar/GetAll?Month=${data.month}&Year=${data.year}`, 'get');
  },

  setRefferUser(data) {
    return request(`${env.mainBaseUrl}/services/app/User/ReferToAFriend`, 'post', data);
  },
};

export default transactionApi;
