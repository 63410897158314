// vendors
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  componentPlaygroundContainer: {
    display: 'grid',
    height: '100vh',
    padding: 25,
    placeItems: 'center',
    width: '100%',
  },
  componentPlaygroundItem: {
    marginBottom: 25,
  },
});
