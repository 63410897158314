import { authInitialState } from '../../config/state/initialState';
import {
  AUTH_BEGIN,
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_SUCCESS,
  GET_USER_INFO_SUCCESS,
  ON_FORGOT_PASSWORD_ERROR,
  ON_FORGOT_PASSWORD_SUCCESS,
  ON_RECOVER_PASSWORD_BEGIN,
  SET_ROLE_SUCCESS,
} from '../action-types';
import {
  GET_USER_BANK_INFORMATION_BEGIN,
  GET_USER_BANK_INFORMATION_ERROR,
  GET_USER_BANK_INFORMATION_SUCCESS,
  SHOW_BANK_USER_INFO_PAGE,
} from '../actions/bank';
import { CLEAR_FORGOT_PASSWORD_OK } from '../actions/user';

const authReducer = (state = authInitialState, action) => {
  switch (action.type) {
    case AUTH_BEGIN:
      return {
        ...state,
        error: null,
        isAuthenticated: false,
        userInfo: {},
      };

    case AUTH_ERROR:
    case ON_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        error: action.error.message,
        isAuthenticated: false,
        userInfo: {},
      };

    case AUTH_SUCCESS:
      const [role] = action.payload.roles || [];
      return {
        ...state,
        error: null,
        isAuthenticated: true,
        userInfo: { ...action.payload, role },
      };

    case AUTH_LOGOUT:
      return authInitialState;

    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload.userInfo
        },
      };

    case ON_RECOVER_PASSWORD_BEGIN:
      return {
        ...state,
      };

    case ON_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        error: false,
        sendPasswordOk: action.payload.success,
      };

    case CLEAR_FORGOT_PASSWORD_OK:
      return {
        ...state,
        sendPasswordOk: false
      };

    case SET_ROLE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userInfo: {
          ...state.userInfo,
          role: action.payload.role,
        },
      };

    case GET_USER_BANK_INFORMATION_BEGIN:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          bankInfo: [],
        }
      };

    case GET_USER_BANK_INFORMATION_ERROR:
      return {
        ...state,
        error: true,
        userInfo: {
          ...state.userInfo,
          bankInfo: [],
        }
      };

    case GET_USER_BANK_INFORMATION_SUCCESS:
      return {
        ...state,
        error: false,
        userInfo: {
          ...state.userInfo,
          bankInfo: action.payload,
        }
      };

    case SHOW_BANK_USER_INFO_PAGE:
      return {
        ...state,
        error: false,
        showBankInfoPage: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
