// vendors
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import numeral from 'numeral';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useHistory } from 'react-router-dom';

// components
import SummaryItem from './summary-item';
import GmSvgIcon from '../../static/svg';

// constants
import { LOAN_CATEGORIES } from '../../config/constants';
import { routes } from '../../config/routes';

// styles
import { useStyles } from './styles';
import 'react-circular-progressbar/dist/styles.css';

const PaymentDetailSummary = ({
  category,
  debtToDate,
  isGodfather,
  isGodson,
  nextPaymentDate,
  nextPaymentValue,
  // loanAmount,
  onGiveBackKarma,
  paidPercentage,
  paidToDate,
  title,
  visible,
}) => {
  const classes = useStyles();
  const history = useHistory();

  if (!visible) {
    return null;
  }

  const getCategory = () => {
    switch (category) {
      case LOAN_CATEGORIES.capital:
        return 'Plante';
      case LOAN_CATEGORIES.health:
        return 'Salud';
      case LOAN_CATEGORIES.rent:
        return 'Arriendo';
      case LOAN_CATEGORIES.study:
        return 'Estudio';
      case LOAN_CATEGORIES.transport:
        return 'Transporte';
      default:
        return '';
    }
  };

  const nextPaymentDateContent = (
    <SummaryItem
      label="Fecha del próximo pago:"
      value={moment(nextPaymentDate).format('DD MMM YYYY')}
    />
  );

  const nextPaymentValueContent = (
    <SummaryItem
      label="Valor del próximo pago:"
      value={numeral(nextPaymentValue).format('$0,0')}
    />
  );

  const debtToDateContent = (
    <SummaryItem
      label="Valor total adeudado:"
      value={numeral(debtToDate).format('$0,0')}
    />
  );

  const KarmaProgress = ({ showLabel }) => (
    <>
      <CircularProgressbar
        value={paidPercentage}
        text={`${paidPercentage}%`}
        strokeWidth={18}
        styles={buildStyles({
          pathColor: '#FFC600',
          textColor: '#000000',
        })}
      />
      {showLabel
        ? <Typography className={classes.summaryPercentageLabel}>Karma Retribuido</Typography>
        : null}
    </>
  );

  const commonContent = (
    <>
      <Grid container>
        <Grid item>
          <Typography className={classes.activityTitle}>
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid className={classes.summaryPercentage} item md={6} xs={6}>
          <KarmaProgress />
        </Grid>
        <Grid className={classes.summaryItemsContent} item md={6} xs={6}>
          <SummaryItem
            label="Monto pagado:"
            value={numeral(paidToDate).format('$0,0')}
          />
          <SummaryItem
            label="Categoría del prestamo:"
            value={getCategory()}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container justify="space-evenly">
        <Grid className={classes.godsonSummaryItem} item>
          {nextPaymentValueContent}
          {debtToDateContent}
        </Grid>
        <Grid className={classes.godsonSummaryItem2} item>
          {nextPaymentDateContent}
        </Grid>
      </Grid>
    </>
  );

  let content = null;

  if (isGodfather) {
    content = (
      <>
        {commonContent}
        <Grid container justify="center">
          <Button
            className={classes.supportMoreStoriesButton}
            onClick={() => history.push(routes.histories)}
            variant="contained"
          >
            APOYAR MÁS HISTORIAS
          </Button>
        </Grid>
      </>
    );
  }

  if (isGodson) {
    content = (
      <>
        {commonContent}
        <Grid container justify="center">
          <Button
            className={classes.supportMoreStoriesButton}
            startIcon={<GmSvgIcon enabled icon="payment-report" />}
            onClick={onGiveBackKarma}
            variant="contained"
          >
            RETRIBUIR EL BUEN KARMA
          </Button>
        </Grid>
      </>
    );
  }

  return content;
};

export default PaymentDetailSummary;
