import { makeStyles } from '@material-ui/core/styles';

// styles
const useStyles = makeStyles({
  storyTextFieldContainer: (props) => ({
    backgroundColor: props?.backgroundColor || '#EAD2D5',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: 400,
    height: 'auto',
    padding: '20px',
    textAlign: 'left',
    width: '100%',
    '&>div': {
      padding: '5px'
    }
  }),
  storyTextFieldTitle: {
    fontSize: '15px',
    fontWeight: 700,
    marginBottom: '15px',
    textAlign: 'left',
  },
  storyTextFieldPlaceholder: (props) => ({
    color: '#1A2E3C',
    fontStyle: !props?.value && !props.normalText
      ? 'italic'
      : 'normal'
  })
});

export default useStyles;
