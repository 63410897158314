// vendors
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  cardContainer: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  closeIcon: {
    left: 10,
    position: 'absolute',
    top: 10,
  },
  confirmButton: {
    '&>span': {
      color: '#FFFFFF',
      fontWeight: 400,
    },
  },
  closeButton: {
    '&>span': {
      color: 'rgba(0, 0, 0, 0.38)',
      fontWeight: 400,
    },
    marginRight: 10
  },
  content: {
    backgroundColor: '#FFFFFF',
    overflow: 'scroll',
    minHeight: 250,
  },
  contentMedia: {
    padding: '8px 8px 20px',
  },
  singleContent: {
    paddingTop: 20
  },
  superModal: {
    borderRadius: 10,
    maxWidth: 350,
    width: '80%'
  },
  fullSizeSuperModal: {
    borderRadius: 10,
    height: '90vh',
    maxWidth: '85%',
    width: '80%',
  },
  modalContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '100vh',
    position: 'fixed',
    width: '100%',
    zIndex: 9999,
    left: 0,
    top: 0
  },
  headerImage: {
    height: 200,
  },
  headerTitleContainer: {
    left: '50%',
    position: 'absolute',
    top: '10%',
    width: '100%',
  },
  headerTitle: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 700,
    minWidth: 200,
    left: '-50%',
    position: 'relative',
  },
}));
