// vendors
import React from 'react';

const CenterActionEnabled = (
  <>
    <path d="M16.5018 4.38497L10.4822 0L0 7.6407L4.0021 20H16.0413L12.0356 7.6407L16.5018 4.38497Z" fill="#33647E" />
    <path d="M22.5178 0L16.5018 4.38863L20.9645 7.6407L16.9587 20H28.9979L33 7.6407L22.5178 0Z" fill="#FF8189" />
    <path d="M16.5018 4.38498L12.0355 7.64071L16.0413 20H16.9587L20.9644 7.64071L16.5018 4.38498Z" fill="#F4D3D6" />
  </>
);

export default CenterActionEnabled;
