// vendors
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// material ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// components
import Activities from './activities';

// actions
import { getMyActivities } from '../../redux/actions/activity';

// styles
import { useStyles } from './styles';

const NotificationsPage = ({
  activities,
  getMyActivities
}) => {
  const classes = useStyles();

  useEffect(() => {
    getMyActivities();
  }, []);

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12} md={12} className={classes.title}>
        <Typography component="span" variant="h2">
          Actividad
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Activities activities={activities} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ activity }) => ({
  activities: activity.items
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMyActivities
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);
