// vendors
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

// components
import GmAutocomplete from '../../autocomplete';

// json
import professions from '../../../static/data/professions.json';

// styles
import { useStyles } from '../about-me/styles';

export const GmAboutMe1 = ({ formik }) => {
  const classes = useStyles();
  const onProfessionChange = (values) => {
    if (values) {
      formik.setFieldValue('profession', values.name);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <GmAutocomplete
          label="Profesión"
          onOptionChange={onProfessionChange}
          options={professions.sort((a, b) => a.name > b.name ? 1 : -1)}
          style={{ paddingBottom: 20 }}
          value={formik.values.profession}
        />
      </Grid>
      <FormControl style={{ paddingBottom: 20, width: '100%' }}>
        <InputLabel id="stateTypeLabel" className={classes.inpuLabelMaritalStyle}>
          Estado civil
        </InputLabel>
        <Select
          defaultValue={formik.values.maritalStatus}
          error={formik.touched.maritalStatus && Boolean(formik.errors.maritalStatus)}
          id="maritalStatus"
          label="Estado civil"
          fullWidth
          labelId="maritalStatus"
          name="maritalStatus"
          onChange={formik.handleChange}
          style={{ textAlign: 'left' }}
          value={formik.values.maritalStatus}
          variant="outlined"
        >
          <MenuItem value="Casado">Casado/a</MenuItem>
          <MenuItem value="Divorciado">Divorciado</MenuItem>
          <MenuItem value="Otro">Otro</MenuItem>
          <MenuItem value="Soltero">Soltero/a</MenuItem>
          <MenuItem value="Unión libre">Unión libre</MenuItem>
          <MenuItem value="Viudo">Viudo/a</MenuItem>
        </Select>
      </FormControl>
      <Grid item xs={12}>
        <TextField
          error={formik.touched.childCounter && Boolean(formik.errors.childCounter)}
          fullWidth
          id="childCounter"
          label={!formik.values.childCounter ? '¿Cantidad de hijos?' : null}
          name="childCounter"
          onChange={formik.handleChange}
          style={{ paddingBottom: 20 }}
          type="number"
          value={formik.values.childCounter}
          variant="outlined"
        />
      </Grid>
    </>
  );
};

export default GmAboutMe1;
