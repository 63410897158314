// vendors
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';

// constants
import { ROLES } from '../../config/constants';

// styles
import { useStyles } from './styles';

const GmSeekSupport = ({ sendRole }) => {
  const classes = useStyles();
  const handleOnSendRole = () => sendRole(ROLES.godson);

  return (
    <Grid
      className={classes.seekSupport}
      container
      direction="column"
      justify="space-between"
      onClick={handleOnSendRole}
    >
      <Grid className={classes.supportDescription} item>
        <strong className={classes.cardTitle}>Necesito apoyo</strong>
        <Typography className={classes.actionText}>
          Deseo encontrar personas que me acompañen a cumplir mis sueños.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GmSeekSupport;
