// vendors
import React from 'react';
import Button from '@material-ui/core/Button';

const SignPromissoryNote = ({
  classes,
  hasPromissoryNote,
  messageId,
  onSign,
  visible,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Button
      className={classes.signPromissoryNoteButton}
      color="primary"
      disabled={hasPromissoryNote}
      fullWidth
      key={messageId}
      onClick={onSign}
      variant="contained"
    >
      FIRMAR PAGARÉ
    </Button>
  );
};

export default SignPromissoryNote;
