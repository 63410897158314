import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  accountVerifyForm: {
  },
  brandLogo: {
    marginTop: 20,
  },
  errorMessage: {
    color: 'red',
    margin: 0,
  },
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    boxShadow: 'none',
    marginBottom: 10,
  },
  supportTitle: {
    marginBottom: 15,
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Open Sans'
  },
  textFieldInput: {
    width: '100%',
  },
  verifyCodeButton: {
    position: 'relative',
    marginTop: 15,
    '& .MuiButton-label': {
      color: 'white',
      fontWeight: 500,
    },
    padding: '0px 40px',
    lineHeight: 1.3,
    fontSize: 15
  },
  auxiliaryText: {
    fontSize: 15,
    textAlign: 'left',
    marginBottom: 20
  },
  sendCodeButton: {
    position: 'relative',
    marginTop: 15,
    '& .MuiButton-label': {
      color: 'white',
      fontWeight: 500,
    },
    padding: '0px 25px',
    lineHeight: 1.3,
    fontSize: 15
  },
  haveAccount: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 14,
    marginTop: 45,
    marginBottom: 15,
  },
  haveAccountLink: {
    fontWeight: 600,
    cursor: 'pointer',
    color: '#1DCAD3',
    marginLeft: 5,
    textDecoration: 'none',
  },
});

export { useStyles };
