// vendors
import {
  LogLevel,
  HubConnectionBuilder,
  JsonHubProtocol,
} from '@microsoft/signalr';

// actions
import { receiveMessage } from '../actions/createHistory';

// constants
import env from '../../config/env';
import { AUTH_LOGOUT } from '../action-types';
import { MESSAGE_TYPES } from '../../config/constants';

const signalrConnection = (encryptedAccessToken) => {
  const encodedEncryptedAccessToken = encodeURIComponent(encryptedAccessToken);

  const signalrUrl = `${env.realTimeBaseUrl}?enc_auth_token=${encodedEncryptedAccessToken}`;
  const options = {
    logMessageContent: true,
    logger: LogLevel.None,
    // currently sending encoded access token on request
    // accessTokenFactory: () => encryptedAccessToken,
  };

  const connection = new HubConnectionBuilder()
    .withUrl(signalrUrl, options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(LogLevel.None);

  return connection.build();
};

const signalrMiddleware = () => (store) => (next) => (action) => {
  const { socketOn } = store.getState().history;
  const { encryptedAccessToken, userId } = store.getState().auth.userInfo;

  if (action.type === AUTH_LOGOUT && socketOn) {
    const { lSignalrConnection } = store.getState().history;
    lSignalrConnection?.stop();
  }

  if (action.type === 'SOCKET_CONNECTION_SUCCESS' || socketOn || !encryptedAccessToken || !userId) {
    return next(action);
  }

  const socket = signalrConnection(encryptedAccessToken);

  socket.start().then(() => {
    socket?.on('getNotification', (res) => {
      const {
        data,
        id: messageId,
        messageThreadId,
        messageType,
        postByUserId
      } = res.notification.data;

      const isWorkingOnChatPage = window.location.pathname.match(new RegExp('/?[a-z]*-?chat-?[a-z]*/[0-9]*/?[0-9]*')) || false;
      const isCurrentMessageDoneByCurrentUser = postByUserId ? userId.toString() === postByUserId.toString() : false;

      if ((isWorkingOnChatPage && messageType !== MESSAGE_TYPES.message) || (isWorkingOnChatPage && !isCurrentMessageDoneByCurrentUser)) {
        socket.invoke('MarkAsReadMessage', { messageId });
      }

      store.dispatch(receiveMessage({
        content: data,
        messageId,
        messageThreadId,
        messageType,
        postByUserId,
        userId,
      }));
    });
  });

  store.dispatch({
    type: 'SOCKET_CONNECTION_SUCCESS',
    payload: { socket },
  });

  return next(action);
};

export default signalrMiddleware();
