// vendors
import React, {
  memo,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// actions
import { setChatMessage } from '../../redux/actions/createHistory';
import { clearUnreadMessages } from '../../redux/actions/chat';

// components
import GmMessageActionBar from './message-action-bar';

// styles
import MessagesHistoryContainer from './card-actions';

// constants
const NEW_LINE_0 = '\r';
const NEW_LINE_1 = '\n';
const NEW_LINE_2 = '\r\n';
const UNALLOWED_KEYS = ['', ' ', NEW_LINE_0, NEW_LINE_1, NEW_LINE_2];

const ChatContainer = memo(({
  isReferral = false,
  onLastPaymentConfirmation,
  onLoanConfirmation,
  onOpenLoanCalculator,
  onPaymentConfirmation,
  setShowAUCOSdkIframe,
  showAUCOSdkIframe,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');

  const { history: { chatsHistory, messages } } = useSelector((state) => state);

  useEffect(() => {
    dispatch(clearUnreadMessages({ unreadMessage: params.id }));
  }, [params.id]);

  const handleOnSendMessage = () => {
    if (!message) {
      return;
    }

    setMessage('');
    dispatch(setChatMessage({
      content: message,
      messageThreadId: chatsHistory.threadId,
    }));
  };

  const handleOnSetMessage = (event) => {
    if (!message.length && UNALLOWED_KEYS.includes(event.target.value)) {
      return;
    }

    setMessage(event.target.value);
  };

  const handleOnKeyPress = ({ key }) => {
    if (key === 'Enter' && !UNALLOWED_KEYS.includes(message)) {
      handleOnSendMessage();
    }
  };

  return (
    <>
      <MessagesHistoryContainer
        isReferral={isReferral}
        onLastPaymentConfirmation={onLastPaymentConfirmation}
        onLoanConfirmation={onLoanConfirmation}
        onPaymentConfirmation={onPaymentConfirmation}
        setShowAUCOSdkIframe={setShowAUCOSdkIframe}
        showAUCOSdkIframe={showAUCOSdkIframe}
      />
      <GmMessageActionBar
        isReferral={isReferral}
        message={message}
        onKeyPress={handleOnKeyPress}
        onSetMessage={handleOnSetMessage}
        onOpenLoanCalculator={onOpenLoanCalculator}
        onSendMessage={handleOnSendMessage}
        visible={messages}
      />
    </>
  );
});

export default ChatContainer;
