// vendors
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';

// components
import LastPaymentConfirmation from './last-payment-confirmation';

// constants
import { userIsGodson, MESSAGE_TYPES, PAYMENT_TYPES } from '../../config/constants';

const PaymentConfirmation = ({
  auth,
  classes,
  confirmButtonVisible,
  eventMessage,
  histories,
  isConfirmed = false,
  messageId,
  messageType,
  onAddStory,
  onGoToStories,
  onPaymentConfirmation,
  onRefer,
  renderDetails,
}) => {
  const isGodson = userIsGodson(auth.userInfo.role);

  if (eventMessage.IsLastPayment) {
    return (
      <LastPaymentConfirmation
        auth={auth}
        classes={classes}
        eventMessage={eventMessage}
        histories={histories}
        isConfirmed={isConfirmed}
        messageId={messageId}
        onAddStory={onAddStory}
        onGoToStories={onGoToStories}
        onRefer={onRefer}
      />
    );
  }

  const getPaymentContainerClass = () => {
    switch (eventMessage?.PaymentType) {
      case PAYMENT_TYPES.loan:
        return isConfirmed ? classes.paymentRedContainer : classes.paymentBlueContainer;
      case PAYMENT_TYPES.loanPayment:
        return isConfirmed ? classes.paymentBlueContainer : classes.paymentRedContainer;
      default:
        return classes.paymentRedContainer;
    }
  };

  return (
    <Paper className={classes.paper} elevation={0} key={messageId}>
      <Grid
        container
        justify="center"
        className={getPaymentContainerClass()}
        spacing={0}
      >
        {isConfirmed ? (
          <Typography className={classes.paymentConfirmed} component="div">
            {`Pago recibido y aceptado ${eventMessage?.User?.FullName ? `por ${eventMessage?.User?.FullName}` : ''}`}
          </Typography>
        ) : (
          <>
            <Grid item className={classes.proposalAvatar}>
              <Avatar
                className={classes.proposalAvatarImg}
                alt="gm-user-avatar"
                src={eventMessage.User.ProfilePicture}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.proposalName} component="div">
                {eventMessage.User.FullName}
              </Typography>
              <Typography className={classes.proposalName} component="div">
                {messageType === MESSAGE_TYPES.lastPaymentConfirmation
                  ? 'te ha hecho el último pago.'
                  : 'está retribuyendo tu apoyo y ha realizado un pago'}
              </Typography>
            </Grid>
          </>
        )}
        {renderDetails({
          classes,
          date: eventMessage.Date,
          debtToDate: eventMessage?.DebtToDate,
          isConfirmed,
          isGodson,
          messageType,
          paymentType: eventMessage?.PaymentType,
          value: eventMessage?.Value,
        })}
        <Grid item xs={12}>
          {confirmButtonVisible
            ? (
              <Button
                className={eventMessage?.PaymentType === PAYMENT_TYPES.loan ? classes.confirmButtonBlue : classes.confirmButtonRed}
                onClick={() => onPaymentConfirmation({ ...eventMessage, messageId })}
                variant="contained"
                disableElevation
              >
                CONFIRMAR
              </Button>
            )
            : null}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PaymentConfirmation;
