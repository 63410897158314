export const STORY_STATUS = {
  created: 0,
  finalized: 5,
  funded: 4,
  partiallyFunded: 3,
  published: 1,
};

export const PROPOSAL_STATUS = {
  edited: 1,
  deleted: 0,
  disabled: 3,
  enabled: 2,
};
