// packages
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// styles
const useStyles = makeStyles(() => ({
  gmAlertMessageContainer: {
    width: '100%',
  },
}));

export const GmAlertMessage = ({
  toggleVisibility,
  severity = 'error',
  text = '',
  children,
  style,
  variant = 'standard',
  visible = true,
}) => {
  const classes = useStyles();
  const [lVisible, setLVisible] = useState(visible);

  const handleOnToggleAlertVisibility = () => {
    if (!toggleVisibility) {
      setLVisible(lVisible);
      return;
    }

    toggleVisibility(!lVisible);
  };

  if (!visible) {
    return null;
  }

  return (
    <Alert
      action={(toggleVisibility
        ? (
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={handleOnToggleAlertVisibility}
            size="small"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )
        : null
      )}
      className={classes.gmAlertMessageContainer}
      severity={severity}
      style={style}
      variant={variant}
    >
      {text || children}
    </Alert>
  );
};

export default GmAlertMessage;
