// vendors
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';

// constants
import { ROLES } from '../../config/constants';

// styles
import { useStyles } from './styles';

const GmGiveSupport = ({ sendRole }) => {
  const classes = useStyles();
  const handleOnSendRole = () => sendRole(ROLES.godfather);

  return (
    <Grid
      className={classes.giveSupport}
      container
      direction="column"
      justify="space-between"
      onClick={handleOnSendRole}
    >
      <Grid className={classes.supportDescription} item>
        <strong className={classes.cardTitle}>Quiero apoyar</strong>
        <Typography className={classes.actionText}>
          Me encanta ayudar. Quiero conocer historias de gente increíble a la cual apoyar.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GmGiveSupport;
