/* eslint-disable max-len */

// vendors
import React from 'react';

const ReferringEnabled = (
  <>
    <path d="M6.78 14H0V31.45H6.78V14Z" fill="#F93549" />
    <path d="M63.61 0H56.83V45.45H63.61V0Z" fill="#F93549" />
    <path d="M56.83 45.45L6.78003 31.45V14L56.83 0V45.45Z" fill="#FFC600" />
    <path d="M81.27 21.8H71.65H71.15V22.3V24.22V24.72H71.65H81.27H81.77V24.22V22.3V21.8H81.27Z" fill="#F93549" />
    <path d="M72.65 12.5L73.01 12.85L73.36 12.5L80.1 5.75997L80.45 5.40997L80.1 5.05997L78.74 3.69997L78.38 3.33997L78.03 3.69997L71.29 10.43L70.94 10.79L71.29 11.14L72.65 12.5Z" fill="#F93549" />
    <path d="M73.36 34.02L73.01 33.67L72.65 34.02L71.29 35.38L70.94 35.74L71.29 36.09L78.03 42.83L78.38 43.18L78.74 42.83L80.1 41.47L80.45 41.11L80.1 40.76L73.36 34.02Z" fill="#F93549" />
  </>

);

export default ReferringEnabled;
