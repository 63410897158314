// vendors
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';

// components
import GmSvgIcon from '../../static/svg';

// constants
export const ICON_COLOR_MAP = {
  0: '#D7384A',
  1: '#EA8088',
  2: '#F6C500',
  3: '#74C8D2',
  4: '#47647C',
};

const GmKarmaReview = ({
  className,
  iconStyles = {},
  maxReviewCount = 5,
  onReview = Function.prototype,
  readOnly = false,
  value = undefined,
  visible = true
}) => {
  const [reviewItems, setReviewItems] = useState([]);

  useEffect(() => {
    const defaultReviewItems = Array.from(Array(maxReviewCount).keys()).map((value) =>
      ({ disabled: true, enabled: false, value: value + 1 }));

    setReviewItems(defaultReviewItems);

    if (value || value === 0) {
      const newReviewItems = defaultReviewItems.map((reviewItemValue) => {
        if (Math.round(value) >= reviewItemValue.value) {
          return {
            ...reviewItemValue,
            disabled: false,
            enabled: true,
          };
        }

        return {
          ...reviewItemValue,
          disabled: true,
          enabled: false,
        };
      });
      setReviewItems(newReviewItems);
    }
  }, [value]);

  if (!visible) {
    return null;
  }

  const handleOnReview = ({ value }) => {
    const updatedReviewItems = reviewItems.map((reviewItemValue) => {
      if (reviewItemValue.value <= value) {
        return {
          ...reviewItemValue,
          disabled: false,
          enabled: true,
        };
      }

      return {
        ...reviewItemValue,
        disabled: true,
        enabled: false,
      };
    });

    if (!readOnly) {
      setReviewItems(updatedReviewItems);
      onReview(value + 1);
    }
  };

  return (
    <Grid
      className={className}
      container
      direction="row"
    >
      {reviewItems.map((reviewItem, index) => (
        <GmSvgIcon
          disabled={reviewItem.disabled}
          enabled={reviewItem.enabled}
          fill={ICON_COLOR_MAP[index]}
          icon="pentagon-review"
          key={reviewItem.value}
          onClick={readOnly ? Function.prototype : () => handleOnReview(reviewItem)}
          style={{ ...iconStyles }}
        />
      ))}
    </Grid>
  );
};

export default GmKarmaReview;
