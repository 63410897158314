// vendors
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Link from '@material-ui/core/Link';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import React from 'react';
import Typography from '@material-ui/core/Typography';

// utils
import { getDownloadComponent } from '../../utils';

// styles
import { useStyles } from './styles';

const PaymentDetailDocuments = ({
  allDocumentsVisible,
  documents,
  onDocumentsHeaderClick,
  title,
  visible,
}) => {
  const classes = useStyles();

  if (!visible) {
    return null;
  }

  const fullHeader = (
    <Grid container justify="space-evenly">
      <Grid item xs={4}>
        <ArrowBackIcon
          className={classes.arrowBackIcon}
          onClick={() => onDocumentsHeaderClick(false)}
        />
      </Grid>
      <Grid className={classes.titleContainer} item xs={4}>
        <Typography className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );

  const summaryHeader = (
    <Grid container justify="space-evenly">
      <Grid item xs={10}>
        <Typography className={classes.subTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <ArrowForwardIcon
          className={classes.arrowForwardIcon}
          onClick={() => onDocumentsHeaderClick(true)}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      {!allDocumentsVisible
        ? summaryHeader
        : fullHeader}
      <GridList
        cellHeight={!allDocumentsVisible
          ? 75
          : 125}
        className={classes.documentsList}
        cols={4}
      >
        {documents.map((doc, index) => (
          <GridListTile key={doc} cols={1}>
            {doc.substring(doc.lastIndexOf('/') + 1).includes('pdf') ? (
              <Grid className={classes.docContainer} container component={Link} href={doc}>
                <Grid item xs={12}>
                  <PictureAsPdfIcon id="doc-icon" fontSize="small" />
                </Grid>
                <Grid item xs={12}>
                  <label className={classes.documentText} htmlFor="doc-icon">{index}</label>
                </Grid>
              </Grid>
            ) : (
              <Link
                component="button"
                onClick={() => getDownloadComponent(doc)}
              >
                <img src={doc} alt={doc} />
              </Link>
            )}
          </GridListTile>
        ))}
      </GridList>
    </>
  );
};

export default PaymentDetailDocuments;
